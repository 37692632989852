<template>
  <div>
    <div>
      <div
        :style="getElementStyle"
        v-if="isFromDocument ? '' : data.properties.filed_content != 'Hide'"
      >
        <div
          v-if="
            isFromDocument
              ? ''
              : !data.properties.hideLabel &&
                data.properties.filed_content != 'Hide'
          "
          :style="getLabelStyles"
          :class="{ 'highlight-border': highlight }"
        >
          <icons
            v-if="isIconRequired"
            :iconName="data.icon_name"
            class="mr-1"
          ></icons>
          <!-- {{ data.properties }} -->
          {{ label || data.label }}
          <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </div>
        <div
          v-if="
            data.validations.multiple &&
            data.input_type != 'ENTITY_VARIABLE' &&
            !checkReadonly()
          "
        >
          <div>
            <div v-if="data.folder" @click="openGallery">
              <div
                v-for="(folder, index) in data.folder"
                :key="index"
                class="folder-container"
              >
                <img
                  :src="folder.thumbnail"
                  :style="getStyle"
                  alt="folder thumbnail"
                  :class="{ 'focus-border-input': highlight }"
                />
                <div
                  class="folder-count"
                  v-if="folder.images && folder.images.length > 0"
                >
                  <span><i class="fa fa-eye"></i></span>
                  <span v-if="folder.images.length > 1">
                    +{{ folder.images.length - 1 }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else>
              <img
                v-if="
                  data.filled_by === 'SENDER_OR_RECEIVER' &&
                  !this.imgData &&
                  !form[data.key] &&
                  !getLogoUrl
                "
                src="../../../../src/assets/img/default_img.jpg"
                alt="IMG"
                :style="getStyle"
                :disabled="
                  isFromDocument ? false : checkReadonly() || readonly()
                "
              />
            </div>
            <label v-if="!isView" class="custom-file-upload">
              <input
                type="file"
                @change="handleFileUpload"
                accept="image/*"
                multiple
                :disabled="
                  isFromDocument ? false : checkReadonly() || readonly()
                "
              />
              Upload
            </label>
            <GalleryModal
              v-if="showGallery"
              :images="data.folder.length > 0 ? data.folder[0].images : []"
              :isView="isView"
              @close="showGallery = false"
              @imagesCleared="resetThumbnail"
              @thumbnailUpdated="updateThumbnail"
              @updateImages="updateReorderedImages"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="!isList && data.properties.filed_content != 'Hide'">
            <img
              v-if="form[data.key]"
              :src="form[data.key]"
              alt="IMG"
              :style="getStyle"
              :class="{ 'focus-border-input': highlight }"
            />
            <img
              v-else-if="getLogoUrl"
              :src="getLogoUrl"
              alt="IMG"
              :style="getStyle"
              :class="{ 'focus-border-input': highlight }"
            />
            <div v-if="isView">
              <p v-if="!form[data.key] && !getLogoUrl">-</p>
            </div>
            <div v-else>
              <img
                v-if="
                  isFromDocument
                    ? this.filledByFromDocument[0] === 'RECEIVER'
                    : data.filled_by === 'RECEIVER' &&
                      !this.imgData &&
                      !form[data.key] &&
                      !getLogoUrl
                "
                src="../../../../src/assets/img/default_img.jpg"
                alt="IMG"
                :style="getStyle"
                :disabled="isDisable"
              />
              <el-button
                v-if="
                  (isFromDocument
                    ? this.filledByFromDocument[0] === 'RECEIVER'
                    : data.filled_by === 'RECEIVER') &&
                  !fromEntityViews &&
                  data.input_type == 'IMAGE'
                "
                size="small"
                type="primary"
                @click="outerVisible = true"
                :disabled="
                  isFromDocument ? false : checkReadonly() || readonly()
                "
                >Upload</el-button
              >
              <el-upload
                v-if="
                  data.input_type == 'GIF' &&
                  data.filled_by === 'RECEIVER' &&
                  !fromEntityViews
                "
                :on-change="uploadGifFile"
                class="d-flex"
                action
                :show-file-list="false"
                :auto-upload="false"
                accept=".png, .jpg, .jpeg, .gif"
              >
                <el-button
                  v-if="
                    data.filled_by === 'RECEIVER' &&
                    !fromEntityViews &&
                    data.input_type == 'GIF'
                  "
                  size="small"
                  type="primary"
                  :disabled="
                    isFromDocument ? false : checkReadonly() || readonly()
                  "
                >
                  Upload
                </el-button>
              </el-upload>
              <img
                v-if="
                  data.filled_by === 'SENDER_OR_RECEIVER' &&
                  !this.imgData &&
                  !form[data.key] &&
                  !getLogoUrl
                "
                src="../../../../src/assets/img/default_img.jpg"
                alt="IMG"
                :style="getStyle"
                :disabled="
                  isFromDocument ? false : checkReadonly() || readonly()
                "
              />

              <el-button
                v-if="
                  data.filled_by === 'SENDER_OR_RECEIVER' &&
                  !fromEntityViews &&
                  data.input_type == 'IMAGE'
                "
                size="small"
                type="primary"
                @click="outerVisible = true"
                :disabled="
                  isFromDocument ? false : checkReadonly() || readonly()
                "
              >
                Upload
              </el-button>
              <el-upload
                v-if="
                  data.input_type == 'GIF' &&
                  data.filled_by === 'SENDER_OR_RECEIVER' &&
                  !fromEntityViews
                "
                :on-change="uploadGifFile"
                action
                :show-file-list="false"
                :auto-upload="false"
                accept=".gif"
              >
                <el-button
                  v-if="
                    data.filled_by === 'SENDER_OR_RECEIVER' && !fromEntityViews
                  "
                  size="small"
                  type="primary"
                  :disabled="
                    isFromDocument ? false : checkReadonly() || readonly()
                  "
                >
                  Upload
                </el-button>
              </el-upload>
              <span
                v-if="
                  form[data.key] &&
                  form[data.key] !== defaultImage &&
                  !checkReadonly()
                "
              >
                <el-button
                  style="
                    background-color: ivory;
                    max-width: 27px;
                    margin-left: 5px;
                  "
                  type="plain"
                  size="mini"
                  circle
                  @click="deleteImage()"
                >
                  <img width="12px" src="@/assets/Trash.svg" />
                </el-button>
              </span>
              <!-- <el-button size="small" type="danger" v-if="form[data.key]"  @click="deleteImage"  >Delete</el-button> -->
            </div>
          </div>
        </div>

        <div v-if="isList && data.properties.filed_content != 'Hide'">
          <img
            :src="getLogoUrlGlobal || require('@/assets/img/default_img.jpg')"
            alt="IMG"
            :style="getStyle"
          />
        </div>
      </div>
    </div>
    <div v-if="isFromDocument">
      <img
        v-if="form[data.key]"
        :src="form[data.key] || require('@/assets/img/default_img.jpg')"
        alt="IMG"
        :style="getStyle"
      />
      <img
        v-else
        src="../../../../src/assets/img/default_img.jpg"
        alt="IMG"
        :style="getStyle"
      />
      <el-button
        v-if="
          (isFromDocument
            ? this.filledByFromDocument[0] === 'RECEIVER'
            : data.filled_by === 'RECEIVER') && !fromEntityViews
        "
        size="small"
        type="primary"
        :disabled="isDisable"
        @click="outerVisible = true"
        >Upload</el-button
      >
    </div>
    <!-- <el-dialog title="Update Picture" :visible.sync="outerVisible" :width="getIsMobile ? '100%' : '30%'">
              <vue-anka-cropper
                class="upload-file"
                :options="{
                  aspectRatio: false,
                  closeOnSave: true,
                  cropArea: 'box',
                  croppedHeight: 1000,
                  croppedWidth: 1000,
                  cropperHeight: false,
                  dropareaMessage:
                    ' Drag & Drop  file here or use the button below.',
                  frameLineDash: [1, 1000],
                  frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                  handleFillColor: 'rgba(255, 255, 255, 0.2)',
                  handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                  handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                  handleSize: 5,
                  handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                  layoutBreakpoint: 40,
                  maxCropperHeight: 1000,
                  maxFileSize: 8000000,
                  overlayFill: 'rgba(0, 0, 0, 0.5)',
                  previewOnDrag: true,
                  previewQuality: 0.65,
                  resultQuality: 0.8,
                  resultMimeType: 'image/jpeg',
                  resultMimeType: 'image/png',
                  resultMimeType: 'image/jpg',
                  resultMimeType: 'image/gif',
                  selectButtonLabel:
                    'Select File Supported : JPG, PNG, JPEG, GIF',
 
                  showPreview: true,
                  skin: 'light',
                  uploadData: {},
                  uploadTo: false,
                }"
                name="logo"
                @cropper-error="fileError"
                @cropper-file-selected="insertImage"
                @cropper-preview="setImageData"
                @cropper-saved="setImageData"
                v-loading="upLoadEffect"
              ></vue-anka-cropper>
              <span slot="footer" class="dialog-footer">
                <el-button
                  @click="
                    outerVisible = false;
                    resetImgData();
                  "
                  >Cancel</el-button
                >
                <el-button type="primary" @click="closeImage"
                  >Save</el-button
                >
              </span>
      </el-dialog> -->

    <dialog-component
      :title="'Update picture'"
      :visible="outerVisible"
      :width="getIsMobile ? '100%' : '100%'"
      :height="getIsMobile ? '100%' : '100%'"
      @before-close="outerVisible = false"
      :style="{ top: getIsMobile ? '0' : '10vh' }"
    >
      <vue-anka-cropper
        class="upload-file mt-1 mb-1"
        :options="{
          aspectRatio: false,
          closeOnSave: true,
          cropArea: 'box',
          croppedHeight: 1000,
          croppedWidth: 1000,
          cropperHeight: 400,
          dropareaMessage: ' Drag & Drop  file here or use the button below.',
          frameLineDash: [1, 1000],
          frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
          handleFillColor: 'rgba(255, 255, 255, 0.2)',
          handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
          handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
          handleSize: 5,
          handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
          layoutBreakpoint: 40,
          maxCropperHeight: 1000,
          maxFileSize: 8000000,
          overlayFill: 'rgba(0, 0, 0, 0.5)',
          previewOnDrag: true,
          previewQuality: 0.65,
          resultQuality: 0.8,
          resultMimeType: 'image/jpeg',
          resultMimeType: 'image/png',
          resultMimeType: 'image/jpg',
          resultMimeType: 'image/gif',
          selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

          showPreview: true,
          skin: 'light',
          uploadData: {},
          uploadTo: false,
        }"
        name="logo"
        @cropper-error="fileError"
        @cropper-file-selected="insertImage"
        @cropper-preview="setImageData"
        @cropper-saved="setImageData"
        v-loading="upLoadEffect"
        accept="image/jpeg,image/png,image/jpg,image/gif"
      ></vue-anka-cropper>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            outerVisible = false;
            resetImgData();
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="closeImage">Save</el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
// import appConfig from "@/config/app";
import { mapGetters } from "vuex";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EntitiesHelper from "../../../mixins/EntitiesHelper";
import GalleryModal from "../formComponentsExecute/GalleryModel.vue";
import { postAPICall } from "../../../helpers/httpHelper";
// import axios from "@/config/axios";
export default {
  name: "templates-formComponentsExecute-ImageExecute",
  mixins: [userPermissionsHelper, EntitiesHelper],
  components: { vueAnkaCropper, GalleryModal },
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "isDisable",
    "checkIsDisabled",
    "checkhideFields",
    "highlight",
    "isInDataTable",
    "fromEntityViews",
    "isIconRequired",
  ],
  data() {
    return {
      defaultImage: require("@/assets/img/default_img.jpg"),
      fileUrl: "",
      isList: false,
      validations: [],
      outerVisible: false,
      imgData: "",
      image_url: "",
      fileUploading: false,
      previewImage: "",
      profileData: null,
      upLoadEffect: false,
      buttondisale: true,
      imageUrlData: "",
      filledByFromDocument: "",
      fileData: null,
      url2Base64: "",
      folder: null,
      showGallery: false,
      currentFolderImages: [],
      previousValue:[],
    };
  },
  async mounted() {
    if (this.data && !this.data.key) {
      this.data.key = await this.generateKeyForField(this.data.label);
    }
    this.data.url = this.data.image_url;
    if (this.data.image_url != null) {
      this.url2Base64 = await this.getImgUrlToBase64(this.data.image_url);
    }
    // this.data.image_url = this.imageUrlData

    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    if (this.isFromDocument) {
      let delimiter = "_";
      this.filledByFromDocument = this.data.filled_by.split(delimiter);
    }
    this.previousValue = this.form[this.data.key]
    if (this.form[this.data.key]) {
      const images = this.form[this.data.key];
      this.data.folder = [
        {
          images,
          thumbnail: images[0] || require("@/assets/img/default_img.jpg"),
        },
      ];
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("companyTemplates", ["getImageUploadData"]),
    ...mapGetters("templatesData", ["getDownloadUrl"]),
    ...mapGetters("auth", ["getAuthenticationDetails"]),
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    getLogoUrl() {
      return this.url2Base64;
    },
    getStyle() {
      if (this.getIsMobile) {
        return `height:${this.data.height}px`;
      }
      return `width: ${this.data.width}px;height:${this.data.height}px`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";
        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += "font-size:" + font_size + "px;";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";

        borderStyle += "font-size:" + font_size + "px;";
      }
      return borderStyle;
    },
  },
  methods: {
    async updateReorderedImages(images) {
      try {
        if (Array.isArray(this.data.folder) && this.data.folder.length > 0) {
          this.data.folder[0].images = images;
          this.form[this.data.key] = images;
          const reorderedFiles = await Promise.all(
            images.map(async (image, index) => {
              if (image instanceof File) {
                return image;
              }
              const file = await this.convertUrlToFile(image, index);
              return file;
            })
          );
          if (reorderedFiles.length > 0) {
            const thumbnailUrl = URL.createObjectURL(reorderedFiles[0]);
            this.updateThumbnail(thumbnailUrl);

            await this.handleAutofillFields(reorderedFiles);
          }
        }
      } catch (error) {
        console.error("Error in updateReorderedImages:", error);
      }
    },
    async convertUrlToFile(url, index) {
      const response = await fetch(url);
      const blob = await response.blob();
      const originalName =
        decodeURIComponent(url.split("/").pop()) || `image_${index + 1}.jpg`;

      return new File([blob], originalName, { type: blob.type });
    },
    updateThumbnail(newThumbnail) {
      if (this.data.folder?.length) {
        this.data.folder[0].thumbnail =
          newThumbnail || require("@/assets/img/default_img.jpg");
      }
    },
    resetThumbnail() {
      if (this.data.folder?.length) {
        this.data.folder[0].thumbnail = require("@/assets/img/default_img.jpg");
        this.$set(this.data.folder[0], "images", []);
        this.form[this.data.key] = [];
      }
    },
    async handleFileUpload(event) {
      const files = Array.from(event.target.files);

      const minFiles = this.data.validations.min_image_files;
      const maxFiles = this.data.validations.max_image_files;
      const currentFileCount = this.data.folder?.[0]?.images?.length || 0;
      const newFileCount = files.length;
      const totalFileCount = currentFileCount + newFileCount;

      if (totalFileCount < minFiles || totalFileCount > maxFiles) {
        this.handleExceed(files);
        event.target.value = "";
        return;
      }
      await this.handleAutofillFields(files);
    },
    async handleAutofillFields(files) {
      this.$emit("loadComponent", {
        value: true,
        loadingText: "Extracting data from the file...",
      });
      var formData = new FormData();
      files.forEach((el) => {
        formData.append("files", el !== undefined ? el : el);
      });
      let response = await postAPICall(
        "PUT",
        `/upload/file-Mul_upload-s3`,
        formData
      );
      this.form[this.data.key] = response.data.map((obj) => obj.Location);
      if (this.form[this.data.key]?.length) {
        const images = this.form[this.data.key];
        const thumbnail = images[0];
        this.data.folder = [{ images, thumbnail }];
      }
    },
    handleExceed(files) {
      this.$message.error(
        `You have exceeded the file selection limit.The allowed limit is ${
          this.data.validations.min_image_files
        } - ${
          this.data.validations.max_image_files
        } files, but you have selected ${
          files.length
        } files. Please reduce your selection to ${
          files.length - this.data.validations.max_image_files
        } files or fewer to proceed`
      );
    },
    openGallery() {
      if (
        this.data.folder.length > 0 &&
        this.data.folder[0].images?.length > 0
      ) {
        this.showGallery = true;
      }
    },
    deleteImage() {
      const defaultImage = require("@/assets/img/default_img.jpg");
      this.$set(this.form, this.data.key, defaultImage);
      this.data.image_url = defaultImage;
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    generateKeyForField(label) {
      let parsedLabel = label.toLowerCase().replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");

      return parsedLabel;
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    setImageData(file) {
      this.fileData = null;
      this.fileData = file;
    },
    insertImage(file) {
      // this.fileData = file;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.fileData = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    closeImage() {
      this.outerVisible = false;
      this.uploadFile(this.fileData);
    },
    uploadFile(file) {
      this.imgData = file;
      this.profileData = file;
      var files = this.dataURLtoFile(file, "image1.jpeg");
      this.uploadImageFile(files);
      setTimeout(() => {
        const doc = document.querySelector(".ankaCropper__previewArea");
        this.imgData = doc.querySelector("img").src;
      }, 1000);
    },
    async uploadImageFile(file) {
      this.loading = true;
      if (
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/jpg"
      ) {
        var formData = new FormData();
        formData.append("files", file !== undefined ? file : file);
        await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);
        if (this.getFileUploadStatus && this.getFileUploadData) {
          this.loading = false;
          this.$set(this.data, "image_url", `${this.getFileUploadData}`);
          // this.$set(this.data, "image_url", `${appConfig.S3_BUCKET_URL}/${this.getFileUploadData}`);
          if (this.isFromDocument) {
            this.$set(this.data, "image_url", `${this.getFileUploadData}`);
            // this.$set(this.data, "value", `${appConfig.S3_BUCKET_URL}/${this.getFileUploadData}`);
          }
          this.$set(this.form, this.data.key, this.data.image_url);
          this.applyFormRules();
          // this.imageUrlData = this.getFileUploadData
          // this.data.image_url = this.imageUrlData
          this.form[this.data.key] = this.data.image_url;
          await this.getBase64(file).then((res) => {
            var img = document.createElement("img");
            img.src = res;
            img.onload = function () {
              img.remove();
            };
          });
        } else {
          this.loading = false;
          this.$alert("Error while uploading file");
        }
      } else {
        this.loading = false;
        this.$alert("Please upload a image file");
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    handleLogoSuccess(res, file) {
      console.log(file);

      this.fileUrl = res.Location;
      this.imageWidth = 100 / this.fileUrl;
      (this.data.image_url = this.fileUrl), (this.loading = false);
      this.$notify.success({
        title: "success",
        message: "Updated Successfully",
      });
    },
    async beforeLogoUpload(file) {
      this.loading = true;
      let isValidMime = true;
      isValidMime = file.size / 1024 / 1024 < 2;
      if (!isValidMime) {
        this.$message.error("picture size can not exceed 2MB!");
      }
      this.loading = false;
      return isValidMime;
    },

    handleLogoError(res) {
      res = JSON.parse(res.message);
      console.log("res", res);
      if (!res.success) {
        this.fileUploadError = res.message;
      }
      this.loading = false;
    },

    linkSubmit() {
      this.loading = true;
      this.data.image_url = this.image_url;
      console.log("this.field.image_url", this.data.image_url);
      this.loading = false;
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById.input_type === "IMAGE") {
        console.log("input_type", this.getGlobalVariableById);
        this.isList = true;

        this.getLogoUrlGlobal = this.getGlobalVariableById.image_url;
        if (this.getGlobalVariableById.image_url) {
          var string = this.data.label;
          this.data.key = string.replace(/ /g, "_");

          this.form[this.data.key] = this.getGlobalVariableById.image_url;
        }
      }
    },
    readonly() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    printvalue() {
      console.log("THIS : ", this.data);
    },
    async uploadGifFile(file) {
      this.loading = true;
      if (file["size"] < 2111775) {
        if (
          file.raw.type == "image/jpeg" ||
          file.raw.type == "image/png" ||
          file.raw.type == "image/jpg" ||
          file.raw.type == "image/gif"
        ) {
          var formData = new FormData();
          formData.append("files", file.raw !== undefined ? file.raw : file);

          await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);
          if (this.getFileUploadStatus && this.getFileUploadData) {
            this.loading = false;
            this.$set(this.data, "image_url", this.getFileUploadData);
            // this.field.image_url = ;
            this.$set(this.form, this.data.key, this.data.image_url);
            this.form[this.data.key] = this.data.image_url;
            await this.getBase64(file.raw).then((res) => {
              // get width, height of image
              var img = document.createElement("img");
              img.src = res;
              img.onload = function () {
                img.remove();
              };
            });
          } else {
            this.loading = false;
            this.$alert("Error while uploading file");
          }
          // self.imgDataWidth = 0;
          // self.imgDataHeight = 0;
        } else {
          this.loading = false;
          this.$alert("Please upload a image file");
        }
      } else {
        this.loading = false;
        this.$alert("File size must not exceed 2MB");
      }
    },
    async setNewImage() {
      this.data.url = this.data.image_url;
      if (this.data.image_url != null) {
        this.url2Base64 = await this.getImgUrlToBase64(this.data.image_url);
      }
    },
    setImagesData(){
      if (this.form[this.data.key]) {
      const thumbnail = this.form[this.data.key][0];
      this.folder = { images: this.form[this.data.key], thumbnail };
    }
    }
  },
  watch: {
    "data.image_url": {
      async handler() {
        this.setNewImage();
      },
      deep: true,
    },
    form : {
      async handler(newVal) {
        if(this.previousValue != newVal[this.data.key]) {
          this.setImagesData();
          this.previousValue = newVal[this.data.key];
        }
      },
      deep: true,
      immediate:true,
    },
  },
};
</script>
<style lang="scss">
.highlight-border {
  color: red !important;
}
.ankaCropper__navigation a:nth-child(4n + 4) {
  display: none !important;
}
.ankaCropper__navigation a:nth-child(5n + 5) {
  display: none !important;
}
.ankaCropper__navigation a:nth-child(7n + 7) {
  display: none !important;
}
.ankaCropper__selectButton {
  font-size: 12px;
}
.cropper {
  height: 600px;
  width: 600px;
  background: #ddd;
}
.folder {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  text-align: center;
}
.folder img {
  width: 2px;
  height: 150px;
  object-fit: cover;
}
.custom-file-upload {
  padding: 9px 15px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.custom-file-upload:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.custom-file-upload input[type="file"] {
  display: none;
}
.folder-container {
  position: relative;
  display: inline-block;
}

.folder-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
