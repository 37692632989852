<template>
  <div>

    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" class="field-required"/>
        </el-col>
        <el-col :span="10">
        <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
          </el-col>

      </el-row>
      <signature-pad v-if="field.filled_by == 'SENDER'" :field="field"></signature-pad>
    </el-form>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import SignaturePad from "./SignaturePad";
export default {
  name: "templates-formComponents-ESignature",
  components: {
    "TitleAndDescription":()=>import("./TitleAndDescription"),
    "IsFieldRequired":()=>import("./IsFieldRequired"),
    "FieldFilledBy":()=>import("./FieldFilledBy"),
    "SignaturePad":()=>import("./SignaturePad")
  },
  props: ["field","form"],
  data() {
    return {
      iconPickerdialogVisible: false,
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1"
        },
        {
          title: "Folder 2",
          _id: "2"
        },
        {
          title: "Folder 3",
          _id: "3"
        }
      ]
    };
  },
  methods:{
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
  }
};
</script>

<style lang="scss">
</style>