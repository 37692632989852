<template>
    <div class="modal" v-loading="phoneCallModelLoading">
    
        <div class="modal-content">
    
            <div class="modal-header">
    
                <h3>Select an Action</h3>
    
                <button class="close-btn" @click="closeModal">&times;</button>
    
            </div>
    
            <div class="modal-body">
    
                <div class="input-group">
    
                    <input type="text" placeholder="Enter phone number" id="phoneNumberInput" v-model="phoneNumber" name="search" class="phone-number">
    
                    <button type="submit" @click="deleteLastCharacter" class="back">
    
                <!-- <i class="fa fa-search"></i> -->
    
                ←
    
              </button>
    
                    <button type="submit" @click="toggleKeypad" class="dialer-pad-info">
    
                <!-- {{ isKeypadVisible ? 'H' : 'S' }} -->
    
                <!-- <i class="material-icons">dialpad</i> -->
    
                <i class="fa fa-tty"></i>
    
              </button>
    
                </div>
    
                <div class="dialer-pad animated fadeInDown" v-if="isKeypadVisible">
    
                    <button @click="addToPhoneNumber('1')">1</button>
    
                    <button @click="addToPhoneNumber('2')">2</button>
    
                    <button @click="addToPhoneNumber('3')">3</button>
    
                    <button @click="addToPhoneNumber('4')">4</button>
    
                    <button @click="addToPhoneNumber('5')">5</button>
    
                    <button @click="addToPhoneNumber('6')">6</button>
    
                    <button @click="addToPhoneNumber('7')">7</button>
    
                    <button @click="addToPhoneNumber('8')">8</button>
    
                    <button @click="addToPhoneNumber('9')">9</button>
    
                    <button @click="addToPhoneNumber('+')">+</button>
    
                    <button @click="addToPhoneNumber('0')">0</button>
    
                    <button @click="addToPhoneNumber('#')">#</button>
    
                </div>
    
                <div class="radio-button-group mts">
    
                    <div class="item">
    
                        <input type="radio" name="button-group" v-model="action" class="radio-button" value="CALL" id="button1" checked />
    
                        <label for="button1"><i class="fa fa-phone"></i>Call</label>
    
                    </div>
    
                    <div class="item">
    
                        <input type="radio" name="button-group" v-model="action" class="radio-button" value="SMS" id="button2" />
    
                        <label for="button2"><i class="fa fa-message"></i> Message</label>
    
                    </div>
    
                </div>
    
                <div v-if="action === 'SMS'">
    
                    <textarea v-model="smsMessage" placeholder="Type your SMS message here..."></textarea>
    
                </div>   
                <!-- Calling Apps -->
    
                <ul class="action-app-pad">
    
                    <li @click="openApp('whatsapp', phoneNumber, action)" class="icons"><i class="fa fa-whatsapp iconsize wa"></i>
    
                    </li>
    
                    <li @click="openApp('skype', phoneNumber, action)" class="icons"><i class="fa fa-skype iconsize skype"></i>
    
                    </li>
    
                    <li @click="openApp('teams', phoneNumber, action)" class="teams" v-if="action === 'CALL'">
    
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="33" fill="currentColor" class="bi bi-microsoft-teams" viewBox="0 0 16 16">
    
                  <path
    
                    d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0m-2.238 9.488-.12-.002a5.2 5.2 0 0 0 .381-2.07V6.306a1.7 1.7 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.6 2.6 0 0 1-2.598 2.598z" />
    
                  <path
    
                    d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.68.68 0 0 1-.682.682H.682A.68.68 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945z" />
    
                </svg>
    
                    </li>
    
                    <li @click="openApp('Vitel', phoneNumber, action)" v-if="enableVitel" class="icons">
    
                        <img src="https://www.vitelglobal.com/static/assets/img/favicon.webp" />
    
                    </li>
    
                    <li @click="openApp('', phoneNumber, action)" v-if="action === 'CALL'" class="icons"><i class="fa fa-sliders iconsize dailpad"></i></li>
    
                    <li @click="openApp('SMS', phoneNumber, action)" v-if="action === 'SMS'" class="icons"><i class="fa fa-envelope iconsize sms"></i></li>
    
                </ul>
    
            </div>
    
        </div>
    
    </div>
</template>

<script>
import { postAPICall } from "@/helpers/httpHelper";
import { validateAndFormatPhoneNumber } from "@/utils/utility";
export default {
    props:["phoneNumber"],
    data() {
        return {
            phoneCallModelLoading:false,
            showModal: true,
            // phoneNumber: "",
            smsMessage: "",
            isSMSMode: false,
            enableVitel: false,
            vitelData: {},
            userVitelData:{},
            isKeypadVisible: false,
            action: "CALL",
            options: [{ value: 'CALL', label: 'Call' },
                { value: 'SMS', label: 'Message' },
            ]
        };
    },
    methods: {
        toggleKeypad() {
            this.isKeypadVisible = !this.isKeypadVisible;
        },
        async getVitelGlobal() {
          this.phoneCallModelLoading=true;
          try{
            let response = await postAPICall("GET", "/user_integrations/list/VITEL_GLOBAL");
            if (response && response.success) {
                // this.enableVitel = response?.data?.clientSecret ? true : false;
                const decrypted = this.$CryptoJS.AES.decrypt(response.data.clientSecret, 'VitelGlobal')
                const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
                this.vitelData = JSON.parse(info);
                    this.vitelData = { ...this.vitelData, _id: response.data._id };
                this.phoneCallModelLoading=false;
            } else {
                // this.enableVitel = false;
                this.vitelData = {};
                this.phoneCallModelLoading=false;
            }
        }
        catch(err)
        {
            console.log(err);
            this.phoneCallModelLoading=false;
        }
        },
        async getConfigurations(){ 
            this.loading=true;
            this.phoneCallModelLoading=true;
        try {
          let response = await postAPICall("GET", "/user_integrations/user/VITEL_GLOBAL");
          if (response && response.success) {            
                this.userVitelData =response.data && response.data.length?response.data[0]:{};
                if(this.userVitelData && this.userVitelData.isActive)
                {   
                    const decrypted = this.$CryptoJS.AES.decrypt(this.userVitelData.clientSecret, 'VitelGlobal')
                    const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
                    let userConfigInfo=JSON.parse(info);
                    this.userVitelData={...this.userVitelData,...userConfigInfo}
                    const format = validateAndFormatPhoneNumber( this.userVitelData.destination);
                    if(format.isValid)
                    {
                        this.userVitelData  = {...this.userVitelData,...format};
                        this.enableVitel=true;
                        this.loading=false;
                    }
                    else{
                        this.userVitelData = {...this.userVitelData};
                        this.enableVitel=true;
                        this.loading=false;
                    } 
                }
                else{
                    this.enableVitel=false;
                    this.loading=false;

                }
            }
            else{
                    this.enableVitel=false;
                    this.loading=false;
            }
        } catch (error) {
          console.error(error);
          this.phoneCallModelLoading=false;
          this.enableVitel=false;
          
        } finally {
            this.phoneCallModelLoading=false;
        }
      },

        closeModal() {
            this.$emit('close');
        },

        // Add number or symbol to phone number
        addToPhoneNumber(value) {
            this.phoneNumber += value;
        },

        // Delete last character from phone number
        deleteLastCharacter() {
            this.phoneNumber = this.phoneNumber.slice(0, -1);
        },

        // Open calling apps like WhatsApp, Skype, Teams
        async openApp(app, phoneNumber, action) {
          this.phoneCallModelLoading=true;
            if (action === 'CALL') {
                switch (app) {
                    case "whatsapp":
                        this.$message.success("Redirecting to Whatsapp....");
                        setTimeout(() => {
                          window.open(`https://wa.me/${phoneNumber}`,'_blank');
                          this.phoneCallModelLoading=false;
                        }, 2000);
                        
                        break;
                    case "skype":
                        this.$message.success("Redirecting to Skype....");
                        setTimeout(() => {
                          window.open(`skype:${phoneNumber}?call`,'_blank');
                          this.phoneCallModelLoading=false;
                        }, 2000);
                        
                        break;
                    case "teams":
                        this.$message.success("Redirecting to Microsoft Teams....");
                        setTimeout(() => {
                          window.open(`msteams:call/${phoneNumber}`,'_blank');
                          this.phoneCallModelLoading=false;
                        }, 2000);
                        
                        break;
                    case "Vitel":
                        {
                            try{
                            let payload = { action: "CALL", destinationNumber: phoneNumber, message: "" };
                            let response= await postAPICall("PATCH", `/user_integrations/vitel_action/${this.vitelData._id}/${this.userVitelData._id}`, payload);
                            this.$message.success(response.success?"Call Initiated please accept on your Device / Application ":'Call Initiated ');
                            this.phoneCallModelLoading=false;
                            }
                            catch(error)
                            {
                                console.log("call",error)
                              this.$message.error(error.message);
                              this.phoneCallModelLoading=false;
                            }

                            break;
                        }
                    default:
                        window.location.href = `tel:${phoneNumber}`;
                        break;
                }
                this.closeModal();
            } else if (action === 'SMS') {
                if (this.smsMessage.trim()) {
                    switch (app) {
                        case "whatsapp":
                            {
                                let toNum = phoneNumber.replace(/[^0-9]/g, '');
                                this.$message.success("Redirecting to Whatsapp....");
                                setTimeout(() => {
                                  window.open(`https://wa.me/${toNum}?text=${encodeURIComponent(this.smsMessage)}`,'_blank');
                                this.phoneCallModelLoading=false;
                                }, 2000);
                                
                                break;
                            }
                        case "skype":
                            this.$message.success("Redirecting to Whatsapp....");
                            setTimeout(() => {
                              window.open(`skype:${phoneNumber}?chat&message=${encodeURIComponent(this.smsMessage)}`,'_blank');
                              this.phoneCallModelLoading=false;
                                }, 2000);
                            
                            break;
                        case "Vitel":
                            {
                              try{
                                let payload = { action: "SMS", destinationNumber: phoneNumber, message: this.smsMessage };
                                let response= await postAPICall("PATCH", `/user_integrations/vitel_action/${this.vitelData._id}/${this.userVitelData._id}`, payload);
                                this.$message.success(response.message);
                                this.phoneCallModelLoading=false;
                              }
                              catch(error)
                              {
                                this.$message.error(error.message);
                                this.phoneCallModelLoading=false;
                              }
                                break;
                            }
                        case "SMS":
                            {
                                if (this.smsMessage.trim()) {
                                    const smsUrl = `sms:${this.phoneNumber}?body=${encodeURIComponent(
                                        this.smsMessage
                                      )}`;
                                    window.location.href = smsUrl;
                                    this.closeModal();
                                } else {
                                    alert("Please type a message before sending.");
                                }
                                break;
                            }
                        default:
                            window.location.href = `tel:${phoneNumber}`;
                            break;
                      }
                      this.closeModal();
                } else {
                    alert("Please type a message before sending.");
                }

            }

        },

        // Open the phone dialer app
        openDialer(phoneNumber) {
            window.location.href = `tel:${phoneNumber}`;
            this.closeModal();
        },
    },
    async mounted() {
        console.log("Phone called",this.phoneNumber)
        this.phoneCallModelLoading=true;
            // this.phoneNumber = phoneNumber; // Set phone number
            // this.showModal = true;
       await Promise.all([this.getVitelGlobal(),this.getConfigurations()]);
       
       this.phoneCallModelLoading=false;
    },
    beforeDestroy(){
        console.log("Destroy Called");
    }
};
</script>

<style scoped>
.mts {
    margin: 0rem 1rem;
}

.iconsize {
    font-size: 33px;
}

.icons:hover {
    .wa {
        color: green;
    }
    .skype {
        color: skyblue;
    }
    .dailpad,
    .sms {
        color: gray;
    }
}

.teams:hover {
    color: blue
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999 !important;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    min-width: 420px !important;
    max-width: 720px !important;
    text-align: center;
}

/* Modal Header */

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.modal-header h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.hr {
    border: 1px solid #ddd;
}

/* Close Button Style */

.close-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
}

.close-btn:hover {
    color: #f00;
    /* Red color when hovered */
}

/* Modal Body */

.modal-body {
    padding-top: 10px;
    color: #555;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

li:hover {
    background-color: #f0f0f0;
}

button {
    /*padding: 10px;
  margin-top: 20px;*/
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}

.output {
    font-family: "Exo";
    font-size: 2rem;
    height: 60px;
    font-weight: bold;
    color: #1976d2;
    width: 100%;
    border: none;
}

input {
    /*width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;*/
}

.dialer-pad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-top: 20px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateY(-20px);
}

.dialer-pad button {
    /*padding: 15px; */
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: 'Exo';
    font-size: 2rem;
    cursor: pointer;
    color: black;
}

.action-app-pad {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    margin-top: 20px;
}

textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    font-size: 16px;
}

.input-group {
    display: flex;
    border: 1px solid #00000040;
    background-color: white;
    height: 50px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, .13);
}

.phone-number:active,
.phone-number:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.input-group .phone-number {
    /* padding: 10px; 
   font-size: 17px; */
    border: 1px solid grey;
    float: left;
    width: 73.99%;
    background: #f1f1f1;
    border: none;
    outline: none;
    box-shadow: none;
    font-family: "Exo";
    font-size: 2rem;
}

.input-group .back {
    float: left;
    width: 12.50%;
    /* padding: 10px; */
    background: #de4c4c;
    color: white;
    font-size: 17px;
    border: 1px solid rgb(225, 177, 177);
    border-left: none;
    cursor: pointer;
    border-radius: 0;
}

.input-group .back:hover {
    background: #a32d3b;
}

.input-group .dialer-pad-info {
    float: left;
    width: 12.50%;
    /* padding: 10px; */
    background: #b39d9ddf;
    color: white;
    font-size: 17px;
    border: 1px solid rgb(225, 177, 177);
    border-left: none;
    cursor: pointer;
    border-radius: 0;
}

.input-group .dialer-pad-info:hover {
    background: #e2ababdf;
}

.input-group .phone-number::after {
    content: "";
    clear: both;
    display: table;
}

.animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

/* Styling for the radio button group */

.radio-button-group {
    display: flex;
    padding: 5px 0;
}

.radio-button-group .item {
    position: relative;
    flex: 1;
}

/* Hide the radio button itself, but allow the label to be clickable */

.radio-button-group .radio-button {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
}

.radio-button-group .radio-button+label {
    cursor: pointer;
    border: 1px solid #CCC;
    margin-right: -2px;
    color: #555;
    background-color: #ffffff;
    display: block;
    text-align: center;
    /*border-radius: 5px; padding: 16px 10px;*/
    transition: all 0.3s;
}

.radio-button-group {
    display: flex;
}

.radio-button-group .item {
    width: 100%;
}

.radio-button-group .radio-button {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
}

.radio-button-group .radio-button+label {
    padding: 0.35rem 0.55rem;
    cursor: pointer;
    border: 1px solid #CCC;
    margin-right: -2px;
    color: #555;
    background-color: #ffffff;
    display: block;
    text-align: center;
}

.radio-button-group .item:first-of-type .radio-button+label {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.radio-button-group .item:last-of-type .radio-button+label {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.radio-button-group .radio-button:checked+label {
    background-color: #1ba0ff;
    color: #FFF;
}
</style>
