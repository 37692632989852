<template>
  <div id="app">
    <el-col
      :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
      ref="colRef"
      :style="computedStyles"
      class="bottom-space"
    >
    <span style="display: flex;">
    <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
      <label for="text">{{ data.label }}</label>
      <span
        v-if="data.validations.required"
        style="color: red; font-weight: bold"
      >
        *
      </span>
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description"></i>
      </span>
    </span>
    </el-col>
    <el-input v-model="address" placeholder="Enter Address"></el-input>
    <el-button @click="getCoordinates(address)" type="primary"
      >Search Address</el-button
    >
    <el-input v-if="!data.validations.mapViewRequired"></el-input>
    <div
      v-else
      class="map-container"
      style="border: 2px solid cadetblue; border-radius: 10px; flex-grow: 1"
    >
      <l-map :zoom="zoom" :center="center" style="height: 100%">
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker :lat-lng="coordinates" :icon="markerIcon"></l-marker>
        <!-- Geofence circle -->
        <l-circle
          :lat-lng="data.validations.geoFenceCoordinates"
          :radius="data.validations.geoFenceRadius"
          :color="'red'"
          :fill-color="'red'"
          :fill-opacity="0.3"
        ></l-circle>
      </l-map>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LCircle } from "vue2-leaflet"; // Import LCircle for geofence
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
export default {
  name: "App",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle, // Register the LCircle component
  },
  props: ["data","isIconRequired"],
  data() {
    return {
      address: "",
      computedStyles: {},
      isDefalutPos: true,
      showLabel: true,
      zoom: 10,
      center: [17.4065, 78.4772], // default center coordinates
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      coordinates: [17.4065, 78.4772], // coordinates to display
    };
  },
  methods: {
    updateCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.coordinates = [latitude, longitude];
          this.center = [latitude, longitude];
        },
        (error) => {
          console.error("Error getting location:", error.message);
          // Handle the error appropriately
        }
      );
    },
    async getCoordinates(address) {
      try {
        const config = {
          params: {
            address: address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );
        const coordinates = response.data.results[0].geometry.location;
        this.coordinates = [coordinates.lat, coordinates.lng];
        this.center = [coordinates.lat, coordinates.lng];
        return coordinates;
      } catch (error) {
        console.error("Error fetching coordinates:", error);
        return null;
      }
    },
    async getEpiCenterCoordinates(address) {
      try {
        const config = {
          params: {
            address: address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );
        const coordinates = response.data.results[0].geometry.location;
        this.data.validations.geoFenceCoordinates = [
          coordinates.lat,
          coordinates.lng,
        ];
        return coordinates;
      } catch (error) {
        console.error("Error fetching coordinates:", error);
        return null;
      }
    },
  },
  watch: {
    // Watch for changes to data.validations.geoFenceCoordinates
    "data.validations.geoFenceCoordinates": {
      handler(newVal) {
        if (newVal) {
          this.getEpiCenterCoordinates(newVal);
        }
      },
      immediate: true, // Call the handler immediately after the component is mounted
    },
  },
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.getEpiCenterCoordinates(this.data.validations.geoFenceCoordinates);
  },
};
</script>

<style>
#app {
  height: 100%;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
}

#button {
  border: none;
  background-color: cadetblue;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  margin-top: 5px;
}
.map-container {
  flex-grow: 1;
  margin: 10px 0; /* Optional margin for better spacing */
}
</style>
