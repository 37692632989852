var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[(!_vm.refresh)?_c('div',{staticClass:"form-group"},[_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 8 : 24}},[(_vm.isIconRequired)?_c('icons',{staticClass:"mr-1",attrs:{"iconName":_vm.data.icon_name}}):_vm._e(),(_vm.data.properties.filed_content != 'Hide' && ! _vm.hasLabel)?_c('label',{class:{ 'highlight-border': _vm.highlight },style:(_vm.getLabelStyles),attrs:{"for":"text"},on:{"change":_vm.applyFormRules}},[(!_vm.data.is_hide_question_no)?_c('span',[_vm._v(_vm._s(_vm.data.questionIndex)+"."+_vm._s(_vm.label||_vm.data.label))]):_vm._e(),_vm._v(" "),(_vm.data.is_hide_question_no)?_c('span',[_vm._v(_vm._s(_vm.data.question_before)),(_vm.data.question_before && _vm.data.question_before !== '')?_c('span',[_vm._v(".")]):_vm._e(),_vm._v(_vm._s(_vm.label||_vm.data.label))]):_vm._e()]):_vm._e(),(_vm.data.validations.required && ! _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":_vm.showLabel 
        &&
        !_vm.isDefalutPos
        ?
        12
        :
        24},on:{"change":_vm.applyFormRules}},[(!_vm.isView && _vm.data.question_type == 'TEXT')?_c('el-input',{style:(_vm.getStyle),attrs:{"type":"textarea","placeholder":"Your Answer","disabled":_vm.checkReadonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
            !_vm.isView &&
            _vm.data.question_type == 'YES_OR_NO' &&
            _vm.data.properties.filed_content != 'Hide'
          )?_c('el-radio-group',{attrs:{"disabled":_vm.checkReadonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('el-radio',{attrs:{"label":"YES","border":""}},[_vm._v("YES")]),_c('el-radio',{attrs:{"label":"NO","border":""}},[_vm._v("NO")])],1):_vm._e(),(
            !_vm.isView &&
            _vm.data.question_type == 'MCQ' &&
            _vm.data.question_options_format &&
            _vm.data.question_options_format != 'DROP_DOWN'
          )?_c('el-checkbox-group',{attrs:{"max":_vm.data.max_selection
              ? _vm.data.max_selection
              : _vm.data.options && _vm.data.options.length
              ? _vm.data.options.length
              : 1},on:{"change":_vm.checkAnswered},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('ol',{class:_vm.data.question_options_format.toLowerCase()},_vm._l((_vm.data.options),function(option,index){return _c('li',{key:index + option + '_option'},[_c('el-checkbox',{attrs:{"value":option,"label":option,"border":""}},[_vm._v(" "+_vm._s(String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)))+". "+_vm._s(option))])],1)}),0)]):_vm._e(),(
            !_vm.isView &&
            _vm.data.question_type == 'MCQ' &&
            _vm.data.question_options_format &&
            _vm.data.question_options_format == 'DROP_DOWN' &&
            _vm.data.properties.filed_content != 'Hide'
          )?_c('el-select',{attrs:{"disabled":_vm.checkReadonly(),"max":_vm.data.max_selection
              ? _vm.data.max_selection
              : _vm.data.options && _vm.data.options.length
              ? _vm.data.options.length
              : 1,"multiple":"","multiple-limit":_vm.data.max_selection
              ? _vm.data.max_selection
              : _vm.data.options && _vm.data.options.length
              ? _vm.data.options.length
              : 1,"collapse-tags":""},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.data.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option,"label":option}})}),1):_vm._e()],1),(_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('el-col',{attrs:{"span":24}},[(_vm.data.question_type == 'TEXT')?_c('el-input',{style:(_vm.getStyle),attrs:{"type":"textarea","placeholder":_vm.data.label,"readonly":""},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.data.question_type == 'YES_OR_NO')?_c('el-radio-group',{attrs:{"fill":'#898ff5',"disabled":true},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('el-radio',{attrs:{"label":"YES","border":""},on:{"change":_vm.applyFormRules}},[_vm._v("YES")]),_c('el-radio',{attrs:{"label":"NO","border":""},on:{"change":_vm.applyFormRules}},[_vm._v("NO")])],1):_vm._e(),(
            _vm.data.question_type == 'MCQ' &&
            _vm.data.question_options_format != 'DROP_DOWN'
          )?_c('el-checkbox-group',{attrs:{"max":_vm.data.max_selection
              ? _vm.data.max_selection
              : _vm.data.options && _vm.data.options.length
              ? _vm.data.options.length
              : 1,"disabled":true},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_c('ol',{class:_vm.data.question_options_format.toLowerCase()},[_vm._l((_vm.data.options),function(option,index){return _c('li',{key:index},[_c('el-checkbox',{attrs:{"label":option,"border":""}},[_vm._v(" "+_vm._s(String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)))+". "+_vm._s(option))])],1)}),(_vm.data.has_other_option)?_c('li',[_c('el-checkbox',{attrs:{"value":"others","label":"Others","border":""}},[_vm._v(" "+_vm._s(String.fromCharCode(_vm.data.options.length + 65))+". Others")])],1):_vm._e()],2)]):_vm._e(),(_vm.data.has_other_option && _vm.showMSQ && _vm.otherOptionSelected)?_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.data.other_option_label ? _vm.data.other_option_label : "Enter other option")+" "),_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form[_vm.data.key + '/otheroption']),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key + '/otheroption', $$v)},expression:"form[data.key + '/otheroption']"}})],1):_vm._e(),(
            _vm.data.question_type == 'MCQ' &&
            _vm.data.question_options_format &&
            _vm.data.question_options_format == 'DROP_DOWN'
          )?_c('el-select',{attrs:{"disabled":"","max":_vm.data.max_selection
              ? _vm.data.max_selection
              : _vm.data.options && _vm.data.options.length
              ? _vm.data.options.length
              : 1,"multiple":"","multiple-limit":_vm.data.max_selection
              ? _vm.data.max_selection
              : _vm.data.options && _vm.data.options.length
              ? _vm.data.options.length
              : 1,"collapse-tags":""},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.data.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option,"label":option}})}),1):_vm._e()],1):_vm._e()],1)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }