<template>
  <div
    :style="getElementStyle"
    v-if="!profilePage && data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 8 : data.description ? 20 : 24"
        v-if="!data.properties.hideLabel"
        class="bottom-space"
      >
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label
          for="text"
          v-if="hasLabel"
          :style="getLabelStyles"
          :class="{ 'highlighted-label': highlight }"
        >{{
          label||data.label
        }}</label>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
           <span v-if="data.description">
        <i
          class="el-icon-info"
          :title="data.description"
        ></i>
      </span>
        </el-popover>
        </span>
      </el-col>
      <el-col v-else :span="showLabel ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
            <span v-if="hasLabel" class="transparentcol"></span>
            <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
          </el-col>
      <!-- <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
      </el-col> -->
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24" class="execute-view">
        <div v-if="!isView && data.properties.filed_content != 'Hide'">
          <el-radio-group
            v-model="form[data.key]"
            :disabled="isFromDocument ? false : checkReadonly() || readonly()"
            @change="applyFormRules"
            :readonly="readonly()"
          >
            <el-radio label="YES">YES</el-radio>
            <el-radio label="NO">NO</el-radio>
          </el-radio-group>
        </div>
        <p v-if="isView && data.properties.filed_content != 'Hide'">
          <template v-if="form[data.key]">{{ form[data.key] }}</template>
          <template v-else>-</template>
        </p>

        <!-- <el-select
          v-if="!isView && isList"
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false :checkReadonly()"
          clearable
          @change="applyFormRules"
        > -->
        <!-- <el-option v-for="(option,index) of options" :value="option" :key="index"></el-option> -->
        <!-- </el-select>  -->
      </el-col>
    </el-row>
    <!-- <label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
    <el-popover
      v-if="data.description"
      placement="top-start"
      trigger="hover"
      :content="data.description"
    >
      <i class="el-icon-info" slot="reference"></i>
    </el-popover>
    <div v-if="!isView">
      <el-radio-group v-model="form[data.key]" :dislabled="checkReadonly()">
        <el-radio label="YES">YES</el-radio>
        <el-radio label="NO">NO</el-radio>
      </el-radio-group>
    </div>

    <p v-if="isView">
      <template v-if="form[data.key]">{{form[data.key]}}</template>
      <template v-else>-</template>
    </p> -->
  </div>
  <div v-else>
    <div v-if="isView">
      {{ form[data.key] }}
    </div>
    <div v-else>
      <div v-if="data.properties.filed_content != 'Hide'">
        <el-radio-group
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly() ||readonly()"
          @change="applyFormRules"
          :readonly="readonly()"
        >
          <el-radio label="YES">YES</el-radio>
          <el-radio label="NO">NO</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

export default {
  name: "templates-formComponentsExecute-YesOrNoExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "checkIsDisabled",
    "profilePage",
    "checkhideFields",
    "colorFields",
    "highlight",
    "isIconRequired"
  ],
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      validations: [],
      showLabel: true,
      // isDefalutPos: true
    };
  },
  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = true;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefaultPos = false;
          }
        }
        //If it's an entity varaible, have to check access
        if (
          this.data.field_assignable === "read_only" &&
          this.data.is_entity_variable
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    }
  },
  mounted() {
    //if (!this.form[this.data.key]) {
    //  this.$set(this.form, this.data.key, this.data.default_value);
      // this.form[this.data.key]=this.data.default_value
    //}
    // form[data.key]
  }
};
</script>

<style lang="scss" scoped>
  .highlighted-label {
  color: red;
}
.transparentcol{
  color: white; 
  display: inline-block; 
  height: 20px;
  opacity: 0;
}
.execute-view {
  font-weight: normal;
}
</style>
