var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"side-navigation-menu",class:{ 'is-open': _vm.isCollapse }},[_c('div',{staticStyle:{"max-height":"90vh !important"}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: 90vh;"}},[_c('el-menu',{ref:"sidebarMenu",class:{
          'default-style': !_vm.getContactLoginSlug.styled_sidemenu,
          'inverted-color-style': _vm.getContactLoginSlug.styled_sidemenu,
        },attrs:{"active-text-color":"#F754A2","collapse":!_vm.isCollapse}},[_vm._l((_vm.sideBarItem),function(sideBarItem){return [(sideBarItem.children && sideBarItem.children.length)?[_c('el-submenu',{key:sideBarItem._id,attrs:{"index":sideBarItem._id}},[_c('template',{slot:"title"},[_c('div',{staticClass:"d-flex"},[_c('icons',{attrs:{"iconName":sideBarItem.icon}}),_vm._v("   "),_c('span',{class:{ ' submenu full-title': sideBarItem.enableFullTitle },attrs:{"title":sideBarItem.title.length > 15 && !sideBarItem.enableFullTitle ? sideBarItem.title : ''}},[_vm._v(_vm._s(sideBarItem.enableFullTitle ? sideBarItem.title : _vm.$options.filters.truncate(sideBarItem.title, 15)))])],1)]),_c('el-menu-item-group',[_vm._l((sideBarItem.children),function(sideBarSubItem){return [(sideBarSubItem.type == 'Child')?[_c('el-menu-item',{key:sideBarSubItem._id,staticClass:"custom-icon",attrs:{"index":sideBarSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubItem, true)}}},[_c('div',{staticClass:"d-flex"},[_c('icons',{attrs:{"iconName":sideBarSubItem.icon}}),_vm._v("   "),_c('span',{class:{ 'menu-title full-title': sideBarSubItem.enableFullTitle },attrs:{"title":sideBarSubItem.title.length > 11 && !sideBarSubItem.enableFullTitle
                              ? sideBarSubItem.title
                              : ''}},[_vm._v(" "+_vm._s(sideBarSubItem.enableFullTitle ? sideBarSubItem.title : _vm.$options.filters.truncate(sideBarSubItem.title, 11))+" ")])],1)])]:_vm._e(),(sideBarSubItem.type == 'SubParent')?[_c('el-submenu',{key:sideBarSubItem._id,staticClass:"custom-icon",attrs:{"index":sideBarSubItem._id}},[_c('template',{slot:"title"},[_c('div',{staticClass:"d-flex"},[_c('icons',{attrs:{"iconName":sideBarSubItem.icon}}),_vm._v("   "),_c('span',{class:{ 'menu-title full-title': sideBarSubItem.enableFullTitle },attrs:{"title":sideBarSubItem.title.length > 10 && !sideBarSubItem.enableFullTitle
                                ? sideBarSubItem.title
                                : ''}},[_vm._v(" "+_vm._s(sideBarSubItem.enableFullTitle ? sideBarSubItem.title : _vm.$options.filters.truncate(sideBarSubItem.title, 10))+" ")])],1)]),_c('el-menu-item-group',[_vm._l((sideBarSubItem.children),function(sideBarSubSubItem){return [(sideBarSubSubItem.type == 'Child')?[_c('el-menu-item',{key:sideBarSubSubItem._id,staticClass:"custom-icon",attrs:{"index":sideBarSubSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubSubItem, true)}}},[_c('div',{staticClass:"d-flex"},[_c('icons',{attrs:{"iconName":sideBarSubItem.icon}}),_vm._v("   "),_c('span',{class:{ 'menu-title full-title': sideBarSubSubItem.enableFullTitle },attrs:{"title":sideBarSubSubItem.title.length > 8 && !sideBarSubSubItem.enableFullTitle
                                      ? sideBarSubSubItem.title
                                      : ''}},[_vm._v(" "+_vm._s(sideBarSubSubItem.enableFullTitle ? sideBarSubSubItem.title : _vm.$options.filters.truncate(sideBarSubSubItem.title, 8))+" ")])],1)])]:_vm._e()]})],2)],2)]:_vm._e()]})],2)],2)]:[_c('el-menu-item',{key:sideBarItem._id,staticClass:"custom-icon",attrs:{"index":sideBarItem._id},on:{"click":function($event){return _vm.routeClick(sideBarItem, false)}}},[_c('div',{staticClass:"d-flex"},[_c('icons',{attrs:{"iconName":sideBarItem.icon}}),_vm._v("   "),_c('span',{class:{ 'submenu full-title': sideBarItem.enableFullTitle },attrs:{"title":sideBarItem.title.length > 16 && !sideBarItem.enableFullTitle ? sideBarItem.title : ''}},[_vm._v(_vm._s(sideBarItem.enableFullTitle ? sideBarItem.title : _vm.$options.filters.truncate(sideBarItem.title, 16)))])],1)])]]})],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }