import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    integrationsList:[],
  };
};

const state = initialState();

const getters = {
  getIntegrationsList: state => state.integrationsList,
};

const mutations = {
  setIntegrationsList:(state,data) =>{
    state.integrationsList = data
  },
  resetData: state => {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  reset: function (context) {
    context.commit("UserIntegrations/resetData", null, {
      root: true
    });
  },
  fetchIntegrationsList: async (context) => {
    try {
      context.commit('UserIntegrations/setIntegrationsList', [], {
        root: true
      })
      const response = await axios.get('/user_integrations/list');
      context.commit('UserIntegrations/setIntegrationsList', response.data.data, {
        root: true
      });
    } catch (error) {
      context.commit('UserIntegrations/setIntegrationsList', [], {
        root: true
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};