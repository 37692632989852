<template>
    <div>
      <el-form label-position="right">
        <title-and-description :field="field" />
      </el-form>
      <div>
        <div class="main-form mt-1 scrollbar-content d-flex" style="gap: 10px">
          <div class="d-flex-col">
            <label>Entities</label>
            <el-select
              v-model="addStatsData.entity_id"
              no-data-text="No Entities available"
              filterable
              default-first-option
              @change="getEntityDateFields"
              class="mb-1"
            >
              <el-option
                v-for="(entity, index) of getAllEntitiesData"
                :value="entity._id"
                :key="index"
                :label="entity.name"
                :disabled="checkSelectedEntity(entity)"
              >
                <span style="float: left">{{ entity.name }}</span>
              </el-option>
            </el-select>
            <EntityFilters
              v-if="addStatsData && addStatsData.entity_id"
              :field="addStatsData"
              :fieldsData="fieldsData"
            ></EntityFilters>
          </div>
          <div class="d-flex-col">
            <label>Fields</label>
            <el-select
              v-model="addStatsData.field"
              filterable
              clearable
              default-first-option
              class="mb-1"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(fl, index) of addStatsData.entity_id
                  ? getTemplateDateFields
                  : []"
                :value="fl.key"
                :key="fl.key + index"
                :label="`${fl.entity_name} - ${fl.template_name} - ${fl.label} `"
                :disabled="
                  (field && field.selectedEntities
                    ? field.selectedEntities
                    : []
                  ).findIndex((ex) => ex.field == fl.key) != -1
                "
              >
                <span
                  >{{ fl.entity_name }} - {{ fl.template_name }} -
                  {{ fl.label }}</span
                >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-button
              type="primary"
              icon="el-icon-plus"
              class="mt-1"
              @click="addNewDate"
              :disabled="checkNewDate"
              >Add</el-button
            >
          </div>
        <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
        </div>
        <div>
          <el-table :data="field.selectedEntities" border="">
            <el-table-column prop="Contact Type" label="Fields">
              <template slot-scope="scope">
                {{ getFieldLabel(scope.row.field) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="Custom Message"
              label="Custom Message"
              width="250"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.field &&
                    scope.row.field.includes('#') &&
                    scope.row.field.split('#')[0]
                  "
                >
                  <CustomEntityMention
                    :entity_id="scope.row.field.split('#')[0]"
                    :data="scope.row"
                    :isFromApplicationuser="false"
                  >
                  </CustomEntityMention>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="show every year" label="Show every year">
              <template slot-scope="scope">
                <div class="d-flex">
                  <div>
                    <el-checkbox
                      type="textarea"
                      v-model="scope.row.show_every_year"
                      class="mr-2"
                    >
                    </el-checkbox>
  
                    <el-link
                      class="mr-2"
                      @click.native.prevent="
                        deleteRow(scope.$index, field.selectedEntities)
                      "
                      type="danger"
                      icon="el-icon-delete"
                    >
                    </el-link>
                  </div>
                  <div>
                    <el-color-picker
                      title="colorPicker"
                      size="mini"
                      v-model="scope.row.color"
                    ></el-color-picker>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Show background color">
              <template slot-scope="scope">
                <div>
                  <el-checkbox
                    :disabled="checkBackgroundColor(scope.$index)"
                    type="textarea"
                    v-model="scope.row.applyBackground"
                  ></el-checkbox>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import templateBuilderHelper from "../../../mixins/templateBuilderHelper";
  import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
  
  export default {
    name: "templates-formComponents-Calender",
    mixins: [templateBuilderHelper, CustomDashboardConfig],
    components: {
      TitleAndDescription: () => import("./TitleAndDescription"),
      EntityFilters: () => import("./EntityFilters"),
      CustomEntityMention: () =>
        import("@/components/widgets/entityMention/index.vue"),
    },
  
    props: ["field", "allFormTemplates", "fieldsData"],
    computed: {
      ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
      checkNewDate() {
        return (
          !this.addStatsData.entity_id ||
          !this.addStatsData.field ||
          !this.addStatsData.field.length
        );
      },
    },
    async mounted() {
      this.getAllEntitiesData = await this.fetchAllEntities(false);
      this.getAllEntitiesData.forEach((entity) => {
        const templateIds = (
          entity && entity.templates ? entity.templates : []
        ).flatMap((e) => e.template_id);
        const entityTemplates = (this.allFormTemplates || []).filter(
          (e) => (templateIds || []).indexOf(e._id) !== -1
        );
        (entityTemplates || []).forEach((e) => {
          let data = JSON.parse(JSON.stringify(e));
          let fields = this.getTemplateFieldsByType(
            data,
            ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
            entity
          );
          this.fieldsLabelMap[data._id] = fields;
        });
      });
    },
    data() {
      return {
        iconPickerdialogVisible: false,
        getAllEntitiesData: [],
        addStatsData: {
          entity_id: "",
          field: "",
          filters: [],
        },
        getTemplateDateFields: [],
        fieldsLabelMap: {},
      };
    },
    methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
      getFieldLabel(field) {
        let t = field.split("#")[1];
        if(this.fieldsLabelMap && t && this.fieldsLabelMap?.[t]){
          let fieldObj = (this.fieldsLabelMap?.[t] || []).find(
            (e) => e.key == field
          );
          return fieldObj?.label;
        }
        return field.split("#")?.[2] || field;
      },
      deleteRow(index, rows) {
        rows.splice(index, 1);
      },
      checkBackgroundColor(index) {
        if (index > -1 && this.field?.selectedEntities) {
          let noSelectio = (this.field.selectedEntities || []).findIndex(
            (e) => e.applyBackground
          );
          if (noSelectio != -1 && noSelectio != index) {
            return true;
          }
        }
        return false;
      },
      addNewDate() {
        let obj = JSON.parse(JSON.stringify(this.addStatsData));
        (obj.field || []).forEach((field) => {
          if (this.field.selectedEntities) {
            this.field.selectedEntities.push({
              ...obj,
              ...{
                field: field,
              },
            });
          } else {
            this.$set(this.field, "selectedEntities", [
              {
                ...obj,
                ...{
                  field: field,
                },
              },
            ]);
          }
        });
        this.addStatsData = {
          entity_id: "",
          field: "",
          filters: [],
        };
      },
      checkSelectedEntity() {
        return false;
      },
      getEntityDateFields() {
        this.getTemplateDateFields = [];
        if (this.addStatsData?.entity_id) {
          const entity = this.getAllEntitiesData.find(
            (e) => e._id == this.addStatsData?.entity_id
          );
          const templateIds = (
            entity && entity.templates ? entity.templates : []
          ).flatMap((e) => e.template_id);
          const entityTemplates = (this.allFormTemplates || []).filter(
            (e) => (templateIds || []).indexOf(e._id) !== -1
          );
          (entityTemplates || []).forEach((e) => {
            let data = JSON.parse(JSON.stringify(e));
            let fields = this.getTemplateFieldsByType(
              data,
              ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
              entity
            );
            this.getTemplateDateFields = [
              ...this.getTemplateDateFields,
              ...fields,
            ];
            this.fieldsLabelMap[data._id] = fields;
          });
        }
      },
    },
  };
  </script>
  
  <style lang="scss"></style>
  