<template>
  <div
    :style="getElementStyle"
    v-if="!loading && data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 8 : 24"
        v-if="!data.properties.hideLabel"
        class="bottom-space"
      >
        <label for="text" v-if="hasLabel" :style="getLabelStyles">
          <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>{{
          label || data.label
        }}</label>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="
            color: red;
            font-weight: bold;
            margin-left: 5px;
            margin-right: 5px;
          "
        >
          *
        </span>
        <span
          v-if="data.properties.is_unique"
          style="color: red; font-weight: bold; margin-left: 5px"
        >
          <i class="el-icon-attract"></i
        ></span>
      </el-col>
      <el-col
        v-else
        :span="showLabel ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <span
          v-if="hasLabel"
          style="color: white; display: inline-block; height: 20px; opacity: 0"
        ></span>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="
            color: red;
            font-weight: bold;
            margin-left: 5px;
            margin-right: 5px;
          "
        >
          *
        </span>
      </el-col>
      <el-popover
        v-if="data.description"
        placement="top-start"
        trigger="hover"
        :content="data.description"
      >
        <i class="el-icon-info" slot="reference"></i>
      </el-popover>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24" class="execute-view">
        <!-- :picker-options="pickerOptions" -->

        <el-date-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          type="daterange"
          :style="getStyle"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly()"
          :placeholder="data.placeholder || data.label"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          popper-class="my-popover"
          :format="getFormat()"
          @change="onchange"
          :readonly="readonly()"
          :picker-options="DateRangeOption()"
        ></el-date-picker>

        <el-date-picker
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          prefix-icon="el-icon-date"
          :size="profilePage ? 'mini' : ''"
          v-model="form[data.key]"
          type="daterange"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          range-separator="To"
          :start-placeholder="data.placeholder || 'Start date'"
          :end-placeholder="data.placeholder1 || 'End date'"
          popper-class="my-popover"
          :format="getFormat()"
          @change="onchange"
          :readonly="readonly()"
          :class="{ 'highlighted-border': highlight }"
          :picker-options="DateRangeOption()"
        >
        </el-date-picker>

        <!-- 
          <el-date-picker
          v-if="!isView  && !isList"
            prefix-icon="el-icon-date"
            v-model="dates"
            type="datetimerange"
              :placeholder="data.placeholder || data.label" 
            :style="getStyle"
            :disabled="isFromDocument ? false : checkReadonly()"
            :picker-options="pickerOptions"
                range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                    popper-class="my-popover"
                :format="'MM-dd-yyyy HH:mm:ss'"   
              
                @change="onchange"
          >
          </el-date-picker>
         -->

        <!-- <el-date-picker
          v-if="isList"
            prefix-icon="el-icon-date"
            v-model="form[this.data.key]"
            :readonly="true"
            type="date"
              :placeholder="data.placeholder || data.label" 
            :style="getStyle"
            :disabled="isFromDocument ? false : checkReadonly()"
            :format="
              getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
                ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
                : 'MM-dd-yyyy'
            "
          >
          </el-date-picker> -->

        <!-- <el-date-picker
          v-if="!isView  && !isList"
            prefix-icon="el-icon-date"
            v-model="form[data.key]"
            type="date"
              :placeholder="data.placeholder || data.label" 
            :style="getStyle"
            :disabled="isFromDocument ? false : checkReadonly()"
            :format="
              getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
                ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
                : 'MM-dd-yyyy'
            "
          >
          </el-date-picker> -->

        <p
          v-if="
            isView &&
            form[this.data.key] &&
            data.properties.filed_content != 'Hide'
          "
          :style="getStyle"
        >

          <template>
            {{ form[this.data.key] | toDateRange }}
          </template>
        </p>

        <p v-else></p>
      </el-col>
    </el-row>
    <!--<div class="form-group">
        <label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
        <el-popover
          v-if="data.description"
          placement="top-start"
          title="Help"
          trigger="click"
          :content="data.description"
        >
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
  
        <el-date-picker
          v-if="!isView"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="checkReadonly()"
        ></el-date-picker>
  
        <p v-if="isView" :style="getStyle">
          <template v-if="form[data.key]">{{form[data.key] | moment(getFormat()}}</template>
          <template v-else>-</template>
        </p>
      </div>-->
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

import { mapGetters } from "vuex";
import { bus } from "../../../main";
import * as moment from "moment";
export default {
  name: "templates-formComponentsExecute-DateExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "profilePage",
    "checkIsDisabled",
    "colorFields",
    "highlight",
    "fromEntityViews",
    "isIconRequired"
  ],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    ...mapGetters("company", ["getCompanySettings"]),
    getStyle() {
      return ` width: 100%`;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
  },
  data() {
    return {
      dates: [],
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
      pickerOptions: {
        shortcuts: [
          // {
          //   text: 'Today',
          //   onClick() {
          //     bus.$emit("date_range_entity",'TODAY')
          //   }
          // },
          {
            text: "24 Hours",
            onClick() {
              bus.$emit("date_range_entity", "TOMORROW");
            },
          },
          {
            text: "Weekly",
            onClick() {
              bus.$emit("date_range_entity", "WEEK");
            },
          },
          {
            text: "Bi-weekly",
            onClick() {
              bus.$emit("date_range_entity", "BIWEEKLY");
            },
          },
          {
            text: "Monthly",
            onClick() {
              bus.$emit("date_range_entity", "MONTHLY");
            },
          },
          {
            text: "Quarterly",
            onClick() {
              bus.$emit("date_range_entity", "QUARTERLY");
            },
          },
          {
            text: "Half-yearly",
            onClick() {
              bus.$emit("date_range_entity", "HALFYEARLY");
            },
          },
          {
            text: "Yearly",
            onClick() {
              bus.$emit("date_range_entity", "YEARLY");
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.loading = true;
    //this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    this.loading = false;
    this.$emit("applyFormRules");
  },
  methods: {
    DateRangeOption() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.daterange_settings &&
        this.data.properties.daterange_settings === "Past Dates" &&
        this.data.properties.selected_weekdays
      ) {
        const selectedWeekdays = this.data.properties.selected_weekdays;
        return {
          disabledDate: (date) => {
            const currentDate = new Date();
            if (selectedWeekdays.length > 0) {
              return (
                date.getTime() >= currentDate.getTime() ||
                !selectedWeekdays.includes(date.getDay())
              );
            } else {
              return date.getTime() >= currentDate.getTime();
            }
          },
        };
      } else if (
        this.data &&
        this.data.properties &&
        this.data.properties.daterange_settings &&
        this.data.properties.daterange_settings === "Future Dates" &&
        this.data.properties.selected_weekdays
      ) {
        const selectedWeekdays = this.data.properties.selected_weekdays;
        return {
          disabledDate: (date) => {
            const currentDate = new Date();
            if (selectedWeekdays.length > 0) {
              return (
                date.getTime() < currentDate.getTime() ||
                !selectedWeekdays.includes(date.getDay())
              );
            } else {
              return date.getTime() < currentDate.getTime();
            }
          },
        };
      } else if (
        this.data &&
        this.data.properties &&
        this.data.properties.daterange_settings &&
        this.data.properties.daterange_settings === "WeekDays"
      ) {
        let selectedWeekdays = this.data.properties.selected_weekdays;
        return {
          disabledDate: (date) => {
            const day = date.getDay();
            return !selectedWeekdays.includes(day);
          },
        };
      } else if (
        this.data &&
        this.data.properties &&
        this.data.properties.daterange_settings &&
        this.data.properties.daterange_settings === "Range" &&
        this.data.properties.selected_weekdays
      ) {
        let start = this.data.properties.date_strat_range;
        let end = this.data.properties.date_end_range;
        const selectedWeekdays = this.data.properties.selected_weekdays;
        return {
          disabledDate(date) {
            if (start && end) {
              if (selectedWeekdays.length > 0) {
                return !(
                  date.getTime() > new Date(start).getTime() &&
                  date.getTime() < new Date(end).getTime() &&
                  selectedWeekdays.includes(date.getDay())
                );
              } else {
                return !(
                  date.getTime() > new Date(start).getTime() &&
                  date.getTime() < new Date(end).getTime()
                );
              }
            }
            return false;
          },
        };
      } else {
        return {};
      }
    },

    defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key]
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      }
    },
    getFormat() {
      let format = this.getDefaultDateFormat;
      if (
        this.getCompanySettings?.priority == "company" &&
        this.getCompanySettings.date
      ) {
        format = this.getCompanySettings.date;
      }
      return format && format.includes("Do") == false
        ? format.replace("YYYY", "yyyy").replace("DD", "dd")
        : "MM-dd-yyyy";
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "DATE_RANGE") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
          this.form[this.data.key + "_from"] =
            this.getGlobalVariableById.value[0];
          this.form[this.data.key + "_to"] =
            this.getGlobalVariableById.value[1];
        }
      }
    },
    // checkReadonly() {
    //   if (this.data.styles && this.data.styles.labelStyle) {
    //     if (this.data.styles.labelStyle == "hide") {
    //       this.showLabel = false;
    //     }
    //     if (this.data.styles.labelStyle == "right") {
    //       this.isDefalutPos = false;
    //     }
    //   }
    //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // },
    readonly() {
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_global_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        //If it's an entity varaible, have to check access
        if (
          this.data.field_assignable === "read_only" &&
          this.data.is_entity_variable
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    onchange(value) {
      this.applyFormRules();
      this.form[this.data.key + "_from"] = value[0];
      this.form[this.data.key + "_to"] = value[1];
    },
  },
  created() {
    bus.$on("date_range_entity", (data) => {
      // if(data==='TODAY')
      // {
      //     const end = new Date()
      //     const start = new Date()
      //     this.formBuilder.settings.formbuilder_date_range_entity=[start, end]
      // }
      if (data === "TOMARROW") {
        const end = new Date();
        const start = new Date();
        var newDate = moment(end).add(1, "days");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "WEEK") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(7, "days");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "BIWEEKLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(15, "days");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "MONTHLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "months");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "QUARTERLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(3, "months");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "HALFYEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(6, "months");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "YEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "years");
        this.form[this.data.key] = [start, newDate];
      }
    });
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss" scoped>
.myDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: flex-start;
  overflow: auto;
  float: left;
}
.highlighted-border {
  border-color: red !important;
}
.labelStyle {
  display: contents;
}
.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
  font-size: 12px;
}
.el-input__icon {
  height: 100%;
  width: 35px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 40px;
}

.el-date-editor .el-range__icon {
  font-size: 14px;
  margin-left: -1px;
  width: 24px;
  color: #c0c4cc;
  float: left;
  line-height: 32px;
}

.el-date-editor .el-range-input {
  width: 40%;
}
.el-date-editor .el-range-separator {
  display: inline-block;
  height: 100%;
  padding: 0 2px;
  margin-top: 24px;
  text-align: center;
  line-height: 10px;
  font-size: 13px;
  width: 25%;
  color: #303133;
  font-weight: 500;
}

.el-date-editor .el-range__close-icon {
  font-size: 14px;
  color: #c0c4cc;
  width: 8px;
  display: inline-block;
  float: right;
  line-height: 32px;
}
.el-date-editor .el-range-editor .el-input__inner .el-date-editor--daterange {
  height: 70px;
  width: 100%;
}
.execute-view {
  font-weight: normal;
}
</style>
