<template>
  <div
    style="width: 100%"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-row :gutter="10" class="mlr-1">
      <el-col :sm="24" :md="24">
        <el-form
          :model="domainForm"
          :rules="rules"
          ref="domainForm"
          label-position="top"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-row :gutter="10" style="margin-bottom: 20px">
            <el-col :sm="12" :md="12">
              <el-form-item label="Domain Name" prop="domainName">
                <el-input
                  v-model="domainForm.domainName"
                  placeholder="Enter your domain name"
                  :disabled="isDomainAdded && !isDomainDeleted"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col
              :sm="4"
              :md="4"
              style="margin-top: 50px"
              v-if="!isDomainAdded || isDomainDeleted"
            >
              <el-button
                type="primary"
                size="small"
                @click="submitForm('domainForm')"
                style="width: 100%"
              >
                Add Domain
              </el-button>
            </el-col>
            <el-col
              :sm="4"
              :md="4"
              style="margin-top: 50px"
              v-if="isDomainAdded && !isDomainDeleted"
            >
              <el-button
                type="primary"
                size="small"
                @click="deleteDomain"
                style="width: 100%; margin-top: 4px"
              >
                Remove Domain
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-row class="mlr-1" v-if="isDomainAdded">
      <el-dropdown placement="bottom" style="float: right">
        <button
          class="btn btn-outline-secondary btn-sm m-lr-1 fa-lg"
          type="button"
        >
          <i class="fa fa-cloud-upload"></i>
        </button>
        <el-dropdown-menu>
          <a @click="exportToExcel">
            <el-dropdown-item>
              <i class="fa fa-file-excel-o"></i> Export as Excel
            </el-dropdown-item></a
          ></el-dropdown-menu
        >
      </el-dropdown>
    </el-row>

    <el-row v-if="isDomainAdded && dnsRecords.length" class="mlr-1">
      <el-col :sm="24" :md="24">
        <el-table :data="dnsRecords" style="width: 100%" :stripe="true">
          <el-table-column label="Key" prop="key">
            <template slot-scope="scope">
              {{ scope.row.key }}
            </template>
          </el-table-column>
          <el-table-column label="Type" prop="type">
            <template slot-scope="scope">
              {{ scope.row.type }}
            </template>
          </el-table-column>

          <el-table-column label="Host" prop="host">
            <template slot-scope="scope">
              {{ scope.row.host }}
              <el-tooltip content="Click to copy" placement="top">
                <el-button
                  icon="el-icon-copy-document"
                  size="mini"
                  type="text"
                  @click="copyValue(scope.row.data)"
                  style="padding: 0; font-size: 14px; line-height: 1; margin: 0"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="Value" prop="data">
            <template slot-scope="scope">
              {{ scope.row.data }}
              <el-tooltip content="Click to copy" placement="top">
                <el-button
                  icon="el-icon-copy-document"
                  size="mini"
                  type="text"
                  @click="copyValue(scope.row.data)"
                  style="padding: 0; font-size: 14px; line-height: 1; margin: 0"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            label="Validation Status"
            prop="valid"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span
                style="
                  font-size: 16px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <i
                  v-if="scope.row.valid"
                  class="fa fa-check"
                  style="color: green"
                ></i>
                <i v-else class="fa fa-times" style="color: red"></i>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-row class="mlr-1" v-if="isDomainAdded && !isDomainValidated">
      <el-col>
        <el-button type="primary" @click="validateDomain">Validate</el-button>
      </el-col>
    </el-row>
    <el-row
      class="mlr-1"
      v-if="isDomainAdded && isDomainValidated"
      style="display: flex; align-items: center"
    >
      <div style="font-weight: bold; font-size: 14px; color: #606266">
        From Emails
      </div>
      <el-select
        v-model="domainForm.emailprefixes"
        multiple
        filterable
        allow-create
        default-first-option
        placeholder="Enter from emails"
        style="width: 300px"
        :collapse-tags="true"
        :collapse-tags-tooltip="false"
      >
        <el-option
          v-for="item in domainForm.emailprefixes"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <div
        style="
          padding: 4px 5px;
          white-space: nowrap;
          background: #f5f7fa;
          border: 1px solid #dcdfe6;
          border-left: none;
          border-radius: 0 4px 4px 0;
        "
      >
        {{ domainForm.domainName }}
      </div>
    </el-row>
    <el-row
      class="mlr-1"
      v-if="isDomainAdded && isDomainValidated"
      style="display: flex; align-items: center"
    >
      <div style="font-weight: bold; font-size: 14px; color: #606266">
        Default Email
      </div>
      <el-input
        placeholder="Please Enter default email"
        v-model="domainForm.defaultEmail"
        style="width: 300px"
      >
      </el-input>
      <div
        style="
          padding: 4px 5px;
          white-space: nowrap;
          background: #f5f7fa;
          border: 1px solid #dcdfe6;
          border-left: none;
          border-radius: 0 4px 4px 0;
        "
      >
        {{ domainForm.domainName }}
      </div>
    </el-row>
    <el-row
      class="mlr-1"
      v-if="
        isDomainAdded &&
        isDomainValidated &&
        domainForm.emailprefixes.length &&
        domainForm.defaultEmail
      "
      style="display: flex; align-items: center"
    >
      <el-button type="primary" @click="savePrefixes" size="small">
        Save
      </el-button>
    </el-row>
  </div>
</template>

<script>
import { postAPICall } from "@/helpers/httpHelper";
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";
export default {
  name: "AddDomainComponent",
  data() {
    return {
      loading: false,
      loadingText: "Loading ...",
      isDomainAdded: false,
      isDomainValidated: false,
      isDomainDeleted: false,
      domainForm: {
        domainName: "",
        domain_id: null,
        emailprefixes: [],
        defaultEmail: null,
      },
      dnsRecords: [],
      rules: {
        domainName: [
          {
            required: true,
            message: "Please enter the domain name",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9.-]+$/,
            message: "Invalid domain format",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchExistedDomain();
  },
  computed: {
    ...mapGetters("auth", ["getActiveWorkspace"]),
  },
  methods: {
    async fetchExistedDomain() {
      const response = await postAPICall("GET", "/get-domain-details");
      if (response.data) {
        this.domainForm.domainName = response.data?.domain_info?.domain;
        this.isDomainAdded = true;
        let isValid = response.data?.domain_info?.is_validated;

        this.isDomainValidated = isValid ? true : false;
        if (response.data?.domain_info?.dns_Permissions_set) {
          this.formatDNSRecords(
            response.data?.domain_info?.dns_Permissions_set
          );
        }
        this.domainForm.domain_id = response.data?.domain_info?.domain_id;
        this.domainForm.defaultEmail = response.data?.domain_info?.defaultEmail
          ? response.data?.domain_info?.defaultEmail
          : "";
        this.domainForm.emailprefixes = response.data?.domain_info
          ?.email_prefixes
          ? response.data?.domain_info?.email_prefixes
          : [];
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        this.loading = true;
        this.loadingText = "Adding domain in server...";
        if (valid) {
          try {
            const response = await postAPICall("POST", "/white-label-domain", {
              domain: this.domainForm.domainName,
            });
            if (response && response.success) {
              this.$notify({
                title: "Success",
                message: response.message || "Domain added successfully!",
                type: "success",
              });
              this.isDomainAdded = true;
              this.isDomainDeleted = false;
              this.domainForm.domain_id = response.data.data.id;
              this.formatDNSRecords(response.data.data.dns);
            } else {
              this.$notify({
                title: "Error",
                message: "Domain addition failed!",
                type: "error",
              });
            }
          } catch (error) {
            this.$notify({
              title: "Error",
              message: "An error occurred while adding the domain.",
              type: "error",
            });
          }
        } else {
          this.$notify({
            title: "Error",
            message: "Validation failed. Please check the form.",
            type: "error",
          });
        }
        this.loading = false;
      });
    },
    formatDNSRecords(dns) {
      this.dnsRecords = Object.keys(dns).map((key) => ({
        key: key,
        type: dns[key].type,
        host: dns[key].host,
        data: dns[key].data,
        valid: dns[key].valid,
      }));
    },
    copyValue(value) {
      const textArea = document.createElement("textarea");
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.$notify({
        title: "Copied!",
        message: "The value has been copied to the clipboard.",
        type: "success",
      });
    },
    exportToExcel() {
      let worksheet = XLSX.utils.json_to_sheet(this.dnsRecords);
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "DNS Data");
      XLSX.writeFile(workbook, "dns_records.xlsx");
    },
    async validateDomain() {
      this.loading = true;
      this.loadingText = "Validating domain...";
      try {
        const response = await postAPICall("POST", "/validate-domain", {
          domain_id: this.domainForm.domain_id,
        });
        if (response && response.success) {
          let validationResults = response?.data?.data?.validation_results;
          this.dnsRecords = this.dnsRecords.map((record) => {
            let validationResult = validationResults[record.key];
            if (validationResult) {
              record.valid = validationResult.valid;
            }
            return record;
          });
          let count = 0;
          Object.keys(validationResults).forEach((key) => {
            if (validationResults[key].valid) {
              count++;
            }
          });
          if (count == 3) {
            this.isDomainValidated = true;
          }
          this.$notify({
            title: "Success",
            message: "Validation status updated successfully!",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Error",
            message: response.data.message || "Domain validation failed!",
            type: "error",
          });
        }
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "An error occurred while validating the domain.",
          type: "error",
        });
      }
      this.loading = false;
    },
    async savePrefixes() {
      this.loading = true;
      this.loadingText = "Saving domain prefixes...";
      try {
        const response = await postAPICall("POST", "/save-domain-prefixes", {
          domain_id: this.domainForm.domain_id,
          email_prefixes: this.domainForm?.emailprefixes,
          defaultEmail: this.domainForm.defaultEmail,
        });
        if (response && response.success) {
          this.$notify({
            title: "Success",
            message: "Domain prefixes saved successfully!",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Error",
            message: "An error occurred while saving the domain prefixes.",
            type: "error",
          });
        }
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "An error occurred while saving the domain prefixes",
          type: "error",
        });
      }
      this.loading = false;
    },
    async deleteDomain() {
      this.loading = true;
      this.loadingText = "Deleting domain...";
      try {
        const response = await postAPICall("DELETE", "/delete-domain", {
          domain_id: this.domainForm.domain_id,
        });
        if (response && response.success) {
          this.isDomainAdded = false;
          this.isDomainValidated = false;
          this.dnsRecords = [];
          this.domainForm.defaultEmail = null;
          this.domainForm.emailprefixes = [];
          this.isDomainDeleted = true;
          this.domainForm.domainName = "";

          this.$notify({
            title: "Success",
            message: "Domain deleted successfully!",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Error",
            message: response.data.message || "Domain deletion failed!",
            type: "error",
          });
        }
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "An error occurred while deleting the domain.",
          type: "error",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mlr-1 {
  margin: 1rem !important;
}
.el-form-item {
  margin-bottom: 15px;
}
.el-row[style] {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.el-row + .el-row {
  margin-top: 2rem;
}
</style>
