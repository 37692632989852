<template>
  <div v-loading="loading">
    <div
      v-if="
        content_fields.indexOf(selectedItem.type) == -1 ||
        (content_fields.indexOf(selectedItem.type) == -1 &&
          selectedItem.field_type == 'ENTITY_FIELD') ||
        selectedItem.field_type == 'ENTITY_VARIABLE'
      "
    >
      <el-form>
        <el-col :span="24" v-loading="loading">
          <el-form-item label="Field Type">
            <el-select
              v-model="fieldType"
              filterable
              placeholder="Select a Recipient"
              class="w-100"
              @change="changeFieldType"
              :disabled="
                (fieldType == 'ENTITY' && checkForChildren) ||
                (selectedItem.type == 'REPEATABLE_PARENT' &&
                  checkForRepeatableChild)
              "
            >
              <el-option
                v-for="(type, i) in fieldTypes"
                :key="type.type + i"
                :value="type.type"
                :label="type.label"
              >
              </el-option>
              <el-option
                v-for="item in documentRecipientsList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
                :disabled="item.user_type == 'CC'"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.email
                }}</span>
              </el-option>
              <el-option
                v-for="(type, i) in allFieldRepeatable"
                :key="type.key + i"
                :value="type.key"
                :label="type.label"
              >
                <span style="float: left">{{ type.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >Repeatable Variable</span
                >
              </el-option>
              <el-option
                v-for="(type, i) in allFieldEntities"
                :key="type.key + i"
                :value="type.key"
                :label="type.label"
              >
                <span style="float: left">{{ type.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >Entity Variable</span
                >
              </el-option>
              <el-option value="ENTITY" label="Entity">
                <span style="float: left">Entity</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >Entity</span
                >
              </el-option>
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="fieldType == 'CUSTOM_FIELD'">
          <el-form-item label="Select Field">
            <el-select v-model="selectedItem.type" @change="updateField">
              <el-option
                v-for="(ty, key) in customFields"
                :key="ty.type + key"
                :label="ty.label"
                :value="ty.type"
                >{{ ty.label }}
              </el-option>
            </el-select>
          </el-form-item>
          <div class="min-max-selections">
            <template v-if="selectedItem.type === 'MULTI_SELECT'">
              <el-form-item label="Min Selections">
                <el-input-number
                  @change="handleMinMaxValues"
                  :min="0"
                  v-model="selectedItem.min_selection"
                />
              </el-form-item>
              <el-form-item label="Max Selections">
                <el-input-number
                  :min="0"
                  v-model="selectedItem.max_selection"
                />
              </el-form-item>
              <el-form-item v-if="errorMessage" :error="errorMessage" />
            </template>
          </div>
        </el-col>
        <el-col
          :span="24"
          v-else-if="fieldType == 'ENTITY'"
          v-loading="loading"
        >
          <el-form-item label="Select Entity">
            <el-select
              v-model="selectedItem.entity_id"
              @change="changeGEntity(true)"
              :disabled="fieldType == 'ENTITY' && checkForChildren"
              :loading="loading"
              filterable
            >
              <el-option
                v-for="entity in allEntitiesList"
                :key="entity._id"
                :label="entity.name"
                :value="entity._id"
              >
                {{ entity.name }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-checkbox
              v-model="selectedItem.do_not_print_entity_primary_field"
              >Do not print field</el-checkbox
            >
            <!-- <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
        >
          <i class="el-icon-info"></i>
          <template slot="reference">
            <i class="el-icon-info"></i>
          </template>
          <p style="word-break: keep-all">
            It will only print the entity variables,
             excluding the primary fields,
              on the completed document.
          </p>
        </el-popover>
              -->
          </el-form-item>
          <el-form-item
            label="Select Entity Data"
            v-if="selectedItem.entity_id"
          >
            <el-select
              v-model="selectedItem.entity_data_id"
              @change="changeEntityData"
              :loading="loading"
              clearable
              filterable
            >
              <el-option
                v-for="(temp, i) in allEntityDataList"
                :key="temp._id"
                :value="temp._id"
                :label="getLabel(temp, i)"
                >{{ getLabel(temp) }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Label *" v-if="selectedItem.entity_id">
            <el-input v-model="selectedItem.label" width="100"></el-input>
          </el-form-item>
          <el-form-item label="Placeholder" v-if="selectedItem.entity_id">
            <el-input v-model="selectedItem.placeholder" width="100"></el-input>
          </el-form-item>
          <el-form-item label="Fields Filled by  *">
            <el-select v-model="selectedItem.filled_by">
              <el-option
                v-for="(user, index) of documentRecipientsList.filter(
                  (e) => e && e.user_type && e.user_type != 'CC'
                )"
                :key="user.value + index"
                :label="user.name"
                :value="user.value"
              >
                <span style="float: left">{{ user.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ user.email }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-checkbox
            v-model="selectedItem.allow_user_download"
            v-if="
              selectedItem.filled_by == 'SENDER' && selectedItem.type == 'FILE'
            "
            :label="true"
            >Allow the recipient to download the file.</el-checkbox
          >
          <el-checkbox
            v-model="selectedItem.required"
            v-if="selectedItem.type != 'DATE_SIGNED'"
            :label="true"
            >Required Field
          </el-checkbox>
        </el-col>
        <el-col
          :span="24"
          v-else-if="selectedItem.field_type == 'ENTITY_VARIABLE'"
        >
          <el-form-item
            label="Select Template"
            v-if="!selectedItem.parent_repeatable_field_key"
          >
            <el-select
              v-model="selectedItem.template_id"
              @change="changeGTemplate(true)"
            >
              <div v-for="temp of allEntityFormTemplatesList" :key="temp._id">
                <el-option :value="temp._id" :label="temp.name">
                  <span style="float: left">{{ temp.name }}</span>
                  <span
                    style="float: right; color: #8492a6; font-size: 13px"
                    v-if="temp.is_relational"
                    >Relation</span
                  >
                </el-option>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Select Field"
            v-if="
              selectedItem.template_id &&
              (!selectedItem.key.includes('~') ||
                selectedItem.key.includes('-'))
            "
          >
            <el-select
              v-model="selecteGField"
              @change="fillFieldData"
              :disabled="
                selectedItem.type == 'REPEATABLE_PARENT' &&
                checkForRepeatableChild
              "
            >
              <el-option
                v-if="
                  checkIsRepeatableGTemplate &&
                  !selectedItem.parent_repeatable_field_key
                "
                value="PARENT_FIELD"
                label="Repeatable Variable"
                style="color: green"
              >
              </el-option>
              <el-option
                v-for="field in selectedGTemplateFields"
                :key="field.key"
                :value="field.key"
                :label="field.label"
              >
                {{ field.label }}
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="
              selectedItem.type == 'HEADING' &&
              selectedItem.field_type === 'ENTITY_VARIABLE'
            "
            label="Select Tag *"
          >
            <el-select
              v-model="selectedItem.selected_tag"
              no-data-text="Select tag"
            >
              <el-option
                v-for="tag of ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']"
                :value="tag"
                :key="tag"
                :label="tag"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Content">
            <el-input type="textarea" v-model="selectedItem.label"></el-input>
          </el-form-item>
          <el-form-item
            label="Repeatable Data"
            v-if="
              checkIsRepeatableGTemplate &&
              !selectedItem.parent_repeatable_field_key &&
              selectedItem.type != 'REPEATABLE_PARENT'
            "
          >
            <el-select v-model="selectedItem.indexKey" @change="fillFieldData">
              <el-option
                v-for="(dt, index) in getRepeatableDataForGTemplateField()"
                :key="index"
                :label="dt.name"
                :value="dt.indexKey"
              >
                {{ dt.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-else>
          <el-form-item
            label="Select Templates"
            v-if="!selectedItem.parent_repeatable_field_key"
          >
            <el-select v-model="fieldTemplate" @change="changeTemplate">
              <el-option
                v-for="temp in fieldTemplates"
                :key="temp.template_id"
                :value="temp.template_id"
                :label="temp.name"
                >{{ temp.label }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="selectedItem.field_type == 'ENTITY_FIELD'" :span="24">
          <el-form>
            <div class="min-max-selections">
              <template v-if="selectedItem.type === 'MULTI_SELECT'">
                <el-form-item label="Min Selections">
                  <el-input-number
                    @change="handleMinMaxValues"
                    :min="0"
                    v-model="selectedItem.min_selection"
                  />
                </el-form-item>
                <el-form-item label="Max Selections">
                  <el-input-number
                    :min="0"
                    v-model="selectedItem.max_selection"
                  />
                </el-form-item>
                <el-form-item v-if="errorMessage" :error="errorMessage" />
              </template>
            </div>
            <el-form-item
              label="Select Field"
              v-if="
                selectedItem.type != 'PRIMARY_FIELDS' &&
                (!selectedItem.key.includes('~') ||
                  selectedItem.key.includes('-'))
              "
            >
              <el-select v-model="entityField" @change="addEntityField">
                <el-option
                  v-if="
                    (selectedItem.indexKey || checkIsRepeatable) &&
                    !selectedItem.parent_repeatable_field_key
                  "
                  value="PARENT_FIELD"
                  label="Repeatable Variable"
                  style="color: green"
                >
                </el-option>
                <el-option
                  v-for="el in entityFields"
                  :key="el.key"
                  :label="el.label"
                  :value="el.key"
                  >{{ el.label }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item
              label="Repeatable Data"
              v-if="
                (selectedItem.indexKey || checkIsRepeatable) &&
                !selectedItem.parent_repeatable_field_key
              "
            >
              <el-select
                v-model="selectedItem.indexKey"
                @change="changeRepeatableData"
              >
                <el-option
                  v-for="dt of getRepeatableDataForIndividualField(
                    selectedItem
                  )"
                  :key="dt.indexKey"
                  :label="dt.name"
                  :value="dt.indexKey"
                >
                  <span>{{ dt.name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-form>
    </div>
    <div v-if="selectedItem.type == 'PRIMARY_FIELDS'">
      <el-form>
        <el-col :span="24">
          <el-form-item
            label="Select Field"
            v-if="selectedItem.type == 'PRIMARY_FIELDS'"
          >
            <el-select
              v-model="selectedItem.selected_tag"
              @change="changeToPrimaryField"
            >
              <el-option
                v-for="(field, index) of Object.keys(userFields)"
                :key="index"
                :label="userFields[field]"
                :value="field"
                >{{ userFields[field] }}</el-option
              >
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Placeholder">
            <el-input v-model="selectedItem.placeholder" width="100"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            v-if="
              selectedItem.selected_tag == 'full_name' ||
              selectedItem.selected_tag == 'first_name' ||
              selectedItem.selected_tag == 'last_name'
            "
          >
            <el-checkbox v-model="selectedItem.is_use_boxes"
              >Split data into boxes</el-checkbox
            >
          </el-form-item>
        </el-col>
        <el-col :span="8" class="mr-1">
          <el-form-item
            v-if="
              selectedItem.is_use_boxes &&
              (selectedItem.selected_tag == 'full_name' ||
                selectedItem.selected_tag == 'first_name' ||
                selectedItem.selected_tag == 'last_name')
            "
          >
            <label>Add box width</label>
            <el-input
              placeholder="example 20"
              type="number"
              v-model="selectedItem.boxWidth"
              @input="handleEnteredBoxWidth"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="ml-1">
          <el-form-item v-if="selectedItem.is_use_boxes">
            <label>Enter number of boxes to be added</label>
            <el-input
              type="number"
              :disabled="!selectedItem.boxWidth"
              v-model="selectedItem.boxNumber"
              @input="handleEnteredBoxNumbers"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24" 
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'INITIAL' &&
            selectedItem.type != 'SELECT' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'DATE_TIME_RANGE' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'LIST' &&
            selectedItem.type != 'TIME_RANGE' &&
            selectedItem.type != 'DATE_RANGE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'my-signature' &&
            selectedItem.type != 'MY_INITIAL' &&
            selectedItem.type != 'MULTI_SELECT' &&
            selectedItem.type != 'MULTI_LINE_TEXT' &&
            selectedItem.type != 'ATTACHMENT' &&
            selectedItem.type != 'ENTITY'
          "
          >
          <el-form-item>
            <el-radio-group v-model="selectedItem.alignStyles">
              <el-radio-button size="mini" @click="alignLeft()" :label="'left'">
                <img src="@/assets/img/Left.svg"  height="15px" width="15px"/>
              </el-radio-button>
              <el-radio-button size="mini" @click="alignRight()" label="Center">
                <img src="@/assets/img/Middle.svg"  height="15px" width="15px"/>
              </el-radio-button>
              <el-radio-button size="mini" @click="alignMiddle()" label="Right">
                <img src="@/assets/img/Right.svg"  height="15px" width="15px"/>
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col> -->
        <el-col
          :span="24"
          v-if="
            selectedItem.selected_tag == 'address' &&
            selectedItem.filled_by == 'SENDER'
          "
        >
          <el-form-item>
            <el-radio-group
              v-model="selectedItem.addressInfo"
              @change="handleAddressChange"
            >
              <el-radio-button
                @click="assignPersonalAddress()"
                label="Personal Address"
              ></el-radio-button>
              <el-radio-button
                @click="assignCompanyAddress()"
                label="Company Address"
              ></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col
          :span="24"
          v-if="
            (selectedItem && selectedItem.is_entity_variable) ||
            selectedItem.field_type == 'ENTITY_VARIABLE'
          "
        >
          <el-checkbox v-model="selectedItem.field_assignable"
            >Update Parent</el-checkbox
          >
        </el-col>
        <el-col :span="24" v-if="!selectedItem.group_key">
          <el-form-item label="Fields Filled by  *">
            <el-select v-model="selectedItem.filled_by">
              <el-option
                v-for="(user, index) of documentRecipientsList.filter(
                  (e) => e && e.user_type && e.user_type != 'CC'
                )"
                :key="user.value + index"
                :label="user.name"
                :value="user.value"
              >
                <span style="float: left">{{ user.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ user.email }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-checkbox
          v-model="selectedItem.allow_user_download"
          v-if="
            selectedItem.filled_by == 'SENDER' && selectedItem.type == 'FILE'
          "
          :label="true"
          >Allow the recipient to download the file.</el-checkbox
        >
        <el-checkbox
          v-model="selectedItem.required"
          v-if="selectedItem.type != 'PRIMARY_FIELDS'"
          :label="true"
          >Required Field
        </el-checkbox>
      </el-form>
    </div>
    <div v-else-if="selectedItem.section == 'display'">
      <div v-if="selectedItem.type == 'MY_INITIAL'">
        <Initial ref="myInitialPad"></Initial>
      </div>
      <img
        :src="selectedItem.url"
        v-if="selectedItem.type == 'logo'"
        height="100"
      />
      <template v-if="selectedItem.type == 'my-signature'">
        <MySignature
          ref="mySignaturePad"
          :selectedItem="selectedItem"
          :fromDocument="true"
        />
      </template>
    </div>

    <div
      v-else-if="
        selectedItem.type == 'HEADING' &&
        selectedItem.field_type !== 'ENTITY_VARIABLE'
      "
    >
      <el-form>
        <el-form-item label="Select Tag *">
          <el-select
            v-model="selectedItem.selected_tag"
            no-data-text="Select tag"
          >
            <el-option
              v-for="tag of ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']"
              :value="tag"
              :key="tag"
              :label="tag"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Content">
          <el-input type="textarea" v-model="selectedItem.content"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div v-else-if="selectedItem.type == 'PARAGRAPH'">
      <el-form v-if="selectedItem.field_type !== 'ENTITY_FIELD'">
        <el-form-item label="Content" class="paragraph-field-content">
          <el-input type="textarea" v-model="selectedItem.content"></el-input>
        </el-form-item>
      </el-form>
      <el-form v-else>
        <el-form-item label="Content" class="paragraph-field-content">
          <el-input type="textarea" v-model="selectedItem.value"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div v-else-if="selectedItem.type == 'SINGLE_LINE_CONTENT'">
      <el-form>
        <el-form-item label="Content">
          <el-input type="text" v-model="selectedItem.content"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div v-else-if="selectedItem.type == 'DATA_TABLE'">
      <el-form :inline="true">
        <el-form-item label="Label">
          <el-input v-model="selectedItem.label" width="100"></el-input>
        </el-form-item>
        <el-form-item>
          <h4>Table Header Properties :</h4>
          <el-row>
            <el-col :span="selectedItem.map_keys ? 24 : 24">
              <el-checkbox v-model="selectedItem.map_keys"
                >Do not map with configured keys</el-checkbox
              >
            </el-col>

            <el-col :span="10" class="mr-1" v-if="!selectedItem.map_keys">
              <label>Add Column Title*</label>
              <el-input
                placeholder="Enter column headers seperated by ','"
                type="textarea"
                v-model="selectedItem.latestHeader"
                @keyup.enter.native="addTableHeader(selectedItem.latestHeader)"
              ></el-input>

              <el-button
                type="primary"
                class="button-new-tag mt-1"
                size="mini"
                @click="addTableHeader(selectedItem.latestHeader)"
                :disabled="
                  !selectedItem.latestHeader ||
                  selectedItem.headers.indexOf(selectedItem.latestHeader) != -1
                "
                >+ Add Options</el-button
              >
            </el-col>
            <el-col :span="10" class="m1-1">
              <label class="m1-1">Add Column Width</label>
              <el-input
                placeholder="ex: min 100,100,100"
                type="text"
                v-model="selectedItem.widthHeader"
                @input="validateInput"
                @keyup.enter.native="addColumnsWidth(selectedItem.widthHeader)"
              ></el-input>
              <el-button
                type="primary"
                class="button-new-tag mt-1"
                size="mini"
                @click="addColumnsWidth(selectedItem.widthHeader)"
                :disabled="
                  !selectedItem.widthHeader ||
                  selectedItem.columnWidth.indexOf(selectedItem.widthHeader) !=
                    -1
                "
                >+ Add Options</el-button
              >
            </el-col>
            <el-col :span="8" class="ml-1" v-if="selectedItem.map_keys">
              <label>Add Columns</label>
              <el-input-number
                type="number"
                :min="0"
                v-model="selectedItem.cols"
                @change="handleColumnCountChange"
              ></el-input-number>
            </el-col>
          </el-row>

          <!-- <el-input type="text" v-model="selectedItem.latestHeader"></el-input>
          <el-button
            class="mt-1"
            type="success"
            icon="el-icon-check"
            size="small"
            @click="addTableHeader(selectedItem.latestHeader)"
            :disabled="
              !selectedItem.latestHeader ||
              selectedItem.headers.indexOf(selectedItem.latestHeader) != -1
            "
            >Add</el-button
          > -->
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-radio-group v-model="selectedItem.borderOption">
              <el-col :span="6">
                <el-radio :label="7">Print all borders</el-radio>
              </el-col>
              <el-col :span="8">
                <el-radio :label="1">Print header borders</el-radio>
              </el-col>
              <el-col :span="8">
                <el-radio :label="2">Print header & last borders</el-radio>
              </el-col>
              <el-col :span="14" style="margin-top: 15px">
                <el-radio :label="3"
                  >Print last column, header & last borders</el-radio
                >
              </el-col>
              <el-col :span="8" style="margin-top: 15px">
                <el-radio :label="4">Do not print table headers</el-radio>
              </el-col>
              <el-col :span="24" style="margin-top: 15px">
                <el-radio :label="5"
                  >Print last column, header & last borders with left
                  alignment</el-radio
                >
              </el-col>
              <el-col :span="24" style="margin-top: 15px">
                <el-radio :label="6"
                  >Print headers with column borders only</el-radio
                >
              </el-col>
            </el-radio-group>
          </el-row>
          <!-- <el-divider type="horizontal"></el-divider> -->
        </el-form-item>
        <el-divider mode="horizontal"></el-divider>
        <el-form-item>
          <el-row>
            <el-col :span="24">
              <h3>Header Styles</h3>
            </el-col>
            <el-col :span="8">
              <label>Border Width</label>
              <el-input-number
                type="number"
                :min="1"
                v-model="selectedItem.borderWidth"
              ></el-input-number>
            </el-col>
            <el-col :span="8" class="ml-1">
              <label>Select Font</label>
              <el-select
                v-model="selectedItem.headerFont.name"
                placeholder="Select"
                class="text-editot-slt"
              >
                <el-option v-for="item in fontTypes" :key="item" :value="item">
                  <p :style="getFontType(item)">{{ item }}</p>
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6" class="ml-1">
              <label>Header Font Color</label>
              <el-color-picker
                v-model="selectedItem.headerFont.color"
                class="text-editor-color"
              ></el-color-picker>
            </el-col>
            <el-col :span="9">
              <label>Header Font Size</label>
              <el-select
                v-model="selectedItem.headerFont.size"
                placeholder="Size"
                class="text-editot-slt-size"
              >
                <el-option
                  v-for="item in fontSizes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="4" class="ml-1">
              <label>Header Bold</label>
              <el-button
                type="plain"
                @click="makeTextBold(selectedItem)"
                :class="
                  selectedItem.headerFont.style == 1 ||
                  selectedItem.headerFont.style == 3
                    ? 'text-bold text-editot-btn-active'
                    : 'text-bold text-editot-btn'
                "
              >
                <b>B</b>
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <label>Enter Header Height:</label>
              <el-input
                type="number"
                v-model="selectedItem.heightHeader"
                placeholder="By default 20."
              ></el-input>
            </el-col>
            <el-col :span="9" class="ml-1">
              <label>Enter Footer Height:</label>
              <el-input
                type="number"
                v-model="selectedItem.heightFooter"
                placeholder="By default 15."
              ></el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24" class="mt-1">
              <h3>Row Styles</h3>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" class="ml-1">
              <label>Select Rows Font</label>
              <el-select
                v-model="selectedItem.rowFont.name"
                placeholder="Select"
                class="text-editot-slt"
              >
                <el-option v-for="item in fontTypes" :key="item" :value="item">
                  <p :style="getFontType(item)">{{ item }}</p>
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="9" class="ml-1">
              <label>Row Font Size</label>
              <el-select
                v-model="selectedItem.rowFont.size"
                placeholder="Size"
                class="text-editot-slt-size"
              >
                <el-option
                  v-for="item in fontSizes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="15" class="ml-1">
              <label>Enter the number of rows per table:</label>
              <el-input
                type="number"
                v-model="selectedItem.number_of_rows"
                placeholder="By default 10."
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="15" class="ml-1">
              <label>Enter row height:</label>
              <el-input
                type="number"
                v-model="selectedItem.rowHeight"
                placeholder="By default 20."
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <div v-else-if="selectedItem.section == 'table'">
      <el-form :inline="true">
        <el-form-item label="Edit Column Title*" v-if="selectedHeader">
          <el-input type="text" v-model="selectedHeader"></el-input>
          <el-button
            class="mt-1"
            type="success"
            icon="el-icon-check"
            size="small"
            @click="updateTableColumn"
            :disabled="!selectedHeader"
            >Update</el-button
          >
        </el-form-item>
        <el-form-item label="Add Column Title*" v-else>
          <el-input type="text" v-model="selectedItem.latestHeader"></el-input>
          <el-button
            class="mt-1"
            type="success"
            icon="el-icon-check"
            size="small"
            @click="addTableHeader(selectedItem.latestHeader)"
            :disabled="
              !selectedItem.latestHeader ||
              selectedItem.headers.indexOf(selectedItem.latestHeader) != -1
            "
            >Add</el-button
          >
        </el-form-item>
      </el-form>

      <div
        v-if="
          selectedItem &&
          selectedItem.headers &&
          selectedItem.headers.length &&
          !tableElementLoading
        "
      >
        <div v-loading="tableLoading">
          <el-table :data="selectedItem.data" style="width: 100%">
            <el-table-column type="index" width="30"></el-table-column>
            <el-table-column
              v-for="(item, tindex) of getTableHeaders"
              :key="selectedItem.headers[tindex]"
            >
              <template slot="header">
                {{ item }}
                <span>
                  <el-button
                    type="text"
                    size="mini"
                    class="tomato-red"
                    @click="editTableColumn(tindex)"
                    icon="el-icon-edit"
                  ></el-button>
                  <el-button
                    type="text"
                    size="mini"
                    class="tomato-red"
                    @click="deleteTableColumn(item)"
                    icon="el-icon-delete"
                  ></el-button>
                </span>
              </template>
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="selectedItem.data[scope.$index][item]"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Actions" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  @click="deleteTableRow(scope.$index)"
                  icon="el-icon-delete"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="text-right">
          <el-button
            @click="addTableRow"
            type="primary"
            plain
            class="mt-1"
            size="small"
            >Add A Row</el-button
          >
        </div>
      </div>
    </div>
    <div
      v-else-if="
        selectedItem.type == 'IMAGE' &&
        selectedItem.field_type != 'ENTITY_FIELD' &&
        selectedItem.field_type != 'ENTITY_VARIABLE'
      "
    >
      <el-form label-position="top">
        <el-form-item label="Label">
          <el-input v-model="selectedItem.label" width="100"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <vue-anka-cropper
          class="upload-file"
          :options="{
            aspectRatio: false,
            closeOnSave: true,
            cropArea: 'box',
            croppedHeight: 1000,
            croppedWidth: 1000,
            cropperHeight: false,
            dropareaMessage: ' Drag & Drop  file here or use the button below.',
            frameLineDash: [1, 1000],
            frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
            handleFillColor: 'rgba(255, 255, 255, 0.2)',
            handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
            handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
            handleSize: 5,
            handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
            layoutBreakpoint: 40,
            maxCropperHeight: 1000,
            maxFileSize: 8000000,
            overlayFill: 'rgba(0, 0, 0, 0.5)',
            previewOnDrag: true,
            previewQuality: 0.65,
            resultQuality: 0.8,
            resultMimeType: 'image/jpeg',
            resultMimeType: 'image/png',
            resultMimeType: 'image/jpg',
            resultMimeType: 'image/gif',
            selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

            showPreview: true,
            skin: 'light',
            uploadData: {},
            uploadTo: false,
          }"
          name="logo"
          @cropper-error="fileError"
          @cropper-preview="uploadImageFile"
          @cropper-file-selected="uploadImageFile"
          v-loading="uploadImageLoading"
        ></vue-anka-cropper>
      </div>
    </div>
    <div v-else-if="selectedItem.type == 'ATTACHMENT'">
      <el-form label-position="top">
        <el-form-item label="Label">
          <el-input v-model="selectedItem.label" width="100"></el-input>
        </el-form-item>
        <el-form-item label="Document Name">
          <el-select
            placeholder="Document names"
            :clearable="true"
            :filterable="true"
            :multiple="true"
            :allow-create="true"
            :default-first-option="true"
            v-model="selectedItem.file_names"
            style="width: 100%"
            @change="checkRequiredFiles"
          ></el-select>
        </el-form-item>
        <div
          v-if="selectedItem.required && selectedItem.file_names.length"
          style="display: flex"
          class="mb-1"
        >
          <label class="mr-1" style="margin-top: 1%">Requires atleast</label>
          <el-input
            placeholder="1"
            :min="1"
            type="number"
            size="medium"
            :max="selectedItem.file_names.length"
            v-model="selectedItem.requiredFiles"
            @change="checkRequiredFiles"
            style="width: 20%"
            class="mr-1"
          >
          </el-input>
          <p style="margin-top: 1%">documents</p>
        </div>
        <el-row :gutter="10" v-if="selectedItem.filled_by == 'SENDER'">
          <el-col :span="24" class="mb-1" style="font-weight: 500">
            {{ selectedAttachmentFile.title }}
          </el-col>
          <el-col :span="12">
            <span>Document name</span>
            <el-select
              placeholder="Select Name"
              :filterable="true"
              :allow-create="true"
              clearable
              :default-first-option="true"
              v-model="selectedAttachmentFile.document_name"
              style="width: 100%"
            >
              <el-option
                v-for="(name, index) in selectedItem.file_names"
                :key="index"
                :value="name"
                >{{ name }}</el-option
              >
            </el-select>
          </el-col>
          <el-col :span="12">
            <span>Document ID</span>
            <el-input
              placeholder="Enter number"
              v-model="selectedAttachmentFile.document_number"
              style="width: 100%"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span>Description</span>
            <el-input
              placeholder="Enter description"
              v-model="selectedAttachmentFile.description"
              style="width: 100%"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span>Valid from</span>
            <el-date-picker
              v-model="selectedAttachmentFile.valid_from"
              type="date"
              v-bind:clearable="true"
              v-bind:editable="false"
              v-bind:picker-options="setMaxDate"
              placeholder="Select Date"
              style="width: 100%"
              :format="getGlobalDateSettings"
            ></el-date-picker>
          </el-col>
          <el-col :span="8">
            <span>Expires on</span>
            <el-date-picker
              type="date"
              v-model="selectedAttachmentFile.expires_on"
              v-bind:clearable="true"
              v-bind:editable="false"
              v-bind:picker-options="setMinDate"
              placeholder="Select Date"
              style="width: 100%"
              :format="getGlobalDateSettings"
            ></el-date-picker>
          </el-col>
        </el-row>
        <div
          v-loading="attachmentLoading"
          class="mt-1"
          v-if="selectedItem.filled_by == 'SENDER'"
        >
          <el-upload
            class="avatar-uploader"
            action
            :on-change="uploadFile"
            :show-file-list="false"
            :auto-upload="false"
            multiple
          >
            <div class="upload-attchment-box">
              <img
                src="@/assets/img/fields/attachment-filling.svg"
                alt="Upload File"
                height="20"
              />
              <h4 class="ml-1">Upload a file</h4>
            </div>
          </el-upload>
        </div>
        <div
          v-if="selectedItem.files && selectedItem.files.length"
          style="margin-top: 1%"
        >
          Uploaded files
          <el-collapse accordion>
            <el-collapse-item
              v-for="(file, key) in selectedItem.files"
              :key="key"
              :name="key"
              :title="file.document_name || `Attachment ${key + 1}`"
            >
              <p>{{ file.title }}</p>
              <p>Name:{{ file.document_name || "-" }}</p>
              <p>Id:{{ file.document_number || "-" }}</p>
              <p>Description: {{ file.description || "-" }}</p>
              <p>Valid from: {{ file.valid_from | globalDateFormat }}</p>
              <p>Expires on: {{ file.expires_on | globalDateFormat }}</p>
              <el-button @click="removeAttachment(key)" type="danger"
                >Remove</el-button
              >
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- <el-col :span="24"
        v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'INITIAL' &&
            selectedItem.type != 'LIST' &&
            selectedItem.type != 'SELECT' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'DATE_TIME_RANGE' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'TIME_RANGE' &&
            selectedItem.type != 'DATE_RANGE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'my-signature' &&
            selectedItem.type != 'MY_INITIAL' &&
            selectedItem.type != 'MULTI_LINE_TEXT' &&
            selectedItem.type != 'ATTACHMENT' &&
            selectedItem.type != 'MULTI_SELECT' &&
            selectedItem.type != 'ENTITY'
          "
        >
          <el-form-item>
            <el-radio-group v-model="selectedItem.alignStyles">
              <el-radio-button size="mini" @click="alignLeft()" :label="'left'">
                <img src="@/assets/img/Left.svg"  height="15px" width="15px"/>
              </el-radio-button>
              <el-radio-button size="mini" @click="alignRight()" label="Center">
                <img src="@/assets/img/Middle.svg"  height="15px" width="15px"/>
              </el-radio-button>
              <el-radio-button size="mini" @click="alignMiddle()" label="Right">
                <img src="@/assets/img/Right.svg"  height="15px" width="15px"/>
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col> -->
        <el-col
          :span="24"
          v-if="
            selectedItem.selected_tag == 'address' &&
            selectedItem.filled_by == 'SENDER'
          "
        >
          <el-form-item>
            <el-radio-group v-model="selectedItem.addressInfo">
              <el-radio-button
                @click="assignPersonalAddress()"
                label="Personal Address"
              ></el-radio-button>
              <el-radio-button
                @click="assignCompanyAddress()"
                label="Company Address"
              ></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-form-item>
          <el-checkbox
            v-if="
              (selectedItem && selectedItem.is_entity_variable) ||
              selectedItem.field_type == 'ENTITY_VARIABLE'
            "
            v-model="selectedItem.field_assignable"
            >Update Parent</el-checkbox
          >
        </el-form-item>
        <el-form-item label="Fields Filled by">
          <el-select v-model="selectedItem.filled_by" width="100">
            <el-option
              v-for="(user, index) of documentRecipientsList.filter(
                (e) => e && e.user_type && e.user_type != 'CC'
              )"
              :key="index"
              :label="user.name"
              :value="user.value"
            >
              <span style="float: left">{{ user.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">
                {{ user.email }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          label="Field belong to"
          v-if="selectedItem.field_type == 'ENTITY_FIELD'"
        >
          <el-select v-model="selectedItem.selected_user" disabled>
            <el-option
              v-for="(user, index) of documentRecipientsList"
              :key="index"
              :label="user.name"
              :value="user.value"
            >
              <span style="float: left">{{ user.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                user.email
              }}</span>
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-checkbox
          v-model="selectedItem.allow_user_download"
          v-if="
            selectedItem.filled_by == 'SENDER' && selectedItem.type == 'FILE'
          "
          :label="true"
          >Allow the recipient to download the file.</el-checkbox
        >
        <el-checkbox
          v-model="selectedItem.required"
          v-if="selectedItem.type != 'DATE_SIGNED'"
          :label="true"
          >Required Field
        </el-checkbox>
      </el-form>
    </div>
    <div v-else>
      <el-form label-position="top">
        <el-col :span="24" v-if="selectedItem.type == 'CURRENCY'">
          <el-form-item label="Select currency type *">
            <el-select
              v-model="selectedItem.validations.currency"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in CurrencyTypes"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <span style="float: left">{{ item.value }}</span>
                <span
                  style="
                    float: right;
                    color: var(--el-text-color-secondary);
                    font-size: 13px;
                  "
                  >{{ item.name }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-form-item label="Label *" v-if="fieldType != 'ENTITY'">
          <el-input v-model="selectedItem.label" width="100"></el-input>
        </el-form-item>
        <el-form-item
          v-if="
            ((selectedItem.selected_tag == 'full_name' ||
              selectedItem.selected_tag == 'first_name' ||
              selectedItem.selected_tag == 'last_name') &&
              selectedItem.type == 'PRIMARY_FIELDS') ||
            selectedItem.type == 'SINGLE_LINE_TEXT' ||
            selectedItem.type == 'NUMBER' ||
            selectedItem.type == 'DATE'
          "
        >
          <el-checkbox v-model="selectedItem.is_use_boxes"
            >Split data into boxes</el-checkbox
          >
          <el-popover placement="top-start" width="200" trigger="hover">
            <i class="el-icon-info"></i>
            <template slot="reference">
              <i class="el-icon-info"></i>
            </template>
            <p style="word-break: keep-all">
              For certain types of PDF documents, such as bank applications and
              other forms, if they contain boxes that need to be filled in, you
              can enable this feature to input data into those boxes.
            </p>
          </el-popover>
        </el-form-item>
        <el-form-item
          label="Placeholder"
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'INITIAL' &&
            selectedItem.type != 'ENTITY'
          "
        >
          <el-input v-model="selectedItem.placeholder" width="100"></el-input>
        </el-form-item>

        <!-- <el-form-item
              label="No of Rows"
              v-if="selectedItem.type == 'MULTI_LINE_TEXT'"
            >
              <el-input v-model="selectedItem.rows" width="100"></el-input>
            </el-form-item> -->
        <el-form-item
          v-if="
            selectedItem.is_use_boxes &&
            (selectedItem.type == 'SINGLE_LINE_TEXT' ||
              selectedItem.type == 'NUMBER' ||
              selectedItem.type == 'DATE')
          "
        >
          <label>Add box width</label>
          <el-input
            type="number"
            v-model="selectedItem.boxWidth"
            @input="handleEnteredBoxWidth"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.is_use_boxes &&
            (selectedItem.type == 'SINGLE_LINE_TEXT' ||
              selectedItem.type == 'NUMBER' ||
              selectedItem.type == 'DATE')
          "
        >
          <label>Enter number of boxes to be added</label>
          <el-input
            type="number"
            :disabled="!selectedItem.boxWidth"
            v-model="selectedItem.boxNumber"
            @input="handleEnteredBoxNumbers"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type != 'FILE' &&
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'RADIO_BUTTON_GROUP' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'LIST' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'DATE_TIME_RANGE' &&
            selectedItem.type != 'DATE_RANGE' &&
            selectedItem.type != 'SELECT' &&
            selectedItem.type != 'MULTI_SELECT' &&
            selectedItem.type != 'INITIAL' &&
            selectedItem.type != 'ZSEAL' &&
            selectedItem.type != 'ENTITY' &&
            selectedItem.type != 'IMAGE' &&
            selectedItem.type != 'TIME_RANGE' &&
            selectedItem.type != 'FIXED_TIME' &&
            selectedItem.type != 'WEEKDAYS'
          "
          label="Value"
        >
          <el-input
            @input="handleChangeBox"
            v-model="selectedItem.value"
            width="100"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="selectedItem.type == 'YES_OR_NO'" label="Value">
          <el-radio v-model="selectedItem.value" label="YES">Yes</el-radio>
          <el-radio v-model="selectedItem.value" label="NO">No</el-radio>
          <el-button @click="selectedItem.value = ''">None Selected</el-button>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type == 'FIXED_TIME' ||
            (selectedItem.type == 'TIME' &&
              selectedItem.is_fixed &&
              selectedItem.field_type == 'ENTITY_FIELD')
          "
        >
          <el-row>
            <el-col :span="18">
              <label>Time Range</label>
              <el-time-picker
                is-range
                v-model="selectedItem.time_range"
                range-separator="To"
                start-placeholder="Start time"
                end-placeholder="End time"
              >
              </el-time-picker>
            </el-col>
            <el-col :span="6" class="step-time-head">
              <label>Step Time</label>
              <el-select
                placeholder="Step time"
                v-model="selectedItem.step_time"
                filterable
                style="width: 100%"
                class="step-time"
              >
                <el-option
                  v-for="t in 11"
                  :key="t + 'start'"
                  :value="t * 5 + ':00'"
                  :label="t * 5 + ' minutes'"
                >
                  {{ t * 5 + " minutes" }}
                </el-option>
                <el-option
                  v-for="t in 7"
                  :key="t + 'start_hours'"
                  :value="t * 60 + ':00'"
                  :label="t * 60 + ' minutes'"
                >
                  {{ t * 60 + " minutes" }}
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-divider v-if="selectedItem.type == 'LIST'"></el-divider>
        <el-row v-if="selectedItem.type == 'LIST'">
          <el-col :span="24">
            <el-form-item label="List Items Data"> </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-input
                v-model="newElement.name"
                placeholder="Enter Name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="ml-1">
            <el-form-item>
              <el-input
                v-model="newElement.value"
                placeholder="Enter Value"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="ml-1">
            <el-form-item>
              <el-button
                @click="addElement"
                type="plain"
                icon="el-icon-plus"
                class="f-weight-600"
              >
                Add
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          class="vue-data-table-mini mb-2"
          v-if="this.selectedItem.type == 'LIST'"
        >
          <el-table border :data="this.selectedItem.list_data">
            <el-table-column prop="name" label="Name" min-width="250">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="value" label="Value" width="250">
              <template slot-scope="scope">
                <el-input v-model="scope.row.value"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="value"
              label="Actions"
              width="100"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  @click="deleteValue(scope.$index)"
                  icon="el-icon-delete"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- <el-form-item v-if="selectedItem.type == 'RADIO_BUTTON_GROUP'" label="Value">
          <el-radio-group
            v-model="selectedItem.value"
           
            :change="changeField"
          >
            <el-radio
              v-for="(option, index) of selectedItem.options"
              :value="option"
              :key="index"
              :label="option"
              >{{ option }}</el-radio
            >

          </el-radio-group>   
          
        </el-form-item> -->
        <el-checkbox
          v-if="
            selectedItem.type == 'CHECKBOX' ||
            selectedItem.inputType == 'CHECKBOX_GROUP'
          "
          v-model="selectedItem.is_use_cross_mark"
          >Use cross mark in checkbox</el-checkbox
        >
        <el-form-item
          :class="selectedItem.is_use_cross_mark ? 'css-label' : ''"
          v-if="
            selectedItem.type == 'CHECKBOX' ||
            selectedItem.inputType == 'CHECKBOX_GROUP'
          "
          label="Value"
        >
          <input
            type="checkbox"
            v-if="
              (!selectedItem.is_use_cross_mark &&
                selectedItem.type == 'CHECKBOX') ||
              selectedItem.inputType == 'CHECKBOX_GROUP'
            "
            v-model="selectedItem.value"
            style="width: 25px; height: 25px"
            :disabled="checkBoxDisabled(selectedItem)"
          />
          <input
            type="checkbox"
            class="custom-checkbox"
            v-if="
              (selectedItem.is_use_cross_mark &&
                selectedItem.type == 'CHECKBOX') ||
              selectedItem.inputType == 'CHECKBOX_GROUP'
            "
            v-model="selectedItem.value"
            style="width: 25px; height: 25px"
            :disabled="checkBoxDisabled(selectedItem)"
          />
          <!-- <el-switch class="mb-2 ml-1" v-model="selectedItem.is_off_check_mark" @change="handleSwitchChange"></el-switch>
          <input type="checkbox" /> 
          <label  for="demo_box_2" name="demo_lbl_2" class="css-label">Value</label> --->

          <div
            v-if="
              (selectedItem.type == 'CHECKBOX' ||
                selectedItem.inputType == 'CHECKBOX_GROUP') &&
              selectedItem.field_type != 'ENTITY_FIELD' &&
              !selectedItem.group_key
            "
          >
            <el-checkbox v-model="selectedItem.is_show_label"
              >Show Label</el-checkbox
            >
          </div>
          <div
            v-if="
              selectedItem.type == 'CHECKBOX' &&
              selectedItem.field_type != 'ENTITY_FIELD' &&
              !selectedItem.group_key
            "
          >
            <el-checkbox v-model="selectedItem.is_check_box_group"
              >Checkbox Group</el-checkbox
            >
          </div>
          <el-row
            :gutter="10"
            v-if="
              selectedItem.is_check_box_group ||
              selectedItem.group_key ||
              selectedItem.inputType == 'CHECKBOX_GROUP' ||
              selectedItem.inputType == 'CHECKBOX'
            "
          >
            <el-col :span="12">
              <span>Select Group</span>
              <div class="d-flex">
                <el-select
                  default-first-option
                  placeholder="Select Group"
                  v-model="selectedItem.group_key"
                  clearable
                  style="width: 100%"
                  :disabled="selectedItem.field_type == 'ENTITY_FIELD'"
                  @change="updateEntityFields"
                >
                  <el-option
                    v-for="(name, index) in checkBoxGroupArray.filter(
                      (e) => !e.isFromFormTemplate
                    )"
                    :value="name.key"
                    :key="index"
                    :label="name.label ? name.label : name.key"
                    >{{ name.label }}
                  </el-option>
                </el-select>
                <el-tooltip
                  v-if="selectedItem.field_type != 'ENTITY_FIELD'"
                  placement="top-left"
                  content="Add Group"
                >
                  <el-button
                    v-if="selectedItem.field_type != 'ENTITY_FIELD'"
                    @click="openAddGroupDialog"
                    class="ml-1"
                    type="primary"
                    size="mini"
                  >
                    <i class="el-icon-circle-plus-outline"></i
                  ></el-button>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="4" v-if="selectedItem.group_key">
              <div>
                <span>Min</span>
                <span>
                  <el-input
                    :disabled="selectedItem.field_type == 'ENTITY_FIELD'"
                    type="number"
                    @blur="checkMinValue"
                    v-model="checkBoxGrpObj.min_value"
                    placeholder="Minimum Value"
                  ></el-input>
                </span>
              </div>
            </el-col>
            <el-col :span="4" v-if="selectedItem.group_key">
              <div>
                <span>Max</span>
                <span>
                  <el-input
                    :disabled="selectedItem.field_type == 'ENTITY_FIELD'"
                    type="number"
                    v-model="checkBoxGrpObj.max_value"
                    placeholder="Maximum Value"
                  ></el-input>
                </span>
              </div>
            </el-col>

            <el-col :span="6">
              <div
                class="mt-2"
                v-if="
                  (selectedItem.type == 'CHECKBOX' ||
                    selectedItem.inputType == 'CHECKBOX_GROUP') &&
                  selectedItem.group_key
                "
              >
                <el-checkbox v-model="checkBoxGrpObj.show_checkbox_label"
                  >Show Label</el-checkbox
                >
              </div>
            </el-col>
            <!-- <el-col :span="6"
              ><div
                class="mt-2"
                v-if="(selectedItem.type == 'CHECKBOX' || selectedItem.inputType == 'CHECKBOX_GROUP' )&& selectedItem.group_key"
              >
                <el-checkbox
                  :disabled="selectedItem.field_type == 'ENTITY_FIELD'"
                  @change="checkPreviousCheckboxValue()"
                  v-model="checkBoxGrpObj.required"
                  >Required</el-checkbox
                >
              </div></el-col
            > -->
            <!-- <el-col :span="24"
             v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'INITIAL' &&
            selectedItem.type != 'SELECT' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'DATE_TIME_RANGE' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'LIST' &&
            selectedItem.type != 'TIME_RANGE' &&
            selectedItem.type != 'DATE_RANGE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'my-signature' &&
            selectedItem.type != 'MY_INITIAL' &&
            selectedItem.type != 'MULTI_SELECT' &&
            selectedItem.type != 'MULTI_LINE_TEXT' &&
            selectedItem.type != 'ATTACHMENT' &&
            selectedItem.type != 'ENTITY'
          "
          >
          <el-form-item>
            <el-radio-group v-model="selectedItem.alignStyles">
              <el-radio-button size="mini" @click="alignLeft()" :label="'left'">
                <img src="@/assets/img/Left.svg"  height="15px" width="15px"/>
              </el-radio-button>
              <el-radio-button size="mini" @click="alignRight()" label="Center">
                <img src="@/assets/img/Middle.svg"  height="15px" width="15px"/>
              </el-radio-button>
              <el-radio-button size="mini" @click="alignMiddle()" label="Right">
                <img src="@/assets/img/Right.svg"  height="15px" width="15px"/>
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col> -->
            <el-col
              :span="24"
              v-if="
                selectedItem.selected_tag == 'address' &&
                selectedItem.filled_by == 'SENDER'
              "
            >
              <el-form-item>
                <el-radio-group v-model="selectedItem.addressInfo">
                  <el-radio-button
                    @click="assignPersonalAddress()"
                    label="Personal Address"
                  ></el-radio-button>
                  <el-radio-button
                    @click="assignCompanyAddress()"
                    label="Company Address"
                  ></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div
                class="mt-2"
                v-if="
                  selectedItem.type == 'CHECKBOX' ||
                  (selectedItem.inputType == 'CHECKBOX_GROUP' &&
                    selectedItem.group_key)
                "
              >
                <span>Fields Filled By:</span>
                <el-select v-model="checkBoxGrpObj.filled_by" width="100">
                  <el-option
                    v-for="(user, index) of documentRecipientsList.filter(
                      (e) => e && e.user_type && e.user_type != 'CC'
                    )"
                    :key="index"
                    :label="user.name"
                    :value="user.value"
                  >
                    <span style="float: left">{{ user.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">
                      {{ user.email }}
                    </span>
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col
              :span="12"
              v-if="selectedItem.group_key && currentGroupIndex > -1"
            >
              <!-- <div>
                <span>Minimum Value:</span>
                <span>
                  <el-input
                    :disabled="selectedItem.field_type == 'ENTITY_FIELD'"
                    type="number"
                    v-model="checkBoxGrpObj.min_value"
                    placeholder="Minimum Value"
                  ></el-input>
                </span>
              </div> -->
            </el-col>
            <!-- <el-col
              :span="12"
              v-if="selectedItem.group_key"
            >
              <div v-if="selectedItem.field_type == 'ENTITY_FIELD'">
                <span>Filled By:</span>
                <span >
                  <el-select
                    v-model="checkBoxGrpObj.filled_by"
                    :disabled="selectedItem.field_type == 'ENTITY_FIELD'"
                  >
                    <el-option
                      v-for="(user, key) in documentRecipientsList"
                      :key="key"
                      :value="user.value"
                      :label="user.name"
                      :disabled="user.user_type == 'CC'"
                    >
                      <span style="float: left">{{ user.name }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                      >
                        {{ user.email }}
                      </span>
                    </el-option>
                  </el-select>
                </span>
              </div>
            </el-col> -->
            <!--<el-col :span="12"></el-col>
            <el-col :span="12"></el-col>
            <el-col :span="12"></el-col> -->
          </el-row>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type == 'SELECT' || selectedItem.type == 'MULTI_SELECT'
          "
          label="Value"
          width="100"
        >
          <el-select
            placeholder="Select"
            :clearable="true"
            :filterable="true"
            :multiple="selectedItem.type == 'MULTI_SELECT' ? true : false"
            v-model="selectedItem.value"
            :disabled="
              (selectedItem.type == 'SELECT' ||
                selectedItem.type == 'MULTI_SELECT') &&
              !selectedItem.options.length
            "
          >
            <el-option
              v-for="op in selectedItem.options"
              :key="op"
              :label="op"
              :value="op"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="selectedItem.type == 'WEEKDAYS'"
          label="Value"
          width="100"
        >
          <el-select
            placeholder="Select"
            :clearable="true"
            :filterable="true"
            :multiple="selectedItem.allow_multiple === true ? true : false"
            v-model="selectedItem.value"
            :disabled="selectedItem.type == 'WEEKDAYS'"
          >
            <el-option
              v-for="op in this.weekdays_options"
              :value="op.value"
              :label="op.name"
              :key="op.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'ZSEAL' &&
            // selectedItem.type != 'RADIO' &&
            selectedItem.type == 'DATE' &&
            !selectedItem.is_use_boxes
          "
          label="Value"
        >
          <el-date-picker
            placeholder="Date"
            v-model="selectedItem.value"
            style="width: '100%'"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME_RANGE' &&
            selectedItem.type != 'DATE_RANGE' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'FIXED_TIME' &&
            selectedItem.type != 'TIME_RANGE' &&
            selectedItem.type != 'ZSEAL' &&
            // selectedItem.type != 'RADIO' &&
            selectedItem.type == 'DATE_TIME'
          "
          label="Value"
        >
          <el-date-picker
            type="datetime"
            format="MM-dd-yyyy HH:mm:ss"
            placeholder="Date"
            v-model="selectedItem.value"
            style="width: '100%'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'FIXED_TIME' &&
            selectedItem.type != 'TIME_RANGE' &&
            selectedItem.type != 'ZSEAL' &&
            // selectedItem.type != 'RADIO' &&
            selectedItem.type != 'DATE_RANGE' &&
            selectedItem.type == 'DATE_TIME_RANGE'
          "
          label="Value"
        >
          <el-date-picker
            type="datetimerange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            popper-class="my-popover"
            :format="
              globalDateTimeFormat
                ? globalDateTimeFormat
                : 'MM-dd-yyyy HH:mm:ss'
            "
            placeholder="Date"
            v-model="selectedItem.value"
            style="width: '100%'"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'ZSEAL' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'FIXED_TIME' &&
            selectedItem.type != 'TIME_RANGE' &&
            // selectedItem.type != 'RADIO' &&
            selectedItem.type != 'DATE_TIME_RANGE' &&
            selectedItem.type == 'DATE_RANGE'
          "
          label="Value"
        >
          <el-date-picker
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            popper-class="my-popover"
            :format="
              globalDateTimeFormat ? globalDateTimeFormat : 'MM-dd-yyyy '
            "
            placeholder="Date"
            v-model="selectedItem.value"
            style="width: '100%'"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'FIXED_TIME' &&
            selectedItem.type != 'DATE_TIME_RANGE' &&
            // selectedItem.type != 'RADIO' &&
            selectedItem.type != 'DATE_RANGE' &&
            selectedItem.type == 'TIME_RANGE'
          "
          label="Value"
        >
          <el-time-picker
            type="timerange"
            range-separator="To"
            start-placeholder="Start time"
            end-placeholder="End time"
            popper-class="my-popover"
            :format="globalDateTimeFormat ? globalDateTimeFormat : 'HH:mm:ss'"
            placeholder="time"
            v-model="selectedItem.value"
            style="width: '100%'"
          ></el-time-picker>
        </el-form-item>
        <el-col :span="22">
          <el-form-item
            v-if="selectedItem.type == 'DATE_SIGNED'"
            label="Signature Field"
            width="100"
          >
            <el-form-item
              v-if="
                selectedItem.type != 'YES_OR_NO' &&
                selectedItem.type != 'CHECKBOX' &&
                selectedItem.type != 'SIGNATURE' &&
                selectedItem.type != 'DATE_SIGNED' &&
                selectedItem.type != 'DATE' &&
                selectedItem.type != 'DATE_TIME_RANGE' &&
                selectedItem.type != 'DATE_RANGE' &&
                selectedItem.type != 'ZSEAL' &&
                selectedItem.type != 'DATE_TIME' &&
                // selectedItem.type != 'RADIO' &&
                selectedItem.type != 'TIME_RANGE' &&
                (selectedItem.type == 'FIXED_TIME' ||
                  selectedItem.type == 'TIME')
              "
              label="Value"
            >
              <el-time-picker
                type="time"
                format=" HH:mm:ss"
                placeholder="time"
                v-model="selectedItem.value"
                style="width: '100%'"
              >
              </el-time-picker>
            </el-form-item>
            <!-- <el-form-item
          v-if="
            selectedItem.type != 'YES_OR_NO' &&
            selectedItem.type != 'CHECKBOX' &&
            selectedItem.type != 'SIGNATURE' &&
            selectedItem.type != 'DATE_SIGNED' &&
            selectedItem.type != 'DATE' &&
            selectedItem.type != 'DATE_TIME' &&
            selectedItem.type != 'TIME' &&
            selectedItem.type != 'DATE_TIME_RANGE' && 
             selectedItem.type != 'LIST' &&
            selectedItem.type == 'RADIO'
          "
          label="Value"
        >
        <el-radio v-model="item.value" 
        >radio</el-radio >--->
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <div v-if="errors" class="error-message">
            {{ errorMessage }}
          </div>
          <el-form-item
            v-if="
              selectedItem.type == 'SELECT' ||
              selectedItem.type == 'MULTI_SELECT'
            "
            label="Options"
            width="100"
          >
            <el-select
              placeholder="options"
              :clearable="true"
              :filterable="true"
              :multiple="true"
              :allow-create="true"
              :default-first-option="true"
              v-model="selectedItem.options"
              @change="validateOptions"
            ></el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            v-if="selectedItem.type == 'ZSEAL'"
            label="Select 'Currency' field to map it's value with 'Zseal'"
          >
            <el-select
              v-model="selectedItem.mapped_currency"
              @change="mapCurrencyValueToZseal"
            >
              <el-option
                v-for="(field, index) of this.elements.filter(
                  (e) => e.type == 'CURRENCY'
                )"
                :key="index"
                :label="field.label"
                :value="field.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-radio-group
              v-model="selectedItem.alignStyles"
              @change="setAlignStyles"
            >
              <el-radio-button @change="setAlignStyles('left')" :label="'left'">
                <img src="@/assets/img/Left.svg" height="15px" width="15px" />
              </el-radio-button>
              <el-radio-button
                @change="setAlignStyles('center')"
                :label="'Center'"
              >
                <img src="@/assets/img/Middle.svg" height="15px" width="15px" />
              </el-radio-button>
              <el-radio-button
                @change="setAlignStyles('right')"
                :label="'Right'"
              >
                <img src="@/assets/img/Right.svg" height="15px" width="15px" />
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col
          :span="24"
          v-if="
            selectedItem.selected_tag == 'address' &&
            selectedItem.filled_by == 'SENDER'
          "
        >
          <el-form-item>
            <el-radio-group v-model="selectedItem.addressInfo">
              <el-radio-button
                @click="assignPersonalAddress()"
                label="Personal Address"
              ></el-radio-button>
              <el-radio-button
                @click="assignCompanyAddress()"
                label="Company Address"
              ></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col
          :span="24"
          v-if="
            (selectedItem && selectedItem.is_entity_variable) ||
            selectedItem.field_type == 'ENTITY_VARIABLE'
          "
        >
          <el-checkbox v-model="selectedItem.field_assignable"
            >Update Parent</el-checkbox
          >
        </el-col>
        <el-col :span="24" v-if="!selectedItem.group_key">
          <el-form-item
            label="Fields Filled by  *"
            v-if="fieldType != 'ENTITY' && !selectedItem.is_check_box_group"
          >
            <el-select v-model="selectedItem.filled_by">
              <el-option
                v-for="(user, index) of documentRecipientsList.filter(
                  (user) => user.user_type !== 'CC'
                )"
                :key="user.value + index"
                :label="user.name"
                :value="user.value"
              >
                <span style="float: left">{{ user.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ user.email }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-checkbox
          v-model="selectedItem.allow_user_download"
          v-if="
            selectedItem.filled_by == 'SENDER' && selectedItem.type == 'FILE'
          "
          :label="true"
          >Allow the recipient to download the file.</el-checkbox
        >
        <el-checkbox
          v-model="selectedItem.required"
          v-if="
            selectedItem.type != 'DATE_SIGNED' &&
            fieldType != 'ENTITY' &&
            !(selectedItem.group_key && selectedItem.type == 'CHECKBOX')
          "
          :label="true"
          >Required Field
        </el-checkbox>
      </el-form>
    </div>
    <div v-if="selectedItem.type == 'ENTITY' && selectedItem.entity_id">
      <el-checkbox v-model="selectedItem.allow_to_add_new_entity_data"
        >Allow To Add New Data</el-checkbox
      >
    </div>
    <div
      v-if="
        selectedItem.type == 'ENTITY' &&
        selectedItem.entity_id &&
        !selectedItem.entity_data_id
      "
    >
      <EntityFilters
        :field="selectedItem"
        :fieldsData="elements"
        :fromDocument="true"
        :documentUsers="documentRecipientsList"
      ></EntityFilters>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../../main";
import appConfig from "@/config/app";
const MySignature = () => import("@/components/signature/MySignature");
import vueAnkaCropper from "vue-anka-cropper";
const Initial = () => import("@/components/account/Initial.vue");
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import CompanyDocumentsHelper from "../../../mixins/CompanyDocumentsHelper";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import { fetchEntitiesByPagination } from "@/repo/entityRepo";
import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";

export default {
  name: "configureDocuments-ConfigureCompanyDocument",
  mixins: [
    CustomDashboardConfig,
    entityRelationshipMixin,
    templateBuilderHelper,
    ComanyDocumentsPresignedURLsHelper,
    CompanyDocumentsHelper
  ],
  components: {
    vueAnkaCropper,
    MySignature,
    Initial,
    EntityFilters: () =>
      import("@/components/templates/formComponents/EntityFilters.vue"),
  },
  props: {
    selectedItem: Object,
    documentRecipientsList: Array,
    allUsersEntityData: Array,
    getEntityDataByQuery: Array,
    getTemplatesData: Array,
    isDraft: Boolean,
    allTemplatesData: Object,
    allAttachments: [],
    elements: Array,
    isTemplate: Boolean,
    globalVariablesData: Array,
    isCheckBoxGroup: Boolean,
    checkBoxGrpObj: Object,
    checkBoxGroupArray: Array,
    currentGroupIndex: Number,
    filesData: Object,
  },
  data() {
    return {
      getAllFormTemplatesData:[],
      fontTypes: [],
      num: 0,
      num1: 0,
      tableData: [],
      weekdays_options: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" },
      ],
      // checkBoxGrpObj: this.checkBoxGroupObjects,
      userFields: {
        first_name: "First Name",
        last_name: "Last Name",
        middle_name: "Middle Name",
        company_name: "Company",
        address: "Address",
        phone: "Phone",
        email: "Email",
        full_name: "Full Name",
        title: "Title",
      },
      setMaxDate: "",
      font_Size: 16,
      fontSizes: [
        {
          value: 8,
          label: 8,
        },
        {
          value: 8.5,
          label: 8.5,
        },
        {
          value: 10,
          label: 10,
        },
        {
          value: 10.5,
          label: 10.5,
        },
        {
          value: 12,
          label: 12,
        },
        {
          value: 12.5,
          label: 12.5,
        },
        {
          value: 14,
          label: 14,
        },
        {
          value: 14.5,
          label: 14.5,
        },
        {
          value: 16,
          label: 16,
        },
        {
          value: 16.5,
          label: 16.5,
        },
        {
          value: 18,
          label: 18,
        },
        {
          value: 18.5,
          label: 18.5,
        },
        {
          value: 20,
          label: 20,
        },
        {
          value: 20.5,
          label: 20.5,
        },
        {
          value: 22,
          label: 22,
        },
        {
          value: 22.5,
          label: 22.5,
        },
        {
          value: 24,
          label: 24,
        },
        {
          value: 26,
          label: 26,
        },
        {
          value: 28,
          label: 28,
        },
        {
          value: 30,
          label: 30,
        },
        {
          value: 32,
          label: 32,
        },
        {
          value: 36,
          label: 36,
        },
        {
          value: 48,
          label: 48,
        },
        {
          value: 56,
          label: 56,
        },
        {
          value: 72,
          label: 72,
        },
        {
          value: 84,
          label: 84,
        },
        {
          value: 96,
          label: 96,
        },
      ],
      setMinDate: "",
      attachmentLoading: false,
      selectedAttachmentFile: {
        document_name: "",
        attachment_url: "",
        valid_from: "",
        document_number: "",
        expires_on: "",
        title: "",
        description: "",
        activeDetails: [],
      },
      selectedUserEntity: {},
      uploadImageLoading: false,
      imgData: null,
      content_fields: [
        "my-signature",
        "MY_INITIAL",
        "HEADING",
        "PARAGRAPH",
        "IMAGE",
        "SINGLE_LINE_CONTENT",
      ],
      newElement: {
        name: "",
        value: "",
      },
      fieldType: "",
      fieldTemplate: "",
      entityField: "",
      entityFields: [],

      fieldTemplates: [
        {
          template_id: "PRIMARY_FIELDS",
          name: "Primary Fields",
        },
      ],
      customFields: [
        {
          type: "SINGLE_LINE_TEXT",
          label: "Single Line Text",
        },
        {
          type: "MULTI_LINE_TEXT",
          label: "Multi Line Text",
        },
        {
          type: "SELECT",
          label: "Select",
        },
        {
          type: "LIST",
          label: "List",
        },
        {
          type: "DATE_TIME_RANGE",
          label: "Date Time Range",
        },
        {
          type: "DATE_RANGE",
          label: "Date Range",
        },
        {
          type: "DATE_TIME",
          label: "Date Time",
        },
        {
          type: "TIME",
          label: "Time",
        },
        {
          type: "MULTI_SELECT",
          label: "Multi Select",
        },
        {
          type: "NUMBER",
          label: "Number",
        },
        {
          type: "YES_OR_NO",
          label: "Yes Or No",
        },
        {
          type: "DATE",
          label: "Date",
        },
        {
          type: "SIGNATURE",
          label: "Signature",
        },
        {
          type: "INITIAL",
          label: "Initial",
        },
        {
          type: "ATTACHMENT",
          label: "Attachment",
        },
        {
          type: "CHECKBOX",
          label: "Checkbox",
        },
        {
          type: "CURRENCY",
          label: "Currency",
        },
        {
          type: "FORMULA",
          label: "Formula",
        },
        {
          type: "FIXED_TIME",
          label: "Fixed Time",
        },
        {
          type: "TIME_RANGE",
          label: "Time Range",
        },
        {
          type: "WEEKDAYS",
          label: "Weekdays",
        },
        {
          type: "DATA_TABLE",
          label: "Data Table",
        },
      ],
      fieldTypes: [
        // {
        //   type: "PRIMARY_FIELDS",
        //   label: "Primary Field",
        // },
        {
          type: "CUSTOM_FIELD",
          label: "Custom Field",
        },
      ],
      loading: false,
      allEntitiesList: [],
      allEntityFormTemplatesList: [],
      allEntityDataList: [],
      selecteGField: "",
      selectedGTemplateFields: [],
      CurrencyTypes: [
        {
          value: "USD",
          name: "$",
        },

        {
          value: "CAD",
          name: "$",
        },
        {
          value: "AUD",
          name: "$",
        },
        {
          value: "INR",
          name: "₹",
        },
        {
          value: "EUR",
          name: "€",
        },
      ],
      relationshipsData: [],
      errors: false,
      errorMessage: "",
      initialValidation: false,
      alignContLeft: false,
      alignContRight: false,
      alignContCenter: false,
    };
  },
  async mounted() {
    this.initialValidation = true;
    this.loading = true;
    if (this.selectedItem) {
      if (!this.selectedItem.indexKey) {
        this.$set(this.selectedItem, "indexKey", "");
      }
      if (!this.selectedItem.filters) {
        this.$set(this.selectedItem, "filters", []);
      }
      if (!this.selectedItem.validations) {
        this.$set(this.selectedItem, "validations", {
          currency: "",
          locale: "en-US",
          required: false,
          type: "NUMBER",
        });
      }
    }

    let [response] = await Promise.all([
      fetchEntitiesByPagination({ get_all: true }, true),
      this.fetchAllFormTemplates()
    ]);
    this.getAllEntities = response;
    this.allEntitiesList = this.getAllEntities.data;
    await Promise.all([this.getAllFontTypes()]);
    if (
      this.selectedItem.type == "PRIMARY_FIELDS" ||
      this.selectedItem.field_type == "ENTITY_FIELD"
    ) {
      this.fieldType = this.selectedItem.selected_user;
      let selectedUser = this.documentRecipientsList.find(
        (e) => e && e.value == this.fieldType
      );
      let entityId =
        selectedUser.contact_type && selectedUser.contact_type._id
          ? selectedUser.contact_type._id
          : selectedUser.contact_type
          ? selectedUser.contact_type
          : selectedUser.entity_data_id && selectedUser.entity_data_id.company
          ? selectedUser.entity_data_id.company
          : null;
      if (this.selectedItem.type == "REPEATABLE_PARENT") {
        this.entityField = "PARENT_FIELD";
      }
      this.selectUserEntity(entityId);
      if (this.selectedItem.type == "PRIMARY_FIELDS") {
        this.fieldTemplate = "PRIMARY_FIELDS";
      } else {
        this.setEntityField();
      }
    } else if (
      this.selectedItem.type == "ENTITY" &&
      !this.selectedItem.parent_entity_field_key
    ) {
      this.fieldType = "ENTITY";
      this.changeGEntity(false);
    } else if (this.selectedItem.field_type == "ENTITY_VARIABLE") {
      if (this.selectedItem.parent_repeatable_field_key) {
        this.fieldType = this.selectedItem.parent_repeatable_field_key;
        this.changeGEntity(false);
        this.getEntityVariableList();
        this.changeGTemplate(false);
      } else {
        this.changeGEntity(false);
        this.fieldType = this.selectedItem.parent_entity_field_key;
        let parentField = this.elements.find(
          (e) => e.key == this.selectedItem.parent_entity_field_key
        );
        if (parentField && parentField.entity_id) {
          let parentEntity = this.allEntitiesList.find(
            (e) => e._id == parentField.entity_id
          );
          this.fetchEntityRelationships(
            parentField.entity_id,
            true,
            false,
            parentField.key,
            parentEntity,
            ""
          );
          this.getEntityVariableList(parentField.entity_id);
        } else {
          this.getEntityVariableList();
        }
        // this.getEntityVariableList();
        this.changeGTemplate(false);
      }
    } else {
      this.fieldType = "CUSTOM_FIELD";
    }
    let that = this;
    that.setMaxDate = {
      disabledDate: (time) => {
        return (
          time.getTime() >
          new Date(that.selectedAttachmentFile.expires_on).getTime()
        );
      },
    };
    that.setMinDate = {
      disabledDate: (time) => {
        return (
          time.getTime() <
          new Date(that.selectedAttachmentFile.valid_from).getTime()
        );
      },
    };

    this.loading = false;
    if (
      (this.selectedItem != null &&
        this.selectedItem.field_type != "ENTITY_FIELD" &&
        this.selectedItem.type == "SINGLE_LINE_TEXT") ||
      (this.selectedItem.type == "NUMBER" &&
        this.selectedItem.value &&
        this.selectedItem.value.length)
    ) {
      this.selectedItem.boxNumber =
        this.selectedItem.boxNumber && this.selectedItem.boxNumber != ""
          ? this.selectedItem.boxNumber
          : this.selectedItem.value && this.selectedItem.value.length
          ? this.selectedItem.value.length
          : 0;
    }
    if (
      this.selectedItem != null &&
      this.selectedItem.field_type != "ENTITY_FIELD" &&
      this.selectedItem.type == "PRIMARY_FIELDS" &&
      (this.selectedItem.selected_tag == "full_name" ||
        this.selectedItem.selected_tag == "first_name" ||
        this.selectedItem.selected_tag == "last_name") &&
      this.selectedItem.content &&
      this.selectedItem.content.length
    ) {
      this.selectedItem.boxNumber =
        this.selectedItem.boxNumber && this.selectedItem.boxNumber != ""
          ? this.selectedItem.boxNumber
          : this.selectedItem.content && this.selectedItem.content.length
          ? this.selectedItem.content.length
          : 0;
    }
    if (
      (this.selectedItem != null &&
        this.selectedItem.field_type == "ENTITY_FIELD" &&
        this.selectedItem.type == "SINGLE_LINE_TEXT") ||
      (this.selectedItem.type == "NUMBER" &&
        this.selectedItem.value &&
        typeof this.selectedItem.value === "string")
    ) {
      const valueWithoutCommas = this.selectedItem.value.replace(/,/g, "");
      this.selectedItem.boxNumber =
        this.selectedItem.boxNumber && this.selectedItem.boxNumber != ""
          ? this.selectedItem.boxNumber
          : valueWithoutCommas.length;
      this.selectedItem.boxes = Array.from(valueWithoutCommas);
    }
    // let id =
    //   this.getActiveWorkspace &&
    //   this.getActiveWorkspace.company_id &&
    //   this.getActiveWorkspace.company_id._id
    //     ? this.getActiveWorkspace.company_id._id
    //     : this.getActiveWorkspace.company_id;
    // await this.$store.dispatch("company/fetchCompany", id);
  },
  computed: {
    minGreaterThanMax() {
      return this.selectedItem.min_selection > this.selectedItem.max_selection;
    },
    ...mapGetters("fileUpload", ["getUploadFileDataStatus", "getFontTypes"]),
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityRecordsForTable",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat"]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    checkIsAllUsersConfigured() {
      let isAllUsersConfigured = true;
      this.documentRecipientsList.forEach((user) => {
        if (user && !user.email) {
          isAllUsersConfigured = false;
        }
      });
      return isAllUsersConfigured;
    },
    checkIsRepeatable() {
      if (this.fieldTemplate) {
        let temp = this.fieldTemplates.find(
          (e) => e && e.template_id == this.fieldTemplate
        );
        if (temp && temp.is_repeatable) {
          return true;
        }
      }
      return false;
    },
    checkForRepeatableChild() {
      return (this.elements || []).filter(
        (e) => e.parent_repeatable_field_key == this.selectedItem.key
      ).length
        ? true
        : false;
    },
    checkForChildren() {
      return (this.elements || []).filter(
        (e) => e.parent_entity_field_key == this.selectedItem.key
      ).length
        ? true
        : false;
    },
    basePdfdownloadUrl() {
      return appConfig.S3_BUCKET_URL;
      // return appConfig.JAVA_BASE_URL
    },
    getLogoUrl() {
      return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
    },
    checkIsRepeatableGTemplate() {
      const selectedGTemplate = this.allEntityFormTemplatesList.find(
        (el) => el._id == this.selectedItem.template_id
      );
      if (selectedGTemplate && selectedGTemplate.is_repeatable) {
        return true;
      }
      return false;
    },
    allFieldEntities() {
      return (this.elements || []).filter(
        (e) =>
          e.type == "ENTITY" &&
          // !e.parent_entity_field_key &&
          !e.parent_repeatable_field_key
      );
    },
    allFieldRepeatable() {
      return (this.elements || []).filter((e) => e.type == "REPEATABLE_PARENT");
    },
    getGlobalDateSettings() {
      if (this.getDefaultDateFormat) {
        return this.getDefaultDateFormat
          .replace("DD", "dd")
          .replace("YYYY", "yyyy");
      }
      return "MM-dd-yyyy";
    },
  },
  methods: {
    handleMinMaxValues() {
      if (this.selectedItem.min_selection > this.selectedItem.max_selection) {
        this.$message.error(
          "Min Selection should not be greater than Max Selection"
        );
      }
      console.log(this.selectedItem.min_selection);
    },
    makeTextBold() {
      if (this.selectedItem) {
        if (this.selectedItem.headerFont.style == 1) {
          this.selectedItem.headerFont.style = 0;
        } else if (this.selectedItem.headerFont.style == 3) {
          this.selectedItem.headerFont.style = 2;
        } else if (this.selectedItem.headerFont.style == 2) {
          this.selectedItem.headerFont.style = 3;
        } else {
          this.selectedItem.headerFont.style = 1;
        }
      }
    },
    handleAddressChange() {
      if (this.selectedItem.addressInfo === "Personal Address") {
        this.selectedItem.content = ""; // Clear content for personal address
      } else if (this.selectedItem.addressInfo === "Company Address") {
        this.selectedItem.content = ""; // Clear content for company address
      }
    },
    mapCurrencyValueToZseal(data) {
      this.number = data;
      this.elements.map((e) => {
        if (e && e.type == "ZSEAL") {
          if (!e.value || e.value == " ") {
            e.value = this.convertToImage(data);
          }
          this.selectedItem.zseal_image_url = e.zseal_image_url;
        }
      });
    },
    async convertToImage(data) {
      const numberString = data.toString();
      const [wholeNumber, decimalPart] = numberString.split(".");
      const wholeNumberDigits = wholeNumber.split("");
      const wordDigits = wholeNumberDigits.map(this.convertDigitToWord);

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const fontSize = 12;
      const lineHeight = 35;
      const textHeight = lineHeight / 2;
      const canvasWidth =
        wordDigits.length * lineHeight -
        20 +
        (decimalPart ? decimalPart.length * lineHeight : 2 * lineHeight);
      const canvasHeight = 38;
      const commaFontSize = 18;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.font = `${fontSize}px Arial`;
      ctx.fillStyle = "#747474"; // Set the background color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white"; // Set the text color
      ctx.textAlign = "center";

      ctx.fillStyle = "white"; // Set the text color for commas
      const commaPositions = []; // Store the x-positions of commas

      wordDigits.forEach((text, index) => {
        const digit = wholeNumberDigits[index];
        const textX = index * lineHeight;
        ctx.font = `${fontSize}px Arial`;
        ctx.fillText(digit, textX + lineHeight / 2 + 2, textHeight - 4);
        ctx.fillText(text, textX + lineHeight / 2 + 2, lineHeight - 5);
      });

      const decimalText = decimalPart ? decimalPart.padEnd(2, "0") : "00";
      const decimalTextX = wholeNumberDigits.length * lineHeight + 15;
      const decimalTextY = textHeight;
      const decimalWidthBasedOnLineHeight = lineHeight - 15;
      const decimalWidth = decimalText.length * decimalWidthBasedOnLineHeight; // Width of the decimal part

      // Set a different background color for the decimal part
      ctx.fillStyle = "#D3D3D3"; // Example: Gray background color
      ctx.fillRect(decimalTextX, 0, decimalWidth, canvasHeight);

      // Set the text color for the decimal part
      ctx.fillStyle = "black";

      ctx.font = `bold ${fontSize}px Arial`;
      ctx.fillText(
        decimalText,
        decimalTextX + lineHeight / 2 + 2,
        decimalTextY - 5
      );

      // Reset the background color and text color for the cents text
      ctx.fillStyle = "black"; // Original background color
      ctx.font = `bold 10px Arial`;
      ctx.fillText(
        "cents",
        decimalTextX + lineHeight / 2,
        decimalTextY + textHeight - 12
      );

      for (let i = numberString.length - 1, count = 1; i >= 0; i--, count++) {
        if (count % 3 === 0 && i > 0) {
          wordDigits.splice(i, 0, ",");
          commaPositions.push(i);
        }
      }

      wordDigits.forEach((digit, index) => {
        if (digit === ",") {
          wordDigits[index] = " ";
          commaPositions.forEach((position) => {
            const commaX = position * lineHeight;
            const commaY = textHeight;
            ctx.font = `${commaFontSize}px Arial`;
            ctx.fillStyle = "white";
            ctx.fillText(",", commaX, commaY);
          });
        }
      });
      const imageData = canvas.toDataURL("image/png");
      this.elements.forEach((e) => {
        if (e && e.type === "ZSEAL") {
          e.zseal_image_url = imageData;
        }
      });
    },

    convertDigitToWord(digit) {
      // Convert a single digit into its word equivalent
      const words = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const digitIndex = parseInt(digit, 10);
      if (isNaN(digitIndex)) {
        return " ";
      }
      return words[digitIndex];
    },
    getFontType(item) {
      let style = `font-family:${item}`;
      return style;
    },
    async getAllFontTypes() {
      await this.$store.dispatch("fileUpload/fetchFontTypes");
      if (this.getFontTypes) {
        this.fontTypes = this.getFontTypes.fontNames;
      }
    },
    validateInput(value) {
      // Only allow numbers and commas
      const regex = /^[0-9,]*$/;
      if (regex.test(value)) {
        this.selectedItem.widthHeader = value;
        this.checkColumnsWidthWithCols(value);
      } else {
        this.selectedItem.widthHeader = value.replace(/[^0-9,]/g, "");
      }
    },
    checkColumnsWidthWithCols(widthHeader) {
      const widths = widthHeader.split(",").map(Number);
      const sum = widths.reduce((acc, val) => acc + val, 0);

      if (sum > 950) {
        this.$message.error("The sum of column widths cannot exceed 950.");
        return;
      }

      const numColumns = this.selectedItem.cols;

      if (
        this.selectedItem.map_keys &&
        numColumns !== 0 &&
        widths.length > numColumns
      ) {
        this.$message.error(
          "Number of column widths exceeds the specified number of columns."
        );
        let numbersArray = widthHeader.split(",");
        numbersArray.pop();
        this.selectedItem.widthHeader = numbersArray.join(",");
      }
    },
    handleChangeBox(data) {
      this.selectedItem.boxNumber = data.length;
      this.selectedItem.boxes = Array.from(data);
    },
    assignPersonalAddress() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.address) {
        this.$set(
          this.selectedItem,
          "content",
          this.getAuthenticatedUser.address
        );
      }
    },
    assignCompanyAddress() {
      if (
        this.getActiveWorkspace?.company_id &&
        this.getActiveWorkspace?.plan_type != "INDIVIDUAL" &&
        this.getActiveWorkspace?.company_id != "0" &&
        this.getActiveWorkspace?.company_id != "0"
      ) {
        this.$set(this.selectedItem, "content", this.getCompanyDetails.address);
      }
    },
    setAlignStyles(style) {
      console.log("syul", style);
      if (this.selectedItem) {
        if (this.selectedItem.alignStyles) {
          this.selectedItem.alignStyles = style;
        }
      }
    },
    handleEnteredBoxNumbers(item) {
      const newBoxNumber = parseInt(item);

      if (
        this.selectedItem &&
        this.selectedItem.boxWidth &&
        this.selectedItem.boxWidth * newBoxNumber > this.selectedItem.width
      ) {
        this.$message.error(
          "Entered boxes should not be greater than field length!"
        );
        this.selectedItem.boxNumber = 0;
      } else {
        if (
          newBoxNumber > this.selectedItem.boxes &&
          this.selectedItem.boxes.length
        ) {
          const diff = newBoxNumber - this.selectedItem.boxes.length;
          for (let i = 0; i < diff; i++) {
            this.selectedItem.boxes.push("");
          }
        } else if (
          newBoxNumber < this.selectedItem.boxes &&
          this.selectedItem.boxes.length
        ) {
          const diff = this.selectedItem.boxes.length - newBoxNumber;
          this.selectedItem.boxes.splice(-diff);
        } else {
          this.selectedItem.boxNumber = parseInt(item);
          const diff = newBoxNumber - this.selectedItem.boxes.length;
          for (let i = 0; i < diff; i++) {
            this.selectedItem.boxes.push("");
          }
        }
        this.$set(this.selectedItem, "boxNumber", newBoxNumber);
        this.$forceUpdate();
      }
    },
    //     handleEnteredBoxNumbers(item) {

    //   this.$set(this.selectedItem,"boxNumber",item)
    //   if (
    //     this.selectedItem &&
    //     this.selectedItem.boxWidth &&
    //     this.selectedItem.boxWidth * item > this.selectedItem.width
    //   ) {
    //     this.selectedItem.boxNumber = " ";
    //     this.$message.error(
    //       "Entered boxes should not be greater than field length!"
    //     );
    //   } else {
    //     const newBoxNumber = parseInt(item);

    //     if (newBoxNumber > this.selectedItem.boxes.length) {
    //       // Add empty strings to the boxes array based on the difference between newBoxNumber and current length
    //       for (let i = this.selectedItem.boxes.length; i < newBoxNumber; i++) {
    //         this.selectedItem.boxes.push("");
    //       }
    //     } else if (newBoxNumber < this.selectedItem.boxes.length) {
    //       // Remove elements from the boxes array based on the difference between newBoxNumber and current length
    //       this.selectedItem.boxes.splice(newBoxNumber);
    //     }
    //     this.selectedItem.boxNumber = newBoxNumber;
    //   }
    // },
    handleEnteredBoxWidth() {
      if (
        this.selectedItem &&
        this.selectedItem.boxWidth > this.selectedItem.width
      ) {
        this.selectedItem.boxWidth = " ";
        this.$message.error(
          "Each box width should not be greater than field length!"
        );
      }
    },
    handleColumnCountChange() {
      this.selectedItem.headers = [];
      for (let i = 1; i <= this.selectedItem.cols; i++) {
        this.selectedItem.headers.push(`Column ${i}`);
      }
    },
    addColumnsWidth(width) {
      this.tableLoading = true;
      width = width.trim();
      const widths = width.split(",").map(Number);
      if (!this.arraysAreEqual(widths, this.selectedItem.columnWidth)) {
        this.selectedItem.columnWidth.splice(
          0,
          this.selectedItem.columnWidth.length,
          ...widths
        );
      }
      const totalWidth = this.selectedItem.columnWidth.reduce(
        (sum, width) => sum + width,
        0
      );
      if (totalWidth > 950) {
        this.$message.error("Columns width should not exceed 950.");
      } else {
        this.selectedItem.columnWidth = widths;
        this.$message.success("Column Widths Added Successfully");
      }
    },

    arraysAreEqual(array1, array2) {
      if (array1.length !== array2.length) {
        return false;
      }
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
          return false;
        }
      }
      return true;
    },
    addTableHeader(header) {
      this.selectedItem.headers = [];
      this.selectedItem.table_columns_data = [];
      this.tableLoading = true;
      header = header.trim();
      let inputValues = header.split(",");
      inputValues.forEach((value) => {
        let newKey = this.generateKeyForField(value);
        let obj = {
          name: value,
          key: newKey,
          value: "",
        };

        this.selectedItem.headers.push(value);
        this.selectedItem.table_columns_data.push(obj);
      });
      // this.selectedItem.latestHeader = "";
      let data = [];
      this.selectedItem.table_data.map((item) => {
        let rowHeader = {};
        this.selectedItem.headers.map((header) => {
          rowHeader[header] = item[header] || "";
        });
        data.push(rowHeader);
      });
      this.selectedItem.table_data = data;
      // if (
      //   this.selectedItem.columnWidth &&
      //   this.selectedItem.columnWidth.length === 0
      // ) {
      //   const totalHeaderLength = this.selectedItem?.headers?.length;
      //   const columnWidthValue = (950 / totalHeaderLength).toFixed(0);
      //   this.selectedItem.columnWidth = this.selectedItem.headers.map(
      //     () => columnWidthValue
      //   );
      // }
      this.tableLoading = false;
      this.$message.success("Column Headers Added Successfully");
    },
    generateKeyForField(label) {
      let labelVal = label.trim();
      let parsedLabel = labelVal.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      return parsedLabel;
    },
    //     addTableRow() {
    //   this.tableLoading = true;
    //   let row = {};
    //   this.selectedItem.headers.forEach((header) => {
    //     row[header] = "";
    //   });
    //   this.selectedItem.data.push(row);
    //   this.tableLoading = false;
    //   this.generateTable();
    // },
    deleteValue(index) {
      this.selectedItem.list_data.splice(index, 1);
    },
    addElement() {
      let duplicates = this.selectedItem.list_data.filter(
        (e) => e.name == this.newElement.name
      );
      if (this.newElement.name == "" || !this.newElement.name) {
        this.$message.error("Please add name!");
      } else if (this.newElement.value == "" || !this.newElement.value) {
        this.$message.error("Please add value!");
      } else if (duplicates.length > 0) {
        this.$message.error("The values already exist!");
      } else {
        this.selectedItem.list_data.push(this.newElement);
        this.newElement = {
          name: "",
          value: "",
        };
      }
    },
    openAddGroupDialog() {
      if (this.selectedItem.is_check_box_group) {
        this.$emit("openAddNew");
      }
    },
    changeField(val) {
      this.documentData[this.selectedItem.key] = val;
    },
    // checkMaxValueOfFeild(){
    //   this.checkBoxGroupArray.forEach((el) => {
    //    let data = this.elements.filter((e)=> e && e.group_key && e.group_key == el.key && el.value)
    //    if(data && data.length === el.max_value){
    //     return true
    //    }else{
    //     return false
    //    }
    //   })
    // },
    checkBoxDisabled(item) {
      if (item && item.type == "CHECKBOX" && item.group_key) {
        let currentActiveGroupOfAField = this.checkBoxGroupArray.find(
          (gkey) => gkey.key == item.group_key
        );
        let groupSelectedFields = this.elements.filter(
          (field) =>
            field.group_key &&
            field.group_key == item.group_key &&
            field.value == true
        );

        let maxValue = parseInt(
          currentActiveGroupOfAField && currentActiveGroupOfAField.max_value
        );
        if (maxValue == 0 || !maxValue) {
          return false;
        }
        let fieldCount = groupSelectedFields.length;
        if (fieldCount >= maxValue && !item.value) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    checkMinValue() {
      if (
        this.checkBoxGrpObj &&
        this.checkBoxGrpObj.min_value &&
        this.checkBoxGrpObj.max_value &&
        parseInt(this.checkBoxGrpObj.min_value) >
          parseInt(this.checkBoxGrpObj.max_value)
      ) {
        this.checkBoxGrpObj.min_value = this.checkBoxGrpObj.max_value;
        this.$message.error(
          "The minimum value should not be greater than maximum value."
        );
      }
    },
    // checkPreviousCheckboxValue() {
    //   if (this.checkBoxGrpObj.required && (!this.checkBoxGrpObj.min_value || this.checkBoxGrpObj.min_value == 0)) {
    //       this.$confirm(
    //       "It is a required field. When unchecked, it becomes an optional field.? ",
    //       "Warning",
    //       {
    //         confirmButtonText: "Yes",
    //         cancelButtonText: "No",
    //       }
    //     ).then(async () => {
    //       this.checkBoxGrpObj.required = false;

    //     });

    //   }
    // },
    // checkForMergeOrUpload() {
    //   if (this.isMergeDocument && this.files && this.files.length > 1) {
    //     this.mergeAllDocs();
    //   } else {
    //     this.uploadSingleFile();
    //   }
    // },
    // async uploadSingleFile() {
    //   try {
    //     this.replaceDocumentLoading = true;
    //     var formData = new FormData();
    //     this.files.forEach((file, index) => {
    //       if (index == 0) {
    //         this.fieldsFormUpload.selectedFile =
    //           file.raw !== undefined ? file.raw : file;
    //         this.fieldsFormUpload.selectedFileName =
    //           file.raw !== undefined ? file.raw.name : file.name;
    //         this.fieldsFormUpload.title =
    //           file.raw !== undefined ? file.raw.name : file.name;
    //       }
    //       formData.append(
    //         "uploadedFiles",
    //         file.raw !== undefined ? file.raw : file
    //       );
    //     });
    //     let companyId =
    //       this.getActiveWorkspace && this.getActiveWorkspace.company_id
    //         ? this.getActiveWorkspace.company_id
    //         : this.getAuthenticatedUser._id;
    //     let params = {
    //       data: formData,
    //       companyId: companyId,
    //     };
    //     await this.$store.dispatch("fileUpload/uploadFilesToConvertv4", params);
    //     if (this.getUploadFileDataStatus) {
    //       let tempUrl = [];
    //       this.getUploadFileDataStatus.forEach((file) => {
    //         if (file.url.includes(appConfig.S3_BUCKET_URL)) {
    //           tempUrl.push(file.url.replace(appConfig.S3_BUCKET_URL, ""));
    //         } else {
    //           tempUrl.push(file.url);
    //         }
    //       });
    //       let fileNames = this.files.flatMap((e) => e.name);
    //       let fileUploadUrls = tempUrl;
    //       this.updateConfigDocument(fileUploadUrls, fileNames);
    //     } else {
    //       this.logoError = "Error in uploading file. Please try again..";
    //     }
    //   } catch (err) {
    //     this.replaceDocumentLoading = false;
    //   }
    // },

    /**** Commented  */
    getRepeatableDataForGTemplateField() {
      let result = [];
      const selectedEntity = this.selectedItem.entity_id
        ? this.allEntitiesList.find((e) => e._id == this.selectedItem.entity_id)
        : null;
      if (selectedEntity && selectedEntity.templates) {
        const selctedTemplate = selectedEntity.templates.find(
          (e) => e.template_id == this.selectedItem.template_id
        );
        const max_value =
          selctedTemplate && selctedTemplate.max_records
            ? selctedTemplate.max_records
            : 1;
        const selectedGTemplate = this.allEntityFormTemplatesList.find(
          (el) => el._id == this.selectedItem.template_id
        );
        for (let i = 0; i < max_value; i++) {
          result.push({
            name:
              selectedGTemplate && selectedGTemplate.name
                ? selectedGTemplate.name + " " + (i + 1)
                : "New data " + (i + 1),
            indexKey:
              selectedGTemplate &&
              selectedGTemplate.name.replaceAll("-", "").split(" ").join("")
                ? selectedGTemplate.name
                    .replaceAll("-", "")
                    .split(" ")
                    .join("") +
                  ":" +
                  (i + 1)
                : "newData:" + (i + 1),
          });
        }
      }
      return result;
    },
    fillFieldData() {
      if (this.selectedItem.template_id) {
        this.selectedItem.global_variable_entity_field = `${this.selectedItem.template_id}#${this.selecteGField}`;
        if (this.selecteGField == "PARENT_FIELD") {
          this.selectedItem.type = "REPEATABLE_PARENT";
          this.$set(this.selectedItem, "repeatable_data", []);
          let indx =
            parseInt(this.getLastIndexOfElement("REPEATABLE_PARENT")) + 1;
          this.selectedItem.key = "REPEATABLE_PARENT_" + indx;
          this.selectedItem.index = indx;
          const selectedEntity = this.selectedItem.entity_id
            ? this.allEntitiesList.find(
                (e) => e._id == this.selectedItem.entity_id
              )
            : null;
          let meteData = {
            max_value: 1,
            primary_key: null,
            template_name: "",
          };

          if (selectedEntity && selectedEntity.templates) {
            const selctedTemplate = selectedEntity.templates.find(
              (e) => e.template_id == this.selectedItem.template_id
            );
            meteData.max_value =
              selctedTemplate && selctedTemplate.max_records
                ? selctedTemplate.max_records
                : 1;
          }
          const selectedGTemplate = this.allEntityFormTemplatesList.find(
            (el) => el._id == this.selectedItem.template_id
          );
          if (selectedGTemplate) {
            if (
              selectedGTemplate.primary_fields &&
              selectedGTemplate.primary_fields[0] &&
              selectedGTemplate.primary_fields[0]["key"]
            ) {
              meteData.primary_key = selectedGTemplate.primary_fields[0]["key"];
            }
            if (selectedGTemplate.name) {
              meteData.template_name = selectedGTemplate.name;
            }
          }
          this.$set(this.selectedItem, "repeatable_field", meteData);
          if (this.allEntityDataList && this.allEntityDataList.length) {
            let parentField = this.elements.find(
              (e) => e.key == this.selectedItem.parent_entity_field_key
            );
            if (parentField && parentField.entity_data_id) {
              let selectedEntityData = this.allEntityDataList.find(
                (e) => e._id == parentField.entity_data_id
              );
              if (this.selectedItem.indexKey) {
                const selectedTemplateData =
                  selectedEntityData &&
                  selectedEntityData.entityData &&
                  selectedEntityData.entityData[this.selectedItem.template_id]
                    ? selectedEntityData.entityData[
                        this.selectedItem.template_id
                      ]
                    : null;
                const repeatableData =
                  selectedTemplateData &&
                  Object.keys(selectedTemplateData) &&
                  selectedTemplateData[Object.keys(selectedTemplateData)[0]]
                    ? selectedTemplateData[Object.keys(selectedTemplateData)[0]]
                    : [];
                this.$set(this.selectedItem, "repeatable_data", repeatableData);
              }
            }
          }
        } else {
          let isRepeatableDateField =
            this.fieldType &&
            this.fieldType.includes("_") &&
            this.fieldType.split("_")[0] == "REPEATABLE"
              ? true
              : false;
          let item = this.selectedGTemplateFields.find(
            (e) => e.key == this.selecteGField
          );
          if (item) {
            if (item.inputType == "FORMULA") {
              let isCurrency = item.selected_fields.find(
                (e) => e.input_type == "CURRENCY"
              );
              let isCurrencyType =
                item.validations && item.validations.currency ? true : false;
              if (item.result_type) {
                item.inputType = item.result_type;
              } else if (isCurrency || isCurrencyType) {
                item.inputType = "CURRENCY";
              } else if (item.date_result_type) {
                item.inputType = "DATE";
              } else {
                item.inputType = "NUMBER";
              }
            }
            if (item.inputType == "PHONE_COUNTRY_CODE") {
              item.inputType = "SINGLE_LINE_TEXT";
            }
            if (item.inputType == "FILE") {
              item.inputType = "ATTACHMENT";
            }
            if (item.inputType == "LIST") {
              this.$set(this.selectedItem, "list_data", item.list_data);
            }
            if (item.inputType == "WEEKDAYS") {
              this.$set(
                this.selectedItem,
                "allow_multiple",
                item.allow_multiple
              );
            }
            if (item.inputType == "ENTITY") {
              this.selectedItem.entity_id = item.entity_id;
            }
          }
          console.log("itemitem", item);
          if (item.label) {
            this.selectedItem.label = item.label;
            this.selectedItem.type = item.inputType;
            this.selectedItem.section = item.type ? item.type : "input";
            if (item.validations) {
              this.selectedItem.validations = item.validations;
            }
            let key = this.selectedItem.parent_entity_field_key;
            if (this.selectedItem.indexKey) {
              key = key + "-" + this.selectedItem.indexKey;
            }
            this.selectedItem.key =
              key + "-" + item.key + "#" + item.template_id;
            this.selectedItem.placeholder =
              item.placeholder || this.selectedItem.label;
            if (item && item.options && item.options.length) {
              this.selectedItem.options = item.options;
            }
          }
          if (this.allEntityDataList && this.allEntityDataList.length) {
            if (isRepeatableDateField) {
              let parentField = this.elements.find(
                (e) => e.key == this.selectedItem.parent_repeatable_field_key
              );
              if (parentField) {
                this.selectedItem.indexKey = parentField.indexKey
                  ? parentField.indexKey
                  : "newData:1";
                this.selectedItem.key =
                  this.selectedItem.parent_repeatable_field_key +
                  "-" +
                  this.selectedItem.indexKey +
                  "-" +
                  item.key +
                  "#" +
                  item.template_id;
              }
            } else {
              let parentField = this.elements.find(
                (e) => e.key == this.selectedItem.parent_entity_field_key
              );
              if (parentField) {
                let selectedEntityData = this.allEntityDataList.find(
                  (e) => e._id == parentField.entity_data_id
                );
                let key = this.selectedItem.key;
                if (this.selectedItem.indexKey) {
                  key = key.split("-")[2];
                } else {
                  key = key.split("-")[1];
                }
                if (key.includes("#")) {
                  key = key.split("#")[0];
                }
                if (this.selectedItem.indexKey) {
                  const selectedTemplateData =
                    selectedEntityData &&
                    selectedEntityData.entityData &&
                    selectedEntityData.entityData[this.selectedItem.template_id]
                      ? selectedEntityData.entityData[
                          this.selectedItem.template_id
                        ]
                      : null;
                  const repeatableData =
                    selectedTemplateData &&
                    Object.keys(selectedTemplateData) &&
                    selectedTemplateData[Object.keys(selectedTemplateData)[0]]
                      ? selectedTemplateData[
                          Object.keys(selectedTemplateData)[0]
                        ]
                      : [];
                  if (this.selectedItem.indexKey.includes(":")) {
                    let index = this.selectedItem.indexKey.split(":")[1];
                    this.selectedItem.value =
                      item &&
                      item.is_global_variable &&
                      item.global_variable_id &&
                      item.value
                        ? item.value
                        : repeatableData[index - 1] &&
                          repeatableData[index - 1][key]
                        ? repeatableData[index - 1][key]
                        : "";
                    this.selectedItem.entity_data_id = this.selectedItem.value;
                    if (this.selectedItem.type == "ENTITY") {
                      bus.$emit("entityDataId-selected", {
                        entityDataId: this.selectedItem.value,
                        form: this.selectedItem,
                        reset: true,
                      });
                    }
                  } else {
                    let selectedRow = repeatableData.find(
                      (e) => e.indexKey == this.selectedItem.indexKey
                    );
                    this.selectedItem.value =
                      item &&
                      item.is_global_variable &&
                      item.global_variable_id &&
                      item.value
                        ? item.value
                        : selectedRow && selectedRow[key]
                        ? selectedRow[key]
                        : "";
                    this.selectedItem.entity_data_id = this.selectedItem.value;
                    if (this.selectedItem.type == "ENTITY") {
                      bus.$emit("entityDataId-selected", {
                        entityDataId: this.selectedItem.value,
                        form: this.selectedItem,
                        reset: true,
                      });
                    }
                  }
                } else {
                  if (
                    item &&
                    item.is_global_variable &&
                    item.global_variable_id &&
                    item.value
                  ) {
                    this.selectedItem.value = item.value;
                  } else if (
                    selectedEntityData &&
                    selectedEntityData.entityData &&
                    selectedEntityData.entityData[
                      this.selectedItem.template_id
                    ] &&
                    selectedEntityData.entityData[
                      this.selectedItem.template_id
                    ][key]
                  ) {
                    this.selectedItem.value =
                      item &&
                      item.is_global_variable &&
                      item.global_variable_id &&
                      item.value
                        ? item.value
                        : selectedEntityData.entityData[
                            this.selectedItem.template_id
                          ][key];
                  }
                }
              }
            }
          }
        }
      }
    },
    changeGTemplate(reset) {
      this.selectedGTemplateFields = [];
      const selectedGTemplate = this.allEntityFormTemplatesList.find(
        (el) => el._id == this.selectedItem.template_id
      );
      if (selectedGTemplate) {
        if (
          this.selectedItem.template_id.includes("~") &&
          !this.selectedItem.template_id.includes("-")
        ) {
          this.selectedItem.key = selectedGTemplate.key;
          this.selectedItem.filters = selectedGTemplate.filters;
          this.selectedItem.entity_id = selectedGTemplate.relation_entity_id;
          this.selectedItem.type = "ENTITY";
        } else {
          this.selectedGTemplateFields =
            this.getTemplateFields(selectedGTemplate);
        }
      }
      if (reset) {
        if (
          !this.selectedItem.template_id.includes("~") &&
          !this.selectedItem.template_id.includes("-")
        ) {
          this.selectedItem.key = "";
        }
        this.selecteGField = "";
        this.selectedItem.indexKey = "";
      } else {
        if (this.selectedItem.type == "REPEATABLE_PARENT") {
          this.selecteGField = "PARENT_FIELD";
        } else if (
          this.selectedItem.key.includes("~") &&
          !this.selectedItem.template_id.includes("-")
        ) {
          let key = this.selectedItem.key;
          if (key.includes(this.selectedItem.parent_entity_field_key)) {
            key = key.replace(this.selectedItem.parent_entity_field_key);
          }
          if (key.includes("-")) {
            key = key.split("-")[1];
          }
          if (key.includes("#")) {
            key = key.split("#")[0];
          }
          this.selecteGField = key;
        } else {
          let key = this.selectedItem.key;
          if (this.selectedItem.indexKey) {
            key = key.split("-")[2];
          } else {
            key = key.split("-")[1];
          }
          if (key.includes("#")) {
            key = key.split("#")[0];
          }
          this.selecteGField = key;
        }
      }
    },
    getEntityVariableList(entityId) {
      if (entityId) {
        this.selectedGEntity = this.allEntitiesList.find(
          (e) => e._id == entityId
        );
      } else {
        this.selectedGEntity = this.allEntitiesList.find(
          (e) => e._id == this.selectedItem.entity_id
        );
      }
      let selctedTemplates = this.selectedGEntity.templates
        .filter((e) => e.template_id)
        .flatMap((e) => e.template_id);
      const repeatableTemplates = this.selectedGEntity.templates
        .filter((e) => e.is_repeatable)
        .flatMap((e) => e.template_id);
      this.allEntityFormTemplatesList = [];
      if (
        this.getAllFormTemplatesData &&
        this.getAllFormTemplatesData.data
      ) {
        let selectedEntityTemplates =
          this.getAllFormTemplatesData.data.filter(
            (temp) => selctedTemplates.indexOf(temp._id) != -1
          );
        this.allEntityFormTemplatesList = selectedEntityTemplates.map((e) => {
          if (e._id) {
            e.is_repeatable =
              repeatableTemplates.indexOf(e._id) != -1 ? true : false;
          }
          return e;
        });
      }
    },

    async updateEntityFields() {
      //   if (this.currentGroupIndex > -1) {
      let data1 = this.checkBoxGroupArray.find(
        (grp) => grp.key == this.selectedItem.group_key
      );
      bus.$emit("groupFieldUpdateOnChange", data1);
      //  }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        if (
          e.input_type == "ENTITY_VARIABLE" &&
          e.global_variable_entity_field &&
          e.global_variable_entity_field.includes("#") &&
          this.getAllFormTemplatesData.data
        ) {
          let id = e.global_variable_entity_field.split("#")[0];
          let selectedTemplate = this.getAllFormTemplatesData.data.find(
            (e) => e._id == id
          );
          if (
            selectedTemplate &&
            selectedTemplate.sections &&
            selectedTemplate.sections[0]
          ) {
            let key = e.global_variable_entity_field.split("#")[1];
            let field = selectedTemplate.sections[0].fields.find(
              (el) => el.key == key
            );
            if (field && field.options) {
              e.options = [...field.options];
            }
          }
        }
        return e;
      });
      fields = this.setGlobalVariables(fields, this.globalVariablesData);
      return fields;
    },
    getLastIndexOfElement(type) {
      let typeEle = this.elements.filter((element) => element.type == type);
      if (typeEle && typeEle.length) {
        typeEle = typeEle.sort((a, b) => a.index - b.index);
        return typeEle[typeEle.length - 1] && typeEle[typeEle.length - 1].index
          ? typeEle[typeEle.length - 1].index
          : 0;
      }
      return 0;
    },
    //entity data lable
    getLabel(item, i) {
      let selectedEntity = this.selectedGEntity
        ? this.selectedGEntity
        : item.entity_id;
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    //select entity
    async changeGEntity(reset) {
      this.loading = true;
      this.selectedGEntity = this.allEntitiesList.find(
        (e) => e._id == this.selectedItem.entity_id
      );
      this.allEntityDataList = [];
      if (reset) {
        if (this.selectedItem.entity_data_id) {
          this.selectedItem.entity_data_id = "";
        }
        if (this.selectedGEntity && this.selectedGEntity.name) {
          this.selectedItem.label =
            "Entity-" +
            this.selectedGEntity.name +
            " " +
            this.selectedItem.index;
          this.selectedItem.placeholder =
            "Select " +
            this.selectedGEntity.name +
            " " +
            this.selectedItem.index;
        }
      }
      if (this.selectedGEntity && this.selectedGEntity._id) {
        let params = {
          entity_id: this.selectedGEntity._id,
          template_fields_data: this.selectedGEntity.templates.map(
            (template) => {
              return {
                template_id: template.template_id,
              };
            }
          ),
          filters: this.selectedItem.filters,
        };

        await this.$store.dispatch(
          "entities/fetchEntityRecordsForTable",
          params
        );

        if (
          this.getEntityRecordsForTable &&
          this.getEntityRecordsForTable.data
        ) {
          this.allEntityDataList = this.getEntityRecordsForTable.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      }
    },
    //change entity data
    changeEntityData() {
      // const selectedEntityData = this.allEntityDataList.find(
      //   (e) => e && e._id == this.selectedItem.entity_data_id
      // );
    },
    //fetch all entities
    //fetch all templates
    async fetchAllFormTemplates() {
      try {
          
          this.getAllFormTemplatesData =
            await getAllCompanyTemplatesByPagination({
              get_all :true,
              
                include_standard: true,
              
            });
          }catch(err){
            this.$message.error('Error',err)
          }
    },
    updateField() {
      if (this.minGreaterThanMax) {
        this.errorMessage =
          "Minimum selections cannot be greater than maximum selections.";
      } else {
        this.errorMessage = "";
      }
      if (this.selectedItem.type == "MULTI_LINE_TEXT") {
        this.selectedItem.height = 60;
      }
      if (this.selectedItem.type) {
        this.selectedItem.placeholder = "Enter value";
        this.selectedItem.label = this.selectedItem.type
          .split("_")
          .join(" ")
          .toLowerCase();
        if (this.selectedItem.type == "CURRENCY") {
          this.selectedItem.value = 0;
        } else {
          this.selectedItem.value = "";
        }
        if (this.selectedItem.parent_entity_field_key) {
          this.selectedItem.parent_entity_field_key = "";
        }
        if (this.selectedItem.entity_id) {
          this.selectedItem.entity_id = "";
        }
        if (this.selectedItem.type == "FORMULA") {
          this.$set(this.selectedItem, "validations", {
            currency: "",
            locale: "en-US",
            required: false,
            type: "NUMBER",
          });
          this.$set(this.selectedItem, "selected_fields", []);
        }
      }
    },
    changeToPrimaryField() {
      if (this.selectedItem.template_id) {
        delete this.selectedItem.template_id;
      }
      if (this.selectedItem.template_field_id) {
        delete this.selectedItem.template_field_id;
      }
      if (this.selectedItem.entity_id) {
        delete this.selectedItem.entity_id;
      }
      if (this.selectedItem.selected_tag == "address") {
        this.selectedItem.height = 50;
      }
      if (this.selectedItem.selected_tag) {
        this.selectedItem.placeholder =
          "Enter " + this.selectedItem.selected_tag.split("_").join(" ");
      }
      this.selectedItem.label = this.selectedItem.selected_tag
        .split("_")
        .join(" ");
    },
    setEntityField() {
      this.entityFields = [];
      let selectedTemplate = this.fieldTemplates.find(
        (e) =>
          e &&
          this.selectedItem &&
          this.selectedItem.template_id &&
          e.template_id == this.selectedItem.template_id
      );
      if (selectedTemplate && selectedTemplate.fields) {
        this.fieldTemplate = this.selectedItem.template_id;
        this.entityFields = selectedTemplate.fields;
        let key = this.selectedItem.key.split("-")[2]
          ? this.selectedItem.key.split("-")[2]
          : this.selectedItem.key.split("-")[1];
        let selectedField = "";
        if (key) {
          if (key.includes("#")) {
            key = key.split("#")[0];
          }
          selectedField = this.entityFields.find((e) => e && e.key == key);
        } else {
          selectedField = this.entityFields.find(
            (e) => e && e._id == this.selectedItem.template_field_id
          );
        }
        if (selectedField && selectedField.key) {
          this.entityField = selectedField.key;
        }
      }
    },
    setEntityDataToField(rawKey, template_id, indexKey) {
      let dataValues =
        this.allTemplatesData[rawKey.split("-")[0] + "-" + template_id];
      if (dataValues) {
        if (indexKey && Object.keys(dataValues)[0]) {
          let key = rawKey.split("-")[2];
          if (key.includes("#")) {
            key = key.split("#")[0];
          }
          let innerData = dataValues[Object.keys(dataValues)[0]];
          if (innerData && innerData.length) {
            let selectedInnerData = innerData.find(
              (e) => e && e.indexKey == indexKey
            );
            if (selectedInnerData && selectedInnerData[key]) {
              return (
                selectedInnerData[key] +
                "" +
                (selectedInnerData[key + "/name"]
                  ? "-" + selectedInnerData[key + "/name"]
                  : "")
              );
            } else {
              return "";
            }
          }
          return "";
        } else {
          let key = rawKey.split("-")[1];
          if (key.includes("#")) {
            key = key.split("#")[0];
          }
          return dataValues[key];
        }
      } else {
        return "";
      }
    },
    changeRepeatableData() {
      if (this.selectedItem.key.includes("-")) {
        this.selectedItem.key =
          this.selectedItem.selected_user +
          "-" +
          this.selectedItem.indexKey +
          "-" +
          this.selectedItem.key.split("-")[2];
      }
      let value = this.setEntityDataToField(
        this.selectedItem.key,
        this.selectedItem.template_id,
        this.selectedItem.indexKey,
        this.selectedItem.allow_multiple
      );
      if (this.selectedItem.type == "ENTITY") {
        this.selectedItem.entity_data_id = value
          ? typeof value == "string" &&
            value.includes("-") &&
            value.split("-")[0]
            ? value.split("-")[0]
            : value
          : "";
      }
      if (this.selectedItem.type == "REPEATABLE_PARENT") {
        let value = this.setRepeatableUserDataToField(
          this.selectedItem.selected_user,
          this.selectedItem.template_id
        );
        if (value) {
          this.selectedItem.repeatable_data = value;
        } else {
          this.selectedItem.repeatable_data = [];
        }
      }
      this.selectedItem.value = value
        ? this.selectedItem.type == "ENTITY" &&
          value.includes("-") &&
          value.split("-")[1]
          ? value.split("-")[1]
          : value
        : "";
    },
    addEntityField() {
      if (this.entityField == "PARENT_FIELD") {
        this.selectedItem.type = "REPEATABLE_PARENT";
        this.$set(this.selectedItem, "repeatable_data", []);
        let indx =
          parseInt(this.getLastIndexOfElement("REPEATABLE_PARENT")) + 1;
        this.selectedItem.key = "REPEATABLE_PARENT_" + indx;
        this.selectedItem.index = indx;
        const selectedEntity = this.selectedItem.entity_id
          ? this.allEntitiesList.find(
              (e) => e._id == this.selectedItem.entity_id
            )
          : null;
        const selectedGTemplate =
          this.getAllFormTemplatesData &&
          this.getAllFormTemplatesData.data
            ? this.getAllFormTemplatesData.data.find(
                (e) => e._id == this.selectedItem.template_id
              )
            : {};
        let meteData = {
          max_value: 1,
          primary_key: null,
          template_name: "",
        };
        if (selectedEntity && selectedEntity.templates) {
          const selctedTemplate = selectedEntity.templates.find(
            (e) => e.template_id == this.selectedItem.template_id
          );
          meteData.max_value =
            selctedTemplate && selctedTemplate.max_records
              ? selctedTemplate.max_records
              : 1;
        }
        if (selectedGTemplate) {
          if (
            selectedGTemplate.primary_fields &&
            selectedGTemplate.primary_fields[0] &&
            selectedGTemplate.primary_fields[0]["key"]
          ) {
            meteData.primary_key = selectedGTemplate.primary_fields[0]["key"];
          }
          if (selectedGTemplate.name) {
            meteData.template_name = selectedGTemplate.name;
          }
        }
        this.$set(this.selectedItem, "repeatable_field", meteData);
        let value = this.setRepeatableUserDataToField(
          this.selectedItem.selected_user,
          this.selectedItem.template_id
        );
        if (value) {
          this.selectedItem.repeatable_data = value;
        } else {
          this.selectedItem.repeatable_data = [];
        }
      } else {
        let item = this.entityFields.find(
          (e) => e && e.key == this.entityField
        );
        if (item && item.inputType) {
          if (item.inputType == "FORMULA") {
            let isCurrency = item.selected_fields.find(
              (e) => e.input_type == "CURRENCY"
            );
            let isCurrencyType =
              item.validations && item.validations.currency ? true : false;
            if (item.result_type) {
              item.inputType = item.result_type;
            } else if (isCurrency || isCurrencyType) {
              item.inputType = "CURRENCY";
            } else if (item.date_result_type) {
              item.inputType = "DATE";
            } else {
              item.inputType = "NUMBER";
            }
          }
          if (item.inputType == "WEEKDAYS" || item.input_type == "WEEKDAYS") {
            console.log(item, "weeeeeeeekkkkkkk");
            this.selectedItem.allow_multiple = item.allow_multiple;
          }
          if (item.inputType == "LIST") {
            this.selectedItem.list_data = item.list_data;
          }
          if (item.inputType == "TIME" && item.is_fixed) {
            this.selectedItem.is_fixed = item.is_fixed;
          }
          if (item.inputType == "PHONE_COUNTRY_CODE") {
            item.inputType = "SINGLE_LINE_TEXT";
          }
          if (item.inputType == "FILE") {
            item.inputType = "ATTACHMENT";
          }
          this.selectedItem.type = item.inputType;
          this.selectedItem.template_id = item.template_id;
          if (item.validations) {
            this.selectedItem.validations = item.validations;
          }
          let exKey = "";
          exKey = this.selectedItem.parent_repeatable_field_key
            ? this.selectedItem.parent_repeatable_field_key
            : this.selectedItem.selected_user;
          let parentField = this.selectedItem.parent_repeatable_field_key
            ? this.elements.find(
                (e) => e.key == this.selectedItem.parent_repeatable_field_key
              )
            : null;
          if (this.checkIsRepeatable || (parentField && parentField.indexKey)) {
            exKey = exKey + "-" + this.selectedItem.indexKey;
          }
          this.selectedItem.key =
            exKey + "-" + item.key + "#" + item.template_id;
          this.selectedItem.template_field_id = item._id;
          this.selectedItem.label = item.label;
          this.selectedItem.section = "input";
          this.selectedItem.placeholder =
            item.placeholder || this.selectedItem.label;
          if (item && item.options && item.options.length) {
            this.selectedItem.options = item.options;
          }
          if (
            (this.selectedUserEntity && this.selectedUserEntity.id) ||
            item.entity_id
          ) {
            this.selectedItem.entity_id =
              item.inputType == "ENTITY" && item.entity_id
                ? item.entity_id
                : this.selectedUserEntity.id;
          }
          let value =
            item &&
            item.is_global_variable &&
            item.global_variable_id &&
            item.value
              ? item.value
              : this.setEntityDataToField(
                  this.selectedItem.key,
                  this.selectedItem.template_id,
                  this.selectedItem.indexKey,
                  this.selectedItem.allow_multiple
                );
          if (this.selectedItem.type == "ENTITY") {
            this.selectedItem.entity_data_id = value
              ? value.includes("-") && value.split("-")[0]
                ? value.split("-")[0]
                : value
              : "";
          }
          this.selectedItem.value = value
            ? this.selectedItem.type == "ENTITY" &&
              value.includes("-") &&
              value.split("-")[1]
              ? value.split("-")[1]
              : value
            : "";
          this.filesData[this.selectedItem.key] = this.selectedItem.value;
        }
      }
    },
    setRepeatableUserDataToField(selectedUser, template_id) {
      let dataValues = this.allTemplatesData[selectedUser + "-" + template_id];
      if (dataValues && Object.keys(dataValues)[0]) {
        return dataValues[Object.keys(dataValues)[0]];
      } else {
        return null;
      }
    },
    getRepeatableDataForIndividualField(data) {
      let result = [];
      const selectedUser = this.documentRecipientsList.find(
        (e) => e.value == data.selected_user
      );
      let entityId =
        selectedUser && selectedUser.contact_type
          ? selectedUser.contact_type._id
            ? selectedUser.contact_type._id
            : selectedUser.contact_type
          : data.entity_id;
      const selectedEntity =
        this.getEntityDataByQuery && this.getEntityDataByQuery.length
          ? this.getEntityDataByQuery.find((e) => e._id == entityId)
          : null;
      if (selectedEntity && selectedEntity.templates) {
        let template = selectedEntity.templates.find(
          (el) => el && el.template_id && el.template_id._id == data.template_id
        );
        let templateId =
          template && template.template_id && template.template_id._id
            ? template.template_id._id
            : template.template_id;
        const selectedTemplateData =
          this.getTemplatesData && this.getTemplatesData.length
            ? this.getTemplatesData.find(
                (t) =>
                  (selectedUser &&
                    selectedUser.entity_data_id &&
                    t.template_id == templateId &&
                    t.entity_data_id == selectedUser.entity_data_id._id) ||
                  t.entity_data_id ==
                    (selectedUser.entity_data_id &&
                      selectedUser.entity_data_id._id)
              )
            : null;

        if (template && template.max_records) {
          let primaryTempKey =
            template.template_id &&
            template.template_id.primary_fields &&
            template.template_id.primary_fields[0] &&
            template.template_id.primary_fields[0].key
              ? template.template_id.primary_fields[0].key
              : template.template_id.sections &&
                template.template_id.sections[0] &&
                template.template_id.sections[0].fields &&
                template.template_id.sections[0].fields[0] &&
                template.template_id.sections[0].fields[0].key
              ? template.template_id.sections[0].fields[0].key
              : "";
          let key =
            selectedTemplateData &&
            selectedTemplateData.template_data &&
            Object.keys(selectedTemplateData.template_data) &&
            Object.keys(selectedTemplateData.template_data)[0]
              ? Object.keys(selectedTemplateData.template_data)[0]
              : template.template_id.name.toLowerCase().split(" ").join("_");
          const repeatableData =
            key &&
            selectedTemplateData &&
            selectedTemplateData.template_data &&
            selectedTemplateData.template_data[key]
              ? selectedTemplateData.template_data[key]
              : [];
          let name =
            template && template.template_id && template.template_id.name
              ? template.template_id.name
              : "New Data";
          for (let i = 0; i < template.max_records; i++) {
            if (repeatableData && repeatableData.length && repeatableData[i]) {
              result.push({
                name: repeatableData[i][primaryTempKey]
                  ? repeatableData[i][primaryTempKey + "/name"]
                    ? repeatableData[i][primaryTempKey + "/name"]
                    : repeatableData[i][primaryTempKey]
                  : "Data",
                data: repeatableData[i],
                indexKey: repeatableData[i]["indexKey"],
              });
            } else if (this.isTemplate || !this.checkIsAllUsersConfigured) {
              result.push({
                name: name + " " + (i + 1),
                data: {},
                indexKey: "newData:" + (i + 1),
              });
            }
          }
        }
      }
      if (!result.length) {
        this.selectedItem.indexKey = "";
      }
      return result;
    },
    async saveCapturedSignature() {
      await this.$refs.mySignaturePad.saveCapturedSignature();
    },
    async saveCapturedInitial() {
      await this.$refs.myInitialPad.saveCapturedSignature();
    },
    changeTemplate() {
      if (this.fieldTemplate == "PRIMARY_FIELDS") {
        this.selectedItem.type = "PRIMARY_FIELDS";
        this.selectedItem.field_type = "CUSTOM_FIELD";
      } else if (this.fieldTemplate.includes("~")) {
        let relatedField = this.fieldTemplates.find(
          (e) => e.template_id == this.fieldTemplate
        );
        if (relatedField) {
          this.selectedItem.type = "ENTITY";
          this.selectedItem.field_type = "ENTITY";
          this.selectedItem.key = relatedField.key;
          this.selectedItem.filters = relatedField.filters;
          this.selectedItem.entity_id = relatedField.relation_entity_id;
        }
      } else {
        this.selectedItem.type = "";
        this.selectedItem.field_type = "ENTITY_FIELD";
        this.selectedItem.template_id = this.fieldTemplate;
        this.selectedItem.template_field_id = "";
        this.selectedItem.indexKey = null;
        this.selectedItem.key = "";
        this.selectedItem.entity_id = this.selectedUserEntity.id;
        this.entityField = "";
        this.entityFields = [];
        if (this.selectedItem.indexKey) {
          this.selectedItem.indexKey = "";
        }
        this.updateField();
        let selectedTemplate = this.fieldTemplates.find(
          (e) => e && e.template_id == this.fieldTemplate
        );
        if (selectedTemplate && selectedTemplate.fields) {
          this.entityFields = selectedTemplate.fields;
        }
        if (!selectedTemplate.is_repeatable) {
          this.selectedItem.indexKey = null;
        }
      }
    },
    changeFieldType() {
      let field = this.elements.find((e) => e.key == this.fieldType);
      if (this.fieldType == "CUSTOM_FIELD") {
        this.selectedItem.field_type = "CUSTOM_FIELD";
        this.selectedItem.type = "SINGLE_LINE_TEXT";
        this.selectedItem.label = "Single line text";
        this.selectedItem.indexKey = null;
        this.selectedItem.section = "input";
        this.selectedItem.placeholder = "Enter Value";
        if (this.selectedItem.content) {
          delete this.selectedItem.content;
        }
        if (this.selectedItem.template_id) {
          delete this.selectedItem.template_id;
        }
        if (this.selectedItem.parent_entity_field_key) {
          this.selectedItem.parent_entity_field_key = "";
        }
        if (this.selectedItem.parent_repeatable_field_key) {
          this.selectedItem.parent_repeatable_field_key = "";
        }
        return;
      } else if (this.fieldType == "ENTITY") {
        this.selectedItem.field_type = "ENTITY";
        this.selectedItem.type = "ENTITY";
        let indx = parseInt(this.getLastIndexOfElement("ENTITY")) + 1;
        this.selectedItem.key = "ENTITY_" + indx;
        this.selectedItem.index = indx;
        this.$set(this.selectedItem, "entity_data_id", "");
        if (this.selectedItem.parent_repeatable_field_key) {
          this.selectedItem.parent_repeatable_field_key = "";
        }
      } else if (this.fieldType.includes("~")) {
        this.selectedItem.field_type = "ENTITY_VARIABLE";
        let parentField = this.elements.find(
          (e) => e.type == "ENTITY" && this.fieldType == e.key
        );
        let entity_id = parentField.entity_id;
        let selectedGEntity = this.allEntitiesList.find(
          (e) => e._id == entity_id
        );
        this.selectedItem.entity_id = entity_id;
        this.$set(
          this.selectedItem,
          "parent_entity_field_key",
          parentField.key
        );
        // this.selectedItem.parent_entity_field_key = ;
        this.changeGEntity(false);
        this.getEntityVariableList();
        this.fetchEntityRelationships(
          entity_id,
          true,
          false,
          selectedGEntity.slug,
          selectedGEntity,
          ""
        );
      } else if (
        (this.fieldType &&
          this.fieldType.includes("_") &&
          this.fieldType.split("_")[0] == "ENTITY") ||
        (field && field.type == "ENTITY")
      ) {
        let parentField = this.elements.find(
          (e) => e.type == "ENTITY" && this.fieldType == e.key
        );
        if (this.selectedItem.parent_repeatable_field_key) {
          this.selectedItem.parent_repeatable_field_key = "";
        }
        if (parentField && parentField.entity_id) {
          this.selectedItem.field_type = "ENTITY_VARIABLE";
          this.selectedItem.entity_id = parentField.entity_id;
          this.$set(
            this.selectedItem,
            "parent_entity_field_key",
            parentField.key
          );
          this.changeGEntity(false);
          this.selectedItem.type = "";
          this.getEntityVariableList();
          this.fetchEntityRelationships(
            parentField.entity_id,
            true,
            false,
            parentField.key,
            this.selectedGEntity,
            ""
          );
        }
      } else if (
        this.fieldType &&
        this.fieldType.includes("_") &&
        this.fieldType.split("_")[0] == "REPEATABLE"
      ) {
        let parentField = this.elements.find(
          (e) => e.type == "REPEATABLE_PARENT" && this.fieldType == e.key
        );
        if (parentField && parentField.template_id && parentField.entity_id) {
          this.selectedItem.field_type =
            parentField.field_type == "ENTITY_FIELD"
              ? "ENTITY_FIELD"
              : "ENTITY_VARIABLE";
          this.$set(
            this.selectedItem,
            "parent_repeatable_field_key",
            parentField.key
          );
          this.selectedItem.entity_id = parentField.entity_id;
          this.selectedItem.template_id = parentField.template_id;
          if (parentField.indexKey) {
            this.selectedItem.indexKey = parentField.indexKey;
          }
          if (parentField.field_type != "ENTITY_FIELD") {
            this.selectedItem.type = "";
            this.$set(
              this.selectedItem,
              "parent_entity_field_key",
              parentField.parent_entity_field_key
            );
            this.changeGEntity(false);
            this.getEntityVariableList();
            this.changeGTemplate(true);
          } else {
            this.selectedItem.selected_user = parentField.selected_user;
            this.entityFields = [];
            const selectedGTemplate =
              this.getAllFormTemplatesData &&
              this.getAllFormTemplatesData.data
                ? this.getAllFormTemplatesData.data.find(
                    (e) => e._id == this.selectedItem.template_id
                  )
                : {};
            if (selectedGTemplate && selectedGTemplate.sections) {
              this.entityFields = this.getTemplateFields(selectedGTemplate);
            }
          }
        }
      } else {
        let selectedUser = this.documentRecipientsList.find(
          (e) => e && e.value == this.fieldType
        );
        if (selectedUser) {
          this.selectedItem.selected_user = selectedUser.value;
          let entityId =
            selectedUser.contact_type && selectedUser.contact_type._id
              ? selectedUser.contact_type._id
              : selectedUser.contact_type
              ? selectedUser.contact_type
              : selectedUser.entity_data_id &&
                selectedUser.entity_data_id.company
              ? selectedUser.entity_data_id.company
              : null;
          this.fieldTemplate = "PRIMARY_FIELDS";
          this.selectedItem.type = "PRIMARY_FIELDS";
          this.selectedItem.indexKey = null;
          this.selectUserEntity(entityId);
          let selectedGEntity = this.allEntitiesList.find(
            (e) => e._id == entityId
          );
          let value = selectedUser.entity_data_id
            ? selectedUser.entity_data_id._id
              ? selectedUser.entity_data_id._id
              : selectedUser.entity_data_id
            : "";
          this.fetchEntityRelationships(
            entityId,
            true,
            true,
            "",
            selectedGEntity,
            value
          );
          if (this.selectedItem.field_type == "ENTITY_VARIABLE") {
            this.selectedItem.field_type = "ENTITY_FIELD";
          }
          if (this.selectedItem.parent_entity_field_key) {
            this.selectedItem.parent_entity_field_key = "";
          }
          if (this.selectedItem.parent_repeatable_field_key) {
            this.selectedItem.parent_repeatable_field_key = "";
          }
        }
      }
    },
    selectUserEntity(contact_type) {
      this.selectedUserEntity = {};
      if (contact_type) {
        this.selectedUserEntity = this.allUsersEntityData.find(
          (e) => e.id == contact_type || e.name == contact_type
        );
      } else if (this.getCompanyDetails && this.getCompanyDetails._id) {
        this.selectedUserEntity = this.allUsersEntityData.find(
          (e) => e.name == this.getCompanyDetails._id
        );
      }
      if (this.selectedUserEntity && this.selectedUserEntity.templates) {
        this.fieldTemplates = [];
        this.fieldTemplates.push({
          template_id: "PRIMARY_FIELDS",
          name: "Primary Fields",
        });
        this.selectedUserEntity.templates.forEach((element) => {
          if (element) {
            this.fieldTemplates.push(element);
          }
        });
      }
    },
    checkRequiredFiles() {
      if (
        this.selectedItem.requiredFiles > this.selectedItem.file_names.length
      ) {
        this.selectedItem.requiredFiles =
          this.selectedItem.file_names.length > 0
            ? this.selectedItem.file_names.length
            : 1;
      }
    },
    uploadImageFile(file) {
      setTimeout(() => {
        const doc = document.querySelector(".ankaCropper__previewArea");
        this.imgData = doc.querySelector("img").src;
      }, 1000);
      let imgDataWidth = 0;
      let imgDataHeight = 0;
      let that = this;
      this.imgData = file;
      var img = document.createElement("img");
      img.src = file;
      img.onload = function () {
        var w = img.width;
        var h = img.height;
        imgDataWidth = w / 2;
        imgDataHeight = h / 2;
        img.remove();
        that.$emit("fillImageData", { file, imgDataWidth, imgDataHeight });
      };
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    removeAttachment(index) {
      this.selectedItem.files.splice(index, 1);
    },
    async uploadFile(file) {
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          await this.attachFiles(file);
        } else {
          this.$message.error("Large file.Maximum upload file size : 25 MB!");
        }
      } else {
        this.$message.warning("File format is not supported");
      }
    },
    async attachFiles(file) {
      try {
        this.attachmentLoading = true;
        let docPaths = await this.uploadFileForAttachments([file]);
        this.setUploadedSingleFile(file, docPaths);
      } catch (err) {
        this.attachmentLoading = false;
      }
    },

    setUploadedSingleFile(file, docPaths) {
      this.selectedAttachmentFile.attachment_url = docPaths[0];
      this.selectedAttachmentFile.title = file.raw.name;
      let obj = {
        document_name: this.selectedAttachmentFile.document_name,
        attachment_url: this.selectedAttachmentFile.attachment_url,
        valid_from: this.selectedAttachmentFile.valid_from,
        document_number: this.selectedAttachmentFile.document_number,
        expires_on: this.selectedAttachmentFile.expires_on,
        title: this.selectedAttachmentFile.title,
        description: this.selectedAttachmentFile.description,
      };
      this.selectedItem.files.push(obj);
      this.attachmentErrors = "";
      this.attachmentLoading = false;
    },

    resetSelectedAttachmentFile() {
      this.selectedAttachmentFile.document_name = "";
      this.selectedAttachmentFile.attachment_url = "";
      this.selectedAttachmentFile.valid_from = "";
      this.selectedAttachmentFile.document_number = "";
      this.selectedAttachmentFile.expires_on = "";
      this.selectedAttachmentFile.title = "";
      this.selectedAttachmentFile.description = "";
    },

    //maheswari
    validateOptions() {
      const options = this.selectedItem.options.map((opt) => opt.trim());

      // Check if options array is empty
      if (options.length === 0 && this.initialValidation) {
        this.errors = true;
        this.errorMessage = " ";
        return;
      }

      // Check if any option consists only of spaces
      const hasInvalidOptions = options.some((opt) => opt.trim() === "");
      if (hasInvalidOptions) {
        this.errors = true;
        this.errorMessage =
          "An option should not be only of spaces,so please remove it.";
        return;
      }

      const normalizedOptions = options.map((opt) => opt.toLowerCase());
      const uniqueOptionsSet = new Set();
      let hasDuplicates = false;

      for (let opt of normalizedOptions) {
        if (uniqueOptionsSet.has(opt)) {
          hasDuplicates = true;
          break;
        }
        uniqueOptionsSet.add(opt);
      }
      if (hasDuplicates) {
        this.errors = true;
        this.$message.error(
          "An option with the same value already exists. The duplicate has been removed."
        );
        this.selectedItem.options.pop();
      }
      this.errors = false;
      this.errorMessage = "";
    },
  },
  watch: {
    handler: function () {
      this.generateTableData();
    },
    "selectedItem.value": function (newValue) {
      if (newValue) {
        this.selectedItem.boxNumber = newValue.length;
      } else {
        this.selectedItem.boxNumber = 0;
      }
    },
    "selectedItem.mapped_currency": {
      handler: "mapCurrencyValueToZseal",
      immediate: true,
    },
    "selectedItem.cols"(newVal) {
      const count = parseInt(newVal);
      this.selectedItem.table_columns_data = Array.from(
        { length: count },
        () => ({
          name: "",
          key: "",
          value: "",
        })
      );
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
}
.radio-group {
  margin-top: 2px;
}
@media screen and (max-width: 1366px) {
  .step-time {
    width: 65% !important;
    margin-left: 30px;
    margin-bottom: 0px !important;
  }
  .step-time-head label {
    margin-left: 30px;
  }
}
</style>
<style scoped>
.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: 1px solid #999;
  border-radius: 4px;
  position: relative;
}

.custom-checkbox:checked::before {
  content: "\00D7"; /* Cross mark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 38px;
  color: black;
}
.min-max-selections {
  display: flex;
  gap: 20px !important;
}
</style>

<style lang="scss">
.paragraph-field-content {
  .el-textarea .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 50vh;
    font-size: inherit;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  *::-webkit-scrollbar,
  *::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-clip: padding-box;
    border: 10px solid rgba(0, 0, 0, 0.19);
  }
}
</style>
