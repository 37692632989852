<template>
  <section
    class="preview-template-view"
    v-loading="loading"
    element-loading-text="Loading template..."
  >
    <div class="preview-form-header" v-if="!fromEntityViews">
      <el-row>
        <el-col
          :lg="12"
          :md="12"
          :sm="12"
          :xs="24"
          :class="this.templateIdViewId ? '' : 'd-flex'"
        >
          <div v-if="!this.templateIdViewId" class="mr-1">
            <icons v-if="templateIconPath" :iconName="templateIconPath"></icons>
          </div>
          <div style="display: flex">
            <h1 v-if="!this.templateIdViewId">{{ name }}</h1>
            <p v-if="!this.templateIdViewId">{{ description }}</p>
          </div>
        </el-col>
        <el-col
          v-if="!this.templateIdViewId && !isMenuTemplate"
          :md="12"
          :sm="12"
          :xs="24"
        >
          <div class="header-action-block d-flex justify-end">
            <el-button
              size="small"
              icon="el-icon-edit"
              class="edit-btn button-left"
              @click="backToEditTemplate()"
              >Edit</el-button
            >
            <el-button
              size="small"
              icon="el-icon-back"
              class="back-btn btn button-left"
              @click="backToTemplates()"
              >Back</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="isFromFormbuilder" class="default-save">
      <span class="warning" style="font-size: 14px">
        <i class="el-icon-info"></i> You can enter default values in the form
        below and please click on "Save Default Values" to save them.
      </span>
      <el-button type="primary" size="small" @click="saveDefaultValues">
        <i class="el-icon-circle-check"></i> Save default values
      </el-button>
    </div>
    <el-row
      type="flex"
      justify="center"
      class="main-view-template"
      v-if="!interactiveQuestionsMode"
    >
      <el-col :span="24">
        <el-form>
          <el-scrollbar
            :wrap-style="
              this.templateIdViewId && !this.fromEntityViews
                ? 'max-width: calc(100% - 0px);height: calc(100vh - 0px);'
                : 'max-width: calc(100% - 0px);'
            "
          >
            <div
              class="form-builder"
              ref="formBuilder"
              :style="getStylediv"
              id="form_builder"
            >
              <div
                v-if="fieldsData && fieldsData.length"
                class="form-fields-holder"
              >
                <!-- {{form}} -->

                <div
                  v-for="(field, index) of fieldsData"
                  :key="index"
                  :style="getExistedStyle(field) || getStyle(field, index)"
                >
                  <DateExecute
                    :data="field"
                    v-if="
                      field.input_type === 'DATE' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :isIconRequired="templateStyles?.icons_required"
                  ></DateExecute>
                  <icon-execute
                    :data="field"
                    v-if="
                      field.input_type === 'ICON' && !checkhideFields(field)
                    "
                    :form="form"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                  ></icon-execute>
                  <random-text-execute
                    :data="field"
                    v-if="
                      field.input_type === 'RANDOM_TEXT' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :isIconRequired="templateStyles?.icons_required"
                  ></random-text-execute>
                  <DateTimeExecute
                    :data="field"
                    v-if="
                      field.input_type === 'DATE_TIME' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></DateTimeExecute>
                  <DateTimeRangeExecute
                    :data="field"
                    v-if="
                      field.input_type === 'DATE_TIME_RANGE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :colorFields="colorFields"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></DateTimeRangeExecute>
                  <DateRangeExecute
                    :data="field"
                    v-if="
                      field.input_type === 'DATE_RANGE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    :isView="fromEntityViews"
                    :fromEntityViews="fromEntityViews"
                    :colorFields="colorFields"
                    @applyFormRules="rulesEventEmitter"
                    :isIconRequired="templateStyles?.icons_required"
                  ></DateRangeExecute>
                  <ListExecute
                    :data="field"
                    v-if="
                      field.input_type === 'LIST' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    :colorFields="colorFields"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></ListExecute>
                  <TimeExecute
                    :data="field"
                    v-if="
                      field.input_type === 'TIME' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></TimeExecute>
                  <TimeRangeExecute
                    :data="field"
                    v-if="
                      field.input_type === 'TIME_RANGE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></TimeRangeExecute>
                  <FileExecuteView
                    :data="field"
                    v-if="
                      (field.input_type === 'FILE' ||
                        field.inputType === 'FILE') &&
                      !checkhideFields(field)
                    "
                    :hasLabel="hasLabel"
                    :form="form"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></FileExecuteView>

                  <MultiLineTextExecute
                    :data="field"
                    v-if="
                      (field.input_type === 'MULTI_LINE_TEXT' ||
                        field.input_type === 'RICH_TEXT') &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                  ></MultiLineTextExecute>
                  <SingleLineTextExecute
                    :data="field"
                    v-if="
                      (field.input_type === 'SINGLE_LINE_TEXT' ||
                        field.input_type === 'EMAIL' ||
                        field.input_type === 'PASSWORD' ||
                        field.input_type === 'MASKED' ||
                        field.input_type === 'HYPER_LINK') &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :isView="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></SingleLineTextExecute>
                  <location-execute
                    :data="field"
                    v-if="field.input_type === 'LOCATION'"
                    :form="form"
                    :hasLabel="hasLabel"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :label="getFieldLabelFromRules(field)"
                    :colorFields="colorFields"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></location-execute>
                  <MultiSelectExecute
                    :data="field"
                    v-if="
                      field.input_type === 'MULTI_SELECT' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :hideOptions="hideOptions"
                    :isIconRequired="templateStyles?.icons_required"
                  ></MultiSelectExecute>
                  <SelectExecute
                    :data="field"
                    v-if="
                      field.input_type === 'SELECT' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :hideOptions="hideOptions"
                    :isIconRequired="templateStyles?.icons_required"
                  ></SelectExecute>
                  <YesOrNoExecute
                    :data="field"
                    v-if="
                      field.input_type === 'YES_OR_NO' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></YesOrNoExecute>
                  <NumberExecute
                    :data="field"
                    v-if="
                      (field.input_type === 'NUMBER' ||
                        field.input_type === 'ZIP_CODE') &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :templateRules="templateRules"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :fromEntityViews="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></NumberExecute>
                  <PhoneCountryCodeExecute
                    :data="field"
                    v-if="
                      field.input_type === 'PHONE_COUNTRY_CODE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></PhoneCountryCodeExecute>
                  <CurrencyExecute
                    :data="field"
                    v-if="
                      field.input_type === 'CURRENCY' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :checkhideFields="checkhideFields(field)"
                    :label="getFieldLabelFromRules(field)"
                    :fromEntityViews="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></CurrencyExecute>
                  <HeadingExecute
                    :data="field"
                    v-if="
                      field.input_type === 'HEADING' && !checkhideFields(field)
                    "
                    :form="form"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                  ></HeadingExecute>
                  <CheckBoxExecute
                    :data="field"
                    v-if="
                      field.input_type === 'CHECKBOX' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :singleCompanyTemplateCheckBoxArray="
                      singleCompanyTemplateCheckBoxArray
                    "
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></CheckBoxExecute>
                  <RadioExecute
                    :data="field"
                    v-if="
                      field.input_type === 'RADIO' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></RadioExecute>
                  <ESignatureExecute
                    :data="field"
                    v-if="
                      field.input_type === 'SIGNATURE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    class="esignature-execute"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></ESignatureExecute>
                  <GlobalVariableExecute
                    :data="field"
                    v-if="field.input_type === 'GLOBAL_VARIABLE'"
                    :form="form"
                    :fieldsData="fieldsData"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :hasLabel="hasLabel"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                  ></GlobalVariableExecute>
                  <CalenderExecute
                    :data="field"
                    v-if="field.input_type === 'CALENDER'"
                    :form="form"
                    :is-view="fromEntityViews"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    :fieldsData="fieldsData"
                    :allEntitiesData="allEntitiesData"
                    :allFormTemplates="allFormTemplates"
                    :isIconRequired="templateStyles?.icons_required"
                  ></CalenderExecute>
                  <EntityVariableExecute
                    :data="field"
                    v-if="
                      field.input_type === 'ENTITY_VARIABLE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :class="'dataTable' + index"
                    @updateTableData="updateTableFieldData"
                    @entityDataUpdated="setDataToEntityVariables"
                    @clearEntityFields="unsetEntityVariables"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                  ></EntityVariableExecute>
                  <EntityExecute
                    :data="field"
                    v-if="
                      field.input_type === 'ENTITY' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :label="getFieldLabelFromRules(field)"
                    @entityDataUpdated="setDataToEntityVariables"
                    @clearEntityFields="unsetEntityVariables"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :refreshEntityFields="refreshEntityFields"
                    :isIconRequired="templateStyles?.icons_required"
                  ></EntityExecute>
                  <CheckBoxGroupExecute
                    :data="field"
                    v-if="
                      field.input_type === 'CHECKBOX_GROUP' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></CheckBoxGroupExecute>
                  <RadioGroupExecute
                    :data="field"
                    v-if="
                      field.input_type === 'RADIO_BUTTON_GROUP' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></RadioGroupExecute>
                  <ParagraphExecute
                    :data="field"
                    v-if="field.input_type === 'PARAGRAPH'"
                    :form="form"
                    :fromEntityViews="fromEntityViews"
                  ></ParagraphExecute>
                  <SingleLineContentExecute
                    :data="field"
                    v-if="
                      field.input_type === 'SINGLE_LINE_CONTENT' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :colorFields="colorFields"
                    :fieldsData="fieldsData"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                  ></SingleLineContentExecute>
                  <AuthorizedSignatureExecute
                    :data="field"
                    v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                    :form="form"
                    :fromEntityViews="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></AuthorizedSignatureExecute>
                  <ImageExecute
                    :data="field"
                    v-if="
                      (field.input_type === 'IMAGE' ||
                        field.input_type === 'GIF') &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :isView="fromEntityViews"
                    :hasLabel="hasLabel"
                    @close="closeGalleryModal"
                    :isIconRequired="templateStyles?.icons_required"
                  ></ImageExecute>
                  <VideoExecute
                    :data="field"
                    v-if="
                      field.input_type === 'VIDEO' && !checkhideFields(field)
                    "
                    :form="form"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :hasLabel="hasLabel"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></VideoExecute>
                  <FormulaExecute
                    :data="field"
                    v-if="
                      field.input_type === 'FORMULA' && !checkhideFields(field)
                    "
                    :hasLabel="hasLabel"
                    :form="form"
                    :label="getFieldLabelFromRules(field)"
                    :fieldsData="fieldsData"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></FormulaExecute>
                  <ActionButtonExecute
                    :data="field"
                    v-if="
                      field.input_type === 'ACTION_BUTTON' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :fieldsData="fieldsData"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :selfTemplateId="selectedTemplateId"
                    :templateName="name"
                    @actionButtonCallBacks="actionButtonCallBacks"
                  ></ActionButtonExecute>
                  <HtmlExecute
                    :data="field"
                    v-if="field.input_type === 'HTML_CONTENT'"
                    :form="form"
                    :fromEntityViews="fromEntityViews"
                  ></HtmlExecute>
                  <HorizontalLineExecute
                    :data="field"
                    v-if="field.input_type === 'HORIZONTAL_LINE'"
                    :form="form"
                    :fromEntityViews="fromEntityViews"
                  ></HorizontalLineExecute>
                  <CarouselExecute
                    :data="field"
                    v-if="field.input_type === 'CAROUSEL'"
                  >
                  </CarouselExecute>
                  <TableExecute
                    :data="field"
                    :form="form"
                    :hasLabel="hasLabel"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :isView="fromEntityViews"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    v-if="field.input_type === 'TABLE'"
                  >
                  </TableExecute>
                  <DIVExecute
                    :data="field"
                    v-if="field.input_type === 'DIVISION' && !getIsMobile"
                    :visibility="checkhideFields(field)"
                    :form="form"
                    :fromEntityViews="fromEntityViews"
                    :class="'dataTable' + index"
                    ref="dataTable"
                  ></DIVExecute>
                  <CurrencyTypesExecute
                    :data="field"
                    v-if="field.input_type === 'CURRENCY_TYPE' && !getIsMobile"
                    :visibility="checkhideFields(field)"
                    :form="form"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :isView="fromEntityViews"
                    :fromEntityViews="fromEntityViews"
                    :class="'dataTable' + index"
                    ref="dataTable"
                    :isIconRequired="templateStyles?.icons_required"
                  ></CurrencyTypesExecute>
                  <NavBarExecute
                    :data="field"
                    v-if="field.input_type === 'NAV_BAR' && !getIsMobile"
                    :visibility="checkhideFields(field)"
                    :form="form"
                    :fromEntityViews="fromEntityViews"
                    :class="'dataTable' + index"
                    ref="dataTable"
                  ></NavBarExecute>
                  <AggregateFunctionExecute
                    :data="field"
                    v-if="
                      field.input_type === 'AGGREGATE_FUNCTION' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></AggregateFunctionExecute>
                  <QuestionExecute
                    :data="field"
                    v-if="
                      field.input_type === 'QUESTION' && !checkhideFields(field)
                    "
                    :form="form"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></QuestionExecute>
                  <AutoIncrementExecute
                    :data="field"
                    v-if="
                      field.input_type === 'AUTO_INCREMENT_NUMBER' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    @applyFormRules="rulesEventEmitter"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></AutoIncrementExecute>
                  <StarRatingExecute
                    :data="field"
                    v-if="
                      field.input_type === 'STAR_RATING' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></StarRatingExecute>
                  <WeekDaysExecute
                    :data="field"
                    v-if="
                      field.input_type == 'WEEKDAYS' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                  ></WeekDaysExecute>
                  <DataTableExecute
                    :class="'dataTable' + index"
                    ref="dataTable"
                    :data="field"
                    v-if="field.input_type === 'DATA_TABLE'"
                    :visibility="checkhideFields(field)"
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :hiddenColumns="hideFields"
                    :disabledColumnsFields="disabledFields"
                    :checkAllFieldsDisabled="checkIsDisabled(field)"
                    @updateTableData="updateTableFieldData"
                    @onNewRowAdded="updateTopForBelowElements(index)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></DataTableExecute>
                  <EntityTableExecute
                    :class="'dataTable' + index"
                    ref="dataTable"
                    :data="field"
                    v-if="field.input_type === 'ENTITY_TABLE'"
                    :visibility="checkhideFields(field)"
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :colorFields="colorFields"
                    :hiddenColumns="hideFields"
                    :checkAllFieldsDisabled="checkIsDisabled(field)"
                    :entityTableDetails="getEntityTableDetails(field, form)"
                    @updateTableData="updateTableFieldData"
                    @onNewRowAdded="updateTopForBelowElements(index)"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :refreshEntityFields="refreshEntityFields"
                    :isIconRequired="templateStyles?.icons_required"
                  ></EntityTableExecute>
                  <PaymentLinkExecute
                    :data="field"
                    v-if="
                      field.input_type === 'PAY_BUTTON' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                  ></PaymentLinkExecute>

                  <PayablesExecute
                    :data="field"
                    v-if="field.input_type === 'RECEIVE_BUTTON'"
                    :form="form"
                    :hasLabel="hasLabel"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                  />

                  <PayVariableExecute
                    :data="field"
                    v-if="
                      field.input_type === 'PAYMENT_VARIABLE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :fieldsData="fieldsData"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @entityDataUpdated="setDataToEntityVariables"
                    @clearEntityFields="unsetEntityVariables"
                    @applyFormRules="rulesEventEmitter"
                  ></PayVariableExecute>
                  <ConcatenateExecute
                    :data="field"
                    v-if="
                      field.input_type == 'CONCATENATE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :isIconRequired="templateStyles?.icons_required"
                  ></ConcatenateExecute>
                  <AudioExecute
                    :data="field"
                    v-if="
                      field.input_type == 'AUDIO' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></AudioExecute>
                  <CaptchaExecute
                    :data="field"
                    v-if="
                      field.input_type == 'CAPTCHA' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :isIconRequired="templateStyles?.icons_required"
                  ></CaptchaExecute>
                  <QRExecute
                    :data="field"
                    v-if="field.input_type == 'QR' && !checkhideFields(field)"
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                  ></QRExecute>

                  <QRReaderExecute
                    :data="field"
                    v-if="
                      field.input_type == 'QR_READER' && !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                  ></QRReaderExecute>
                  <IntegrationExecute
                    :data="field"
                    v-if="
                      field.input_type == 'INTEGRATION' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :fieldsData="fieldsData"
                    @integrationDataUpdated="setDataToIntegrationVariables"
                    :integration-data="integrationData"
                    :integrationFieldValue="integrationFieldValue"
                    :triggeredIntegrationField="dependentFields"
                    :label="getFieldLabelFromRules(field)"
                    @integrationFieldValueUpdated="setDataToIntegrationField"
                    @handleFormChange="handleFormChange"
                  >
                  </IntegrationExecute>
                  <IntegrationVariableExecute
                    :data="field"
                    v-if="
                      field.input_type == 'INTEGRATION_VARIABLE' &&
                      !checkhideFields(field)
                    "
                    :form="form"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :label="getFieldLabelFromRules(field)"
                    :checkIsDisabled="checkIsDisabled(field)"
                    @applyFormRules="rulesEventEmitter"
                    :fromEntityViews="fromEntityViews"
                    :isView="fromEntityViews"
                    :fieldsData="fieldsData"
                    @integrationDataUpdated="setDataToIntegrationVariables"
                    :integration-data="integrationData"
                    @handleFormChange="handleFormChange"
                    :filteredIntegrationVariableFields="
                      allFilteredIntegrationVaribleFields
                    "
                    :selectedIntegrationRecord="selectedIntegrationRecord"
                  >
                  </IntegrationVariableExecute>

                  <!-- <div
                    v-if="!refresh && !hideFields.find((e) => e === field.key)"
                  >-->

                  <!-- </div> -->
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-form>
      </el-col>
    </el-row>
    <template v-if="interactiveQuestionsMode">
      <ViewInteractiveTemplate></ViewInteractiveTemplate>
    </template>
    <!-- <el-row type="flex" justify="center">
      <el-col :span="18">
        <div class="footer-action-block">
          <el-button class="submit-btn button-center">SUBMIT</el-button>
        </div>
      </el-col>
    </el-row>-->
    <!-- <el-button
      v-if="isFlow && hasNext"
      class="submit-button btn button-right"
      @click="next"
    >Save & Next</el-button>-->
  </section>
</template>

<script>
// const { bus } =()=>import("../../main")
import { bus } from "../../main";
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import dataTableStylesHelper from "@/mixins/dataTableStylesHelper";

import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
var fieldsData = require("./fields.json");
// import templateConstants from "../../constants/lg-en/templates";

import NavigationHelper from "@/mixins/navigationHelper";
// import { Input } from 'element-ui';
// import { dataTableAllowedExcecuteFields } from "./formComponentsExecute/index";

import { fetchSingleCompanyTemplate } from "@/repo/templatesRepo";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  name: "templates-ViewTemplate",
  components: {
    // ...dataTableAllowedExcecuteFields,
    DateExecute: () => import("./formComponentsExecute/DateExecute.vue"),
    DateTimeExecute: () =>
      import("./formComponentsExecute/DateTimeExecute.vue"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute.vue"),
    DateRangeExecute: () =>
      import("./formComponentsExecute/DateRangeExecute.vue"),
    LocationExecute: () =>
      import("./formComponentsExecute/LocationExecute.vue"),
    ListExecute: () => import("./formComponentsExecute/ListExecute.vue"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute.vue"),
    TimeRangeExecute: () =>
      import("./formComponentsExecute/TimeRangeExecute.vue"),
    FileExecuteView: () =>
      import("./formComponentsExecute/FileExecuteView.vue"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute.vue"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute.vue"),
    TableExecute: () => import("./formComponentsExecute/TableExecute.vue"),
    CarouselExecute: () =>
      import("./formComponentsExecute/CarouselExecute.vue"),
    MultiSelectExecute: () =>
      import("./formComponentsExecute/MultiSelectExecute.vue"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute.vue"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute.vue"),
    YesOrNoExecute: () => import("./formComponentsExecute/YesOrNoExecute.vue"),
    HeadingExecute: () => import("./formComponentsExecute/HeadingExecute.vue"),
    CheckBoxExecute: () =>
      import("./formComponentsExecute/CheckBoxExecute.vue"),
    RadioExecute: () => import("./formComponentsExecute/RadioExecute.vue"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute.vue"),
    ParagraphExecute: () =>
      import("./formComponentsExecute/ParagraphExecute.vue"),
    GlobalVariableExecute: () =>
      import("./formComponentsExecute/GlobalVariableExecute.vue"),
    EntityVariableExecute: () =>
      import("./formComponentsExecute/EntityVariableExecute.vue"),
    SingleLineContentExecute: () =>
      import("./formComponentsExecute/SingleLineContentExecute.vue"),
    AuthorizedSignatureExecute: () =>
      import("./formComponentsExecute/AuthorizedSignatureExecute.vue"),
    ImageExecute: () => import("./formComponentsExecute/ImageExecute.vue"),
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute.vue"),
    ActionButtonExecute: () =>
      import("./formComponentsExecute/ActionButtonExecute.vue"),
    HtmlExecute: () => import("./formComponentsExecute/HtmlExecute.vue"),
    AggregateFunctionExecute: () =>
      import("./formComponentsExecute/AggregateFunctionExecute.vue"),
    VideoExecute: () => import("./formComponentsExecute/VideoExecute.vue"),
    HorizontalLineExecute: () =>
      import("./formComponentsExecute/HorizontalLineExecute.vue"),
    DIVExecute: () => import("./formComponentsExecute/DIVExecute.vue"),
    CurrencyTypesExecute: () =>
      import("./formComponentsExecute/CurrencyTypesExecute.vue"),
    NavBarExecute: () => import("./formComponentsExecute/NavBarExecute.vue"),
    CurrencyExecute: () =>
      import("./formComponentsExecute/CurrencyExecute.vue"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute"),
    CheckBoxGroupExecute: () =>
      import("./formComponentsExecute/CheckBoxGroupExecute"),
    //RadioButtonGroupExecute
    RadioGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute"),
    QuestionExecute: () =>
      import("./formComponentsExecute/QuestionExecute.vue"),
    AutoIncrementExecute: () =>
      import("./formComponentsExecute/AutoIncrementExecute.vue"),
    StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute.vue"),

    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    DataTableExecute: () => import("./formComponentsExecute/DataTableExecute"),
    PaymentLinkExecute: () =>
      import("./formComponentsExecute/PaymentExecute.vue"),
    PayablesExecute: () =>
      import("./formComponentsExecute/PayablesExecute.vue"),
    PayVariableExecute: () =>
      import("./formComponentsExecute/PayVariableExecute.vue"),
    ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute.vue"),
    EntityTableExecute: () =>
      import("./formComponentsExecute/EntityTableExecute"),
    ViewInteractiveTemplate: () => import("./ViewInteractiveTemplate.vue"),
    AudioExecute: () => import("./formComponentsExecute/AudioExecute.vue"),
    CaptchaExecute: () => import("./formComponentsExecute/CaptchaExecute.vue"),
    QRExecute: () => import("./formComponentsExecute/QRExecute.vue"),
    QRReaderExecute: () =>
      import("./formComponentsExecute/QRReaderExecute.vue"),
    IconExecute: () => import("./formComponentsExecute/IconExecute.vue"),
    RandomTextExecute: () =>
      import("./formComponentsExecute/RandomTextExecute.vue"),
    CalenderExecute: () =>
      import("./formComponentsExecute/CalenderExecute.vue"),
    IntegrationExecute: () =>
      import("./formComponentsExecute/IntegrationExecute.vue"),
    IntegrationVariableExecute: () =>
      import("./formComponentsExecute/IntegrationVariableExecute.vue"),
  },
  props: [
    "templateIdViewId",
    "fromEntityViews",
    "formData",
    "formbuilderId",
    "isFromFormbuilder",
    "isEntityStep",
    "defaultValuesForm",
  ],
  data() {
    return {
      //templateIdViewId:this.templateIdViewId,
      form: {},
      showGallery: false,
      labelsData: {},
      changedLabel: "",
      fieldsData: [],
      currentFormBuilderWidth: null,
      name: "",
      description: "",
      type: "",
      slug: "",
      storedFormBuilderWidth: null,
      isFlow: false,
      componentName: "",
      formHeight: 600,
      singleCompanyTemplateCheckBoxArray: [],
      loading: false,
      fieldActions: [],
      templateIconPath: "block-portfolio.svg",
      templateStyles: {},
      hasLabel: true,
      pos: {
        x: 0,
        y: 0,
        height: 0,
      },
      interactiveQuestionsMode: false,
      interactiveQuestionsModeType: "vertical",

      interactiveQuestionsLoading: false,
      completedFieldsData: [],
      lastInteactiveQuestionIndex: -1,
      templateRules: [],
      showTemplateFields: [],
      hideFields: [],
      hideDivisionFields: [],
      disabledFields: [],
      refresh: false,
      colorFields: {},
      company_id: "",
      isMenuTemplate: false,
      refreshEntityFields: false,
      allEntitiesData: [],
      allFormTemplates: [],
      hideOptions: {},
      integrationData: null,
      integrationFieldValue: null,
      dependentFields: [],
      allFilteredIntegrationVaribleFields: [],
      selectedIntegrationRecord: {},
    };
  },

  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("companyTemplates", [
      "getTemplateAddStatus",
      "getTemplateUpdateStatus",
      "getSingleCompanyTemplate",
      "getSingleDefaultTemplate",
      "getTemplateErrors",
      "getAllCompanyTemplatesData",
      "getTemplateDataTempVariable",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    // ...mapGetters("standardFields", [
    //   "getFList",
    //   "getField",
    //   "getGeneralErrorMessage",
    // ]),

    textFieldPreviewClass() {
      return (inputType) => {
        const styles = ["common-field-preview"];
        if (
          inputType === "SINGLE_LINE_TEXT" ||
          inputType === "PASSWORD" ||
          inputType === "EMAIL" ||
          inputType === "MASKED" ||
          inputType === "HYPER_LINK"
        ) {
          styles.push("single-line-text-field-preview");
        } else if (inputType === "MULTI_LINE_TEXT") {
          styles.push("multi-line-text-field-preview");
        } else if (
          inputType === "NUMBER" ||
          inputType == "PHONE" ||
          inputType == "ZIP_CODE" ||
          inputType == "FAX"
        ) {
          styles.push("number-field-preview");
        } else if (inputType === "DATE" || inputType === "DATE_TIME") {
          styles.push("date-field-preview");
        }
        // else if (inputType === "DATE_TIME") {
        //   styles.push("date_time-field-preview");
        // }

        return styles;
      };
    },

    getTemplateSlug() {
      return this.name.toLowerCase().split(" ").join("-");
    },
    getStylediv() {
      if (
        this.$route.name == "ViewTemplate" ||
        this.$route.name == "ViewMenuTemplate"
      ) {
        return `height: 90vh;` + this.getElementStyle();
      } else {
        return `height: ${this.formHeight}px;` + this.getElementStyle();
      }
    },
    getFieldsData() {
      this.fieldsData.forEach((f) => {
        if (
          f.input_type == "ACTION_BUTTON" &&
          f.action_button_type === "TEMPLATE"
        ) {
          this.pos.x = f.x;
          this.pos.y = f.y;
          this.pos.height = f.height;
        }
      });
      this.fieldsData.forEach((f) => {
        if (
          f.input_type != "ACTION_BUTTON" &&
          (this.pos.x > 0 || this.pos.y > 0 || this.pos.height > 0)
        ) {
          if (this.pos.y < f.y) {
            if (this.pos.y + this.pos.height + 150 < f.y) {
              return;
            } else {
              f.y = this.pos.y + this.pos.height + 200;
            }
          }
        }
      });
      return this.fieldsData;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });

      return obj;
    },
  },
  mixins: [
    PermissionsHelper,
    NavigationHelper,
    TemplateBuilderHelper,
    dataTableStylesHelper,
  ],
  async mounted() {
    await this.getAllFieldList();
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    if (this.$route.params.template_id || this.templateIdViewId) {
      const isDefaultTemplate = false;
      this.selectedTemplateId = this.$route.params.template_id
        ? this.$route.params.template_id
        : this.templateIdViewId;
      await this.setTemplateInfo(this.selectedTemplateId, isDefaultTemplate);
    }
    if (
      this.$route?.name == "ViewMenuTemplate" ||
      this.$route?.name == "AppUserViewMenuTemplate"
    ) {
      this.isMenuTemplate = true;
    }
    this.fillFormData();
    // this.$store.commit("companyTemplates/setTemplateDataTempVariable", null, {
    //   root: true,
    // });
    this.$store.commit(
      "companyTemplates/setTemplateErrors",
      {},
      {
        root: true,
      }
    );
    [this.allEntitiesData, this.allFormTemplates] = await Promise.all([
      this.fetchAllEntities(),
      this.fetchAllTemplates(),
    ]);
  },
  watch: {
    "$route.params.template_id"() {
      if (this.isMenuTemplate) {
        this.selectedTemplateId = this.$route.params.template_id;
        this.setTemplateInfo(this.selectedTemplateId, false);
      }
    },
  },

  methods: {
    saveDefaultValues() {
      this.$emit(
        "saveFormbuilderDefaultValues",
        this.form,
        this.templateIdViewId,
        this.isEntityStep
      );
    },
    closeGalleryModal() {
      this.showGallery = false;
    },
    actionButtonCallBacks(action) {
      if (action == "REFRESH_PARENT") {
        this.refreshEntityFields = false;
        setTimeout(() => {
          this.refreshEntityFields = true;
        });
      }
    },
    filterAISettingsFields(fieldsData) {
      let aiSettingsFields = [];
      fieldsData.forEach((field) => {
        if (field.isAIEnabled) {
          aiSettingsFields.push(field);
        }
      });
      return aiSettingsFields;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? `background: ${this.templateStyles.background}`
          : "";
      }
      if (this.interactiveQuestionsMode) {
        borderStyle += borderStyle + "overflow: scroll;";
      }
      return borderStyle;
    },
    fillFormData() {
      (Object.keys(this.formData || {}) || []).map((dtLabel) => {
        let field = this.fieldsData.find((fd) => fd.label == dtLabel);
        if (field != undefined) {
          this.$set(this.form, field.key, this.formData[dtLabel]);
        }
      });
    },
    getFieldLabelFromRules(field) {
      if (this.labelsData && this.labelsData[field.key]) {
        return this.labelsData[field.key];
      }
      return field.label;
    },
    updateTopForBelowElements() {
      this.fieldsData.forEach((e, index) => {
        if (e.listen_height) {
          this.$set(this.fieldsData[index], "style", this.getStyle(e, index));
        }
      });

      // this.refresh = true;
      // setTimeout(() => {
      //   this.refresh = false;
      // }, 100);
    },
    getExistedStyle(field) {
      return field.style || undefined;
    },
    checkIsDisabled(field) {
      if (
        field.inputType == "CHECKBOX" &&
        field.is_check_box_group &&
        field.group_key
      ) {
        return this.disabledFields.find((e) => e == field.group_key)
          ? true
          : false;
      }
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      if (
        field.inputType == "CHECKBOX" &&
        field.is_check_box_group &&
        field.group_key
      ) {
        return this.hideFields.find((e) => e == field.group_key) ? true : false;
      }
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },

    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    async setDataToEntityVariables(data, parent, label, changed) {
      // let entityId = data?._id ? data._id : null;
      let form = JSON.parse(JSON.stringify(this.form));
      if (label) {
        this.$set(form, parent.key + "/name", label);
      }
      this.getFieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          (field.inputType == "ENTITY" ||
            field.input_type == "ENTITY_VARIABLE") &&
          (field.field_assignable == "read_only" || !form[field.key] || changed)
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                let existedIds = (
                  form && form[field.key] && form[field.key].length
                    ? form[field.key]
                    : []
                )
                  .filter((e) => e.parentDataId)
                  .flatMap((dt) => dt.parentDataId);
                (data || []).forEach((e) => {
                  if (
                    e?.entityData?.[templateId]?.[key] &&
                    existedIds.indexOf(e._id) == -1
                  ) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach((dt) => {
                          dataValues.push({
                            ...dt,
                            ...{
                              parentDataId: e._id,
                              parentDataName: e.parentDataName,
                            },
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (existedIds.length) {
                  dataValues = [...dataValues, ...(form[field.key] || [])];
                }
                if (field.inputType == "DATA_TABLE") {
                  this.$set(form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(form, field.key, min);
                  } else {
                    this.$set(form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data &&
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];

                if (
                  field.inputType == "ENTITY" ||
                  field.inputType == "INTEGRATION"
                ) {
                  valueName = data.entityData[templateId][key + "/name"];
                  if (typeof value == "object" && value.length) {
                    this.$set(form, field.key + "/default", value);
                  } else {
                    this.$set(form, field.key + "/default", [value]);
                  }
                }
              }

              if (form[field.key]) {
                form[field.key] = value;
                if (valueName) {
                  form[field.key + "/name"] = valueName;
                }
              } else {
                this.$set(form, field.key, value);
                if (valueName) {
                  this.$set(form, field.key + "/name", valueName);
                }
              }
            }
          }
        }
      });
      Object.assign(this.form, form);
      bus.$emit("out-side-entity-update", data, parent, label, changed);
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }
      // if (entityId) {
      //   this.form.parent_data[entityId] = data;
      // } else {
      //   this.form.parent_data = {};
      // }
      this.rulesEventEmitter();
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    async unsetEntityVariables(parent) {
      this.getFieldsData.forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.form, field.key, "");
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }

      // if (entityId) {
      //   this.form.parent_data[entityId] = [];
      // }
      this.rulesEventEmitter();
    },
    getDefaultSizeRangesByFieldType(inputType) {
      if (
        inputType === "SINGLE_LINE_TEXT" ||
        inputType === "PASSWORD" ||
        inputType === "EMAIL" ||
        inputType === "MASKED" ||
        inputType === "HYPER_LINK"
      ) {
        inputType = "SINGLE_LINE_TEXT";
      } else if (inputType === "MULTI_LINE_TEXT" || inputType === "RICH_TEXT") {
        inputType = "SINGLE_LINE_TEXT";
      } else if (
        inputType === "CURRENCY" ||
        inputType === "NUMBER" ||
        inputType === "PHONE" ||
        inputType === "ZIP_CODE" ||
        inputType === "FAX"
      ) {
        inputType = "NUMBER";
      } else if (
        inputType === "DATE" ||
        inputType === "TIME" ||
        inputType === "DATE_TIME"
      ) {
        inputType = "DATE";
      }
      // else if(
      //   inputType === "DATE_TIME"
      // ){
      //   inputType = "DATE_TIME";
      // }
      // else if(
      //   inputType === "TIME"
      // ){
      //   inputType = "TIME";
      // }

      let data = this.fieldActionsObject[inputType];
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },
    next() {
      bus.$emit("next-step", this.form);
    },

    async setTemplateInfo(templateId) {
      this.loading = true;
      this.loadingText = "Fetching Template...";
      try {
        const getSingleCompanyTemplate = await fetchSingleCompanyTemplate(
          templateId
        );
        // await this.$store.dispatch(
        //   "companyTemplates/fetchSingleCompanyTemplate",
        //   templateId
        // );
        await this.setTemplateElements(getSingleCompanyTemplate);
        if (getSingleCompanyTemplate?.sections?.[0]?.checkBoxGroup) {
          this.singleCompanyTemplateCheckBoxArray =
            await getSingleCompanyTemplate.sections[0].checkBoxGroup;
        }
        let newForm = this.mapDefaultValues(
          this.fieldsData,
          JSON.parse(JSON.stringify(this.form)),
          false
        );
        Object.keys(newForm).forEach((key) => {
          this.$set(this.form, key, newForm[key]);
        });
        if (this.isFromFormbuilder && this.defaultValuesForm) {
          Object.keys(this.defaultValuesForm).forEach((key) => {
            this.$set(this.form, key, this.defaultValuesForm[key]);
          });
        }
      } catch (err) {
        console.log("c setTemplateInfo", err);
      }

      this.loading = false;
    },

    calculateRelativeLeft(x) {
      if (this.fromEntityViews) {
        return (800 / 1200) * x;
      }
      return x;
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
      //   );
      // }
      // return x;
    },

    calculateRelativeTop(y) {
      return y;
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
      //   );
      // }
      // return y;
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "RADIO":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },
    calculateRelativeWidth(width) {
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
      //   );
      // }
      if (this.fromEntityViews) {
        return (800 / 1200) * width;
      }
      return width;
    },

    async backToTemplates() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({ path: "/template", query });
    },
    async backToEditTemplate() {
      if (!this.checkPermissionByPermissionName("editFormTemplate")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for edit template. Please contact owner! ",
        });
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          path: "/template/" + this.$route.params.template_id + "/edit",
          query,
        });
      }
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          // ...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        // if (width > field.max_width) {
        //   field.width = field.max_width;
        // } else if (width < field.min_width) {
        //   field.width = field.min_width;
        // } else {
        field.width = width;
        // }
        // if (height > field.max_height) {
        //   field.height = field.max_height;
        // } else if (height < field.min_height) {
        //   field.height = field.min_height;
        // } else {
        field.height = height;
        // }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        return field;
      });
    },
    addFieldsIncrementally(elements) {
      let fieldTypes = {
        INDEPENDENT: [],
        READONLY: [],
        DEPENDENT: [],
      };
      elements.forEach((field) => {
        if (field.input_type == "ENTITY") {
          fieldTypes.INDEPENDENT.push(field);
        } else if (
          field.input_type == "FORMULA" ||
          field.input_type == "AGGREGATE_FUNCTION" ||
          field.field_assignable == "read_only"
        ) {
          fieldTypes.READONLY.push(field);
        } else {
          fieldTypes.DEPENDENT.push(field);
        }
      });
      const batchSize = 30;
      let currentIndex = 0;

      const addBatch = () => {
        const batch = elements.slice(currentIndex, currentIndex + batchSize);
        this.fieldsData.push(...batch);
        currentIndex += batchSize;

        if (currentIndex < elements.length) {
          setTimeout(addBatch, 5000);
        }
      };
      addBatch();
    },
    async setTemplateElements(templateData) {
      this.templateIconPath = templateData.icon;
      this.name = templateData.name;
      this.description = templateData.description;
      this.type = templateData.type;
      this.slug = templateData.slug;
      this.interactiveQuestionsMode = templateData.use_interactive_questionare;
      this.interactiveQuestionsModeType =
        templateData.interactive_questionare_type;
      this.storedFormBuilderWidth = templateData.page_base_width;
      this.templateStyles = templateData.styles;
      //setting template company id
      this.company_id = templateData.company;
      let defaultSection = templateData.sections.find(
        (x) => x.type == "DEFAULT"
      ); // Todo need to change for multiple sections
      // this.fieldsData = defaultSection.fields;

      // we are sorting all fields depends on x,y cordinates to flow in the form
      defaultSection.fields = defaultSection.fields.sort((a, b) => {
        if (a.y - b.y < 10) {
          return a.x - b.x;
        }
        return a.y - b.y;
      });
      await this.fetchOtherTemplates(defaultSection.fields);
      await this.fillFormulaDefaultValues(defaultSection.fields);
      let fieldsData = this.setSizeAndPositionForElements(
        defaultSection.fields
      );
      if (fieldsData.length > 100) {
        // this.addFieldsIncrementally(fieldsData);
        // this.fieldsData = fieldsData.splice(0, 10);
        this.fieldsData = [...fieldsData];
      } else {
        this.fieldsData = [...fieldsData];
      }
      this.fieldsData = this.fieldsData.sort((a, b) => a.y - b.y);
      defaultSection.fields = defaultSection.fields.sort((a, b) => {
        return a.y - b.y;
      });
      this.templateRules = templateData.rules ? [...templateData.rules] : [];
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
      let lastElement = defaultSection.fields[this.fieldsData.length - 1];

      this.formHeight = lastElement.y + lastElement.height;
      this.completedFieldsData = [];
      if (this.interactiveQuestionsMode) {
        this.showNextInteractiveQuestion(0, "push");
      }
    },
    fillFormulaDefaultValues(fields) {
      fields.forEach((field) => {
        if (field?.input_type == "FORMULA") {
          if (!this.form[field.key]) {
            this.$set(this.form, field.key, 0);
          }
        }
      });
    },
    moveToNextQuestion() {
      this.showNextInteractiveQuestion(
        this.lastInteactiveQuestionIndex + 1,
        "push"
      );
    },

    moveToPrevQuestion() {
      this.showNextInteractiveQuestion(
        this.lastInteactiveQuestionIndex,
        "pull"
      );
    },
    showNextInteractiveQuestion(index, type) {
      this.interactiveQuestionsLoading = true;
      setTimeout(() => {
        this.interactiveQuestionsLoading = false;
        if (type == "push") {
          this.completedFieldsData.push(this.fieldsData[index]);
        } else {
          this.completedFieldsData.splice(index, 1);
        }

        this.lastInteactiveQuestionIndex = this.completedFieldsData.length - 1;
      }, 3000);
    },
    doneQuestionare() {
      console.log("dONE");
    },
    async getAllFieldList() {
      try {
        // let params = "form=true&content=true"
        // await this.$store.dispatch("standardFields/getFieldLists", params);

        if (fieldsData) {
          this.fieldActions = [
            ...fieldsData.allFormFields,
            ...fieldsData.allContentFields,
            ...fieldsData.allAdvancedFields,
          ];
        }

        // if(this.getFList){
        //   let dFieldList = this.getFList;
        //   this.fieldActions = [...dFieldList.allFormFields, ...dFieldList.allContentFields];
        // }
      } catch (error) {
        console.log(" getAllFieldList", error);
      }
    },

    updateTableFieldData(details) {
      this.$set(this.form, details.key, details.data);
    },
    async setDataToIntegrationVariables(
      selectedRecord,
      integrationField,
      allApplications
    ) {
      try {
        let integrationVaribleFields = this.fieldsData.filter(
          (field) =>
            field.inputType == "INTEGRATION_VARIABLE" &&
            field.integration_settings.selectedIntegrationField ==
              integrationField.key
        );
        if (integrationVaribleFields.length) {
          if (this.form[integrationField.key]) {
            this.allFilteredIntegrationVaribleFields = integrationVaribleFields;
            let applicationSelected =
              integrationField.integration_settings.selectedApplication;
            let application = allApplications.find(
              (selectedApp) => selectedApp._id === applicationSelected
            );
            let events = application.events;
            let event = integrationField.integration_settings.selectedEvent;
            let eventDetails = events.find(
              (selectedEvent) => selectedEvent._id === event
            );
            if (eventDetails.settings.eachRecordByEvent) {
              let variablesData = await this.getVariblesDataByIntegration(
                integrationField,
                eventDetails,
                events,
                application
              );
              this.selectedIntegrationRecord = variablesData;
            } else {
              this.selectedIntegrationRecord = selectedRecord;
            }
            this.integrationData = this.form[integrationField.key];
          }
        }
        let dependencyFields = this.fieldsData.filter(
          (field) =>
            field.integration_settings &&
            field.integration_settings.templateDependencies &&
            field.integration_settings.templateDependencies.includes(
              integrationField.key
            )
        );
        if (dependencyFields.length) {
          const depFieldKeys = dependencyFields.map((depField) => depField.key);
          this.fieldsData.forEach((field) => {
            if (
              field.inputType === "INTEGRATION_VARIABLE" &&
              depFieldKeys.includes(
                field.integration_settings.selectedIntegrationField
              )
            ) {
              this.$set(this.form, field.key, "");
            }
          });
        }
      } catch (err) {
        console.log("Err", err);
      }
    },
    setDataToIntegrationField(formValue, triggerField) {
      try {
        this.dependentFields = [];
        this.fieldsData.forEach((field) => {
          if (field.integration_settings) {
            let dependencies = field.integration_settings.templateDependencies;
            if (
              field.integration_settings.templateDependencies.includes(
                triggerField.key
              )
            ) {
              let allDependecies = dependencies.every((dep) => this.form[dep]);
              if (allDependecies) {
                this.dependentFields.push(field.key);
                this.integrationFieldValue = formValue;
              }
            }
          }
        });
      } catch (err) {
        console.log("errrr", err);
      }
    },
    async getVariblesDataByIntegration(
      integrationField,
      eventDetails,
      events,
      application
    ) {
      try {
        let connection = "";
        let asset;
        const variableEvent = events.find(
          (event) => event.asset === eventDetails.settings.eachRecordByEvent
        );
        asset = variableEvent.asset;
        let requiredData = {};
        const requiredKeys = variableEvent.settings.requiredFields.map(
          (field) => field.key
        );
        const requiredEvents = events.filter((event) =>
          requiredKeys.includes(event.asset)
        );
        const addUniqueValue = (requiredData, assetName, value) => {
          if (!requiredData[assetName]) {
            requiredData[assetName] = value;
          }
        };
        requiredEvents.forEach((event) => {
          this.fieldsData.forEach((field) => {
            if (event._id === field.integration_settings.selectedEvent) {
              const value = this.form[field.key];
              if (value !== undefined && value !== null && value !== "") {
                const assetName = event.asset;
                addUniqueValue(requiredData, assetName, value);
              }
            }
          });
        });
        Object.keys(requiredData).forEach((field) => {
          if (Array.isArray(requiredData[field])) {
            const uniqueValues = [...new Set(requiredData[field])];
            if (uniqueValues.length === 1) {
              requiredData[field] = uniqueValues[0];
            } else {
              requiredData[field] = uniqueValues;
            }
          } else {
            requiredData[field] = String(requiredData[field]);
          }
        });
        if (integrationField.integration_settings.selectedIntegration) {
          connection =
            integrationField.integration_settings.selectedIntegration;
        } else {
          const templateDependencies =
            integrationField.integration_settings.templateDependencies || [];
          templateDependencies.forEach((dep) => {
            const fieldData = this.fieldsData.find(
              (field) => field.key === dep
            );
            if (
              fieldData &&
              fieldData.integration_settings.selectedIntegration
            ) {
              connection = fieldData.integration_settings.selectedIntegration;
            }
          });
        }
        const requestBody = {
          selectedApplication: application.key,
          selectedEvent: asset,
          selectedIntegration: connection,
          requiredFields: requiredData,
        };
        let variablesData = await this.fetchEventData(requestBody);
        return variablesData.data;
      } catch (err) {
        if (err.response) {
          throw new Error(
            `API Error: ${err.response.data.message || "Unknown error"}`
          );
        } else if (err.request) {
          throw new Error("No response received from the API.");
        } else {
          throw new Error(`Error: ${err.message}`);
        }
      }
    },
    async fetchEventData(requestBody) {
      try {
        const response = await postAPICall(
          "POST",
          `integrations/service/get-event-data`,
          requestBody
        );
        const data = response.data;
        return data;
      } catch (error) {
        console.log("errror", error);
      }
    },
    handleFormChange() {
      console.log("");
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #285ed3;
  background: #285ed3;
  color: #ffffff;
  &:hover {
    color: #285ed3 !important;
    background: transparent;
    border: 1px solid #285ed3;
  }
}
.edit-btn {
  border-radius: 2px;
  padding: 11px 15px;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c;
  &:hover {
    color: white;
    background: #f68c8c;
    border: 1px solid #f68c8c;
  }
  .back-btn {
    padding: 11px 15px;
  }
}
.main-view-template {
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  max-width: 1270px;
  border-bottom: 2px solid #f5f5f5 !important;
  border-right: 2px ridge #f5f5f5 !important;
  border-left: 2px solid #f5f5f5 !important;
  border-top: 2px solid #f5f5f5 !important;
}
.paragraphScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.preview-template-view {
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    // margin-top: -10px !important;
    padding: 10px 10px;
    .el-row {
      border-bottom: 1.2px solid #cbd6e2;
    }
    h1 {
      font-size: 2.1em;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    padding-bottom: 10px;
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin-top: 0.5em;
      &.el-button--danger {
        color: #ffffff;
      }
    }
  }
  .footer-action-block {
    .el-button {
      display: block;
      margin: 2em auto 0;
      padding: 0.75em 2em;
      width: 180px;
      font-size: 1.125em;
    }
    margin-top: 1em;
  }
  .form-builder {
    @media (max-width: 991.98px) {
      width: 100%;
    }
    width: 1270px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.05);
    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 90vh;
      overflow-y: auto;
      scrollbar-width: thin;
      .each-element img {
        max-width: 100%;
      }
      ::v-deep {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.preview-template-view {
  .form-builder {
    .form-fields-holder {
      @media (max-width: 991.98px) {
        display: flex;
        flex-direction: column;
        & > div {
          position: relative !important;
          left: 20px !important;
          top: 0px !important;
          display: grid;
          // margin-top: 30px;
          margin-bottom: 10px;
          width: calc(100% - 40px) !important;
          .esignature-execute {
            left: 0px !important;
            position: relative !important;
          }
        }
      }
      & > div {
        margin-top: 20px;
        & > .form-group {
          height: 100%;
          & > div {
            &:first-child {
              height: 21px;
            }
            &:nth-child(2) {
              height: 100%;
              .el-input {
                // height: 100% !important;
                input {
                  // height: calc(100% - 21px);
                  // height: calc(100% - 0px);
                  // min-height: 30px;
                  height: 100%;
                }
              }
            }
          }

          & > .is-align-middle {
            & > div {
              height: 100%;
              .el-textarea {
                height: 100% !important;
                textarea {
                  height: calc(100% + 21px);
                  min-height: 0px !important;
                }
              }
              .el-select {
                // height: 100% !important;
                .el-input {
                  height: 100%;
                  input {
                    height: 100%;
                    // min-height: 0px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.dashboard-view {
  background: #f8f9fa;
  min-height: 100vh;
  // min-height: calc(100vh - 68px);
  .canvas {
    .main-dashboard-view {
      margin-top: 50px;
      // padding-top: 30px;
      // padding-left: 100px;
      // padding-right: 30px;
    }
  }
  .offCanvas {
    .main-dashboard-view {
      margin-top: 41px;
      // padding-top: 30px;
      // padding-left: 100px;
      // padding-right: 30px;
    }
  }
}
.side-navigation-menu {
  &.is-open {
    min-height: 0px !important;
    max-height: calc(100vh - 68px);
  }
}
.date-style {
  width: 150px;
  height: 38px;
}
.default-save {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
