<template>

  <div :style="getElementStyle">

    <el-row align="middle">

      <el-col :span="(showLabel && !isDefalutPos)?8:((data.description)?20:24)">
        <span style="display: flex;">
        <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label for="text">{{data.label}}</label>
        <span
          v-if="data.validations.required"
          style="color:red;font-weight:bold;"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
      </span>
      </el-col>
      <el-col :span="(showLabel && isDefalutPosq)?4:0">
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="(showLabel && isDefalutPosq)?24:12">

        <div
          class="icon-block"
          :style="getStyle"
        >
          <div class="icon">
            <span v-if="this.data.video_thumbnail_url">
              <img
                :height="this.data.height - 30"
                :width="this.data.width"
                :src="logoUploadUrl+this.data.video_thumbnail_url"
                alt="thumbline"
              />
            </span>
            <span v-else>
              <img
                alt="Avatar"
                src="../../../../src/assets/img/thumbnail.jpg"
                :height="this.data.height - 30"
                :width="this.data.width"
                class="inline-block"
              >
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--<div>
      {{data.label}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>
      <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
    </div>
    <div class="icon-block" :style="getStyle">
      <div class="icon">
        <img
          :height="this.data.height - 30"
          :width="this.data.width"
          :src="this.data.video_thumbnail_url"
          alt="icon"
        />
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "VideoView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField","isIconRequired"],
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    getStyle() {
      return `height:${this.data.height - 30}px`;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      //   if(this.data.styles.labelStyle == 'right'){
      //     this.isDefalutPos = false;
      //   }
    }
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler() {
        //console.log(val, oldVal);
      }
    }
  },
  data() {
    return {
      validations: [],
      isDefalutPos: true,
      showLabel: true
    };
  }
};
</script>

<style lang="scss">
</style>