<template>
  <div :style="getElementStyle">
    <div v-if="!isDefalutPos" style="display: flex; align-items: center;">
        <div style="white-space: nowrap;">
          <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
          <label for="text"> {{ data.label }}</label>
            <span
              v-if="data.validations.required"
              style="color: red; font-weight: bold"
            >
              *
            </span>
            <span style="margin-right: 5px;">
              <span v-if="data.description">
                <i
                  class="el-icon-info"
                  :title="data.description"
                ></i>
              </span>
            </span>
        </div>
        <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24" style="display: contents;">
        <template v-if="data.input_type == 'DATE'">
          <el-date-picker
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            type="date"
            :placeholder="data.placeholder"
          ></el-date-picker>
        </template>
        <template v-if="data.inputType == 'TIME'">
          <el-time-picker
            arrow-control
            :style="getStyle"
            prefix-icon="el-icon-time"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            type="date"
            :placeholder="data.placeholder"
          ></el-time-picker>
        </template>
        <template v-if="data.inputType == 'DATE_TIME'">
          <el-date-picker
            type="datetime"
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            :placeholder="data.placeholder"
          ></el-date-picker>
        </template>
        <template v-if="data.inputType == 'DATE_TIME_RANGE'">
          <el-date-picker
            type="datetimerange"
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            :placeholder="data.placeholder"
            :picker-options="pickerOptions"
            range-separator="To"
            :start-placeholder="data.placeholder || 'Start date'"
            :end-placeholder="data.placeholder1 || 'End date'"
            popper-class="my-popover"
            :format="'MM-dd-yyyy HH:mm:ss'"
          />
        </template>
      </el-col>
    </div>

    <el-row align="middle" v-else>
      <el-col v-if="!isInDataTable" :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24" class="bottom-space">
        <span style="display: flex;">
        <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label for="text"> {{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
      </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <!-- <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <span v-if="data.description">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
      </el-col> -->
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <template v-if="data.input_type == 'DATE'">
          <el-date-picker
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            type="date"
            :placeholder="data.placeholder"
          ></el-date-picker>
        </template>
        <template v-if="data.inputType == 'TIME'">
          <el-time-picker
            arrow-control
            :style="getStyle"
            prefix-icon="el-icon-time"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            type="date"
            :placeholder="data.placeholder"
          ></el-time-picker>
        </template>
        <template v-if="data.inputType == 'DATE_TIME'">
          <el-date-picker
            type="datetime"
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            :placeholder="data.placeholder"
          ></el-date-picker>
        </template>
        <template v-if="data.inputType == 'DATE_TIME_RANGE'">
          <el-date-picker
            type="datetimerange"
            :style="getStyle"
            prefix-icon="el-icon-date"
            v-model="selectedValue"
            :height="data.height"
            :width="data.width"
            :placeholder="data.placeholder"
            :picker-options="pickerOptions"
            range-separator="To"
            :start-placeholder="data.placeholder || 'Start date'"
            :end-placeholder="data.placeholder1 || 'End date'"
            popper-class="my-popover"
            :format="'MM-dd-yyyy HH:mm:ss'"
          />
        </template>
      </el-col>
    </el-row>
    <!--<div>
      {{data.label}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>
      <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
    </div>
      <template v-if="data.input_type=='DATE'">
        <el-date-picker
          :style="getStyle"
          prefix-icon="el-icon-date"
          v-model="selectedValue"
          :height="data.height"
          :width="data.width"
          type="date"
          :placeholder="data.placeholder"
        ></el-date-picker>
      </template>

      <template v-if="data.input_type=='TIME'">
        <el-time-picker
          arrow-control
          :style="getStyle"
          prefix-icon="el-icon-time"
          v-model="selectedValue"
          :height="data.height"
          :width="data.width"
          type="date"
          :placeholder="data.placeholder"
        ></el-time-picker>
      </template>
      <template v-if="data.input_type=='DATE_TIME'">
        <el-date-picker
          type="datetime"
          :style="getStyle"
          prefix-icon="el-icon-date"
          v-model="selectedValue"
          :height="data.height"
          :width="data.width"
          :placeholder="data.placeholder"
        ></el-date-picker>
      </template>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DateView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField","isInDataTable","isIconRequired"],

  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles && this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px;
       width: ${this.data.width}px !important;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";
        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    //gayatri starts
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }

      // return isDefalutPosq;
    }
    //end
  },
  data() {
    return {
      validations: [],
      // isDefalutPosq: true,
      showLabel: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "24 Hours"
          },
          {
            text: "Weekly"
          },
          {
            text: "Bi-weekly"
          },
          {
            text: "Monthly"
          },
          {
            text: "Quarterly"
          },
          {
            text: "Half-yearly"
          },
          {
            text: "Yearly"
          }
        ]
      }
    };
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "DATE") {
          this.isList = true;
          this.data.selectedValue = this.getGlobalVariableById.value;
          this.data.is_list = true;
        } else {
          this.data.selectedValue = this.getGlobalVariableById.value;
          this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss" scopped>
.el-date-editor.el-input {
  width: 100% !important;
}
.eb-time {
  height: 74px !important;
}
</style>
