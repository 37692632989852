var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(!_vm.checkEmailTemplateExists())?_c('div',[_vm._v("Please Select Template")]):(!_vm.showFields.emailView)?_c('div',[_c('div',{staticClass:"size-selector"},[_c('label',{attrs:{"for":"cardHeight"}},[_vm._v("Card Height:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cardHeight),expression:"cardHeight"}],attrs:{"type":"range","id":"cardHeight","min":"100","max":"700"},domProps:{"value":(_vm.cardHeight)},on:{"__r":function($event){_vm.cardHeight=$event.target.value}}}),_c('span',[_vm._v(_vm._s(_vm.cardHeight)+"px")])]),_c('div',{staticClass:"card-container"},_vm._l((_vm.getParsedData),function(dt,index){return _c('div',{key:index,staticClass:"card",style:({ flex: '0 1 ' + _vm.cardWidth + '%' })},[_c('el-scrollbar',[(_vm.actionButtonFieldKeys && _vm.actionButtonFieldKeys.length)?_c('div',_vm._l((_vm.actionButtonFieldKeys),function(actionKey){return _c('div',{key:actionKey,staticClass:"button-container"},[(
                  dt.entityData &&
                  actionKey.split('#') &&
                  actionKey.split('#').length
                )?_c('ActionButtonExecute',{attrs:{"data":_vm.getField(actionKey, dt._id),"form":dt.entityData[actionKey.split('#')[0]],"is-view":false,"isView":false,"workflowDataId":dt._id,"label":_vm.getField(actionKey, dt._id).label,"entity_type":_vm.currentEntity.entity_type,"entityDataId":dt._id,"entityId":_vm.currentEntity._id,"entityData":dt,"checkIsDisabled":false,"selectedTableRows":[],"fieldsData":_vm.getCurrentTemplateFields(actionKey.split('#')[0]),"selfTemplateId":actionKey.split('#')[0],"templateName":"templateName"},on:{"actionExecuted":_vm.refreshCurrentPage}}):_vm._e()],1)}),0):_vm._e(),(dt.ejsTemplate)?_c('div',{staticClass:"mt-1",style:('overflow: scroll;scrollbar-width: thin;max-height:' +
              _vm.cardHeight +
              'px;overflow-x: hidden;'),domProps:{"innerHTML":_vm._s(dt.ejsTemplate)}}):_c('div',{style:('overflow: scroll;scrollbar-width: thin;max-height:' +
              _vm.cardHeight +
              'px;overflow-x: hidden;')},[_vm._v(" No data found ")])])],1)}),0)]):_c('div',[(_vm.parsedData)?_c('entity-email-view',{attrs:{"showFields":_vm.showFields,"selectedRowsIds":_vm.selectedRowsIds,"isApplicationUserSideValid":_vm.isApplicationUserSide,"currentMenu":_vm.currentMenu,"getSelfEntityFilters":_vm.getSelfEntityFilters,"parsedData":_vm.getParsedData,"allFieldsObj":_vm.allFieldsObj,"emailTemplates":_vm.allEmailTemplates,"keyLabelData":_vm.keyLabel,"currentEntity":_vm.currentEntity,"quickUpdateRowData":_vm.quickUpdateRowData,"actionButtonFieldKeys":_vm.actionButtonFieldKeys,"isRelationalDataFetched":_vm.isRelationalDataFetched,"search_string":_vm.search_string},on:{"SaveData":_vm.saveOrder,"updateRow":_vm.updateRow,"getneccesaryInfo":_vm.getNecessaryInfo,"quickUpdateData":_vm.handleQuickUpdateData,"refreshPage":_vm.refreshCurrentPage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }