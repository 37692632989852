<template>
  <div :style="getElementStyle">
    <div v-if="data.properties.filed_content != 'Hide'">
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
      <label v-if="!isInDataTable" :style="getLabelStyles">{{ label || data.label }}</label>
      <el-select
        v-if="!isView && data.properties.filed_content != 'Hide'"
        v-model="form[data.key]"
        @change="applyFormRules"
        :style="getStyle"
        placeholder="Select Currency"
      >
        <el-option
          v-for="currency in CurrencyTypes"
          :key="currency.value"
          :label="`${currency.name} (${currency.value})`"
          :value="currency.value"
        />
      </el-select>
      <p
        v-if="isView && data.properties.filed_content != 'Hide'"
        :style="getStyle"
      >
        <template v-if="form[data.key]">{{ form[data.key] }}</template>
        <template v-else>-</template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencyTypesExecute",
  props: [
    "data",
    "label",
    "form",
    "hasLabel",
    "isActive",
    "colorFields",
    "isView",
    "fromEntityViews",
    "isInDataTable",
    "isIconRequired"
  ],
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px; width: 100%`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "";
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type || "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color || "";
        let bold =
          this.data.styles.font && this.data.styles.font.style === 1
            ? "bold"
            : "";
        let italic =
          this.data.styles.font && this.data.styles.font.style === 2
            ? "italic"
            : "";

        borderStyle = `border: ${type} ${size} ${color};`;
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background};`
          : "";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color};`
          : "";
        if (bold) borderStyle += `font-weight: ${bold};`;
        if (italic) borderStyle += `font-style: ${italic};`;
      }
      return borderStyle;
    },
  },
  data() {
    return {
      CurrencyTypes: [
        { value: "USD", name: "$" },
        { value: "CAD", name: "$" },
        { value: "AUD", name: "$" },
        { value: "INR", name: "₹" },
        { value: "EUR", name: "€" },
        { value: "MXN", name: "$" },
        { value: "ZAR", name: "R" },
        { value: "MYR", name: "RM" },
        { value: "GBP", name: "£", },
      ],
    };
  },
  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
  },
};
</script>

<style lang="scss"></style>
