<template>
  <div class="modal">
    <button class="close-button" @click="$emit('close')">
      <i class="el-icon-close"></i>
    </button>
    <div class="gallery">
      <button
        class="arrow left"
        @click="prevImage"
        :disabled="currentIndex === 0"
      >
        ‹
      </button>
      <div class="image-container">
        <img :src="images[currentIndex]" class="current-image" />
        <div class="image-info">
          <span>{{ currentIndex + 1 }} / {{ images.length }}</span>
        </div>
      </div>
      <button
        class="arrow right"
        @click="nextImage"
        :disabled="currentIndex === images.length - 1"
      >
        ›
      </button>
    </div>

    <draggable
      v-model="reorderedImages"
      class="thumbnails"
      @end="onReorder"
    >
      <img
        v-for="(image, index) in reorderedImages"
        :key="index"
        :src="image"
        @click="currentIndex = index"
        class="thumbnail"
        :class="{ active: index === currentIndex }"
      />
    </draggable>

    <el-button
      v-if="isEditMode"
      style="background-color: ivory; max-width: 27px"
      type="plain"
      size="mini"
      circle
      @click="deleteImage"
    >
      <img width="12px" src="@/assets/Trash.svg" />
    </el-button>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: { draggable },
  props: ["images", "isView"],
  data() {
    return {
      currentIndex: 0,
      reorderedImages: [...this.images],
    };
  },
  computed: {
    isEditMode() {
      return (
        this.$route.path.includes("/entity/edit/") ||
        this.$route.path.includes("/fb/edit/") ||
        this.$route.path.includes("/public/fb/")
      );
    },
  },
  methods: {
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex += 1;
      }
    },
    deleteImage() {
      if (this.images.length > 0) {
        this.reorderedImages.splice(this.currentIndex, 1);
        this.currentIndex = Math.min(
          this.currentIndex,
          this.reorderedImages.length - 1
        );
        this.$emit("thumbnailUpdated", this.reorderedImages[0]);
        this.$emit("updateImages", this.reorderedImages);
        if (this.reorderedImages.length === 0) {
          this.currentIndex = -1;
          this.$emit("imagesCleared");
        }
      }
    },
    onReorder() {
      this.$emit("updateImages", this.reorderedImages);
      this.$emit("thumbnailUpdated", this.reorderedImages[0]);
    },
  },
};
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 2000;
  background: none;
  border: none;
}

.close-button:hover,
.close-button:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.gallery {
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-info {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
}

button.arrow {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

button.arrow.left {
  left: 10px;
}

button.arrow.right {
  right: 10px;
}

button.arrow:disabled {
  color: grey;
  cursor: not-allowed;
}

.current-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnails {
  display: flex;
  margin-top: 10px;
  overflow-x: auto;
  width: 100%;
  padding: 0 10px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
}

.thumbnail.active {
  border-color: white;
}
</style>
