const fontTypes = [
  {
    name: "Default",
    value: "Inter,sans-serif",
  },
  {
    name: "Open Sans Light",
    value: "Open Sans Light,sans-serif",
  },
  {
    name: "Open Sans Regular",
    value: "Open Sans,sans-serif",
  },
  {
    name: "Open Sans Medium",
    value: "Open Sans Medium,sans-serif",
  },
  {
    name: "Open Sans SemiBold",
    value: "Open Sans SemiBold,sans-serif",
  },
  {
    name: "Open Sans Bold",
    value: "Open Sans Bold,sans-serif",
  },
  {
    name: "Roboto Light",
    value: "Roboto Light,sans-serif",
  },
  {
    name: "Roboto Regular",
    value: "Roboto,sans-serif",
  },
  {
    name: "Roboto Medium",
    value: "Roboto Medium,sans-serif",
  },
  {
    name: "Roboto Bold",
    value: "Roboto Bold,sans-serif",
  },
  {
    name: "Lato Light",
    value: "Lato Light,sans-serif",
  },
  {
    name: "Lato Regular",
    value: "Lato,sans-serif",
  },
  {
    name: "Lato Bold",
    value: "Lato Bold,sans-serif",
  },
  {
    name: "Montserrat Light",
    value: "Montserrat Light,sans-serif",
  },
  {
    name: "Montserrat Regular",
    value: "Montserrat,sans-serif",
  },
  {
    name: "Montserrat Bold",
    value: "Montserrat Bold,sans-serif",
  },
  {
    name: "Noto Sans Regular",
    value: "Noto Sans,sans-serif",
  },
  {
    name: "Noto Sans Bold",
    value: "Noto Sans Bold,sans-serif",
  },
  {
    name: "Source Sans Pro Light",
    value: "Source Sans Pro Light,sans-serif",
  },
  {
    name: "Source Sans Pro Regular",
    value: "Source Sans Pro,sans-serif",
  },
  {
    name: "Source Sans Pro Bold",
    value: "Source Sans Pro Bold,sans-serif",
  },
  {
    name: "Work Sans Light",
    value: "Work Sans Light,sans-serif",
  },
  {
    name: "Work Sans Regular",
    value: "Work Sans,sans-serif",
  },
  {
    name: "Work Sans Bold",
    value: "Work Sans Bold,sans-serif",
  },
  {
    name: "IBM Plex Sans Light",
    value: "IBM Plex Sans Light,sans-serif",
  },
  {
    name: "IBM Plex Sans Regular",
    value: "IBM Plex Sans,sans-serif",
  },
  {
    name: "IBM Plex Sans Bold",
    value: "IBM Plex Sans Bold,sans-serif",
  },
  {
    name: "PT Sans Regular",
    value: "PT Sans,sans-serif",
  },
  {
    name: "PT Sans Bold",
    value: "PT Sans Bold,sans-serif",
  },
];

const fontSizes = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
];

export default {
  fontTypes,
  fontSizes,
};
