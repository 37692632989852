<template>
  <div
    :style="getElementStyle"
    v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
  >
  <div v-if="!isDefalutPos" style="display: flex; align-items: center;">
      <div style="white-space: nowrap;">
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide' && !fromRepeatable"
          :style="getLabelStyles"
          >
          <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
          {{label||data.label}}</label
        >
        <span v-if="checkIsRequired && hasLabel && !isView" style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
          >*</span
        >
        <span style="margin-right: 5px;">
          <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
        </span>
      </div>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24" style="display: contents;">
        <!-- {{ form[data.key] }} -->
        <el-select
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly() || readonly()"
          :readonly="readonly()"
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          :suffix-icon="data.properties.el_icon"
          @change="applyFormRules"
          :multiple="data.allow_multiple"
          :multiple-limit="data.max_value"
          collapse-tags
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          v-if="!isView && isList && data.properties.filed_content != 'Hide'"
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly() || readonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          :suffix-icon="data.properties.el_icon"
          :multiple="data.allow_multiple"
          :multiple-limit="data.max_value"
          collapse-tags
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
        <p
          v-if="isView && data.properties.filed_content != 'Hide'"
          :style="getStyle"
        >
          <template v-if="form[data.key]">{{ getWeekFieldValue }}</template>
          <template v-else>-</template>
        </p>
      </el-col>
  </div>

    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 18 : 22"
        v-if="!data.properties.hideLabel && !fromRepeatable"
        :style="computedStyles"
        class="bottom-space"
      >
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide' && !fromRepeatable"
          :style="getLabelStyles"
          >{{ label||data.label}}</label
        >
        <span v-if="checkIsRequired && hasLabel  && !isView" style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
          >*</span
        >
        <span>
          <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
        </span>
      </el-col>
      <el-col v-else :span="showLabel ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <span v-if="hasLabel" style="color: white; display: inline-block; height: 20px; opacity: 0;" ></span>
        <span v-if="checkIsRequired && !hasLabel" style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
          >*</span
        >
      </el-col>
      <!-- <el-col :span="data.description ? 2 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
      </el-col> -->
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24" class="execute-view">
        <!-- {{ form[data.key] }} -->
        <el-select
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly() || readonly()"
          :readonly="readonly()"
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          :suffix-icon="data.properties.el_icon"
          @change="applyFormRules"
          :multiple="data.allow_multiple"
          :multiple-limit="data.max_value"
          collapse-tags
          clearable
          :class="{ 'highlighted-border': highlight }"
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          v-if="!isView && isList && data.properties.filed_content != 'Hide'"
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly() || readonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          :suffix-icon="data.properties.el_icon"
          :multiple="data.allow_multiple"
          :multiple-limit="data.max_value"
          collapse-tags
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
        <p
          v-if="isView && data.properties.filed_content != 'Hide'"
          :style="getStyle"
        >
          <template v-if="form[data.key]">{{ getWeekFieldValue }}</template>
          <template v-else>-</template>
        </p>
      </el-col>
    </el-row>
  </div>
  <div v-else>
      <div v-if="isView && form[data.key] && typeof form[data.key] == 'object'">
        {{ form[data.key].join(",") }} </div>
     <div v-else>
       <el-select
        :style="getStyle"
        :placeholder="data.placeholder"
        v-model="form[data.key]"
        :disabled="isFromDocument ? false : checkReadonly() || readonly()"
        :readonly="readonly()"
      
        v-if="data.properties.filed_content != 'Hide'"
        :suffix-icon="data.properties.el_icon"
        @change="applyFormRules"
        :multiple="data.allow_multiple"
        :multiple-limit="data.max_value"
        collapse-tags
        clearable
      >
        <el-option
          v-for="(option, index) of options"
          :value="option.value"
          :label="option.name"
          :key="index"
        ></el-option>
      </el-select>
     </div>
    </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
export default {
  name: "templates-formComponentsExecute-SelectExecute",
  mixins: [userPermissionsHelper, TemplateBuilderhelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "checkIsDisabled",
    "checkhideFields",
    "fromRepeatable",
    "profilePage",
    "colorFields",
    "highlight",
    "isIconRequired"
  ],
  data() {
    return {
      validations: [],
      options: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" }
      ],
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false
    };
  },
  mounted() {
    this.loading = true;
    //this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (
      this.data.allow_multiple &&
      this.form[this.data.key] &&
      typeof this.form[this.data.key] == "string"
    ) {
      this.$set(this.form, this.data.key, [this.form[this.data.key]]);
    } else if (
      !this.data.allow_multiple &&
      this.form[this.data.key] &&
      typeof this.form[this.data.key] == "object" &&
      this.form[this.data.key].length
    ) {
      this.$set(this.form, this.data.key, this.form[this.data.key][0]);
    }
    this.loading = false;
  },
  computed: {
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    getStyle() {
    return `height:${this.data.height - 30}px; width: 100% !important;`; 
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      borderStyle = borderStyle + `width:${this.data.width}px !important;`;
      return borderStyle;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
     getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
    checkIsRequired() {
      if (this.data.allow_multiple) {
        if (this.data.min_value > 0) {
          return true;
        }
        return false;
      } else {
        return this.data?.validations?.required;
      }
    },
    getWeekFieldValue() {
      if (this.data && this.form && this.form[this.data.key]) {
        if (
          this.data.allow_multiple &&
          typeof this.form[this.data.key] == "object" &&
          this.form[this.data.key].length
        ) {
          let mappedArray = this.form[this.data.key].map(
            e => this.weekDayMap[e]
          );
          return mappedArray.join(", ");
        } else {
          return this.weekDayMap[this.form[this.data.key]];
        }
      }
      return "";
    }
  },
  methods: {
    defaultMethod(){
      if (
      this.data.default_value &&
      (!this.form[this.data.key] ||
        (this.data.allow_multiple && !this.form[this.data.key].length))
    ) {
      this.$set(this.form, this.data.key, this.data.default_value);
    }
    this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "WEEKDAYS") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    
    readonly() {
      if (this.data.field_assignable === "read_only" && this.data.is_global_variable) {
    return true;
  }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }     
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content == "READ ONLY"
      ) {
        return true;
      }
      return false;
    },
    // checkReadonly() {
    //   if(this.checkIsDisabled){
    //     return true
    //   }
    //   if(this.data.styles && this.data.styles.labelStyle){
    //     if(this.data.styles.labelStyle == 'hide'){
    //       this.showLabel = false;
    //     }
    //     if(this.data.styles.labelStyle == 'right'){
    //       this.isDefalutPos = false;
    //     }
    //   }
    //   //If it's an entity varaible, have to check access
    //   if(this.data.field_assignable === 'read_only' && this.data.is_entity_variable){
    //     return true
    //   }
    //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    }
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss" scoped>
.highlighted-border{
  border-color: red !important;
}
.execute-view {
  font-weight: normal;
}
</style>
