<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">API Integrations</h3>
        <el-row :gutter="12" class="integration-button-container">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="d-flex">
            <el-input
              placeholder="Search"
              size="small"
              v-model="search_string"
              clearable
              prefix-icon="el-icon-search"
              round
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchIntegrations"
                size="mini"
              ></el-button>
            </el-input>
            <el-button
              class="ml-1"
              type="info"
              size="mini"
              round
              @click="showDialog"
              >Add new API Integration</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="12">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container"
        >
          <template>
            <data-tables-server
              :total="total"
              style="width: 100%"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :pagination-props="paginationProps"
              :data="data"
              @query-change="loadData"
              @sort-change="sortChange"
            >
              <el-table-column
                label="Name"
                id="name"
                prop="name"
                width="350"
                fixed="left"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                label="Actions"
                prop="action"
                class="action"
                width="230"
              >
                <template slot-scope="scope">
                  <ul class="action-buttons hover-buttons">
                    <li>
                      <el-dropdown>
                        <el-button size="mini" class="el-dropdown-link px-2">
                          Actions
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <a @click="onEdit(scope.row)">
                            <el-dropdown-item
                              ><i class="el-icon-edit-outline"></i>
                              Edit</el-dropdown-item
                            >
                          </a>
                          <a @click="onDelete(scope.row)">
                            <el-dropdown-item
                              ><i class="el-icon-delete"></i>
                              Delete</el-dropdown-item
                            >
                          </a>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </li>
                  </ul>
                </template>
              </el-table-column>
              <el-table-column
                label="Status"
                id="status"
                prop="status"
                width="350"
                fixed="left"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    class="status-container"
                    v-if="scope.row && scope.row.status"
                  >
                    <span
                      :class="['circle', scope.row.status.toLowerCase()]"
                    ></span>

                    <span>{{
                      scope.row.status.charAt(0) +
                      scope.row.status.slice(1).toLowerCase()
                    }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="Created at"
                width="300"
                prop="created_at"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.created_at">
                    <span class="d-inline-block pr-2 text-muted">
                      {{ scope.row.created_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="Last Modified"
                width="300"
                prop="updated_at"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.updated_at">
                    <span
                      class="fs-8 d-inline-block pr-2 text-muted activestatus"
                    >
                      {{ scope.row.updated_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </template>
              </el-table-column>
            </data-tables-server>
          </template>
        </el-col>
      </el-row>
    </div>
    <dialog-component
      title="Confirm Deletion"
      :visible.sync="showDeleteDialog"
      :containerHeight="'40vh'"
      width="30%"
      class="confirm-delete-dialog"
    >
      <div class="confirm-delete-content">
        <span
          >Are you sure you want to delete the integration<br />"{{
            integrationToDelete ? integrationToDelete.name : ""
          }}"?</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDeleteDialog = false">Cancel</el-button>
        <el-button type="primary" @click="confirmDelete">Confirm</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="showIntegration"
      :title="AppIntegrations"
      @before-close="closeIntegration"
      :containerHeight="'80vh'"
      :containerWidth="'35vw'"
      :isShowFooter="true"
      class="custom-dialog"
    >
      <div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="form"
          label-position="top"
          class="custom-form"
        >
          <el-form-item
            label="Name"
            prop="name"
            class="custom-form-item"
            label-width="80px"
          >
            <el-input
              v-model="formData.name"
              class="custom-input"
              required
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
              label="App Type"
              prop="app_type"
              required
              class="custom-form-item"
            >
              <el-select v-model="formData.app_type" class="custom-input">
                <el-option label="Pre Defined" value="PRE_DEFINED" />
                <el-option label="User Defined" value="USER_DEFINED" />
              </el-select>
            </el-form-item> -->
          <template v-if="formData.app_type === 'PRE_DEFINED'">
            <el-form-item
              label="Application"
              prop="application"
              class="custom-form-item"
            >
              <el-select
                v-model="formData.application"
                placeholder="Select application"
                class="custom-input"
                @change="setSelectedEvents"
                filterable
              >
                <el-option
                  v-for="option in predefinedIntegrations"
                  :key="option.key + '_' + option._id"
                  :label="option.label"
                  :value="option.key"
                >
                  <template #default>
                    <img :src="option.logo" alt="Logo" class="option-logo" />
                    {{ option.label }}
                  </template>
                </el-option>
              </el-select>
            </el-form-item>
            <template
              v-if="
                selectedApplicationAuthDetails &&
                selectedApplicationAuthDetails.length > 0
              "
            >
              <div
                v-for="field in selectedApplicationAuthDetails"
                :key="field.key"
                class="custom-form-item"
              >
                <el-form-item :label="null" :prop="field.key">
                  <span v-if="field.isRequired" class="red-asterisk">*</span>
                  {{ field.label }}
                  <el-input
                    v-model="formData.authentication_details[field.key]"
                    :placeholder="field.placeHolder"
                    :type="field.type"
                    class="custom-input"
                  />
                </el-form-item>
              </div>
            </template>

            <!-- <el-form-item
                label="Events"
                prop="event"
                v-if="formData.application"
                class="custom-form-item"
              >
                <el-select
                  v-model="formData.event"
                  placeholder="Select Event"
                  class="custom-input"
                >
                  <el-option
                    v-for="option in selectedApplicationEvents"
                    :key="option.eventName + '_' + option._id"
                    :label="option.eventName.replaceAll('_', ' ')"
                    :value="option.eventName"
                  />
                </el-select>
              </el-form-item> -->
            <el-button
              v-if="showSuggestions"
              @click="oAuthSign"
              class="custom-button"
            >
              Sign into {{ selectedApplication.key }}
            </el-button>
            <el-button
              v-if="
                selectedApplication &&
                selectedApplicationAuthDetails &&
                selectedApplicationAuthDetails.length &&
                !showSuggestions
              "
              @click="saveAuthenticationDetails"
              class="custom-button"
              :disabled="!isFormValid || isSaving"
            >
              <span v-if="isSaving">Saving...</span>
              <span v-else> Save details</span>
            </el-button>
            <span v-if="checkForExistedConnection.length > 0">
              OR choose existed connections
            </span>
            <el-select
              v-model="formData.existed_connection_id"
              placeholder="Existed connections"
              v-if="checkForExistedConnection.length > 0"
              class="custom-inputs"
              @change="updateConnectId"
            >
              <el-option
                v-for="con in checkForExistedConnection"
                :key="con._id"
                :value="con.connection_id"
                :label="con.name"
              ></el-option>
            </el-select>
            <template
              v-if="
                selectedApplication &&
                (selectedApplication.settings.requiredFields ||
                  selectedApplication.dependencies) &&
                formData.connection_id
              "
            >
              <div v-for="dep in combinedDependencies" :key="dep.key">
                <el-select
                  v-model="formData.dependencies[dep.key]"
                  :placeholder="dep.label"
                  v-if="
                    dep.type !== 'text' &&
                    formData.connection_id &&
                    (!dep.internal_dependency_field ||
                      formData.dependencies[dep.internal_dependency_field])
                  "
                  @change="fillDependencyFields"
                  class="custom-inputs"
                >
                  <el-option
                    v-for="con in selectedApplicationDependencies[dep.key]"
                    :key="con.id"
                    :value="con.id"
                    :label="con.name"
                  ></el-option>
                </el-select>
                <el-input
                  v-if="dep.type === 'text'"
                  v-model="formData.dependencies[dep.key]"
                  :placeholder="dep.label"
                  class="custom-input"
                ></el-input>
              </div>
            </template>
            <el-button
              size="mini"
              @click="fetchData"
              v-loading="urlTestLoading"
              v-if="
                this.formData.connection_id &&
                this.formData.event &&
                this.checkDependenciesFilled
              "
              class="custom-button"
            >
              Test
            </el-button>
          </template>
          <template v-else>
            <el-form-item
              label="Authentication Type"
              prop="authentication_type"
              class="custom-form-item"
            >
              <el-input
                v-model="formData.authentication_type"
                class="custom-input"
              />
            </el-form-item>
            <el-form-item
              label="Authentication Details"
              prop="authentication_details"
              class="custom-form-item"
            >
              <el-input
                type="textarea"
                v-model="formData.authentication_details"
                class="custom-input"
              />
            </el-form-item>
            <el-form-item
              label="Response Type"
              prop="response_type"
              class="custom-form-item"
            >
              <el-select
                v-model="formData.response_type"
                placeholder="Select response type"
                class="custom-input"
              >
                <el-option
                  v-for="option in responseOptions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="URL" prop="url" class="custom-form-item">
              <div class="d-flex">
                <el-input
                  v-model="formData.url"
                  @change="resetSampleResponse"
                  required
                  class="custom-input"
                />
                <el-button
                  size="mini"
                  @click="testUrl"
                  v-if="isValidURL(formData.url)"
                  v-loading="urlTestLoading"
                  class="custom-button"
                >
                  Test URL
                </el-button>
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div v-if="testResponse">
        <h3>Preview:</h3>

        <pre class="response-preview">{{ testResponse }}</pre>
        <span v-if="testResponseLength > -1">
          {{ testResponseLength }} More items...
        </span>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="submitForm">Submit</el-button>
        <el-button @click="closeIntegration">Cancel</el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";
import app from "../../../config/app";
export default {
  name: "APiIntegrations",
  components: {},
  data() {
    return {
      responseOptions: [
        {
          label: "Value",
          value: "VALUE",
        },
        {
          label: "Array",
          value: "ARRAY",
        },
      ],
      loading: false,
      urlTestLoading: false,
      testResponseLength: -1,
      showDeleteDialog: false,
      integrationToDelete: null,
      testResponse: "",
      data: [],
      AppIntegrations: "Add API Integrations",
      formData: {
        name: "",
        url: "",
        method: "GET",
        authentication_type: "",
        response_type: "VALUE",
        app_type: "PRE_DEFINED",
        sample_response: null,
        dependencies: {},
        authentication_details: {},
        application: "",
        event: "",
        connection_id: "",
        existed_connection_id: "",
      },
      rules: {
        name: [
          { required: true, message: "Please input the name", trigger: "blur" },
          {
            min: 3,
            message: "Name must be at least 3 characters",
            trigger: "blur",
          },
        ],
        url: [
          { required: false, message: "Please input the URL", trigger: "blur" },
          { type: "url", message: "Please input a valid URL", trigger: "blur" },
        ],
        app_type: [
          {
            required: true,
            message: "Please select the app type",
            trigger: "change",
          },
        ],
        connection_id: [
          {
            required: (formData) =>
              formData.app_type === "PRE_DEFINED" &&
              this.selectedApplication?.settings?.isOauthAuthenticating ===
                false,
            message: "Please authenticate the integration",
            trigger: "change",
          },
        ],
      },
      showIntegration: false,
      search_string: "",
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      order_type: null,
      order_by: null,
      predefinedIntegrations: [],
      selectedApplicationEvents: [],
      selectedApplicationAuthDetails: [],
      selectedApplicationDependencies: {},
      showDependencies: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getActiveWorkspace", "getThirdaPartyFormData"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    paginationPropsTable() {
      return [10, 20, 50];
    },
    selectedApplication() {
      return this.predefinedIntegrations.find(
        (e) => e.key == this.formData.application
      );
    },
    checkForExistedConnection() {
      return this.data.filter(
        (e) =>
          e.app_type == "PRE_DEFINED" &&
          e.application == this.formData.application &&
          e.connection_id &&
          e._id !== this.formData._id
      );
    },
    showSuggestions() {
      return (
        this.selectedApplication &&
        this.selectedApplication.label &&
        this.selectedApplicationAuthDetails &&
        this.selectedApplicationAuthDetails.serviceProviderUrl
      );
    },
    checkDependenciesFilled() {
      return (
        !this.selectedApplication?.dependencies.length ||
        (this.selectedApplication.dependencies &&
        this.formData.dependencies &&
        this.selectedApplication.dependencies.find(
          (e) => !this.formData?.dependencies[e.key]
        )
          ? false
          : true)
      );
    },
    isFormValid() {
      if (
        !Array.isArray(this.selectedApplicationAuthDetails) ||
        this.selectedApplicationAuthDetails.length === 0
      ) {
        return false;
      }
      return this.selectedApplicationAuthDetails.every((field) => {
        return (
          this.formData.authentication_details[field.key] &&
          this.formData.authentication_details[field.key].trim() !== ""
        );
      });
    },
    combinedDependencies() {
      const requiredFields =
        this.selectedApplication?.settings?.requiredFields || [];
      const dependencies = this.selectedApplication?.dependencies || [];
      return [...requiredFields, ...dependencies];
    },
  },
  async mounted() {
    await this.fetchAllPredefinedIntegrations();
    await this.fetchIntergrations();
    await this.checkOAuthReturnScreen();
    await this.setSelectedEvents();
  },
  methods: {
    async checkOAuthReturnScreen() {
      if (
        this.$route.query?.fromOauth &&
        this.$route.query.state &&
        this.getThirdaPartyFormData &&
        this.getThirdaPartyFormData[this.$route.query.state]
      ) {
        this.formData = {
          ...this.getThirdaPartyFormData[this.$route.query.state],
        };
        this.showIntegration = true;
        await this.setSelectedEvents();
        this.fetchDependencies();
      }
    },
    fetchDependencies() {
      if (this.selectedApplication && this.formData?.connection_id) {
        const combinedFields =
          this.selectedApplication.settings?.requiredFields || [];
        combinedFields.push(...(this.selectedApplication.dependencies || []));
        combinedFields.forEach((dep) => {
          if (dep.type === "text") {
            this.$set(this.formData.dependencies, dep.key, "");
          }
          if (dep.type !== "text") {
            this.fillDependencyFields(dep);
          }
        });
      } else {
        console.error(
          "No dependencies or required fields found for this application."
        );
      }
    },

    async fillDependencyFields() {
      let individualDependencies = (
        this.selectedApplication?.settings?.requiredFields || []
      ).filter((e) => {
        const noDependencyLoaded =
          !this.selectedApplicationDependencies[e.key] ||
          !this.selectedApplicationDependencies[e.key].length;
        const dependencyCondition =
          !e.dependencyField.length ||
          e.dependencyField.every(
            (field) => this.formData.dependencies?.[field]
          );
        return noDependencyLoaded && dependencyCondition;
      });

      await Promise.all(
        individualDependencies.map(async (dep) => {
          return this.getUserAccountDetails(dep);
        })
      );
    },

    async getUserAccountDetails(dep) {
      let params = {
        appName: this.formData.application,
        connectionId: this.formData.connection_id,
        field: dep.key,
      };
      if (dep.dependencyField) {
        params["dependencyField"] =
          this.formData.dependencies?.[dep.dependencyField] || "";
      }

      const response = await postAPICall(
        "POST",
        "integrations/service/get-user-data",
        params
      );
      this.selectedApplicationDependencies[dep.key] = response.data;
    },

    updateConnectId(value) {
      if (value) {
        this.formData.connection_id = value;
        this.fetchDependencies();
      } else {
        this.formData.connection_id = "";
      }
    },
    async fetchData() {
      try {
        this.urlTestLoading = true;
        const response = await postAPICall(
          "POST",
          "integrations/service/fetch-data",
          {
            provider: this.formData.application,
            field: this.formData.event,
            connectionId: this.formData.connection_id,
            dependencies: this.formData.dependencies,
            isDateFilterIncluded: this.formData.isDateFilterIncluded,
          }
        );
        this.testResponse =
          typeof response?.data == "object" ? 0 : response.data;
        this.urlTestLoading = false;
      } catch (error) {
        console.log("t", error);
        this.testResponse = `Error: ${error?.response?.message}`;
        this.urlTestLoading = false;
      }
    },
    async oAuthSign() {
      if (!this.selectedApplicationAuthDetails) {
        console.error("OAuth details not available.");
        return;
      }
      const { clientId, serviceProviderUrl, scopes } =
        this.selectedApplicationAuthDetails;
      let key = (Math.random() + 1).toString(36).substring(7);
      let encodedScopes = encodeURIComponent(scopes).replace(/%60/g, "");
      let oauthUrl = `${serviceProviderUrl}?client_id=${clientId}&redirect_uri=${app.APP_URL}/configuration/APiIntegrations&response_type=code&scope=${encodedScopes}&state=${key}`;
      if (this.selectedApplication.key) {
        oauthUrl += "&access_type=offline";
      }
      if (
        this.selectedApplication.key !== "faceBookAdsDup" &&
        this.selectedApplication.key !== "faceBookPagesDup"
      ) {
        // oauthUrl += "&display=popup&extras={"setup":{"channel":"IG_API_ONBOARDING"}}"
        oauthUrl +=
          "&display=popup&extras=" +
          encodeURIComponent('{"setup":{"channel":"IG_API_ONBOARDING"}}');
      }

      if (
        this.selectedApplication.key !== "instagramBusinessDup" &&
        this.selectedApplication.key !== "faceBookAdsDup"
      ) {
        oauthUrl += "&prompt=consent";
      }

      this.$store.commit(
        "auth/setThirdaPartyFormData",
        {
          ...this.getThirdaPartyFormData,
          ...{
            [key]: this.formData,
          },
        },
        {
          root: true,
        }
      );
      //  window.open(oauthUrl);
      window.location.replace(oauthUrl);
    },

    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
      this.setSelectedEvents();
    },
    async setSelectedEvents() {
      try {
        if (this.formData?.application) {
          this.selectedApplicationEvents =
            this.selectedApplication?.events || [];
          if (this.selectedApplication && this.selectedApplication.settings) {
            const { authenticationFields } = this.selectedApplication.settings;
            if (authenticationFields && authenticationFields.length > 0) {
              this.selectedApplicationAuthDetails = authenticationFields;
            } else {
              this.selectedApplicationAuthDetails = null;
            }
          }
          await this.fetchApplicationOAuthDetails();
        } else {
          this.selectedApplicationAuthDetails = null;
          this.selectedApplicationEvents = [];
        }
      } catch (error) {
        console.error("Error in setSelectedEvents:", error);
      }
    },

    async fetchApplicationOAuthDetails() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-application-oauth-deatils",
        {
          application: this.formData?.application,
        }
      );
      this.selectedApplicationAuthDetails = response?.data || null;
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    resetSampleResponse() {
      this.formData.sample_response = null;
      this.testResponse = "";
      this.testResponseLength = -1;
    },
    async testUrl() {
      try {
        this.urlTestLoading = true;
        const response = await fetch(this.formData.url);
        const result = await response.json();
        this.testResponse = result;
        this.testUrlResponse();
        this.urlTestLoading = false;
      } catch (error) {
        this.testResponse = `Error: ${error.message}`;
        this.urlTestLoading = false;
      }
    },
    testUrlResponse() {
      if (typeof this.testResponse == "object") {
        if (this.testResponse.length) {
          this.formData.response_type = "ARRAY";
          if (this.testResponse.length > 5) {
            this.testResponseLength = this.testResponse.length - 5;
            this.testResponse = this.testResponse.splice(0, 4);
          }
        } else {
          this.formData.response_type = "VALUE";
        }
      } else {
        this.formData.response_type = "VALUE";
      }
      this.formData.sample_response = this.testResponse;
    },
    validateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log("Form is valid");
        } else {
          console.log("Form validation failed");
          return false;
        }
      });
    },
    async searchIntegrations() {
      this.page = 1;
      this.fetchIntergrations();
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchIntergrations();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchIntergrations();
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchIntergrations();
      }
    },
    async fetchIntergrations() {
      this.loading = true;
      let params = {
        limit: this.pageSize > 0 ? this.pageSize : 10,
        page: this.page,
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      let integrations = await postAPICall("GET", `integrations`, params);
      let inactiveIntegrations = [];
      const connectionPromises = integrations.data.map(async (integration) => {
        try {
          if (integration.connection_id) {
            const response = await postAPICall(
              "GET",
              `integrations/service/get-tokens-by-connection/${integration.connection_id}`
            );
            integration.status = response?.data?.status || "Unknown";
            if (integration.status.toLowerCase() === "inactive") {
              inactiveIntegrations.push(integration.name);
            }
          } else {
            integration.status = "N/A";
          }
        } catch (error) {
          integration.status = "Error";
        }
      });
      await Promise.all(connectionPromises);
      if (inactiveIntegrations.length > 0) {
        const inactiveList = inactiveIntegrations.join(", ");
        this.$notify({
          type: "warning",
          title: "Reauthorization Required",
          message: `The following integrations are inactive: ${inactiveList}. Please reauthorize.`,
          duration: 6000,
        });
      }

      this.data = integrations.data;
      this.total = integrations.total;
      this.loading = false;
    },
    showDialog() {
      this.AppIntegrations = "Add API Integrations";
      this.showIntegration = true;
      this.formData = {
        name: "",
        url: "",
        method: "GET",
        authentication_type: "",
        response_type: "VALUE",
        app_type: "PRE_DEFINED",
        authentication_details: {},
        dependencies: {},
        application: "",
        event: "",
        connection_id: "",
        existed_connection_id: "",
      };
      this.selectedApplicationAuthDetails = [];
      this.selectedApplicationDependencies = {};
      this.testResponse = "";
    },
    closeIntegration() {
      this.formData = {
        name: "",
        url: "",
        method: "GET",
        authentication_type: "",
        app_type: "PRE_DEFINED",
        authentication_details: "",
        dependencies: {},
      };
      this.selectedApplicationDependencies = {};
      this.testResponse = "";
      this.$store.commit(
        "auth/setThirdaPartyFormData",
        {},
        {
          root: true,
        }
      );
      this.showIntegration = false;
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            if (
              !this.formData.sample_response &&
              this.formData.app_type !== "PRE_DEFINED"
            ) {
              this.$message({
                type: "warning",
                message: "Please test the url before submitting the form",
              });
              return;
            }
            if (Array.isArray(this.formData.connection_id)) {
              this.formData.connection_id =
                this.formData.connection_id.length > 0
                  ? this.formData.connection_id[0]
                  : null;
            }
            if (
              this.formData.app_type == "PRE_DEFINED" &&
              this.selectedApplication?.settings?.isOauthAuthenticating
            ) {
              if (!this.formData.application) {
                this.$message({
                  type: "warning",
                  message: "Please select the application",
                });
                return;
              } else if (
                !this.formData.connection_id &&
                !this.formData.connection_id &&
                !this.selectedApplication?.settings?.isOauthAuthenticating
              ) {
                this.$message({
                  type: "warning",
                  message: "Please authenticate the integration",
                });
                return;
              }
            }
            if (this.formData._id) {
              await postAPICall(
                "PUT",
                `integrations/update/${this.formData._id}`,
                this.formData
              );
              this.$router.replace({ query: {} });
              this.$message.success("API Integration updated successfully!");
            } else {
              await postAPICall("POST", "integrations/create", this.formData);
              this.$router.replace({ query: {} });
              this.$message.success("API Integration added successfully!");
            }
            this.showIntegration = false;
            this.closeIntegration();
            this.fetchIntergrations();
          } catch (error) {
            console.error("Error occurred during API call:", error);
            this.$message.error("Failed to save API Integration!");
          }
        } else {
          console.error("Form validation failed!");
        }
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    async onDelete(row) {
      this.integrationToDelete = row;
      this.showDeleteDialog = false;
      this.$nextTick(() => {
        this.showDeleteDialog = true;
      });
    },

    async confirmDelete() {
      if (!this.integrationToDelete._id) {
        this.$message.error("Invalid Integration ID");
        return;
      }
      try {
        const response = await postAPICall(
          "DELETE",
          `integrations/delete/${this.integrationToDelete._id}`
        );
        console.log("response", response);
        this.fetchIntergrations();
        this.$message.success("API Integration deleted successfully!");
        this.showDeleteDialog = false;
        this.integrationToDelete = null;
      } catch (error) {
        this.$message.error("Failed to delete API Integration!");
      }
    },
    async onEdit(row) {
      this.formData = { ...row };
      this.AppIntegrations = "Edit API Integration";
      this.showIntegration = true;
      this.showDependencies = true;
      this.selectedApplicationDependencies = {};
      this.selectedApplication.dependencies.forEach((dep) => {
        this.$set(this.selectedApplicationDependencies, dep.key, []);
      });
      this.fillDependencyFields();
    },
    async saveAuthenticationDetails() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.isSaving = true;
          try {
            const response = await this.validateCredentials(this.formData);
            if (response) {
              const textDependencies =
                this.selectedApplication.dependencies.filter(
                  (dep) => dep.type === "text"
                );
              if (textDependencies.length > 0) {
                this.fetchDependencies();
                this.$message({
                  type: "info",
                  message: "Please fill in the required text dependencies.",
                });
              } else {
                this.showIntegration = false;
                this.submitForm();
              }
              // this.showIntegration = false;
              // this.submitForm();
            }
          } catch (error) {
            this.$message.error(
              "Failed to save integration. Please try again."
            );
          } finally {
            this.isSaving = false;
          }
        }
      });
    },
    async saveAuthDetailsToServer(data) {
      try {
        let params = {
          appName: this.formData.application,
          authenticationFields: this.formData.authentication_details,
        };
        const response = await postAPICall(
          "POST",
          "integrations/service/get-auth-details",
          params
        );
        if (response?.data) {
          this.formData.connection_id = response.data;
          this.data.push({
            ...data,
            connection_id: response.data,
          });
          this.$store.commit(
            "auth/setThirdaPartyFormData",
            {
              ...this.getThirdaPartyFormData,
              ...{
                [this.$route.query?.state]: {
                  ...this.getThirdaPartyFormData[this.$route.query.state],
                  connection_id: response.data,
                },
              },
            },
            { root: true }
          );
        }
      } catch (error) {
        this.$message({
          message: "Failed to save authentication details. Please try again.",
          type: "error",
        });
        console.error("Error saving authentication details:", error);
      }
    },
    async validateCredentials() {
      try {
        let params = {
          appName: this.formData.application,
          authenticationFields: this.formData.authentication_details,
        };
        const response = await postAPICall(
          "POST",
          "integrations/service/get-auth-details",
          params
        );
        if (response?.data) {
          this.formData.connection_id = response.data;
          this.$message({
            message:
              "Credentials Validated Successfully! Connection Established.",
            type: "success",
          });
          this.saveAuthDetailsToServer();
          return response;
        } else {
          this.$message({
            message: "Invalid credentials. Please check and try again.",
            type: "error",
          });
          return new Error("Invalid credentials, no connection_id returned.");
        }
      } catch (error) {
        this.$message({
          message: "Invalid credentials, Please try again.",
          type: "error",
        });
        console.error("Error during credentials validation:", error);
      }
    },
  },
  watch: {
    "formData.connection_id": function (newVal) {
      if (newVal) {
        this.fetchDependencies();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.option-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
}

.custom-form {
  max-width: 500px;
}
.custom-form-item {
  width: 100%;
}
.custom-input {
  width: 100%;
}
.custom-inputs {
  margin-bottom: 20px;
  width: 100%;
}
.custom-button {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.d-flex {
  display: flex;
  align-items: center;
}
.response-preview {
  border: 1px solid grey;
  border-radius: 5px;
  background-color: rgba(124, 126, 126, 0.123);
  overflow: auto;
}
.confirm-delete-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-delete-content {
  text-align: center;
  margin: auto;
  padding-top: 10%;
}
.input-in-whatsapp {
  width: 90vh;
}
.integration-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.url-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-form-item__label {
  margin-right: 10px;
}

.el-form-item .el-button {
  margin-left: 10px;
}
</style>

<style>
.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0px !important;
  margin: bottom -21px !important;
}
.red-asterisk {
  color: red;
  margin-right: 5px;
}
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #e3a048;
  }
}
</style>
