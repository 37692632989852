<template>
  <section id="workflow-users-list">
    <div class="inner-navbar">
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <div class="icon-block d-flex align-center">
            <div class="icon">
              <el-button icon="el-icon-arrow-left" @click="backToFormbuilder" circle></el-button>
            </div>

            <div class="icon-text pl-1">
              <h3 class="table-head-text">{{ getFlowName() }}</h3>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <el-input
            placeholder="Search"
            v-model="searchQuery"
            size="small"
            clearable
            class="Searching flaot-right mr-1"
            prefix-icon="el-icon-search"
          ></el-input>
        </el-col>
        <div :style="'width: 260px;'">
          <el-select
            v-model="currentFilter"
            @change="updateCurrentFilter"
            clearable
            size="small"
            placeholder="Select saved filter"
            filterable
          >
            <el-option
              v-for="filter in formbuilderAllFilters"
              :key="filter._id"
              :label="filter.filter_name"
              :value="filter._id"
            >{{ filter.filter_name }}</el-option>
          </el-select>
        </div>
        <div class="mr-1 ml-1">
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-badge :value="getCurrentFilters.length" class="item" type="primary">
                <el-tooltip content="Add Filters">
                  <button
                    @click="openFilterModel"
                    class="btn btn-sm btn-outline-secondary"
                    type="button"
                    style="font-size: 1.3rem;"
                  >
                    <i class="fa fa-filter" style="font-size: 1.1rem;margin: 2px;"></i>
                  </button>
                </el-tooltip>
              </el-badge>
            </span>
            <el-dropdown-menu slot="dropdown" v-if="getCurrentFilters.length > 0">
              <el-dropdown-item v-for="(filter, index) in getCurrentFilters" :key="index">
                <div class="d-flex" style="padding: 7px">
                  <el-tag effect="dark" size="mini" class="mr-1">
                    {{
                    index + 1
                    }}
                  </el-tag>
                  <p style="font-weight: 600">{{ getfilterLabel(filter) }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="right-block float-right mr-1"
          v-if="IframePermissions('Export', 'FormBuildersTemplateDataList')"
        >
          <templates-data-export-widget
            :formbuilderDetails="formbuilderDetails"
            :templatesFields="templateFields"
            :selectedFields="sectedFieldKeys"
          ></templates-data-export-widget>
        </div>
        <div class="right-block float-right">
          <el-tooltip content="Create Iframe link" placement="top">
            <el-link
              v-if="getIframeUrlInfo == null"
              icon="el-icon-link"
              style="font-size: 24px; float: right; margin-top: 5px"
              :underline="false"
              class="mt-"
              @click="openViewConfigPage"
            ></el-link>
          </el-tooltip>
        </div>
      </el-row>
    </div>
    <div class="mt-1" v-if="selectedRows.length > 0">
      <el-dropdown>
        <button class="btn btn-primary btn-sm m-lr-1 p-2 rounded" type="button">
          <i class="fa fa-cogs"></i> Bulk Actions
        </button>
        <el-dropdown-menu slot="dropdown">
          <a @click="bulkDelete">
            <el-dropdown-item command="bulkDelete">
              <i class="fa fa-trash-o"></i> Bulk Delete
            </el-dropdown-item>
          </a>
          <a @click="handleNotificationClick">
            <el-dropdown-item command="notifications">
              <i class="el-icon-message"></i>Send Notifications
            </el-dropdown-item>
          </a>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="inner-navbar-content top">
      <div class="vue-data-table-default table-scroll">
        <el-table
          :data="filteredFBDetailedData"
          :total="total"
          :resizable="true"
          border
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          v-loading="loading"
          element-loading-text="Fetching data, please wait..."
          element-loading-spinner="el-icon-loading"
          @sort-change="sortChange"
          style="overflow-wrap: normal"
          class="sidemenu"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" fixed="left"></el-table-column>
          <el-table-column fixed="left" label="User" min-width="220">
            <template slot-scope="scope">
              <div class="d-flex" v-if="!isAnonymousForm()">
                <div>{{ getUserEmail(scope.row) }}</div>
              </div>
              <div v-else>
                <div>{{ scope.row.ip_address }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            class="tableView"
            v-for="(field, index) of allFields
              .filter(
                (e) =>
                  e.input_type !== 'DATA_TABLE' &&
                  e.input_type !== 'ENTITY_TABLE'
              )
              .filter((e) => contentFields.indexOf(e.input_type) === -1)"
            :key="index"
            :label="field.label"
            sortable="custom"
            :prop="field.data_key"
            min-width="250"
          >
            <template slot-scope="scope">
              <div
                v-if="
                  field.input_type == 'FILE' ||
                  (field.input_type == 'ENTITY_VARIABLE' &&
                    field.inputType == 'FILE')
                "
              >
                <div
                  v-if="
                    getFieldData(scope.row, field) &&
                    getFieldData(scope.row, field).length &&
                    getFieldData(scope.row, field).length <= 1
                  "
                >
                  <el-button
                    type="text"
                    style="margin-top: -14px"
                    @click="downloadFile(getFieldData(scope.row, field)[0])"
                    class="mb-1"
                  >
                    Download
                    <i class="el-icon-download"></i>
                  </el-button>
                </div>
                <div
                  class="d-flex"
                  v-else-if="
                    getFieldData(scope.row, field) &&
                    getFieldData(scope.row, field).length &&
                    getFieldData(scope.row, field).length >= 1
                  "
                >
                  <p v-for="(file, index) of getFieldData(scope.row, field)" :key="index">
                    <el-button
                      type="text"
                      style="margin-top: 0px"
                      v-if="file && file.name && index < limit"
                      @click="downloadFile(file)"
                    >
                      {{ file.name | truncate(10, "...")
                      }}
                      <i
                        class="el-icon-download"
                      ></i>
                    </el-button>
                    <br />
                  </p>
                  <el-tag
                    style="cursor: pointer; width: 40px"
                    class="ml-1"
                    :value="getFieldData(scope.row, field).length"
                    v-if="getFieldData(scope.row, field).length > 1"
                    @click="downloadDocumentsPopup()"
                  >+{{ getFieldData(scope.row, field).length - 1 }}</el-tag>
                </div>
                <!-- </p> -->
              </div>

              <div
                class="d-flex"
                v-else-if="
                  field &&
                  (field.input_type === 'IMAGE' ||
                    field.input_type === 'GIF') &&
                  getFieldData(scope.row, field)
                "
              >
                <img :src="getFieldData(scope.row, field)" alt="IMG" width="100" height="60" />
              </div>
              <div
                v-else-if="
                  field &&
                  (field.input_type === 'DATE' || field.inputType === 'DATE')
                "
              >
                <p style="font-size: 13px">{{ getFieldData(scope.row, field) | globalDateFormat }}</p>
              </div>
              <div v-else-if="field.input_type == 'ENTITY'">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="checkApplicationUserPermission(scope.row, field)"
                >
                  <p style="font-size: 13px">{{ getFieldData(scope.row, field) }}</p>
                </el-link>
              </div>
              <div
                v-else-if="
                  (field.input_type == 'DATE_TIME' ||
                    (field.inputType == 'DATE_TIME' &&
                      field.input_type === 'ENTITY_VARIABLE')) &&
                  getFieldData(scope.row, field)
                "
              >
                {{
                getFieldData(scope.row, field)
                | moment("MM-DD-YYYY hh:mm:ss A")
                }}
              </div>
              <div
                v-else-if="
                  field &&
                  (field.input_type == 'DATE_TIME_RANGE' ||
                    (field.inputType == 'DATE_TIME_RANGE' &&
                      field.input_type === 'ENTITY_VARIABLE')) &&
                  getFieldData(scope.row, field)
                "
              >
                <p style="font-size: 13px">
                  {{
                  getFieldData(scope.row, field)
                  | moment("MM-DD-YYYY hh:mm:ss A")
                  }}
                </p>
              </div>
              <div
                v-else-if="
                  field &&
                  (field.input_type == 'DATE_RANGE' ||
                    (field.inputType == 'DATE_RANGE' &&
                      field.input_type === 'ENTITY_VARIABLE')) &&
                  getFieldData(scope.row, field)
                "
              >
                <p
                  style="font-size: 13px"
                >{{ getFieldData(scope.row, field) | moment("MM-DD-YYYY") }}</p>
              </div>
              <div v-else-if="field && field.input_type == 'TIME'">
                <p style="font-size: 13px">{{ getFieldData(scope.row, field) | moment("hh:mm") }}</p>
              </div>
              <div
                v-else-if="
                  field.input_type == 'SIGNATURE' ||
                  (field.input_type == 'ENTITY_VARIABLE' &&
                    field.inputType == 'SIGNATURE')
                "
              >
                <img
                  v-if="getFieldData(scope.row, field)"
                  :src="getFieldData(scope.row, field)"
                  alt="IMG"
                  width="100"
                  height="60"
                />
                <p v-else>-</p>
              </div>
              <div v-else-if="field.input_type == 'CURRENCY'">
                {{
                Intl.NumberFormat(getCurrencyFormet(field).code, {
                style: "currency",
                currency: getCurrencyFormet(field).currency,
                }).format(getFieldData(scope.row, field))
                }}
              </div>
              <div v-else-if="field.input_type == 'FORMULA'">
                <p v-if="field.result_type == 'CURRENCY'">
                  {{
                  Intl.NumberFormat(getCurrencyFormet(field).code, {
                  style: "currency",
                  currency: getCurrencyFormet(field).currency,
                  }).format(getFieldData(scope.row, field))
                  }}
                </p>
                <p v-else>{{ getFieldData(scope.row, field) }}</p>
              </div>
              <div v-else-if="field && field.input_type == 'NUMBER'">
                <p style="font-size: 13px">{{ getFieldData(scope.row, field) }}</p>
              </div>
              <div v-else-if="field.input_type == 'CAPTCHA'">
                <p v-if="getFieldData(scope.row, field)">{{ getFieldData(scope.row, field) }}</p>
                <p v-else>Not verified</p>
              </div>
              <span
                v-else-if="field.input_type == 'RICH_TEXT'"
                v-html="getFieldData(scope.row, field)"
              ></span>
              <span v-else-if="field.input_type === 'HYPER_LINK'">
                <el-link
                  @click="checkNavigationAction(scope.row, field)"
                  style="color: rgb(69 188 237)"
                >{{ getFieldData(scope.row, field) }}</el-link>
              </span>
              <!-- <span
                  v-else-if="field.input_type == 'MULTI_LINE_TEXT'"
                  v-html="getFieldData(scope.row, field)"
                >
              </span>-->
              <div v-else-if="field && field.input_type == 'MULTI_LINE_TEXT'">
                <span v-if="getFieldData(scope.row, field)">
                  <span v-if="getFieldData(scope.row, field).length > 100">
                    {{ truncateText(getFieldData(scope.row, field)) }}
                    <a
                      href="#"
                      @click.prevent="
                        openMultiLineData(
                          getFieldData(scope.row, field),
                          field.label,
                          field
                        )
                      "
                      style="color: #409eff"
                    >Read more</a>
                  </span>
                  <span v-else>{{ getFieldData(scope.row, field) }}</span>
                </span>
              </div>
              <div
                v-else-if="field && field.input_type == 'AGGREGATE_FUNCTION'"
              >{{ getFieldData(scope.row, field) }}</div>
              <div
                v-else-if="
                  getFieldData(scope.row, field) &&
                  field.input_type != 'DATA_TABLE'
                "
              >{{ getFieldData(scope.row, field) }}</div>
              <div
                v-else-if="
                  getFieldData(scope.row, field) &&
                  field.inputType == 'PHONE_COUNTRY_CODE'
                "
              >{{ getFieldData(scope.row, field) }}</div>
              <div
                v-else-if="
                  getFieldData(scope.row, field) &&
                  field.input_type == 'PASSWORD'
                "
              >{{ getFieldData(scope.row, field) }}</div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="150" fixed="right">
            <template slot-scope="scope">
              <div class="button-group">
                <button class="btn btn-light btn-sm m-lr-1 view-button" @click="viewRow(scope.row)">
                  <el-tooltip content="View" placement="top">
                    <i class="el-icon-view"></i>
                  </el-tooltip>
                </button>
                <button class="btn btn-light btn-sm m-lr-1 edit-button" @click="editRow(scope.row)">
                  <el-tooltip content="Edit" placement="top">
                    <i class="el-icon-edit"></i>
                  </el-tooltip>
                </button>
                <button
                  class="btn btn-light btn-sm m-lr-1 delete-button"
                  @click="deleteRow(scope.row)"
                >
                  <el-tooltip content="Delete" placement="top">
                    <i class="el-icon-delete"></i>
                  </el-tooltip>
                </button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      v-if="!loading"
      :class="'custom-pagination'"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next, jumper, sizes, total"
      :total="total"
      small
    ></el-pagination>
    <!-- <el-dialog
      :visible.sync="downloadDocuments"
      title="Download Documents"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="sidemenu">
        <el-table
          border
          :data="data"
          :header-row-class-name="'my-header-row'"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column>
    <template slot-scope="scope">-->
    <!-- {{ scope.row }} -->
    <!-- </template> -->
    <!-- <template
              slot-scope="scope"
              v-if="
                field.input_type == 'FILE' &&
                field.validations.multiple &&
                getFieldData(scope.row, field) &&
                getFieldData(scope.row, field).length &&
                getFieldData(scope.row, field).length >= 1
              "
            >
              <p
                style="font-size: 14px"
                slot="reference"
                v-for="(file, index) of getFieldData(scope.row, field)"
                :key="index"
              >
              <a @click="downloadFile(file)">
                {{ file.name | truncate(20, "...") }} Download <i class="el-icon-download"></i>
              </a>
                
              </p>
    </template>-->
    <!-- </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="downloadDocuments = false"> Close </el-button>
      </span>
    </el-dialog>-->
    <dialog-component
      :modal="false"
      :visible="downloadDocuments"
      :title="'Download Documents'"
      :containerWidth="'700px'"
      @before-close="closeDownloadDocuments"
    >
      <!-- <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row>-->
      <div class="vue-data-table-default">
        <el-table border :data="documentsData" class="tableSty sidemenu" :show-header="true">
          <el-table-column class="labelSty" label="Document Name">
            <template slot-scope="scope">
              <p style="font-size: 14px" slot="reference">{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <ul class="action-buttons">
                <li>
                  <button class="btn btn-light btn-sm m-lr-1" type="button">
                    <span class="text-muted" @click="onPreview(scope.row)" title="preview">
                      <i class="fa fa-eye"></i>
                    </span>
                  </button>
                </li>
                <li>
                  <button class="btn btn-light btn-sm m-lr-1" type="button">
                    <span
                      class="text-muted"
                      @click="onDownloadFile(scope.row)"
                      target="_blank"
                      ref="downloadLink"
                    >
                      <i class="el-icon-download"></i>
                    </span>
                  </button>
                </li>
              </ul>

              <!-- <el-button
                @click="onDownloadFile(scope.row)"
                target="_blank"
                ref="downloadLink"
                style="width: 120px; font-size: 12px"
              >
                Download
                <i class="el-icon-download"></i>
              </el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="downloadDocuments = false">Close</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :title="multiLineTextLabel"
      :visible="openMultiLineTextDialog"
      @before-close="closeMultiLneData"
      :isShowFooter="false"
      :containerMaxWidth="'50vw'"
    >
      <div style="padding: 20px" class="desription-text" v-if="isRichText">
        <!-- <ckeditor :config="editorConfig" v-model="multiLineTextData">
        </ckeditor>-->
        <div v-html="multiLineTextData"></div>
      </div>
      <div class="desription-text" v-else>{{ multiLineTextData | truncateHTML() }}</div>
    </dialog-component>
    <el-drawer
      title="Privacy Link"
      :visible.sync="copyModal"
      :direction="'rtl'"
      :with-header="true"
      :size="getIsMobile ? '50%' : '20%'"
    >
      <IframeLink :routeKey="url_key" type="FormBuildersTemplateDataList"></IframeLink>
    </el-drawer>
    <dialog-component
      title="Send Notification"
      :visible="sendNotificationDialog"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendNotificationDialog = false"
      :isShowFooter="false"
      v-loading="emailConfigLoading"
    >
      <EmailSendBox
        :paramsContent="paramsData"
        :customEmails="notificationEmails"
        v-on:closeNotificationDialog="closeSendNotificationDialog"
      ></EmailSendBox>
    </dialog-component>
    <dialog-component
      :title="'Filters'"
      :visible="dialogEdit"
      :containerWidth="getIsMobile ? '100%' : '70%'"
      @before-close="resetFiltersData"
    >
      <div>
        <FormbuilderFilters
          :currentFormbuilder="formbuilderDetails"
          :formbuilderFiltersData="formbuilderFiltersData"
          :allFormbuilderFields="allFields"
          v-on:delete="confimDelete"
        ></FormbuilderFilters>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="plain" @click="resetFiltersData" size="small">
          <i class="fa fa-close"></i> Cancel
        </el-button>
        <el-button
          type="primary"
          @click="applyFilter"
          size="small"
          :disabled="checkFilterDisabled(formbuilderFiltersData)"
        >
          <i class="el-icon-check"></i>
          {{ getFilterButtonLabel }}
        </el-button>
      </span>
    </dialog-component>
    <complete-entity-data-popup
      :entityId="selectedChildEntityId"
      :entityDataId="selectedChildEntityDataId"
      :entityRefName="selectedChildEntityRefName"
      :primaryDataValue="selectedChildEntityPrimaryDataValue"
      :isMultiple="selectedChildEntityDataIsMultple"
      :isTitleVisible="true"
      v-if="showChildEntityDataDialog"
      @close="closeChildEntityDataDialog"
    ></complete-entity-data-popup>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import fieldsDataHelper from "@/mixins/fieldsDataHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import { postAPICall } from "@/helpers/httpHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import {
  // fetchFormbuilderFiltersFromLocal,
  addSingleFilterToLocal,
  fetchAllFormbuilderFiltersById,
  updateFilterData,
  deleteFilter
} from "@/repo/filtersRepo";

export default {
  mixins: [TemplateBuilderhelper, fieldsDataHelper, NavigationHelper, CustomDashboardConfig],
  components: {
    TemplatesDataExportWidget: () =>
      import("../widgets/templatesDataExportWidget"),
    IframeLink: () => import("../../views/iframeLink.vue"),
    EmailSendBox: () => import("../notifications/emailSendBox.vue"),
    FormbuilderFilters: () => import("./formbuilderFilters.vue"),
    CompleteEntityDataPopup: () =>
      import("../entity/completeEntityDataPopup.vue")
  },
  computed: {
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderRemindersSettings",
      "getFormBuilderUpdateStatus",
      "getFormBuilderPublicURLDetails"
    ]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderUsersData",
      "getFormbuilderUniqueUsersData",
      "getUsersFormbuilderTemplatesData",
      "getFormbuilderDataDeleteStatus",
      "getFormbuilderDataBulkDeleteStatus",
      "getFormbuilderDataBulkDeleteErrors"
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getUpdateFilterData",
      "getDeleteFilterStatus",
      "getCreateNewFilterError",
      "getUpdateFilterError",
      "getDeleteFilterErros"
    ]),
    ...mapGetters("templatesData", ["getDownloadUrl", "getDownloadError"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    filteredFBDetailedData() {
      if (!this.searchQuery) {
        return this.data;
      } else {
        const searchTerm = this.searchQuery.toLowerCase();
        return this.data.filter(row => {
          return Object.values(row).some(value => {
            if (typeof value === "number") {
              return String(value)
                .toLowerCase()
                .includes(searchTerm);
            } else if (typeof value === "string") {
              return value.toLowerCase().includes(searchTerm);
            } else if (typeof value === "object" && value !== null) {
              return JSON.stringify(value)
                .toLowerCase()
                .includes(searchTerm);
            }
            return false;
          });
        });
      }
    },
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50, 100]
      };
    },
    getCurrentFilters() {
      return this.formbuilderFiltersData &&
        this.formbuilderFiltersData.filters &&
        this.formbuilderFiltersData.filters.length
        ? this.formbuilderFiltersData.filters
        : [];
    },
    getFilterButtonLabel() {
      let name = "Apply";
      const filterData = this.formbuilderFiltersData;
      if (filterData) {
        if (filterData.isSaveFilter) {
          name = "Save & Apply";
        } else if (filterData.updateFilter) {
          name = "Update & Apply";
        }
      }
      return name;
    }
  },
  data() {
    return {
      searchQuery: "",
      multipleFileNames: [],
      pageSize: 0,
      sortBy: "_id",
      sortOrder: -1,
      data: [],
      total: 0,
      currentPage: 1,
      formbuilderCode: "",
      formbuilderDetails: "",
      templateIds: [],
      templateFields: [],
      allFields: [],
      sectedFieldKeys: [],
      loading: false,
      downloadDocuments: false,
      limit: 1,
      documentsData: [],
      contentFields: [
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "VIDEO",
        "SINGLE_LINE_CONTENT",
        "HORIZONTAL_LINE",
        "DIVISION",
        "HTML_CONTENT",
        "ACTION_BUTTON",
        "HTML_CONTENT"
      ],
      multiLineTextData: "",
      multiLineTextLabel: "",
      openMultiLineTextDialog: false,
      isRichText: false,
      copyModal: false,
      url_key: "",
      pageSizes: [10, 20, 50, 100],
      selectedRows: [],
      sendNotificationDialog: false,
      notificationEmails: [],
      paramsData: {},
      emailConfigLoading: false,
      dialogEdit: false,
      formbuilderFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        formbuilder_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
        displayType: "",
        data_limit: 10,
        email_template_id: ""
      },
      allFormbuilderFields: [],
      formbuilderAllFilters: [],
      currentFilter: "",
      UsercurrentFilter: "",
      showChildEntityDataDialog: false,
      selectedChildEntityDataId: "",
      selectedChildEntityId: "",
      selectedChildEntityRefName: "",
      selectedChildEntityPrimaryDataValue: "",
      selectedChildEntityDataIsMultple: false,
      isApplicationUserSide: false
    };
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    this.isApplicationUserSide = this.$route?.path?.includes("/ap/")
      ? true
      : false;
    await this.getNecessaryInfo();
  },
  methods: {
    getUserEmail(formbuilderData) {
      if (formbuilderData?.contact) {
        return formbuilderData.contact && formbuilderData.contact.email
          ? formbuilderData.contact.email
          : "";
      }
      return "";
    },
    mapDateFormbuilderFilters(filters) {
      return filters.map((d) => {
        let e = JSON.parse(JSON.stringify(d));
        if (e.global_variable_id) {
          let variable = this.globalVariablesData.find(
            (fl) => fl._id == e.global_variable_id
          );
          if (variable?.value) {
            if (this.isNumber(variable.value)) {
              e.value = parseInt(variable.value);
            } else {
              e.value = variable.value;
            }
          }
        }
        if (
          e.operator == "real_time" &&
          e.data_type == "DATE" &&
          !e.every_year
        ) {
          let data = this.getDatePickerHelper1(e.selected_date_picker, e.value);
          e.value = data.value;
          e.today = data.today;
          e.operator = "between";
        } else if (
          e.data_source == "login_user" &&
          e.value_field &&
          this.getAuthenticatedUser[e.value_field]
        ) {
          e.value = this.getAuthenticatedUser[e.value_field];
        } else if (
          e.field == "created_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.field == "updated_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.data_type == "DATE_TIME" &&
          e.data_source == "REAL_TIME_PICKER" &&
          e.selected_date_picker &&
          e.operator == "dateinclude"
        ) {
          let data = this.getDatePickerHelper(e.selected_date_picker);
          e.value = data.value;
        } else if (e.data_type == "NUMBER" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (e.data_type == "CURRENCY" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          e.data_source == "CURRENT_DATE"
        ) {
          if (e.value == "today") {
            if (e.operator == ">") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == ">=") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<=") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == "=") {
              let data = this.getDatePickerHelper1("today", moment());
              e.value = data.value;
              e.today = data.today;
              e.operator = "between";
            }
          }
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          ["before", "after", "weekdays"].indexOf(e.operator) == -1
        ) {
          if (e.operator == "=") {
            let data = this.getDatePickerHelper1("custom_date", e.value);
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else if (
            ["notbetween", "between"].includes(e.operator) &&
            e.value
          ) {
            let data = this.getDatePickerHelper1("custom_date_range", e.value);
            e.value = data.value;
            e.today = data.today;
          } else if (
            e.operator == "real_time" &&
            e.data_type == "DATE" &&
            e.value &&
            !e.every_year
          ) {
            let data = this.getDatePickerHelper1(
              e.selected_date_picker,
              e.value
            );
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else {
            if ([">=", ">"].includes(e.operator)) {
              e.value = moment(new Date(e.value)).startOf("day");
            } else {
              e.value = moment(new Date(e.value)).endOf("day");
            }
          }
        }
        e["current_date"] = this.getCurrentDate;
        return e;
      });
    },
    checkApplicationUserPermission(rowData, stepData) {
      if (this.isApplicationUserSide) {
        if (
          this.getEntityPermission &&
          this.getEntityPermission.other_entities &&
          this.getEntityPermission.other_entities[stepData.entity_id] &&
          this.getEntityPermission.other_entities[stepData.entity_id]
            .permissions &&
          this.getEntityPermission.other_entities[
            stepData.entity_id
          ].permissions.indexOf("VIEW") !== -1
        ) {
          this.showChildEntityDataDetails(rowData, stepData);
        } else {
          this.$message({
            message: "You don't have permission to view the data",
            type: "warning"
          });
        }
      } else {
        this.showChildEntityDataDetails(rowData, stepData);
      }
    },
    closeChildEntityDataDialog() {
      this.showChildEntityDataDialog = false;
    },
    async showChildEntityDataDetails(rowData, stepData) {
      let field = this.getField(stepData.key);
      this.showChildEntityDataDialog = false;
      this.selectedChildEntityDataIsMultple = false;

      let fieldKey = stepData?.key?.split("#").pop();
      this.selectedChildEntityDataId =
        rowData.entityData[stepData.entity_type_id][stepData.template_id][
          fieldKey
        ];

      this.selectedChildEntityId = stepData.entity_id;

      this.selectedChildEntityRefName = stepData.label;
      this.selectedChildEntityPrimaryDataValue = this.getFieldData(
        rowData,
        stepData
      );

      if (
        field?.allow_multiple &&
        typeof this.selectedChildEntityDataId == "object" &&
        this.selectedChildEntityDataId.length > 1
      ) {
        this.selectedChildEntityDataIsMultple = true;
      }
      this.showChildEntityDataDialog = true;
    },
    getField(key) {
      let foundField = this.allFormbuilderFields.find(e => {
        e.key == key;
      });
      return foundField ? foundField : null;
    },
    confimDelete() {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.deleteFilter();
      });
    },
    async deleteFilter() {
      let id = this.formbuilderFiltersData._id
        ? this.formbuilderFiltersData._id
        : "";
      if (!id) {
        this.$notify({
          title: "Error",
          message: "Filter not selected",
          type: "error"
        });
        return;
      }
      this.loading = true;
      await this.$store.dispatch("filters/deleteFilter", id);
      if (this.getDeleteFilterStatus) {
        await deleteFilter(id);
        this.dialogEdit = false;
        this.resetFormbuilderFiltersData();
        this.currentFilter = "";
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters deleted",
          type: "success"
        });
        this.loading = false;
        this.fetchFilters();
        this.fetchAllWorkflowsTemplatesData();
      } else {
        this.loading = false;
        let msg = "Failed to delete the filters";
        if (this.getDeleteFilterErros?.message) {
          msg = this.getDeleteFilterErros.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error"
        });
      }
    },
    getfilterLabel(filter) {
      let name = "";
      if (filter && filter.field && this.allFields) {
        let filteredTemplate = this.allFields.find(e => e.key == filter.field);
        if (filteredTemplate) {
          name = filteredTemplate.label;
        }
      }
      return name;
    },
    async updateCurrentFilter() {
      this.resetFormbuilderFiltersData();
      let filter = this.currentFilter;
      if (filter) {
        let selectedFilter = this.formbuilderAllFilters.find(
          e => e && e._id == filter
        );
        this.formbuilderFiltersData = {
          ...selectedFilter,
          ...{ isSaveFilter: true }
        };
      }
      await this.fetchAllWorkflowsTemplatesData();
    },
    async fetchFilters() {
      this.formbuilderAllFilters = await fetchAllFormbuilderFiltersById({
        formbuilderId: this.formbuilderDetails._id
      });
    },
    async applyFilter() {
      this.loading = true;
      this.dialogEdit = false;
      if (this.formbuilderFiltersData) {
        this.formbuilderFiltersData.filters = this.formbuilderFiltersData.filters.map(
          e => {
            e.query_type = this.formbuilderFiltersData.query_type
              ? this.formbuilderFiltersData.query_type
              : "AND";
            return e;
          }
        );
        if (
          this.formbuilderFiltersData._id &&
          this.formbuilderFiltersData.updateFilter
        ) {
          this.updateFilter();
        } else if (
          !this.formbuilderFiltersData._id &&
          this.formbuilderFiltersData.isSaveFilter
        ) {
          this.saveFilter();
        }
      }
      if (
        this.formbuilderFiltersData?.displayType &&
        this.formbuilderFiltersData?._id
      ) {
        this.activeEntityView = this.formbuilderFiltersData.displayType;
        let query = { ...this.$route.query };
        query["viewType"] = this.formbuilderFiltersData.displayType;
        if (this.formbuilderFiltersData?._id) {
          query["filter"] = this.formbuilderFiltersData._id;
        }
        this.$router.push({
          name: this.$route.name,
          query: query
        });
      }
      this.loading = false;
      this.fetchAllWorkflowsTemplatesData();
    },
    prepareFilterParams() {
      return {
        filter_name: this.formbuilderFiltersData.filter_name.trim(),
        filters: this.formbuilderFiltersData.filters,
        entity_id: null,
        formbuilder_id: this.formbuilderDetails._id,
        quick_filters: this.formbuilderFiltersData.quick_filters,
        permissions: this.formbuilderFiltersData.permissions,
        tableFieldGroupBy: this.formbuilderFiltersData.tableFieldGroupBy || "",
        tableFieldSubGroupBy:
          this.formbuilderFiltersData.tableFieldSubGroupBy || "",
        tableFieldGroupByFields:
          this.formbuilderFiltersData.tableFieldGroupByFields || [],
        filterEntityVariableColumns: this.formbuilderFiltersData
          .filterEntityVariableColumns,
        displayType: this.formbuilderFiltersData.displayType,
        data_limit: this.formbuilderFiltersData.data_limit
      };
    },
    async saveFilter() {
      let params = this.prepareFilterParams();
      if (!params.filter_name) {
        this.$notify({
          title: "Error",
          message: "Please enter Filter name",
          type: "error"
        });
        return;
      }
      await this.$store.dispatch("filters/fetchCreateNewFilter", params);
      if (this.getCreateNewFilter) {
        //this.showFieldsParent.selectedFilter = [this.getCreateNewFilter._id];
        if (this.$route.query.routeType) {
          this.UsercurrentFilter = this.getCreateNewFilter._id;
        }
        this.currentFilter = this.getCreateNewFilter._id;
        await addSingleFilterToLocal(this.getCreateNewFilter);
        // this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters saved",
          type: "success"
        });
        this.loading = true;
        await this.fetchFilters();
        await this.fetchAllWorkflowsTemplatesData();
        this.loading = false;
      } else {
        this.loading = false;
        let msg = "Filter name already exists";
        if (
          this.getCreateNewFilterError &&
          this.getCreateNewFilterError.message
        ) {
          msg = this.getCreateNewFilterError.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error"
        });
      }
    },
    async updateFilter() {
      let params = this.prepareFilterParams();
      if (!params.filter_name) {
        this.$notify({
          title: "Error",
          message: "Please enter Filter name",
          type: "error"
        });
        return;
      }
      let id = this.formbuilderFiltersData._id
        ? this.formbuilderFiltersData._id
        : "";
      if (!id) {
        await this.saveFilter();
      }
      await this.$store.dispatch("filters/updateFilter", {
        id: id,
        params: params
      });
      if (this.getUpdateFilterStatus) {
        if (this.getUpdateFilterData?.data) {
          await updateFilterData(id, this.getUpdateFilterData.data);
        }
        // this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters updated",
          type: "success"
        });
        this.loading = true;
        await this.fetchFilters();
        this.loading = false;
      } else {
        this.loading = false;
        let msg = "Failed to Save the filters";
        if (this.getUpdateFilterError && this.getUpdateFilterError.message) {
          msg = this.getUpdateFilterError.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error"
        });
      }
    },
    openFilterModel() {
      if (this.currentFilter) {
        let selectedFilter = this.formbuilderAllFilters.find(
          e => e && e._id == this.currentFilter
        );
        //let quick_filters = this.formbuilderFiltersData.quick_filters || [];
        let query_type =
          this.formbuilderFiltersData && this.formbuilderFiltersData.query_type
            ? this.formbuilderFiltersData.query_type
            : selectedFilter &&
              selectedFilter.filters &&
              selectedFilter.filters.length &&
              selectedFilter.filters[0].query_type
            ? selectedFilter.filters[0].query_type
            : "AND";
        this.formbuilderFiltersData = {
          ...JSON.parse(JSON.stringify(selectedFilter)),
          ...{
            updateFilter: true,
            query_type: query_type
          }
        };
      }
      this.dialogEdit = true;
    },
    resetFormbuilderFiltersData() {
      this.dialogEdit = false;
      const resetData = {
        filter_name: "",
        isSaveFilter: false,
        query_type: "AND",
        quick_filters: [],
        columns_settings: [],
        data_limit: 10,
        filters: []
      };
      this.formbuilderFiltersData = resetData;
    },
    checkFilterDisabled(filterData) {
      if (filterData) {
        if (filterData.filters && filterData.filters.length) {
          const lastmonthFilter = filterData.filters.find(
            fl =>
              (fl.operator === "lastmonth" && fl.field === "updated_at") ||
              (fl.operator === "thismonth" && fl.field === "updated_at") ||
              (fl.operator === "lastmonth" && fl.field === "created_at") ||
              (fl.operator === "thismonth" && fl.field === "created_at")
          );

          if (lastmonthFilter) {
            return false;
          }
          const last7DaysFilter = filterData.filters.find(
            fl => fl.selected_date_picker === "last_7_days"
          );

          if (last7DaysFilter) {
            return false;
          }
        }

        if (filterData.filters && filterData.filters.length) {
          return filterData.filters.some(
            fl =>
              !fl ||
              !fl.field ||
              (!fl.operator && fl.field !== "isCreatedFromFormbuilder") ||
              (!fl.data_source &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists" &&
                fl.operator !== "between" &&
                fl.operator !== "notbetween" &&
                fl.operator !== "dateinclude" &&
                fl.operator !== "notdateinclude" &&
                fl.operator !== "real_time" &&
                fl.operator !== "weekdays" &&
                fl.operator !== "filter" &&
                fl.field !== "created_by" &&
                fl.field !== "updated_by") ||
              (fl.data_source === "GLOBAL_VARIABLE" &&
                !fl.global_variable_id &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists") ||
              (fl.data_source === "self_field" &&
                !fl.value_field &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists") ||
              (fl.data_source === "TEMPLATE" &&
                !fl.value_field &&
                fl.operator !== "exists" &&
                fl.operator !== "notexists") ||
              (!fl.value &&
                typeof fl.value !== "number" &&
                typeof fl.value !== "boolean" &&
                fl.data_source !== "self_field" &&
                fl.operator !== "exists" &&
                fl.operator !== "between" &&
                fl.operator !== "notexists" &&
                fl.data_source !== "GLOBAL_VARIABLE" &&
                fl.data_source !== "LOGIN_USER" &&
                fl.operator !== "weekdays" &&
                fl.data_source !== "TEMPLATE")
          ) ||
            (!filterData.filter_name &&
              (filterData.isSaveFilter || filterData.updateFilter)) ||
            (filterData?.displayType === "HTML_CONTENT" &&
              !filterData.email_template_id)
            ? true
            : false;
        } else if (
          filterData.quick_filters &&
          filterData.quick_filters.length
        ) {
          return !filterData.filter_name &&
            (filterData.isSaveFilter || filterData.updateFilter)
            ? true
            : false;
        }
        return filterData.isSaveFilter || filterData.updateFilter
          ? true
          : false;
      }
      return false;
    },
    resetFiltersData() {
      let check = this.checkFilterDisabled(this.formbuilderFiltersData);
      // if (!this.formbuilderFiltersData.filter_name && this.entityFiltersData.isSaveFilter) {
      //   this.formbuilderFiltersData.quick_filters = [];
      // }
      this.formbuilderFiltersData.filters = this.removeEmptyRows(
        this.formbuilderFiltersData.filters || []
      );
      if (check) {
        let selectedFilter;
        selectedFilter = this.formbuilderAllFilters.find(
          e => e && e._id == this.currentFilter
        );
        if (selectedFilter) {
          this.formbuilderFiltersData = {
            ...selectedFilter,
            ...{ updateFilter: true }
          };
        }
      }
      if (
        this.formbuilderFiltersData._id &&
        this.formbuilderAllFilters &&
        this.formbuilderAllFilters.length
      ) {
        let formFiltersData;
        formFiltersData = this.formbuilderAllFilters.find(
          e => e._id == this.formbuilderFiltersData._id
        );
        if (formFiltersData?._id) {
          this.formbuilderFiltersData = { ...formFiltersData };
        }
      }
      this.dialogEdit = false;
    },
    removeEmptyRows(data) {
      if (data && data.length) {
        return data.filter(
          fl =>
            fl &&
            fl.field &&
            fl.operator &&
            (fl.data_source ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            ((fl.data_source == "GLOBAL_VARIABLE" && fl.global_variable_id) ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            ((fl.data_source == "self_field" && fl.value_field) ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            (fl.value ||
              (fl.data_source != "self_field" &&
                fl.operator != "exists" &&
                fl.operator != "notexists" &&
                fl.data_source != "GLOBAL_VARIABLE"))
        );
      }
      return [];
    },
    async viewRow(row) {
      if (this.$route.name == "applicationFormBuilderUserData") {
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true
          }
        );
        this.$router.push({
          name: "ApplicationUserFormbuilderView",
          params: {
            formbuilder_code: this.getFormBuilder.code,
            formbuilder_data_id: row._id
          }
        });
      } else {
        let urlName = "";
        if (this.getIframeUrlInfo != null) {
          urlName = "FormbuilderViewSSO_External";
        } else {
          urlName = "FormbuilderView";
        }
        this.$router.push({
          name: urlName,
          params: {
            formbuilder_code: this.getFormBuilder.code,
            formbuilder_data_id: row._id
          }
        });
      }
    },
    async editRow(row) {
      if (this.$route.name == "applicationFormBuilderUserData") {
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true
          }
        );
        this.$router.push({
          name: "ApplicationUserFormbuilderEdit",
          params: {
            formbuilder_code: this.getFormBuilder.code,
            formbuilder_data_id: row._id
          },
          query: {
            origin: "cwdjkrni"
          }
        });
      } else {
        let urlName = "";
        if (this.getIframeUrlInfo != null) {
          urlName = "FormbuilderEditSSO_External";
        } else {
          urlName = "FormbuilderEdit";
        }
        this.$router.push({
          name: urlName,
          params: {
            formbuilder_code: this.getFormBuilder.code,
            formbuilder_data_id: row._id
          }
        });
      }
    },
    async deleteRow(row) {
      this.$confirm("Are you sure to delete the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.deleteRowData(row);
      });
    },
    async deleteRowData(row) {
      await this.$store.dispatch(
        "formbuilderData/deleteFormbuilderData",
        row._id
      );
      if (this.getFormbuilderDataDeleteStatus) {
        this.$message({
          message: "Response deleted successfully!",
          type: "success"
        });
        this.fetchAllWorkflowsTemplatesData();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Can't delete."
        });
      }
    },
    bulkDelete() {
      const selectedCount = this.selectedRows.length;
      this.$confirm(
        `Are you sure you want to delete ${selectedCount} selected response(s)?`,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(() => {
        this.deleteSelectedFormData();
      });
    },
    async deleteSelectedFormData() {
      let selectedIds = [];
      this.selectedRows.forEach(item => {
        if (item && item._id) {
          selectedIds.push(item._id);
        }
      });
      let params = {
        selectedIds: selectedIds,
        formbuilder_details_id: this.getFormBuilder._id
      };
      this.loading = true;
      await this.$store.dispatch(
        "formbuilderData/formBuilderDataBulkDelete",
        params
      );
      if (this.getFormbuilderDataBulkDeleteStatus) {
        this.loading = false;
        this.$notify.success({
          title: "Success",
          message: "Form data deleted successfully"
        });
        await this.fetchAllWorkflowsTemplatesData();
      } else {
        let message = "Error while deleting data";
        if (this.getFormbuilderDataBulkDeleteErrors) {
          message = this.getFormbuilderDataBulkDeleteErrors;
        }
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: message
        });
      }
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    extractEmails(selectedRows) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const extractedEmails = new Set();
      selectedRows.forEach(item => {
        // Extract emails from entityData
        const entityData = item.entityData;
        for (const entityId in entityData) {
          const entityFields = entityData[entityId];
          for (const fieldId in entityFields) {
            const entity = entityFields[fieldId];
            if (entity.email && emailRegex.test(entity.email)) {
              extractedEmails.add(entity.email);
            }
          }
        }

        // Extract emails from templateData
        const templateData = item.templateData;
        for (const templateId in templateData) {
          const templateFields = templateData[templateId];
          for (const fieldKey in templateFields) {
            const field = templateFields[fieldKey];
            if (typeof field === "string" && emailRegex.test(field)) {
              extractedEmails.add(field);
            }
          }
        }
      });
      if (extractedEmails.size === 0) {
        this.$message.error(
          "No valid email addresses found in the selected row."
        );
        return [];
      }
      return [...extractedEmails];
    },
    handleNotificationClick() {
      this.notificationEmails = this.extractEmails(this.selectedRows);
      if (this.notificationEmails.length === 0) {
        return;
      }
      this.sendNotificationDialog = true;
    },
    async closeSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      params.company_id = this.getFormBuilder.company;
      let endPoint = "/entities-data/users/send-notification";
      let response = await postAPICall("POST", endPoint, params);
      if (response) {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Success",
          message: "Sending mail(s) initiated",
          type: "success"
        });
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: "Error while sending mails",
          type: "error"
        });
      }
      this.sendNotificationDialog = false;
    },
    checkNavigationAction(rowData, stepData) {
      if (
        this.isValidURL(this.getFieldData(rowData, stepData)) &&
        this.isValidHttpUrl(
          this.getValidUrl(this.getFieldData(rowData, stepData))
        )
      ) {
        let url = this.getValidUrl(this.getFieldData(rowData, stepData));
        window.open(url, "_blank");
      } else {
        this.$message.info("Invalid URL");
      }
    },
    isValidURL(url) {
      let re = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    openMultiLineData(htmlContent, label, field) {
      this.openMultiLineTextDialog = true;
      this.multiLineTextData = htmlContent;
      this.multiLineTextLabel = label;
      if ("currency_code" in field && "data_type" in field.currency_code) {
        this.isRichText = false;
      } else {
        this.isRichText = true;
      }
    },
    closeMultiLneData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },
    isAnonymousForm() {
      return this.formbuilderDetails?.form_builders_owner === "ANONYMOUS";
    },
    async downloadFile(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob"
      })
        .then(res => res.blob())
        .then(response => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // if ((!data?.path?.includes(".csv") || !data?.name?.includes(".csv")) && data?.path?.includes(".xlsx")) {
        //   window.open(this.getDownloadUrl, "_blank");
        // }
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading"
        });
      }
    },
    getData() {
      let data = this.allFields.filter(el => {
        if (el && el.inputType == "FILE") {
          return el;
        }
      });
      return data;
    },
    getCurrencyFormet(field) {
      if (
        field &&
        field.validations &&
        field.validations.currency &&
        field.validations.locale
      ) {
        return {
          currency: field.validations.currency,
          code: field.validations.locale
        };
      }
      return {
        currency: "USD",
        code: "en-US"
      };
    },
    getFieldData(formbuilderData, field) {
      let fKey = field?.key?.split("#").pop();
      let templateData;
      if (
        field.entity_type_id &&
        formbuilderData.entityData &&
        formbuilderData.entityData[field.entity_type_id] &&
        formbuilderData.entityData[field.entity_type_id][field.template_id]
      ) {
        templateData =
          formbuilderData.entityData[field.entity_type_id][field.template_id];
      } else if (
        formbuilderData.templateData &&
        formbuilderData.templateData &&
        formbuilderData.templateData[field.template_id]
      ) {
        templateData = formbuilderData.templateData[field.template_id];
      }
      if (templateData) {
        let value = templateData[fKey];
        let otherValue = templateData[fKey + "/otheroption"]
          ? templateData[fKey + "/otheroption"]
          : null;
        // this.multipleFileNames = value.files.flatMap((el) => {
        //   return el.name;
        // });

        if (value || value == "0") {
          if (field.input_type === "NUMBER") {
            if (typeof value === "number") {
              return (
                value +
                (field.properties?.number_type === "PERCENTAGE" ? "%" : "")
              );
            } else if (!isNaN(value)) {
              return (
                parseInt(value) +
                (field.properties?.number_type === "PERCENTAGE" ? "%" : "")
              );
            }
          } else if (field.input_type == "DATE") {
            // let date = new Date(value).toLocaleDateString('en-US', {timeZone: 'america/new_york'});
            return moment(new Date(value)).format(
              this.getDefaultDateFormat &&
                this.getDefaultDateFormat.includes("Do") == false
                ? this.getDefaultDateFormat
                : "MM-DD-yyyy"
            );
          } else if (field.input_type == "NUMBER") {
            return value;
          } else if (field.inputType == "PHONE_COUNTRY_CODE") {
            if (
              templateData[fKey] &&
              templateData[fKey + "_code"]?.countryCallingCode &&
              templateData[fKey + "_code"]?.countryCode
            ) {
              value =
                "+" +
                templateData[fKey + "_code"]?.countryCallingCode +
                "(" +
                templateData[fKey + "_code"]?.countryCode +
                ") " +
                value;
              return value;
            }
            return value;
          } else if (field.input_type == "CAPTCHA") {
            return value ? "Verified" : "Not Verified";
          } else if (field.input_type == "PASSWORD") {
            let pswdLength = templateData[fKey].length;
            return "*".repeat(pswdLength);
          } else if (field.inputType == "AGGREGATE_FUNCTION") {
            return this.getAggregateCurrencyFormat(templateData[fKey], field);
          } else if (
            field.input_type == "DATE_TIME_RANGE" ||
            (field.inputType == "DATE_TIME_RANGE" &&
              field.input_type == "ENTITY_VARIABLE")
          ) {
            let format = this.formbuilderDetails?.global_date_format ? this.formbuilderDetails.global_date_format + ' hh:mm:ss A' : 'MM-DD-YYYY hh:mm:ss A';
            return (
              this.$moment(value[0]).format(format) +
              " To " +
              this.$moment(value[1]).format(format)
            );
          } else if (
            field.input_type == "DATE_RANGE" ||
            (field.inputType == "DATE_RANGE" &&
              field.input_type == "ENTITY_VARIABLE")
          ) {
            let format = this.formbuilderDetails?.global_date_format ? this.formbuilderDetails.global_date_format : 'MM-DD-YYYY';
            return (
              this.$moment(value[0]).format(format) +
              " To " +
              this.$moment(value[1]).format(format)
            );
          } else if (
            (field.input_type == "TIME_RANGE" ||
              (field.inputType == "TIME_RANGE" &&
                field.input_type == "ENTITY_VARIABLE")) &&
            value
          ) {
            if (value.length) {
              return (
                this.$moment(value[0]).format("HH:mm:ss") +
                " - " +
                this.$moment(value[1]).format("HH:mm:ss")
              );
            }
          } else if (
            field.input_type == "QUESTION" &&
            field.question_type == "MCQ" &&
            field.has_other_option &&
            value[0] &&
            value[0].toLowerCase() == "others" &&
            otherValue
          ) {
            return otherValue;
          } else if (
            field.input_type == "FILE" ||
            (field.input_type == "ENTITY_VARIABLE" && field.inputType == "FILE")
          ) {
            this.documentsData = value.files;
            return value.files && value.files.length ? value.files : value;
          } else if (
            field.input_type == "DATE_TIME" ||
            (field.input_type == "ENTITY_VARIABLE" &&
              field.inputType == "DATE_TIME")
          ) {
            let format = this.formbuilderDetails?.global_date_format ? this.formbuilderDetails.global_date_format + ' hh:mm:ss A' : 'MM-DD-YYYY hh:mm:ss A';
            return moment(new Date(value)).format(format);
          } else if (field.input_type == "WEEKDAYS") {
            if (
              field?.allow_multiple &&
              typeof value == "object" &&
              value.length
            ) {
              let mappedArray = value.map(e => this.weekDayMap[e]);
              return mappedArray.join(",");
            } else {
              return this.weekDayMap[value];
            }
          } else if (
            typeof value == "object" &&
            typeof value.length == "number"
          ) {
            return value.join(",");
          } else if (templateData[fKey + "/name"]) {
            return templateData[fKey + "/name"];
          }
          return value;
        } else if (field.input_type == "CURRENCY") {
          return 0;
        } else {
          return;
        }
      } else if (field.input_type == "CURRENCY") {
        return 0;
      }
      return;
    },
    getAggregateCurrencyFormat(value, field) {
      let resultValue = "";
      if (field.selected_fields?.[0]?.validations?.currency) {
        let locale = field.validations?.locale || "en-US";
        return (resultValue = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: field.selected_fields[0].validations.currency
        }).format(value));
      } else {
        resultValue = value;
      }
      return resultValue;
    },
    async sortChange(a) {
      this.sortBy = a.prop;
      this.sortOrder = a.order == "ascending" ? "asc" : "desc";
      this.fetchAllWorkflowsTemplatesData();
      if (a.order == null) {
        this.sortBy = null;
        this.sortOrder = null;
        this.fetchAllWorkflowsTemplatesData();
      }
    },
    async loadData(data) {
      if (data?.type == "sort" && data.sort) {
        return;
      } else if (this.data && this.data.length) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllWorkflowsTemplatesData();
      } else {
        await this.fetchAllWorkflowsTemplatesData();
      }
    },
    getFlowName() {
      return this.getFormBuilder?.name;
    },
    backToFormbuilder() {
      if (this.$route.name == "ApplicaionFormbuilderDataList") {
        this.$router.go(-1);
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/fbd/${this.formbuilder_code}`;
        } else {
          UrlPathAdd = "/fbd/" + this.formbuilder_code;
        }
        this.$router.push({ path: UrlPathAdd });
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.formbuilder_code = this.$route.params.formbuilder_code;

      await this.$store.dispatch("formBuilders/fetchFormBuilderByCode", {
        code: this.formbuilder_code,
        include_template_details: true,
        include_entity_details: true,
        include_documents_details: true
      });

      this.formbuilderDetails = this.getFormBuilder;
      let entities = this.formbuilderDetails.steps.filter(
        step => step.type == "ENTITY"
      );
      let templates = this.formbuilderDetails.steps
        .filter(
          step => step.type == "TEMPLATE" && !step?.settings?.is_repeatable
        )
        .map(step => step.template);
      entities.forEach(entity => {
        let allowedTemplates = entity?.settings?.selectedTemplates
          ? entity.settings.selectedTemplates
          : [];
        if (entity?.entity?.templates) {
          entity.entity.templates.forEach(template => {
            if (
              template?.template_id?._id &&
              !template.is_repeatable &&
              allowedTemplates.indexOf(template?.template_id?._id) != -1
            ) {
              let fields = this.getTemplateFields(
                template.template_id,
                "ENTITY",
                entity.entity
              );
              this.templateFields.push({
                fields: fields,
                label: entity.entity.name + "-" + template.template_id.name,
                key: template._id,
                entity_id: entity._id
              });
              this.allFields = this.allFields.concat(fields);
            }
          });
        }
      });
      this.templateIds = templates.map(template => template._id);
      this.templateFields = [
        ...this.templateFields,
        ...templates.map(template => {
          template.fields = this.getTemplateFields(template, "TEMPLATE");
          this.allFields = this.allFields.concat(template.fields);
          template.label = template.name;
          template.key = template._id;
          return template;
        })
      ];
      this.workflowIconPath =
        this.getFormBuilder.icon_type == "DEFAULT"
          ? this.getFormBuilder.icon || "block-portfolio.svg"
          : "block-portfolio.svg";
      this.templateFields = this.templateFields.map(template => ({
        ...template,
        fields: template.fields.filter(
          field => this.contentFields.indexOf(field.input_type) === -1
        )
      }));
      await Promise.all([
        this.fetchAllWorkflowsTemplatesData(),
        this.fetchFilters()
      ]);
    },
    getTemplateFields(data, type, entity = {}) {
      let fields = [];
      data.sections.forEach(e => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map(e => {
        e.template_id = data._id;
        e.template_title = data.name;
        e.checked = true;
        e.table_display = true;
        e.type = type;
        if (type == "ENTITY" && entity?._id) {
          e.label = entity.name + " - " + data.name + " - " + e.label;
          e.data_key = entity._id + "#" + data._id + "#" + e.key;
          e.key = entity._id + "#" + data._id + "#" + e.key;
          e.entity_type_id = entity._id;
        } else {
          e.data_key = data._id + "#" + e.key;
          e.key = data._id + "#" + e.key;
          e.label = data.name + " - " + e.label;
        }
        return e;
      });
      return fields;
    },
    async fetchAllWorkflowsTemplatesData() {
      try {
        this.loading = true;
        let params = {
          page: this.currentPage,
          limit: this.pageSize > 0 ? this.pageSize : 10,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          formbuilder_details_id: this.getFormBuilder?._id,
          filters: this.mapDateFormbuilderFilters(this.formbuilderFiltersData.filters) || 
                  (this.formbuilderFiltersData?.filters?.length ? this.formbuilderFiltersData.filters : []),
        };
        let response = await postAPICall(
          "POST",
          "/formbuilder-data/all-data",
          params
        );
        if (response.data) {
          this.data = response.data;
          this.currentPage = parseInt(response.page) || 1;
          this.total = response.total || 0;
          if (this.$route?.fullPath?.includes("FILLED_BY_ME")) {
            this.data = this.data.filter(
              a => this.getAuthenticatedUser?._id === a?.contact?._id
            );
          }
        }
        this.loading = false;
      } catch (err) {
        console.log("Error while fecthing formbuilder data", err);
      }
    },
    setFormbuilderData(formbuilderData) {
      return formbuilderData.map(row => {
        if (row.formbuilder_steps_data && row.formbuilder_steps_data.length) {
          row.templates_data = row.formbuilder_steps_data
            .filter(e => e.template_data_id)
            .map(e => e.template_data_id);
        }
        return row;
      });
    },
    downloadDocumentsPopup() {
      this.downloadDocuments = true;
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    async onPreview(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path
      });
      let newWindow = open(
        this.getDownloadUrl,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function() {};
    },
    async onDownloadFile(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob"
      })
        .then(res => res.blob())
        .then(response => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError
        });
      }
    },
    openViewConfigPage() {
      (this.url_key = this.$route.fullPath), (this.copyModal = true);
    },
    async handleSizeChange(val) {
      this.loading = true;
      this.pageSize = val;
      this.currentPage = 1;
      await this.fetchAllWorkflowsTemplatesData();
      this.loading = false;
    },
    async handleCurrentChange(val) {
      this.loading = true;
      this.currentPage = val;
      await this.fetchAllWorkflowsTemplatesData();
      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
.el-table__header {
  word-wrap: break-word;
}
.Searching {
  width: 40% !important;
  float: right;
}
.custom-pagination {
  padding: 10px;
  margin-left: 27%;
}
</style>
<style scoped>
.table-scroll {
  max-height: 70vh;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin;
}
.button-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.view-button {
  background-color: #f4f6f8;
  color: #333;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
}

.edit-button {
  background-color: #e7f1ff;
  color: #007bff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
}

.delete-button {
  background-color: #ffe7e7;
  color: #dc3545;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
}

.view-button:hover,
.edit-button:hover,
.delete-button:hover {
  background-color: #d1d9e1;
  color: #333;
  transition: background-color 0.3s;
}

.inner-navbar-content.top {
  margin-top: 10px !important;
}
</style>
