<template>
    <div class="modal" v-loading="phoneCallModelLoading">
    
        <div class="modal-content">
    
            <div class="modal-header">
    
                <h3>Select an Action</h3>
    
                <button class="close-btn" @click="closeModal">&times;</button>
    
            </div>
    
            <div class="modal-body">
    <div>
  
      <!-- Email composing dialog -->
      <div  class="compose-dialog">
        <form @submit.prevent="submitForm">
          <fieldset>
            <label for="to">To:</label><br>
            <input type="email" name="to" id="to" v-model="emailData.to" ref="to">
          </fieldset>
          <fieldset>
            <label for="from">From:</label><br>
            <input type="email" name="from" id="from" v-model="emailData.from" readonly>
          </fieldset>
          <fieldset>
            <label for="message">Message:</label><br>
            <textarea name="message" id="message" v-model="emailData.message" ref="message"></textarea>
          </fieldset>
          <input type="submit" value="Send Email">
          <a href="#" @click.prevent="closeDialog" class="close-compose-dialog">Close</a>
        </form>
      </div>
    </div>
</div>
    
</div>

</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        phoneCallModelLoading:false,
        isDialogVisible: false,
        emailData: {
          to: '',
          from: 'mak@example.com',
          message: ''
        }
      };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
      submitForm() {
        alert('Email sent successfully!');
        this.isDialogVisible = false;
        this.emailData.to = '';
        this.emailData.message = '';
      }
    }
  };
  </script>
  
  <style scoped>

  
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999 !important;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    min-width: 420px !important;
    max-width: 720px !important;
    text-align: center;
}

/* Modal Header */

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.modal-header h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.hr {
    border: 1px solid #ddd;
}

/* Close Button Style */

.close-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
}

.close-btn:hover {
    color: #f00;
    /* Red color when hovered */
}

/* Modal Body */

.modal-body {
    padding-top: 10px;
    color: #555;
}
  /* Style for the component */
  .compose-button {
    background: gold;
    color: black;
    text-decoration: none;
    text-align: center;
    display: block;
    width: 200px;
    padding: 20px;
    border-bottom: 3px solid rgba(200, 200, 30, 1);
  }
  .compose-button:hover {
    background: orange;
  }
  
  .compose-dialog {
    margin: 40px 0;
  }
  
  fieldset {
    border: none;
    margin: 20px 0;
    padding: 0;
  }
  
  input[type='email'],
  textarea {
    padding: 5px;
    width: 100%;
  }
  
  textarea {
    height: 200px;
  }
  </style>
  