<template>
    <div>
      <div
        class="card mb-3"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="card-header bg-light">
          <h3 class="title fw-normal mb-0 p-2"> <img src="https://customer.vitelglobal.com/assets/img/logo.svg" style="width:10%" />&nbsp;&nbsp;
            <span v-if="companyVitelConfig&& companyVitelConfig.name" style="float:right;"> {{companyVitelConfig.name}}</span>
          </h3> 
          
        </div>
        <!-- No Data Section -->
        <el-row :gutter="12" v-if="!showTextArea && Object.keys(companyVitelConfig).length===0">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            class="table-container mt-20 ml-3 mb-10"
          >

            <div class="no-data">
              <img src="@/assets/img/nodata2.svg" alt="No Data" />
              <p>
                Please contact your administrator to enable Vitel Global on your account
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="12" v-if="!showTextArea && Object.keys(companyVitelConfig).length>0  && Object.keys(loginUserVitelConfig).length===0">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            class="table-container mt-20 ml-3 mb-10"
          >
            <!-- {{ companyVitelConfig}} -->
            <div class="no-data">
              <img src="@/assets/img/nodata2.svg" alt="No Data" />
              <p>
                Your workspace has been successfully activated with Vitel Global. To ensure uninterrupted service, please configure your extension and destination calling numbers within your account settings.
              </p>
              <div>
                <el-button type="primary" @click="createConfiguration"
                  >Add Configuration</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="12" v-if="!showTextArea && Object.keys(companyVitelConfig).length>0 && Object.keys(loginUserVitelConfig).length>0">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            class="mt-20 mb-10"
          >          
            <div style="padding:0 0.55rem;">
              <table id="customers"> 
                <tr>
                  <th>Extension ( {{loginUserVitelConfig.extension}} )</th>
                  <td>
                    <a href="#" @click.prevent="$showPhoneNumberDialog(`+${loginUserVitelConfig.countryCode}${loginUserVitelConfig.nationalPhoneNumber}`)">+{{loginUserVitelConfig.countryCode}} {{loginUserVitelConfig.formattedNationalNumber}}</a>
                  </td>
                  <td>
                    <el-switch
                        v-model="loginUserVitelConfig.isActive"
                        @change="updateStatus"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                      </el-switch>
                  </td>
                </tr>
              </table>
              <br/>
              <h3> Call logs and SMS logs Coming Soon! </h3>
              <br/>
            </div>
          </el-col>
        </el-row>
  
        <!-- Text Area Section with Buttons (Conditional Rendering) -->
        <el-row :gutter="12" v-if="showTextArea">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="8"
            :xl="8"
            class="mb-10"
            
          ></el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="8"
            :xl="8"
            class="mb-10"
            
          >

            <div class="card-form">
              <el-skeleton
                :loading="configurationLoading"
                animated
                :throttle="500"
              >
                <template slot="template">

                  <div style="padding: 14px;">
                    <el-skeleton-item variant="h3" style="width: 80%;" />
                    <div
                      style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
                    >
                      <el-skeleton-item variant="text" style="margin-right: 16px;" />
                      <el-skeleton-item variant="text" style="width: 50%;" />
                    </div>
                  </div>
                </template>
                <template>
                  <el-card shadow="hover">
                    <div><h3>Your Extension</h3></div>
                    <hr/>
                    <form @submit.prevent="onSubmit" >
                      <div class="field">
                        <label for="extension">Extension Number:</label>
                        <input type="text" v-model="form.extension" v-validate="'required|numeric'" name="extension" id="extension" />
                        <span class="error">{{ errors.first('extension') }}</span>
                      </div>
                      <div class="field">
                        <label for="destination">Destination Numbers:</label>
                        <input type="text" v-model="form.destination"  v-validate="'required|numeric|min:10|max:15'"  name="destination"  id="destination" />
                        <span class="error">{{ errors.first('destination') }}</span>
                      </div>
                      <div class="field">
                      <button type="submit">Submit</button>
                      </div>
                    </form>
                  </el-card>
                </template>
              </el-skeleton>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="8"
            :xl="8"
            class="mb-10"
            
          ></el-col>
        </el-row>

        <!-- <button @click.prevent="$showMailCompose('ravi.basolutions@gmail.com')">Mail</button> -->
      </div>
    </div>
  </template>
 
  <script>
  import { postAPICall } from "@/helpers/httpHelper";
  import { mapGetters } from "vuex";
  
  import { validateAndFormatPhoneNumber } from "@/utils/utility";
  
  export default {
    data() {
      return {
        loading: false,
        loadingText: "Loading ...",
        companyVitelConfig: {}, // Existing account
        loginUserVitelConfig : {}, // Existing account
        showTextArea: false, // Manage Extension 
        configurationLoading:false,
        form: {
            extension: '',
            destination: ''
          }
      };
    },
    async mounted() {
      await Promise.all([this.getAccounts(),this.getConfigurations()]);
    },
    computed: {
      ...mapGetters("auth", [
        "getActiveWorkspace",
        "getAuthenticatedUser",
        "getWorkspaceList",
      ]),
      ...mapGetters("UserIntegrations",["getIntegrationsList"]),
    },
    methods: {
      createConfiguration(){
        this.loading = true;
        this.loadingText = "Please wait.....";
        this.showTextArea = true;
        this.configurationLoading=true;
        this.loading = false;
        setTimeout(() => {
          // this.loading = false;
          // this.loadingText = "Please wait.....";
          this.configurationLoading=false;
        }, 2000);
      },
      async onSubmit() {
            this.$validator.validateAll().then(async (result) => {
              if (result) {
                const configuration = this.$CryptoJS.AES.encrypt(JSON.stringify(this.form),"VitelGlobal").toString();
                this.loading = true;
            this.loadingText = "Please wait.....";
            try {
              let data={
                appName:"VITEL_GLOBAL", clientSecret:configuration, isActive:true,configurationId:this.companyVitelConfig._id
              }
             let response= await postAPICall("POST",`/user_integrations/user/${this.$route.params.companyConfigId}`, data)
              if (response && response.success) {
                this.$message.success("Vital Global Configuration enabled!");
                
                setTimeout(async() => {
                  this.loading = false;
                  this.showTextArea = true;
                  this.loadingText = "Please wait.....";
                  await Promise.all([this.getAccounts(),this.getConfigurations()]);
                }, 2000);
              } else {
                this.$message.error(
                  "Failed to create Chat Bot: " + response.message
                );
              }
            } catch (error) {
              console.error(error);
              this.$message.error(
                "An error occurred while enabling the Vitel Global"
              );
            } finally {
              this.loading = false;
            }
  
              } else {
                // alert('Please correct the errors.');
                console.log('Please correct the errors.');
              }
            });
      },
      async getAccounts() {
        this.loading = true;
        this.loadingText = "Loading configurations ...";
        try {
          let response = await postAPICall("GET", "/user_integrations/list/VITEL_GLOBAL");
          if (response && response.success) {
            if(this.$route.params.companyConfigId===response.data._id)
          {
            const decrypted = this.$CryptoJS.AES.decrypt(response.data.clientSecret, 'VitelGlobal')
            const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
            this.companyVitelConfig = JSON.parse(info);
  
          //   const format = validateAndFormatPhoneNumber(this.companyVitelConfig.destination);
  
          //   if(format.isValid)
          // {
            this.companyVitelConfig={...this.companyVitelConfig,...response.data};
          // }
          // else{
          //   // this.$message.error(errorMessage);
          //   console.log(format.errorMessage)
          // }
          }
          else{
            this.$message.error("Configurations data not matched with your Owner!");
            this.loading = false;
          }
          }
        } catch (error) {
          console.error(error);
          
          this.$message.error("Invalid Configuration!");
          this.loading = false;
          
        } finally {
          this.loading = false;
        }
      },
      async getConfigurations(){ 
        this.loading = true;
        this.loadingText = "Loading User configurations ...";
        try {
          let response = await postAPICall("GET", "/user_integrations/user/VITEL_GLOBAL");
          if (response && response.success) {            
            this.loginUserVitelConfig =response.data && response.data.length?response.data[0]:{};
            const decrypted = this.$CryptoJS.AES.decrypt(this.loginUserVitelConfig.clientSecret, 'VitelGlobal')
            const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
            this.loginUserVitelConfig={...this.loginUserVitelConfig,...JSON.parse(info)}

            const format = validateAndFormatPhoneNumber( this.loginUserVitelConfig.destination);
            if(format.isValid)
            {
              this.loginUserVitelConfig  = {...this.loginUserVitelConfig,...format};
            }
          }
        } catch (error) {
          console.error(error);
          
          this.$message.error("Invalid Configuration!");
          this.loading = false;
          
        } finally {
          this.loading = false;
        }
      },
      async updateStatus(){
  
        this.loading = true;
        this.loadingText = "Updating ...";
        try {
          let response = await postAPICall("GET", `/user_integrations/user/${this.companyVitelConfig._id}/${this.loginUserVitelConfig._id}/${this.loginUserVitelConfig.isActive}`);
          if (response && response.success) {
            this.loading = true;
            this.loadingText = "Loading...";
            this.$message.success("Your Vital Global Configuration updated!");
            setTimeout(async () => {
                  this.loading = false;
                  this.loadingText = "Please wait.....";
                  await Promise.all([this.getAccounts(),this.getConfigurations()]);
            }, 1500);
          }
        } catch (error) {
          console.error(error);
          this.$message.error("Failed to update the configuration!");
          await Promise.all([this.getAccounts(),this.getConfigurations()]);
        } finally {
          this.loading = false;
  
        }
      }
    },
    beforeDestroy(){
      this.companyVitelConfig= {};
      this.loginUserVitelConfig = {};
      this.showTextArea= false;
      this.configurationLoading=false;
      this.form= {
            extension: '',
            destination: ''
          }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .no-data {
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }
  
  /* Scrollbar for textarea */
  .scrollable-textarea .el-textarea__inner {
    max-height: 200px;
    overflow-y: auto;
  }
  .card-form{
    padding:1rem;
    margin:1rem;
  }

  .form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    min-width: 360px;
  }
  .field{
    padding:0 0.35rem;
    margin:0.035rem;
  }
  .error{
    color: red;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }

  input {
    width: 100%;
    padding: 10px 0px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  input:focus {
    border-color: #007BFF;
    outline: none;
  }

  .error-message {
    color: #FF0000;
    font-size: 14px;
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  
#customers {
font-family: Arial, Helvetica, sans-serif;
border-collapse: collapse;
width: 100%;
}

#customers td, #customers th {
border: 1px solid #ddd;
padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}
/*
background-color: #04AA6D;
color: white;
*/
#customers th {
padding-top: 12px;
padding-bottom: 12px;
text-align: left;

}

  </style>
  