var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.properties.filed_content != 'Hide')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],style:(_vm.getElementStyle)},[(
      !_vm.data.properties.hideLabel || _vm.data.properties.filed_content != 'Hide'
    )?_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && !_vm.data.properties.hideLabel)?_c('label',{staticStyle:{"margin-right":"5px","font-weight":"bold"},attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 12 : 24}},[(!_vm.isView)?_c('div',[(
            _vm.data.integration_settings.selectedIntegrationFieldVariableType ===
            'select'
          )?[_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":"Select an option","disabled":_vm.checkReadonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.computedSelectValue),callback:function ($$v) {_vm.computedSelectValue=$$v},expression:"computedSelectValue"}},_vm._l((_vm.integrationVariables),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)]:(
            _vm.data.integration_settings.selectedIntegrationFieldVariableType ===
            'date'
          )?[_c('el-date-picker',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"disabled":_vm.checkReadonly(),"type":"date","format":"MM-dd-yyyy"},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}})]:(
            _vm.data.integration_settings.selectedIntegrationFieldVariableType ===
            'text'
          )?[_c('el-input',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"disabled":_vm.checkReadonly(),"suffix-icon":_vm.data.properties.el_icon},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}})]:(
            _vm.data.integration_settings.selectedIntegrationFieldVariableType ===
            'multilineText'
          )?[_c('el-input',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"disabled":_vm.checkReadonly(),"suffix-icon":_vm.data.properties.el_icon,"type":"textarea","autosize":{ minRows: 2, maxRows: 30 }},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}})]:[_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":"Select an option","disabled":_vm.checkReadonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[(
                _vm.data.is_entity_field &&
                _vm.form[_vm.data.key + '/name'] &&
                _vm.form[_vm.data.key]
              )?_c('el-option',{attrs:{"label":_vm.form[_vm.data.key + '/name'],"value":_vm.form[_vm.data.key]}}):_vm._e()],1)],(_vm.isActive || _vm.isDataTableField)?_c('span',{staticClass:"setting-icon",on:{"click":_vm.openSettings}},[_c('i',{staticClass:"el-icon-s-tools"})]):_vm._e()],2):_c('div',[_vm._v(" "+_vm._s(_vm.form[this.data.key])+" ")])])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }