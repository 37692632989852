import { render, staticRenderFns } from "./ApplicationSettings.vue?vue&type=template&id=533f2757&scoped=true"
import script from "./ApplicationSettings.vue?vue&type=script&lang=js"
export * from "./ApplicationSettings.vue?vue&type=script&lang=js"
import style0 from "./ApplicationSettings.vue?vue&type=style&index=0&id=533f2757&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533f2757",
  null
  
)

export default component.exports