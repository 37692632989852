<template>
  <section id="workflow-users-list">
    <div class="vue-data-table-default p-4">
      <!-- <el-button
        type="text"
        icon="el-icon-setting"
        @click="showSettingsDialog"
        style="margin-bottom: 16px"
        title="Configure Columns"
      >
        Configure Columns
      </el-button> -->

      <data-tables-server
        :data="data"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        @query-change="loadData"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="loadingText"
      >
        <el-table-column
          v-for="column in columns"
          :key="column.key"
          :label="column.label"
          :prop="column.key"
          :width="200"
        >
          <template #default="{ row }">
            <div v-if="column.key === 'picture'">
              <img
                :src="row[column.key]"
                alt="Image"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 8px;
                "
              />
            </div>
            <div v-else-if="column.type === 'file'">
              <el-button
                type="primary"
                @click="handleDownload(row, column)"
                size="mini"
              >
                <i class="el-icon-download"></i>
              </el-button>
              <el-button
                type="info"
                @click="handleView(row, column)"
                size="mini"
              >
                <i class="el-icon-view"></i>
              </el-button>
            </div>

            <div v-else-if="column.key === 'commentary'">
              <span v-if="!row.showFullCaption">
                {{
                  processCommentary(row[column.key])
                    .split(" ")
                    .slice(0, 10)
                    .join(" ")
                }}...
                <el-button
                  type="text"
                  @click="toggleCaption(row)"
                  style="color: #409eff"
                >
                  See More
                </el-button>
              </span>
              <span v-else>
                <span v-html="processCommentary(row[column.key])"></span>
                <el-button
                  type="text"
                  @click="toggleCaption(row)"
                  style="color: #409eff"
                >
                  See Less
                </el-button>
              </span>
            </div>

            <div v-else-if="row[column.key] && isContentLong(row[column.key])">
              <div>
                <span v-if="!row.showFullCaption">
                  {{ row[column.key].split(" ").slice(0, 10).join(" ") }}...
                  <el-button
                    type="text"
                    @click="toggleCaption(row)"
                    style="color: #409eff"
                  >
                    See More
                  </el-button>
                </span>
                <span v-else>
                  {{ row[column.key] }}
                  <el-button
                    type="text"
                    @click="toggleCaption(row)"
                    style="color: #409eff"
                  >
                    See Less
                  </el-button>
                </span>
              </div>
            </div>

            <div v-else-if="column.key === 'comments'">
              <span>
                {{ getCommentText(row) }}
              </span>
            </div>
            <div v-else>
              {{ row[column.key] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          key="actions"
          label="Actions"
          prop="actions"
          :width="200"
        >
          <template #default="{ row }">
            <el-button type="primary" size="mini" @click="viewRecord(row)">
              <i class="el-icon-view"></i>
            </el-button>
            <el-button type="primary" size="mini" @click="editRecord(row)">
              <i class="el-icon-edit"></i> </el-button
          ></template>
        </el-table-column>
      </data-tables-server>
    </div>
    <dialog-component
      :title="'Select Columns'"
      :visible="isSettingsDialogVisible"
      @before-close="isSettingsDialogVisible = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'70%'"
      :containerMinHeight="'30%'"
    >
      <el-checkbox-group v-model="selectedFields">
        <el-checkbox
          v-for="column in availableColumns"
          :key="column.key"
          :label="column.key"
        >
          {{ column.label }}
        </el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmSelection">Confirm</el-button>
        <el-button @click="isSettingsDialogVisible = false">Cancel</el-button>
      </span>
    </dialog-component>
    <dialog-component
      title="Edit"
      :visible="isEditDialogVisible"
      @before-close="isEditDialogVisible = false"
      ><editIntegrationData
        :fields="columns"
        :row="selectedRecord"
        :componentData="componentData"
        :integrationDetails="integrationDetails"
      ></editIntegrationData>
      <template #footer>
        <el-button type="primary" @click="updateRecord" size="small">
          <i class="el-icon-loading" v-if="updateLoading"></i>
          {{ updateLoading ? `${updateLoadingText}` : "update" }}</el-button
        >
      </template>
    </dialog-component>
    <dialog-component
      title="View"
      :visible="isViewDialogVisible"
      @before-close="isViewDialogVisible = false"
      ><viewIntegrationData
        :fields="columns"
        :row="selectedRecord"
      ></viewIntegrationData>
      <template #footer>
        <el-button type="primary" @click="goToEdit" size="small">
          <i class="el-icon-edit"></i> Edit</el-button
        >
      </template>
    </dialog-component>
    <div v-if="fileLoading" class="loading-container">
      <div class="loading-content">
        <img src="@/assets/downloading.gif" alt="docs" />
        <p>{{ fileLoadingText }}</p>
      </div>
    </div>
    <div v-if="fileViewLoading" class="loading-container">
      <div class="loading-content">
        <img src="@/assets/fileView.gif" alt="docs" />
        <p>{{ fileLoadingText }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";
import viewIntegrationData from "./viewIntegrationData.vue";
import editIntegrationData from "./editIntegrationData.vue";
export default {
  components: { viewIntegrationData, editIntegrationData },
  name: "IntegrationTable",
  props: {
    componentData: Object,
    integrationDetails: Object,
    customFilters: Array,
  },
  mixins: [],
  data() {
    return {
      total: 0,
      rows: [],
      currentPage: 1,
      pageSize: 0,
      data: [],
      loading: false,
      loadingText: "Loading...",
      columns: [],
      availableColumns: [],
      selectedFields: [],
      isSettingsDialogVisible: false, //
      isEditDialogVisible: false,
      isViewDialogVisible: false,
      showTable: false,
      fileLoading: false,
      fileViewLoading: false,
      fileLoadingText: null,
      selectedRecord: null,
      updateLoading: false,
      updateLoadingText: "",
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    await this.getNecessaryInfo();
    await this.fetchIntegrations();
    this.setDefaultColumns();
  },
  watch: {
    customFilters: {
      handler(newFilters) {
        this.setDefaultColumns();
        console.log("customFilters changed:", newFilters);
      },
      deep: true,
    },
    'componentData.selectedColumns': {
      handler(newSelectedColumns) {
        this.setDefaultColumns();
        console.log("selectedColumns changed:", newSelectedColumns);
      },
      deep: true,
    },
  },
  methods: {
    processCommentary(commentary) {
      if (!commentary) return "";
      const regex = /\{hashtag\|\\#\|([^}]+)\}/g;
      const formattedHashtags = [];
      const nonHashtagText = commentary.replace(regex, "").trim();
      let match;
      while ((match = regex.exec(commentary)) !== null) {
        formattedHashtags.push(`#${match[1]}`);
      }
      const resultText =
        nonHashtagText +
        (formattedHashtags.length > 0
          ? "\n" + formattedHashtags.join(" ")
          : "");
      return resultText;
    },
    setDefaultColumns() {
      if(this.componentData?.show_data_in_table && this.componentData?.selectedColumns){
        let selectedColumns=this.componentData?.selectedColumns
        this.selectedFields=selectedColumns;
      }
      // this.availableColumns =
      //   this.integrationDetails?.matchingEvents?.settings?.availableColumns ||
      //   [];
      // this.selectedFields = this.availableColumns.map((column) => column.key);
      try{
        this.fetchAllData({page:1,pageSize:10});
      }
      catch(error){
      console.log("e",error)
    }},

    showSettingsDialog() {
      this.prepareAvailableColumns();
      this.isSettingsDialogVisible = true;
    },
    prepareAvailableColumns() {
      this.availableColumns =
        this.integrationDetails?.matchingEvents?.settings?.availableColumns ||
        [];
    },
    confirmSelection() {
      this.fetchAllData();
      this.isSettingsDialogVisible = false;
    },

    isContentLong(content) {
      return content && content.length > 100;
    },
    toggleCaption(row) {
      this.$set(row, "showFullCaption", !row.showFullCaption);
    },
    getCommentText(row) {
      if (row.comments && Array.isArray(row.comments.data)) {
        return row.comments.data.map((comment) => comment.text).join(", ");
      }
      return "";
    },
    async loadData(data) {
      if (this.loading) return;
      this.loading = true;
      this.page = data.page;
      this.pageSize = data.pageSize;

      try {
        await this.fetchAllData({
          page: this.page,
          pageSize: this.pageSize,
        });
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.loading = false;
      }
    },

    async getNecessaryInfo() {
      this.prepareColumns();
      this.fetchAllData();
    },
    prepareColumns() {
      this.columns = [];
      if (this.integrationDetails?.matchingEvents?.settings?.availableColumns) {
        this.columns =
          this.integrationDetails.matchingEvents.settings.availableColumns ||
          [];
      }
    },
    async fetchIntegrations() {
      let params = {
        limit: 30,
        page: 1,
      };
      let integrationData = await postAPICall("GET", `integrations`, params);
      return integrationData;
    },
    async fetchAllData({ page, pageSize }) {
      this.loading = true;
      this.total = 0;
      let connection_id =
        this.integrationDetails?.matchedApplication?.integration
          ?.connection_id || "";
      if (!connection_id ) {
        // this.$notify({
        //   title: "Error",
        //   message:
        //     "Connection ID is missing. Please ensure the integration is properly set up.",
        //   type: "error",
        // });
        this.loading = false;
        return;
      }
      let dependencies = this.componentData.dependencies || [];
      try {
        if (!dependencies.length) {
          let savedDependencies = await this.fetchIntegrations();
          const matchingDep = savedDependencies.data.filter(
            (entry) => entry.connection_id === connection_id
          );

          if (matchingDep.length > 0) {
            dependencies = matchingDep
              .map((entry) => entry.dependencies)
              .filter((dep) => dep && Object.keys(dep).length > 0);
            dependencies = Array.from(
              new Set(dependencies.map((dep) => JSON.stringify(dep)))
            ).map((item) => JSON.parse(item));
          } else {
            console.log("No matching entries found.");
          }
        }

        const availableColumns =
          this.integrationDetails?.matchingEvents?.settings?.availableColumns ||
          [];

        // const fields =
        //   this.integrationDetails?.matchingEvents?.settings?.supportType === 'table' && availableColumns.length > 0
        //     ? availableColumns.map((e) => e.key)
        //     : undefined;
        // const fields =
        //   this.selectedFields && this.selectedFields.length > 0
        //     ? this.selectedFields
        //     : this.integrationDetails?.matchingEvents?.settings?.supportType ===
        //         "table" && availableColumns.length > 0
        //     ? availableColumns.map((e) => e.key)
        //     : undefined;
      //   const fields =
      // this.componentData.show_data_in_table &&
      // this.componentData.selectedColumns.length > 0
      //   ? this.componentData.selectedColumns
      //   : undefined;
      let fields;

if (this.componentData.show_data_in_table) {
  fields =
    this.componentData.selectedColumns.length > 0
      ? this.componentData.selectedColumns
      : undefined;
} else {
  fields =
    this.integrationDetails?.matchingEvents?.settings?.supportType === "table" &&
    availableColumns.length > 0
      ? availableColumns.map((e) => e.key)
      : undefined;
}
        const pagination = {
          page: page,
          limit: pageSize,
        };
        const dates = this.customFilters[0]?.dates
        const relevantDependencies =
          dependencies.length > 0 ? dependencies[0] : {};
        const response = await postAPICall(
          "POST",
          "integrations/service/fetch-data",
          {
            asset: this.integrationDetails?.matchingEvents?.asset,
            provider: this.integrationDetails?.matchedApplication?.key,
            // field: this.integrationDetails?.matchingEvents?.eventKey,
            connectionId: connection_id,
            dependencies:
              this.componentData.dependencies || relevantDependencies,
            // ...this.componentData.dates||this.customFilters[0].dates,
            dates,
            fields: fields,
            pagination: pagination,
          }
        );

        this.data = response.data.rows || [];
        if (this.data.length > 0) {
      this.columns = Object.keys(this.data[0]).map((key) => ({
        key,
        label: key.charAt(0).toUpperCase() + key.slice(1), 
      }));
        }
        if (this.data.length) {
          this.total = response.data.metadata.total_records || 0;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // this.$notify({
        //   title: "Error",
        //    message: "Failed to fetch data. Please try again.",
        //   type: "error",
        // });
      } finally {
        this.loading = false;
      }
    },
    async handleDownload(row, column) {
      try {
        this.fileLoadingText = "Please wait! Your file is downloading...";
        this.fileLoading = true;
        let response = await this.downloadMethod(row, column);
        this.fileLoading = false;
        let fileURL = response.data.url;
        if (response.data.type == "url") {
          let blobResponse = await fetch(fileURL, {
            method: "GET",
            responseType: "blob",
          });
          const blob = await blobResponse.blob();
          fileURL = window.URL.createObjectURL(blob);
        }
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = response.data.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.fileLoading = false;
        this.loading = false;
      }
    },
    async handleView(row, column) {
      try {
        this.fileLoadingText = "Please wait! Your file is rendering...";
        this.fileViewLoading = true;
        let response = await this.downloadMethod(row, column);
        this.fileViewLoading = false;
        let fileUrl = response.data.url;
        let fileExtension = response.data.extension;
        if (fileExtension === ".pdf") {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<embed src="${fileUrl}" type="application/pdf" width="100%" height="100%"></embed>`;
        } else if (
          [".jpg", ".jpeg", ".png", ".gif", ".bmp"].includes(fileExtension)
        ) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<img src="${fileUrl}" width="100%" height="100%" />`;
        } else if ([".mp4", ".webm", ".ogg"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<video src="${fileUrl}" width="100%" height="100%" controls></video>`;
        } else if ([".mp3", ".wav", ".ogg"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<audio src="${fileUrl}" controls></audio>`;
        } else if ([".html", ".txt", ".csv"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<iframe src="${fileUrl}" width="100%" height="100%"></iframe>`;
        } else {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = response.data.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        this.loading = false;
        this.this.fileViewLoading = false;
      }
    },
    async downloadMethod(row, column) {
      try {
        let connection_id =
          this.integrationDetails?.matchedApplication?.integration
            ?.connection_id || "";
        let requiredKeys = {};
        column.inputFields.forEach((key) => {
          requiredKeys[`${key}`] = row[key];
        });
        let payload = {
          provider: this.integrationDetails.matchedApplication.key,
          asset: this.integrationDetails.matchingEvents.asset,
          connectionId: connection_id,
          requiredKeys: requiredKeys,
        };
        let response = await postAPICall(
          "POST",
          "integrations/service/download-file",
          payload
        );
        return response;
      } catch (err) {
        return err;
      }
    },
    editRecord(row) {
      this.selectedRecord = row;
      this.isEditDialogVisible = true;
    },
    viewRecord(row) {
      const processedRow = { ...row };
      if (processedRow.commentary) {
        processedRow.commentary = this.processCommentary(
          processedRow.commentary
        );
      }
      this.selectedRecord = processedRow;
      this.isViewDialogVisible = true;
    },

    goToEdit() {
      this.isViewDialogVisible = false;
      this.isEditDialogVisible = true;
    },
    async updateRecord() {
      try {
        let payload = {
          provider: this.integrationDetails.matchedApplication.key,
          connectionId:
            this.integrationDetails.matchedApplication.integration
              .connection_id,
          asset: this.componentData.asset,
          parameters: this.componentData.dependencies,
          data: this.selectedRecord,
        };
        this.updateLoading = true;
        this.updateLoadingText = "updating...";
        let response = await postAPICall(
          "POST",
          "integrations/service/update-record",
          payload
        );
        this.updateLoading = false;
        if (response.success) {
          this.$notify.success({
            title: "Success",
            message: "Record updated successfully",
          });
          this.isEditDialogVisible = false;
        }
      } catch (err) {
        return err;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approver-text {
  color: #3366cc;
}
.list-style {
  margin-top: 10px;
}
.expired-div {
  color: #dc3545;
}
.dull-text {
  color: #888;
  opacity: 0.8;
  overflow-wrap: break-word;
  word-break: normal;
}
.log-expired {
  color: #dc3545;
}
.resend-form-style {
  width: 70px;
  height: 25px;
}
.loading-container {
  position: fixed; /* Fixed positioning to cover the entire page */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Add a semi-transparent background */
  z-index: 9999; /* Ensure it is on top of other elements */
}

.loading-content {
  text-align: center;
  color: white;
}
</style>
