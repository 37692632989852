<template>
  <div :style="getStyle" v-if="data.properties.filed_content != 'Hide'">
    <el-row align="middle">
      <span
        v-if="
          !data.properties.hideLabel || data.properties.filed_content != 'Hide'
        "
      >
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label
          for="text"
          v-if="hasLabel"
          :style="getLabelStyles"
          :class="{ 'highlight-border': highlight }"
        >
          {{ label || data.label }}
        </label>
        <span
          v-if="data.validations.required && hasLabel"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </span>
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
     

      <!-- <YouTubePlayer></YouTubePlayer> -->

      <span v-if="data.filled_by === 'RECEIVER' || data.filled_by === 'SENDER'">
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <el-input
            v-if="
              !isView &&
              data.properties.filed_content != 'Hide' &&
              data.showLink
            "
            placeholder="Enter Video Link"
            v-model="form[data.key]"

          />
          <div id="iframe" v-if="iframeURL" v-loading="loading">
            <iframe
              :style="getStyle()"
              :src="iframeURL"
              frameborder="5"
              allowfullscreen
            ></iframe>
          </div>

          <div v-else>
            <img
              alt="Avatar"
              src="../../../../src/assets/img/thumbnail.jpg"
              :height="this.data.height - 30"
              :width="this.data.width"
              class="inline-block"
            />
          </div>
          <input
            type="file"
            accept="video/*"
            ref="fileInput"
            style="display: none"
            @change="handleVideoFileUpload($event.target.files[0])"
          />
          <el-button
            v-if="
              !isView &&
              data.properties.filed_content !== 'Hide' &&
              data.filled_by === 'RECEIVER'
            "
            class="upload-file upload-button"
            @click="enableDialog"
            :loading="loading"
          >
            Upload
          </el-button>
          <el-button
            v-if="
              !isView &&
              data.properties.filed_content !== 'Hide' &&
              data.filled_by === 'RECEIVER' &&
              form[data.key]
            "
            style="background-color: ivory; max-width: 27px; margin-left: 5px"
            type="plain"
            size="mini"
            circle
            @click="deleteImage()"
          >
            <img width="12px" src="@/assets/Trash.svg" />
          </el-button>
        </el-col>
      </span>
      <span v-else>
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <div id="iframe" v-if="iframeURL">
            <iframe
              :style="getStyle()"
              :src="iframeURL"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div v-if="this.data.video_thumbnail_url">
              <img
                :height="this.data.height - 30"
                :width="this.data.width"
                :src="logoUploadUrl+this.data.video_thumbnail_url"
                alt="thumbline"
              />
            </div>
          <div v-else>
            <img
              alt="Avatar"
              src="../../../../src/assets/img/thumbnail.jpg"
              :height="this.data.height - 30"
              :width="this.data.width"
              class="inline-block"
            />
          </div>

          <input
            type="file"
            accept="video/*"
            ref="fileInput"
            style="display: none"
            @change="handleVideoFileUpload($event.target.files[0])"
          />

          <el-button
            v-if="!isView && data.properties.filed_content !== 'Hide' && !checkReadonly()"
            type="primary"
            size="mini"
            @click="enableDialog"
            :loading="loading"
            @change="applyFormRules"
            :disabled="checkReadonly()"
          >
            Upload
          </el-button>
          <el-button
            v-if="
              !isView &&
              data.properties.filed_content !== 'Hide' &&
              form[data.key] &&
              !checkReadonly()
            "
            style="background-color: ivory; max-width: 27px; margin-left: 5px"
            type="plain"
            size="mini"
            circle
            @click="deleteImage()"
          >
            <img width="12px" src="@/assets/Trash.svg" />
          </el-button>
        </el-col>
      </span>
    </el-row>

    <dialog-component
      :title="'Upload Video'"
      :visible="showDialogue"
      @before-close="closeDialog"
      :containerWidth="'500px'"
      :containerHeight="'260px'"
    >
      <div>
        <p><b>Would you like to upload a video file?</b></p>
        <p>Supported file format: <strong>.MP4</strong></p>
        <p>
          File size: <strong>{{ data.fileSize }}MB</strong>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="mini">NO </el-button>
        <el-button @click="openFileUploader" size="mini" type="primary"
          >YES
        </el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EmbedYoutubeIframeHelper from "@/mixins/EmbedYoutubeIframeHelper";
import { mapGetters } from "vuex";
// import appConfig from "@/config/app";
import { postAPICall } from "../../../helpers/httpHelper";

// import YouTubePlayer from "../../video/YouTubePlayer.vue";
// import VuePlayer from "../../vue-player/mixins/player";
// YouTubePlayer

export default {
  name: "templates-formComponentsExecute-VideoExecute",
  mixins: [userPermissionsHelper, EmbedYoutubeIframeHelper],
  components: {},
  props: ["data", "label", "form", "isView", "hasLabel", "highlight","isIconRequired","checkIsDisabled"],
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("s3FileUpload", [
      "getFileUploadVideo",
      "getFileUploadStatus",
      "getFileUploadData",
    ]),
    getYoutubeVideoSource(value) {
      return this.getYoutubeIframe(value);
    },
     getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
  },
  mounted() {
    this.setIframeUrl();
    this.previousValue = this.form[this.data.key]
  },
  data() {
    return {
      validations: [],
      iframeURL: "",
      isDefalutPos: true,
      showLabel: true,
      showDialogue: false,
      loading: false,
      retryLimit: 3,
      isUpload: false,
    };
  },
  methods: {
    enableDialog() {
      this.showDialogue = true;
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    setIframeUrl() {
      if (this.form[this.data.key]) {
      this.iframeURL = this.getYoutubeIframe(this.form[this.data.key]);
    } else if (this.form[`${this.data.key}_video`]) {
      this.iframeURL = this.getYoutubeIframe(
        this.form[`${this.data.key}_video`]
      );
    } else if (this.data.video_source_url) {
      this.iframeURL = this.getYoutubeIframe(this.data.video_source_url);
    } else {
      this.iframeURL = "";
    }
    },
    closeDialog() {
      this.showDialogue = false;
    },
    openFileUploader() {
      this.$refs.fileInput.click();
      this.showDialogue = false;
    },
    // handleVideoFileUpload(file) {
    //   const maxSizeInBytes = 10 * 1024 * 1024;
    //   if (file.size > maxSizeInBytes) {
    //     this.$alert("File size exceeds the maximum allowed size");
    //     return;
    //   }
    //   this.iframeURL = URL.createObjectURL(file);
    //   if (file && file.type.startsWith('video/')) {
    //     this.uploadVideoFile(file);
    //   } else {
    //     this.$alert("Please upload a valid video file");
    //   }
    // },
    handleVideoFileUpload(file) {
      const maxSizeInBytes = (this.data.fileSize ? this.data.fileSize : 10) * 1024 * 1024;
      if (file.size >= maxSizeInBytes) {
        this.isUpload = true;
        this.$alert(
          `File size exceeds the maximum allowed size ${this.data.fileSize}`
        );
        return;
      }
      let warningMessage = "";
      if (file.size > 4000 * 1024 * 1024) {
        warningMessage =
          "The file is large and may take 5-6 minutes to upload. You need to stay on this page during the upload. Do you want to proceed?";
      } else if (file.size > 2000 * 1024 * 1024) {
        warningMessage =
          "The file is large and may take 3-4 minutes to upload. You need to stay on this page during the upload. Do you want to proceed?";
      } else if (file.size > 1000 * 1024 * 1024) {
        warningMessage =
          "The file is large and may take 2-3 minutes to upload. You need to stay on this page during the upload. Do you want to proceed?";
      }

      if (warningMessage) {
        this.$confirm(warningMessage, "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        })
          .then(() => {
            this.processFile(file);
          })
          .catch(() => {
            this.$refs.fileInput.value = null;
          });
      } else {
        this.processFile(file);
      }
    },
    processFile(file) {
      if (file.type === "video/mp4") {
        this.$bus.$emit("uploadStarted");
        this.iframeURL = URL.createObjectURL(file);
        this.uploadVideoFile(file);
      } else {
        this.$alert("Please upload a valid video file (mp4 format).");
      }
    },
    async uploadVideoFile(file) {
      this.loading = true;
      try {
        let UploadId;
        const retryLimit = 10;
        if (file.type === "video/mp4") {
          let fileName = file.name;
          UploadId = await this.retryPostAPICall(
            "POST",
            `/UploadId`,
            { fileName },
            retryLimit
          );
          const chunkSize = 10 * 1024 * 1024;
          const totalChunks = Math.ceil(file.size / chunkSize);
          let updateAllPromises = [];
          for (let i = 0; i < totalChunks; i++) {
            const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
            const formData = new FormData();
            formData.append("chunk", chunk);
            formData.append("chunkNumber", i + 1);
            formData.append("totalChunks", totalChunks);
            formData.append("fileName", UploadId.formattedFileName);
            formData.append("UploadId", UploadId.uploadId);
            updateAllPromises.push(
              this.retryPostAPICall(
                "POST",
                `/VideoUpload`,
                formData,
                retryLimit
              )
            );
          }
          let data1 = await Promise.all(updateAllPromises);
          const filteredData = data1.map((item) => {
            return {
              ETag: item.ETag,
              PartNumber: item.PartNumber,
            };
          });

          let params = {
            Parts: filteredData,
            fileName: UploadId.formattedFileName,
            UploadId: UploadId.uploadId,
          };
          let response = await postAPICall("POST", `/combinedVideo`, params);
          if (response) {
            const videoUrl = response.data.Location;

            this.$set(this.form, this.data.key, videoUrl);
            this.isUpload = false;
            this.$bus.$emit("uploadFinished");
            this.$message.success("File uploaded successfully");
            this.loading = false;
          } else {
            this.$message.error("Error while uploading file");
          }
        }
      } catch (error) {
        console.error("Error during file upload:", error);
        this.$message.error("Error while uploading file");
      } finally {
        // Set loading to false regardless of success or failure to indicate the end of the operation
      }
    },
    async retryPostAPICall(method, url, data, retryLimit) {
      let attempt = 0;
      while (attempt < retryLimit) {
        try {
          return await postAPICall(method, url, data);
        } catch (error) {
          if (attempt === retryLimit - 1) {
            throw error;
          }
          attempt++;
          const delay = Math.pow(2, attempt) * 1500;
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }
    },
    checkReadonly() {
      if(this.checkIsDisabled){
        console.log("read",this.checkIsDisabled)
        return true;
      }
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        // if(this.data.styles.labelStyle == 'right'){
        //   this.isDefalutPos = false;
        // }
      }
      // If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.input_type == "ENTITY_VARIABLE"
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
    printvalue() {
      console.log("THIS : ", this.data);
    },
    getStyle() {
      return (
        `height:${this.data.height}px;width:${this.data.width}px;overflow:auto;` +
        this.getElementStyle
      );
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    deleteImage() {
      this.$set(this.form, this.data.key, null);
      this.iframeURL = "";
    },
  },
  watch : {
    form : {
      async handler(newVal) {
        if(this.previousValue != newVal[this.data.key]) {
          this.setIframeUrl();
          this.previousValue = newVal[this.data.key];
          this.applyFormRules();
        }
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss">
.highlight-border {
  color: red;
}
// .upload-button {
//   padding: 9px 15px !important;
//   font-size: 12px;
//   border: none !important;
//   border-radius: 5px !important;
// }
.upload-button.disabled-class {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed !important;
}
</style>
