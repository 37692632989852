<template>
  <div>
    <el-tooltip :content="'Assign ' + relationshipDetails.relationship_title">
      <el-button
        :class="isSplitView ? 'assign-btn':'right-block float-right add-buttons'"
        type="primary"
        style="padding: 6px 10px !important"
        @click="showAssignEntities"
        :disabled="
          relationshipDetails.allowOne && existedData.length ? true : false
        "
      >
        <i class="el-icon-circle-check pr-1"></i>
        <span v-if="split_col3">{{ relationshipDetails.assignLabel ? relationshipDetails.assignLabel : 'Assign' | truncate(10, "...") }}</span>
        <span v-else>{{ relationshipDetails.assignLabel ? relationshipDetails.assignLabel : 'Assign' }}</span>
       
      </el-button>
    </el-tooltip>

    <dialog-component
      :title="'Assign ' + relationshipDetails.relationship_title"
      :visible="assignEntitiesDialogVisible"
      @before-close="closeAssignModal"
      :containerMaxHeight="'90%'"
      :containerMaxWidth="'90%'"
    >
      <div v-if="relationshipDetails && relationshipDetails.permissions && relationshipDetails.permissions.assign_type && relationshipDetails.permissions.assign_type == 'detailed_view'" v-loading="optionsLoading" loading-text="Fetching records...">
        <!-- <div class="mt-1 mb-1">
          <el-input prefix-icon="el-icon-search" size="mini" style="float:right;width:250px" placeholder="search" clearable v-model="searchString" @input="search"></el-input>
        </div> -->
        <div v-if="filter && ((filter.quick_filters && filter.quick_filters.length) || relationQuickFilters.length)" class="mt-1 mb-1">
          <QuickEntityFilters
            class="ml-1"
            :filterFields="
              (filter && filter.quick_filters && filter.quick_filters.length) || relationQuickFilters.length
                ? [...filter.quick_filters,...relationQuickFilters]
                : []
            "
            :allFields="entityFields"
            :selectedFileds="selectedFileds"
            @quick-filter-updated="quickFilterUpdated"
          ></QuickEntityFilters>
        </div>
        <el-table :data="childEntitiesData" style="width: 100%;" border stripe class="sidemenu mt-3 mb-3" ref="multipleEntityTable" size="mini" 
        @selection-change="handleSelectionChange" @select="checkSingleSelect" @select-all="checkMultiSelect" max-height="400">
          <el-table-column type="selection" width="40"> </el-table-column>
          <el-table-column :width="step.width ? step.width : 200" v-for="(step, index) of tableColums"
            :label="step.label" :key="step.id + index" :prop="getPropValue(step)" sortable="custom">
            <template slot-scope="scope">
              <div class="d-flex">
                <div class="pl-15" v-if="scope.row.entityData &&
                  scope.row.entityData[step.template_id] &&
                  scope.row.entityData[step.template_id][`${step.id}_code`] &&
                  scope.row.entityData[step.template_id][`${step.id}_code`]
                    .formatInternational
                ">
                  {{
                    scope.row.entityData[step.template_id][`${step.id}_code`]
                      .formatInternational
                  }}
                </div>

                <div class="pl-15" v-else-if="scope.row.entityData &&
                  scope.row.entityData[step.template_id] &&
                  scope.row.entityData[step.template_id][
                  `${step.id}_currency`
                  ] &&
                  scope.row.entityData[step.template_id][
                  `${step.id}_currency`
                  ]
                ">
                  <span v-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'USD'
                  ">
                    {{
                      Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'INR'
                  ">
                    {{
                      Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'EUR'
                  ">
                    {{
                      Intl.NumberFormat("en-IE", {
                        style: "currency",
                        currency: "EUR",
                    }).format(parseFloat(num).toFixed(2))
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'CAD'
                  ">
                    {{
                      Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "CAD",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'AUD'
                  ">
                    {{
                      Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "AUD",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'MXN'
                  ">
                    {{
                      Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'ZAR'
                  ">
                    {{
                      Intl.NumberFormat("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'MYR'
                  ">
                    {{
                      Intl.NumberFormat("ms-MY", {
                        style: "currency",
                        currency: "MYR",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                  <span v-else-if="scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] === 'GBP'
                  ">
                    {{
                      Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      }).format(scope.row.entityData[step.template_id][step.id])
                    }}
                  </span>
                </div>
                <div v-else-if="step && step.type == 'DATE'">
                  {{ getFieldValue(scope.row, step) | globalDateFormat }}
                </div>
                <div v-else-if="step && step.type == 'DATE_TIME'">
                  {{
                    getFieldValue(scope.row, step)
                    | moment("MM-DD-YYYY hh:mm:ss A")
                  }}
                </div>
                <div v-else-if="step && step.type == 'FILE'">
                  <div
                        v-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length <= 1
                        "
                      >
                        <el-button
                          type="text"
                          style="margin-top: -14px"
                          @click="
                            downloadFile(getFieldValue(scope.row, step)[0])
                          "
                          class="mb-1"
                        >
                          <p style="font-size: 13px">
                            Download <i class="el-icon-download"></i>
                          </p>
                        </el-button>
                      </div>
                      <div
                        class="d-flex"
                        v-else-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length >= 1
                        "
                      >
                        <p
                          v-for="(file, index) of getFieldValue(
                            scope.row,
                            step
                          )"
                          :key="index"
                        >
                          <el-button
                            type="text"
                            style="margin-top: -10px"
                            v-if="file && file.name && index < fileLimit"
                            @click="
                              downloadFile(getFieldValue(scope.row, step)[0])
                            "
                          >
                            {{ file.name | truncate(10, "...") }}
                            <i class="el-icon-download"></i>
                          </el-button>
                        </p>
                        <el-tag
                          style="cursor: pointer; width: 40px"
                          class="ml-1"
                          :value="getFieldValue(scope.row, step).length"
                          v-if="getFieldValue(scope.row, step).length > 1"
                          @click="downloadDocumentsPopup(scope.row, step)"
                        >
                          +{{ getFieldValue(scope.row, step).length - 1 }}
                        </el-tag>
                      </div>
                </div>
                <div v-else-if="step &&
                  step.type == 'DATE_TIME_RANGE' &&
                  scope.row.entityData[step.template_id] &&
                  scope.row.entityData[step.template_id][`${step.id}_from`]
                ">
                  {{
                    scope.row.entityData[step.template_id][`${step.id}_from`]
                    | moment("MM-DD-YYYY hh:mm:ss A")
                  }}
                  (From ~ To)
                  {{
                    scope.row.entityData[step.template_id][`${step.id}_to`]
                    | moment("MM-DD-YYYY hh:mm:ss A")
                  }}
                </div>
                <div v-else-if="step && step.type == 'TIME'">
                  {{ getFieldValue(scope.row, step) | moment("hh:mm") }}
                </div>
                <div v-else-if="typeof getFieldValue(scope.row, step) == 'object' &&
                  getFieldValue(scope.row, step).lengths
                ">
                  {{ getFieldValue(scope.row, step).join(",") }}
                </div>
                <div v-else-if="step && step.type == 'AGGREGATE_FUNCTION'">
                  <p style="font-size: 13px">
                    {{
                      getCurrencyFormat(
                        getFieldValue(scope.row, step),
                        step.template_id + "#" + step.id
                    )
                    }}
                  </p>
                </div>
                <div v-else-if="step && (step.type == 'SIGNATURE' || step.inputType == 'SIGNATURE')">
                  <img v-if="getFieldValue(scope.row, step)" :src="getFieldValue(scope.row, step)" alt="IMG" width="100"
                    height="60" />
                  <p v-else>-</p>
                </div>
                <div class="pl-15" v-else>
                  {{ getFieldValue(scope.row, step) }}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-loading="loading" class="popup" v-else>
        <span
          v-if="
            childPrimaryFields &&
            childPrimaryFields[0] &&
            childPrimaryFields[0].label
          "
          style="margin-top: 40%;"
        >
          {{ childPrimaryFields[0].label }}
        </span>
        <el-select
          v-model="selectedChildEntitiesData"
          multiple
          filterable
          collapse-tags
          :multiple-limit="relationshipDetails.allowOne ? 1 : 0"
          :loading="optionsLoading"
          loading-text='Loading...'
          :remote-method="searchEntityData"
          remote
          @change="checkForSelectedData"
          no-data-text="No records"
        >
          <el-option
            v-for="entityData of childEntitiesData"
            :key="entityData._id"
            :label="entityData.entity_display_data"
            :value="entityData._id"
          >
          </el-option>
          <el-option
            value="LOADMORE"
            label="Load more..."
            v-if="!isSearching && hasMore"
          >
          </el-option>
        </el-select>
        <span class="qrCodeBlock" v-if="childPrimaryFields && childPrimaryFields[0] && childPrimaryFields[0].properties && childPrimaryFields[0].properties.qr_code_scanning">
          <el-popover  placement="top-start" trigger="hover"
            :content="qrDescription">
            <i class="el-icon-camera size-2em" slot="reference" @click="enableQRReader"></i>
          </el-popover>
        </span>

        <p v-if="getErrors && Object.keys(getErrors).length">
          {{ getErrors }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAssignModal"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          :disabled="selectedChildEntitiesData.length == 0"
          @click="assignEntitiesData"
          >Assign {{ relationshipDetails.relationship_title }}</el-button
        >
      </span>
    </dialog-component>

    <dialog-component
        v-loading="qrLoader"
        :title="modalTitle"
        :visible="showQrReader"
        @before-close="cancelQRPopup"
        :containerWidth = "'50vw'"
        :containerMaxHeight = "'70vh'"
      >
      <QRReader v-if="showQrReader" :data="data"
      :form="form" :isFromRelations="true" :relationResultField="selectedChildEntitiesData" :allowMultiple="relationshipDetails.allowOne ? true : false"
      @before-close="cancelQRPopup" />
      </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import AutomationHelper from "@/mixins/AutomationHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
export default {
  components: {
    QRReader:()=>import("../templates/formComponentsExecute/QR_Reader.vue"),
    QuickEntityFilters: () =>import("@/components/widgets/entityQuickFilters.vue"),
  },
  mixins : [CustomDashboardConfig, AutomationHelper, EntityHelper,templateBuilderHelper],
  props: [
    "split_col3",
    'isSplitView',
    "relationshipDetails",
    "existedData",
    "unAssignedFieldDetails",
    "childEntityDataById",
    "totalRecords",
    "currentEntityData",
    "filter",
    "relationQuickFilters"
  ],
  data() {
    return {
      assignEntitiesDialogVisible: false,
      childEntitiesData: [],
      selectedChildEntitiesData: [],
      childPrimaryFields: [],
      loading: false,
      realtionshipFilter: {},
      limit: 500,
      fileLimit : 1,
      page: 1,
      optionsLoading: false,
      hasMore: false,
      isSearching: false,
      search_string: "",
      /* QR Scanner fields start  **/
      qrDescription:'Scan QR/ Barcode',
      showQrReader:false,
      modalTitle:'Scan Here',
      qrLoader:false,
      /* QR Scanner fields  end **/
      tableColums : [],
      selectedEntityFields : [],
      entityFields : [],
      searchString : '',
      searchedData : [],
      selectedFileds : {},
    };
  },
  mounted() {
    if (this.relationshipDetails?.permissions?.assign_type == 'detailed_view') {
      this.setTableColumns()
      if (this.relationshipDetails?.filter != '') {
        if (![undefined, null].includes(this.filter)) {
          if(this.filter.quick_filters.length){
            if (this.childEntityDataById && this.childEntityDataById.templates) {
            this.childEntityDataById.templates.forEach((e) => {
              if (e && e.templateInfo && !e.is_repeatable) {
                this.entityFields = [
                  ...this.entityFields,
                  ...this.applyCustomizationOnFields(
                    this.getTemplateFields(e.templateInfo, true, true),
                    e.customization,
                    e
                  ).map((f) => {
                    if (f?.key && !f.key.includes("#")) {
                      f.key = f.template_id + "#" + f.key;
                    }
                    return f;
                  }),
                ];
              }
            });
          }
          this.setRelationshipFields();
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityRecordsForTable",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    ...mapGetters("filters", ["getAllEntityFilters", 'getSingleFilterData']),
    ...mapGetters('entityRelationships',['getEntityRelationships'])
  },
  methods: {
    async setRelationshipFields(){
      await this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
        entity_id: this.childEntityDataById._id,
      })
      if(this.getEntityRelationships && this.getEntityRelationships.length){
        let count = 0;
        let existedFilters = this.entityFields
          .filter((e) => e.entity_id)
          .flatMap((e) => e.entity_id);
        this.getEntityRelationships.forEach((rl) => {
          let isChild = rl.child_entity_id === this.childEntityDataById._id ? false : true;
          let rlEntityId = isChild ? rl.child_entity_id : rl.parent_entity_id;
          if (existedFilters.indexOf(rlEntityId) === -1) {
            this.entityFields.push({
              input_type: "RELATION",
              inputType: "RELATION",
              entity_id: rlEntityId,
              key: "self#related_to/" + (count + 1),
              label:
                `With #` +
                (isChild ? rl.child_relation_title : rl.parent_relation_title),
              section_name: "Relation",
              template_name: "Relation",
              data_type: "RELATION",
              validations: {
                data_type: "RELATION",
              },
            });
            count++;
          }
        });
      }
    },
    quickFilterUpdated(data){
      this.fetchChildEntitesData(data)
    },
    getPropValue(step) {
      if (step.type == "ENTITY") {
        return step.template_id + "#" + step.id + "/name";
      }
      return step.template_id + "#" + step.id;
    },
    setTableColumns(){
      this.selectedEntityFields = this.getAllFieldsFromEntity(
        this.childEntityDataById,
        false
      )
      this.selectedEntityFields = this.selectedEntityFields.map((data) => {
        if (data.key && data.key.includes("#")) {
          data.key = data.key.split("#")[1];
        }
        return data;
      });
      if(this.childEntityDataById && this.childEntityDataById.settings && this.childEntityDataById.settings.length){
        this.tableColums = this.childEntityDataById.settings
      }
      else{
        this.childEntityDataById?.primaryFields.forEach((data) => {
        if (data.inputType == "DATA_TABLE" || data.inputType == 'ENTITY_TABLE') {
          // this.dataTableFields.push(data);
        } else {
          let k = data.key;
          if (data.key && data.key.includes("#")) {
            k = data.key.split("#")[1];
          }
          this.tableColums.push({
            label: data.label,
            id: k,
            template_id: data.template_id,
            type: data.input_type,
            inputType: data.inputType,
          });
        }
      });
      }
    },
    search(){

    },
    handleSelectionChange(rows) {
      this.selectedChildEntitiesData = rows.map(e=> e._id);
    },
    checkMultiSelect() {
      if (this.relationshipDetails.allowOne) {
        this.$refs.multipleEntityTable.clearSelection();
        this.$message({
          message : "You are allowed to select only one " + this.relationshipDetails.relationship_title ,
          type : "warning"}
        );
      }
    },
    checkSingleSelect(val) {
      if (this.relationshipDetails.allowOne && val && val.length > 1) {
        this.$refs.multipleEntityTable.toggleRowSelection(val[0]);
      }
    },
    checkForSelectedData(data) {
      if (data == "LOADMORE") {
        this.page++;
        this.selectedChildEntitiesData = [];
        this.fetchChildEntitesData();
        return;
      }
    },
    searchEntityData(query) {
      if (query) {
        this.isSearching = true;
        if (query.length > 2) {
          this.search_string = query;
          this.page = 1;
          this.fetchChildEntitesData();
        }
      } else {
        this.isSearching = false;
        this.search_string = "";
        this.page = 1;
        this.fetchChildEntitesData();
      }
    },
    async showAssignEntities() {
      if (
        !this.relationshipDetails.parent_entity_data_id ||
        !this.relationshipDetails.parent_entity_data_id.length
      ) {
        let msg = this.relationshipDetails?.parent_relation_title ? 'Please add the primary details of ' + this.relationshipDetails.parent_relation_title : 'Please add the primary details of current entity';
        this.$message({
          message: msg,
          type: "warning",
        });
        return;
      }
      if(this.relationshipDetails && this.relationshipDetails.apply_limit && this.relationshipDetails.limit_type){
        if(this.relationshipDetails.limit_type == 'value'){
          if(this.totalRecords >= this.relationshipDetails.limit_value){
            this.$notify.error({
              message : this.relationshipDetails.warning_message ? this.relationshipDetails.warning_message : "You have reached the limit",
              title : 'Error'
            })
            return
          }
        }
        else if(this.relationshipDetails.limit_type == 'field'){
          let limitRelatedTemplateData = this.currentEntityData?.templates_data.find(dt => dt.template_id.toString() == this.relationshipDetails.limit_field.split('#')[0].toString())
          if(limitRelatedTemplateData !== undefined){
            let value = limitRelatedTemplateData?.template_data_id?.template_data[this.relationshipDetails.limit_field.split('#')[1]]
            if(value && this.totalRecords >= value){
              this.$notify.error({
              message : this.relationshipDetails.warning_message ? this.relationshipDetails.warning_message : "You have reached the limit",
              title : 'Error'
            })
            return
            }
          }
          
        }
      }
      this.childPrimaryFields = this.childEntityDataById.primaryFields.map(
        (e) => {
          return {
            input_type: e.input_type,
            key: e.key,
            template_id: e.template_id,
            label: e.label,
            validations:e.validations,
            properties:e.properties
          };
        }
      );
      this.assignEntitiesDialogVisible = true;
      await this.fetchChildEntitesData();
    },
    getSearchPrimaryFields(index) {
      let result = [];
      if (
        this.childEntityDataById?.primaryFields?.[index]?.template_id &&
        this.childEntityDataById?.primaryFields?.[index]?.key
      ) {
        result = [
          this.childEntityDataById?.primaryFields?.[index]?.template_id +
            "." +
            this.childEntityDataById?.primaryFields?.[index]?.key,
        ];
      }
      return result;
    },
    async fetchChildEntitesData(flt = []) {
      this.loading = false;
      this.optionsLoading = true;
      this.hasMore = false;
      // let id = this.$route.params.entity_id;
      let filters = this.filter !== null && this.filter !== undefined && this.filter.filters.length ? this.filter.filters : []
      let params = {
        entity_id: this.relationshipDetails.relationship_id,
        template_fields_data: [],
        filters: [...flt,...filters],
        limit: this.limit,
        page: this.page,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
        params.primary_search_fields = this.getSearchPrimaryFields(0);
      }

      if (this.unAssignedFieldDetails && this.unAssignedFieldDetails.length) {
        params.not_existed_fields = this.unAssignedFieldDetails;
      }

      this.setCurrentEntityFilters();
      if (this.realtionshipFilter && this.realtionshipFilter.parent_entity_id) {
        params.relationship = this.realtionshipFilter;
      }

      //TODO: Add Search on primary fields with pagination on scroll
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      // await this.$store.dispatch("entities/fetchEntityDataById", {
      //   id: this.relationshipDetails.relationship_id,
      //   params,
      // });
      this.loading = false;
      this.optionsLoading = false;
      if (this.getEntityRecordsForTable) {
        this.hasMore = this.getEntityRecordsForTable.hasMore;
        if (this.isSearching || flt.length) {
          this.childEntitiesData = [];
        }
        this.childEntitiesData = [
          ...this.childEntitiesData,
          ...this.getEntityRecordsForTable.data,
        ];
        if (this.childEntitiesData && this.childEntitiesData[0]) {
          this.childEntitiesData = this.childEntitiesData.map((e, i) => {
            return {
              entityData: e.entityData,
              _id: e._id,
              entity_prime_data: e.entity_prime_data,
              entity_display_data: this.getLabel(e, i),
            };
          });

          let existedIds = this.existedData;

          this.childEntitiesData = this.childEntitiesData.filter((e) => {
            if (existedIds.find((x) => x == e._id)) {
              return false;
            } else {
              return true;
            }
          });
          this.searchedData = this.childEntitiesData
        }
      }
    },

    getLabel(item, i) {
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : i
          ? "data " + i
          : "-";
      if (this.childPrimaryFields) {
        let primaryField = this.childPrimaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;

        if (key) {
          if (item && item.entity_prime_data && item.entity_prime_data[key]) {
            name =
              item.entity_prime_data[key + "/name"] ||
              item.entity_prime_data[key];
          } else {
            name =
              item &&
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id] &&
              item.entityData[primaryField.template_id][key]
                ? item.entityData[primaryField.template_id][key + "/name"] ||
                  item.entityData[primaryField.template_id][key]
                : "-";
          }
        }
      }
      return name;
    },

    async assignEntitiesData() {
      this.loading = true;
      try {
        let data;
        if (this.relationshipDetails.owner_type == "PARENT") {
          data = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: this.selectedChildEntitiesData,
          };
        } else {
          if (this.relationshipDetails.parent_relation_type == "MANY") {
            data = {
              parent_entity_id: this.relationshipDetails.child_entity_id,
              child_entity_id: this.relationshipDetails.parent_entity_id,
              parent_entity_data_id:
                this.relationshipDetails.parent_entity_data_id,
              child_entity_data_ids: this.selectedChildEntitiesData,
            };
          } else {
            data = {
              parent_entity_id: this.relationshipDetails.parent_entity_id,
              child_entity_id: this.relationshipDetails.child_entity_id,
              parent_entity_data_id: this.selectedChildEntitiesData[0],
              child_entity_data_ids: [
                this.relationshipDetails.parent_entity_data_id,
              ],
            };
          }

          if (
            this.relationshipDetails.representation == "FIELD" &&
            this.unAssignedFieldDetails
          ) {
            data.assign_field_data = [];

            this.selectedChildEntitiesData.forEach((entity_data_id) => {
              this.unAssignedFieldDetails.forEach((field) => {
                data.assign_field_data.push({
                  ...field,
                  entity_data_id,
                });
              });
            });
          }
        }

        await this.$store.dispatch(
          "entityRelationships/assignEntityRelationshipData",
          data
        );

        if (this.getEntityRelationshipDataAssignStatus) {
          this.$message.success(
            `${this.relationshipDetails.relationship_title} data added successfully`
          );

          this.$emit("entityDataAssigned");
          this.assignEntitiesDialogVisible = false;
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    setCurrentEntityFilters() {
      if (
        this.relationshipDetails &&
        this.relationshipDetails.child_relation_type == "ONE" &&
        this.relationshipDetails.parent_relation_type == "ONE"
      ) {
        if (this.relationshipDetails.owner_type == "PARENT") {
          this.realtionshipFilter = {
            parent_entity_id: this.relationshipDetails.child_entity_id,
            child_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_id: this.relationshipDetails.parent_entity_id,
            relation_type: "ONE_ONE",
          };
        } else {
          this.realtionshipFilter = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            relation_type: "ONE_ONE",
          };
        }
      }

      if (
        this.relationshipDetails.owner_type == "PARENT" &&
        this.relationshipDetails.parent_relation_type == "MANY" &&
        this.relationshipDetails.child_relation_type == "ONE"
      ) {
        this.realtionshipFilter = null;
      }

      if (
        this.relationshipDetails.owner_type == "PARENT" &&
        this.relationshipDetails.parent_relation_type == "ONE" &&
        this.relationshipDetails.child_relation_type == "MANY"
      ) {
        this.realtionshipFilter = {
          parent_entity_id: this.relationshipDetails.child_entity_id,
          child_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id: this.relationshipDetails.parent_entity_id,
          relation_type: "MANY_ONE",
        };
      }

      if (
        this.relationshipDetails.owner_type == "PARENT" &&
        this.relationshipDetails.parent_relation_type == "MANY" &&
        this.relationshipDetails.child_relation_type == "ONE"
      ) {
        this.realtionshipFilter = null;
      }

      if (
        this.relationshipDetails.owner_type == "CHILD" &&
        this.relationshipDetails.parent_relation_type == "MANY" &&
        this.relationshipDetails.child_relation_type == "ONE"
      ) {
        this.realtionshipFilter = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          relation_type: "MANY_ONE",
        };
      }

      if (
        this.relationshipDetails.owner_type == "CHILD" &&
        this.relationshipDetails.parent_relation_type == "ONE" &&
        this.relationshipDetails.child_relation_type == "MANY"
      ) {
        this.realtionshipFilter = null;
      }
    },

    async addNewData() {
    },
    closeAssignModal(){
      this.childEntitiesData = [];
      this.assignEntitiesDialogVisible = false
    },
    assingClosed() {
      this.childEntitiesData = [];
      this.selectedChildEntitiesData = [];
    },
    enableQRReader(){
      this.qrLoader=true;
      this.showQrReader = true;
      setTimeout(() => {
        this.qrLoader=false;
      }, 1500);
    },
    cancelQRPopup() {
      this.loading=true;
      this.showQrReader=false;
      navigator.mediaDevices.getUserMedia({ video: true })
      navigator.mediaDevices.getUserMedia({video: true, audio: false})
                .then(mediaStream => {
                    const stream = mediaStream;
                    const tracks = stream.getTracks();
                    tracks.forEach(track => track.stop())
                })
      setTimeout(() => {
        this.loading=false;
      }, 1500);
    }
  },
  watch: {
    existedData(newValue) {
      // ...
      console.log({ newValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.assign-btn{
  background-color:white;
  color: var(--primary-color);
  height: 32px;
  margin-bottom: 20px;
  margin-left: 10px !important;
  &:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    border-color: var(--primary-color);
    border: 1.5px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 32px;
  }
}
.add-buttons {
  border: 1.5px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  margin-bottom:10px;
  height: 35px;
  color: var(--primary-contrast-color);
  &:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    border-color: var(--primary-color);
  }
}
.popup{
  padding: 4%;
}
.qrCodeBlock{
  text-align: center;
  padding: 0.2rem;

  .size-2em{
    font-size: 2em !important;
  }
}
</style>
