<template>
  <div>
    <div v-loading="loading" :element-loading-text="loadingText">
      <el-card class="main-chart-container">
        <div class="entity-navbar">
          <div class="left">
            <p class="entity-title">{{ getFieldLabel }}</p>
          </div>
          <div class="right d-flex">
            <div v-if="quickAddEntity">
              <quickEntityAddWidget
                class="mt-1 mr-1"
                :currentEntity="quickentityData"
                :parentForm="form"
                :parentFields="fieldsData"
              ></quickEntityAddWidget>
            </div>
            <el-select
              v-model="showFields.board_field"
              placeholder="Select board field"
              @change="setCurrentField()"
              style="width: 100%"
            >
              <el-option
                v-for="(field, i) in getEntityFieldsByType([
                  'SELECT',
                  'ENTITY',
                ])"
                :key="field.key + 'chart' + i"
                :label="field.label"
                :value="field.key"
                >{{ field.label }}</el-option
              >
            </el-select>
          </div>
        </div>
        <div class="chart-main-container">
          <el-scrollbar>
            <div
              class="pie-body"
              v-if="getSelectedFieldsOptions && getSelectedFieldsOptions.length"
            >
              <div
                v-for="(col, i) in getSelectedFieldsOptions"
                :key="i + 'column' + col"
                class="board-column"
                style="padding: 10px; border-bottom: 1px solid #e0e0e0"
              >
                <div style="font-weight: bold; font-size: 14px; color: #333">
                  {{ getSelectedFieldEntityLabels[i] || getSelectedFieldLabels[i] | truncate(30, "...") }}
                </div>
                <div
                  v-if="currentEntity.templates && showFields.take_value_from"
                  class="board-column-head mt-2"
                >
                  <div style="font-size: 12px; color: #666; margin-top: 4px">
                    {{ getFormattedTotalAmount(col) }} · {{ col.length }}
                    {{ getEntityName() }}
                  </div>
                </div>
                <draggable
                  :list="col"
                  style="
                    height: 55vh;
                    overflow-y: auto;
                    scrollbar-width: thin;
                    padding: 0 10px 0 0;
                    overflow-x: hidden;
                  "
                  group="people"
                  :disabled="!checkPerimission('EDIT')"
                >
                  <div
                    v-for="data in col"
                    :key="data._id"
                    class="card mb-2"
                    @drop="checkEntityDataSwipe(data, i)"
                  >
                    <div class="card-body container d-flex flex-row">
                     
                      <div class="col-6" style="padding-right: 25px;">
                      <h5
                        v-if="entityFields"
                        @click="viewWorkUserData(data)"
                        style="margin-right: 8px"
                      >
                        {{ getLabel(data) | truncate(20, "...") }}
                      </h5>
                      <div class="card-text">
                        <div
                          v-for="row in showFields.BoardViewFields"
                          :key="row"
                          class="mb-2"
                        >
                          <!-- <el-tooltip
                            class="item"
                            effect="dark"
                            :content="getFieldLabels(row)"
                            placement="top"
                          > -->
                          <span class="bold-key"
                            >{{ getFieldLabels(row) }} </span
                          >:
                          <span>{{
                            getFieldValue(row, data) | truncate(22, "...")
                          }}</span>
                          <!-- </el-tooltip> -->
                        </div>
                      </div>
                      <template
                        v-if="
                          showFields.Board_action_group_button &&
                          showFields.Board_action_group_button.length
                        "
                      >
                        <div class="mt-3">
                          <div
                            v-for="(
                              action, index
                            ) in showFields.Board_action_group_button"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              class="card-link text-primary"
                              @click="
                                onActionButtonOpen(
                                  {
                                    template_id: action.split('#')[0],
                                    id: action.split('#')[1],
                                  },
                                  data
                                )
                              "
                            >
                              {{ getActionField(action) }}
                            </a>
                          </div>
                        </div>
                      </template>
                      </div>
                      <div class="iconsContainer"  v-if="showFields.enableOtherOptions">
                      <el-dropdown
                        v-if="showFields.enableOtherOptions"
                        :class="dropdownPositionClass"
                        style=" top: 5px;"
                      >
                        <span class="el-dropdown-link">
                          <i
                            class="el-icon-more"
                            style="
                              transform: rotate(90deg);
                              align-items: center;
                              font-size: 14px;
                              margin-top: 5px;
                              font-wight: bold;
                            "
                          ></i>
                        </span>
                      
                        <el-dropdown-menu slot="dropdown">
                          <a
                            :underline="false"
                            @click="duplicateEntityData(data)"
                            v-if="
                              checkPerimission('DUPLICATE') &&
                              currentEntity &&
                              currentEntity.entity_type != 'INDIVIDUAL'
                            "
                          >
                            <el-dropdown-item
                              icon="el-icon-document-copy"
                              v-if="checkButtonVisible('Duplicate')"
                              >Duplicate</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="editWorkUserData(data)"
                            v-if="
                              checkButtonVisible('Edit') &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Edit'
                                )
                              ) &&
                              checkRolePermission('editEntitiesData')
                            "
                          >
                            <el-dropdown-item icon="el-icon-edit"
                              >Edit</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="viewWorkUserData(data)"
                            v-if="
                             
                              customization != null &&
                              checkButtonVisible('View') &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'View'
                                )
                              ) &&
                              checkRolePermission('viewEntitiesData')
                            "
                          >
                            <el-dropdown-item icon="el-icon-view"
                              >View</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="$emit('open-attachments', data)"
                            v-if="
                              // hasAttachment &&
                              checkPerimission('Attachments') &&
                              checkButtonVisible('Attachments') &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Attachments'
                                )
                              )
                            "
                          >
                            <el-dropdown-item icon="el-icon-folder"
                              >Attachment</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="$emit('open-SendNotification', data)"
                            
                            v-if="
                              checkPerimission('SEND NOTIFICATION') &&
                              checkButtonVisible('Send Notification') &&
                             
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Send Notification'
                                )
                              )
                            "
                          >
                            <el-dropdown-item icon="el-icon-s-promotion"
                              >Send Notification</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="$emit('open-WASendNotification', data)"
                            v-if="
                              checkPerimission('SEND NOTIFICATION') &&
                              checkButtonVisible('Send Notification') &&
                              IframePermissions(
                                'SEND NOTIFICATION',
                                'ViewEntityData'
                              ) &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Send Notification'
                                )
                              )
                            "
                          >
                            <el-dropdown-item
                              ><i class="el-icon-chat-dot-round"></i>Send WA
                              Notification</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="onEntityDataDelete(data)"
                            :underline="false"
                            v-if="
                              checkPerimission('DELETE') &&
                              IframePermissions('DELETE', 'ViewEntityData') &&
                              checkButtonVisible('Delete') &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Delete'
                                )
                              ) &&
                              checkRolePermission('deleteEntitiesData')
                            "
                          >
                            <el-dropdown-item icon="el-icon-delete"
                              >Delete</el-dropdown-item
                            >
                          </a>
                          <a
                            v-for="(action, index) in nonPrimaryActions"
                            :key="index"
                            @click="onAction(action, data)"
                            :underline="false"
                          >
                          <el-dropdown-item style="display: flex; align-items: center; gap: 2px;">
                            <i
                              v-if="!action.icon"
                              :class="fetchActionIcon(action)"
                              style="font-size: 16px;"
                            ></i>
                            <icons
                              v-else
                              :iconName="action.icon"
                              style="font-size: 14px;margin-top:10px"
                            ></icons>
                            <span>{{ action.label }}</span>
                          </el-dropdown-item>
                          </a>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <template
                        v-if="
                          (customization &&
                            customizationButtons &&
                            customizationButtons.length) ||
                          (customization != null &&
                            customization.actions &&
                            customization.actions.length &&
                            getIframeUrlInfo == null)
                        "
                       
                      >
                      <div  class="iconsContainer"  >
                        <button
                        v-for="(action, index) in primaryActions"
                        :key="index"
                        class="icon-circle btn btn-light btn-sm mt-2 mb-1"
                        :style="{ backgroundColor: getBackgroundColor(action, data) }"
                        @click="onAction(action, data)"
                    >
                        <el-tooltip
                            placement="top"
                            :content="getTooltipContent(action, data)"
                            style="align-items: center;"
                        >
                            <template v-if="!action.icon">
                                <i :class="fetchActionIcon(action)" style="font-size: 14px;"></i>
                            </template>
                            <template v-else>
                                <icons :iconName="action.icon" style="font-size: 14px; margin-top: 10px;"></icons>
                            </template>
                        </el-tooltip>
                    </button>
                        <button
                          class="btn btn-light btn-sm m-lr-1"
                          type="button"
                          v-for="button in getButtonsList(customizationButtons)"
                          :key="button"
                        >
                          <span
                            v-if="
                              button === 'Edit' &&
                              checkRolePermission('editEntitiesData')
                            "
                            class="text-muted"
                            title="Edit"
                            @click="editWorkUserData(data)"
                            ><i class="el-icon-edit"></i
                          ></span>
                          <span
                            v-else-if="
                              button === 'View' &&
                              checkRolePermission('viewEntitiesData')
                            "
                            class="text-muted"
                            title="View"
                            @click="viewWorkUserData(data)"
                            ><i class="fa fa-eye"></i
                          ></span>
                          <span
                            v-else-if="
                              button === 'Delete' &&
                              checkRolePermission('deleteEntitiesData')
                            "
                            class="text-muted"
                            title="Delete"
                            @click="onEntityDataDelete(data)"
                            ><i class="el-icon-delete"></i
                          ></span>
                          <span
                            v-else-if="button === 'Send Notification'"
                            class="text-muted"
                            title="Send Notification"
                            @click="openSendNotification(data)"
                            ><i class="fa-solid fa-share"></i
                          ></span>
                        </button>
                        </div>
                      </template>
                      </div>
                    </div>
                  </div>
                  <div class="add-card-button">
                    <el-button class="circle-button" @click="addEntityData(i)">
                      +
                    </el-button>
                  </div>
                </draggable>
              </div>
            </div>
            <div class="entity-empty" v-else>
              <el-empty :description="getDescription"></el-empty>
            </div>
          </el-scrollbar>
        </div>
      </el-card>
    </div>

    <div v-if="quickActionModal && quickActionDummyField">
      <ActionButtonExecute
        :data="quickActionDummyField"
        :isView="false"
        :entityId="currentEntity._id"
        :entityDataId="quickActionDummyField.parent_entityDataId"
        :fromQuickAction="true"
        @closeModal="quickActionModal = false"
      >
      </ActionButtonExecute>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import draggable from "vuedraggable";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import FieldsFormatHelper from "@/mixins/FieldsFormatHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import { fetchEntityById } from "@/repo/entityRepo";
import { fetchFilterById } from "@/repo/filtersRepo";
import axios from "@/config/axios";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  data() {
    return {
      copyData: [],
      localShowFields: { ...this.showFields },
      fieldId: "",
      showWidget: false,
      selectedField: null,
      entityOptionsObj: null,
      quickentityData: {},
      number_fields: [],
      required_sum: 0,
      tagType: ["success", "info", "", "warning", "danger"],
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            label: "",
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      componentData: {},

      baseColor: "#65cff0",
      loading: false,
      entity_id: "",
      currentEntity: {},
      loadingText: "Gathering data...",
      refresh: false,
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
      ],
      allEntityDataList: [],
      getSelectedFieldsOptions: [],
      getSelectedFieldLabels: [],
      getSelectedFieldEntityLabels:[],
      getStandardSelectedFieldOptions: [],
      customDashboardBoardStats: null,
      entityFields: [],
      selectedEntityFields: [],
      quickActionModal: false,
      quickActionDummyField: null,
      relationalDataOfEntityData:{},
      sortOrder:-1,
      sortBy:'_id',

    };
  },
  computed: {
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "geCustomDashboardPieStats",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    dropdownPositionClass() {
      return "dropdown-bottom";
    },
    getFieldLabel() {
      if (this.showFields?.board_field) {
        if (
          this.showFields?.board_field &&
          this.showFields.board_field.includes("#")
        ) {
          let field = this.getEntityFieldsByType(["SELECT", "ENTITY"]).find(
            (e) => e.key == this.showFields.board_field
          );
          if (field?.label) {
            return field.label;
          }
          return this.currentEntity?.name;
        }
        return this.currentEntity?.name;
      }
      return this.currentEntity?.name;
    },
    getDescription() {
      if (!this.showFields.board_field) {
        return "Field not selected...";
      }
      return "No options...";
    },
    quickAddEntity() {
      return (
        this.selectedField?.entity_id &&
        this.selectedField?.allow_quick_add &&
        this.selectedField?.inputType === "ENTITY"
      );
    },
    getCount() {
      return (col) => {
        if (
          this.showFields.board_field &&
          this.showFields.board_field.includes("#") &&
          col &&
          this.allEntityDataList
        ) {
          let count = 0;
          let [temp, key] = this.showFields.board_field.split("#");
          this.allEntityDataList.forEach((data) => {
            if (
              data?.entityData &&
              temp &&
              data.entityData[temp] &&
              data.entityData[temp][key] &&
              data.entityData[temp][key] == col
            ) {
              count++;
            }
          });
          return count;
        }
        return 0;
      };
    },
    getCards() {
      return (col, type) => {
        if (
          this.showFields.board_field &&
          this.showFields.board_field.includes("#") &&
          col &&
          this.allEntityDataList
        ) {
          let result = [];
          let [temp, key] = this.showFields.board_field.split("#");
          if (type === "ENTITY") {
            key += "/name";
          }
          this.allEntityDataList.forEach((data) => {
            if (
              data?.entityData &&
              temp &&
              data.entityData[temp] &&
              data.entityData[temp][key] &&
              data.entityData[temp][key] == col
            ) {
              result.push(data);
            }
          });
          return result;
        }
        return [];
      };
    },
    nonPrimaryActions() {
      if (this.customization) {
        return this.customization.actions.filter(
          (action) => !action.QAPrimary && action.active
        );
      }
      return [];
    },
    primaryActions() {
      if (this.customization) {
        return this.customization.actions.filter(
          (action) => action.QAPrimary && action.active
        );
      }
      return [];
    },
  },
  mixins: [
    NavigationHelper,
    EntitiesHelper,
    FieldsFormatHelper,
    CustomDashboardConfig,
  ],
  components: {
    quickEntityAddWidget: () =>
      import("@/components/widgets/quickEntityAddWidget"),
    draggable,
    ActionButtonExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/ActionButtonExecute"
      ),
  },
  created(){
    this.$bus.$on('relation-rules-exist', this.handleRelationRules);
  },
  watch: {
    "showFields.board_field": {
      handler: function (newValue, oldValue) {
        this.showFields.board_field=newValue;
        this.$emit("SaveData");
        if (oldValue !== newValue) {
          this.loadBoardData();
        }
      },
      deep: true,
    },
    showFields: {
      handler: function () {
        if (
          JSON.stringify(this.showFields) != JSON.stringify(this.showFields)
        ) {
          this.localShowFields = { ...this.showFields };
          this.updateShowFields();
        }
      },
      deep: true,
    },
  },
  props: [
    "data",
    "form",
    "entityId",
    "search_string",
    "showFields",
    "isApplicationUserSide",
    "currentMenu",
    "fieldsData",
    "getSelfEntityFilters",
    "applyFiltersData",
    "entityFiltersData",
    "appliedQuickFilters",
    "entityUserFiltersData",
    "customization",
  ],
  mounted() {
    this.entity_id = this.$route.params.entity_id || this.entityId;
    if (this.$route.query.board_field) {
      this.showFields.board_field = this.$route.query.board_field;
    }
    this.getNecessaryInfo();
  },
  methods: {
    async handleRelationRules(filters = []){
      if (
          this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
          this.isApplicationUserSide
        ) {
          filters = [...filters, ...this.getSelfEntityFilters];
        }
        filters = [
          ...filters,
          ...(this.entityFiltersData.filters || []),
          ...(this.entityUserFiltersData.filters || []),
          ...(this.appliedQuickFilters || []),
        ];
        this.loadingText = "Gathering " + this.currentEntity?.name + " data";
        let primaryActions;
         let relationalEntities=[];
          let relationalData
        if(this.customization)
        {
          primaryActions = this.customization.actions.filter(action => action.QAPrimary == true && action.active);
         if(primaryActions){
          relationalEntities = primaryActions
          .map(action => action.relational_entity_one)
          .filter(entity => entity !== undefined);

        }
      }
                  let params = {
                    entity_id: this.entity_id,
                    template_fields_data: (this.currentEntity.templates || []).map(
                      (template) => {
                        return {
                          template_id: template.template_id,
                        };
                      }
                    ),
                    filters: [...this.applyFiltersData.filters, ...filters],
                    include_contact_profile: true,
                    relationalEntities:relationalEntities,
                  };

                  // await this.$store.dispatch(
                  //   "entities/fetchEntityRecordsForTable",
                  //   params
                  // );
                  const response = await axios.post("/entities-data/entity/data", params);
                  if(response?.data?.relationaldataIdsObject){
                    if (primaryActions.length > 0) {   
            const requests = primaryActions.map( async (action) => {
              const params = {
                entity_id: action.relational_entity_one,
              };
              if(action?.filter){
                 let currentFilter = await fetchFilterById(
                  action.filter
                );
                params["filters"] = [...(currentFilter?.filters || [])]
              }
              return axios.post("/entities-data/entity/data", params);
            });

            try {
              const relationalDataResponses = await Promise.all(requests);
              relationalData = relationalDataResponses.map(response => response.data);
            } catch (error) {
              console.error("Error fetching relational data", error);
            }
          }
          this.relationalDataOfEntityData = Object.keys(response.data.relationaldataIdsObject).reduce((acc, key) => {
            acc[key] = response.data.relationaldataIdsObject[key].map(id => {
              for (const dataChunk of relationalData) {
                const found = dataChunk.data.find(item => item._id === id);
                if (found) return found;
              }
              return undefined; 
            });
            return acc;
          }, {});  
        }
    },
    getSelectedValue(action, data) {
        const matchingValue = Object.keys(this.relationalDataOfEntityData).find(
            key => key === data._id
        );
        if (matchingValue) {
            const ruleValues = [];
            action.rules?.forEach(() => {
                this.relationalDataOfEntityData[matchingValue].forEach(matchedObject => {
                    if (action.relational_entity_one === matchedObject?.entity_id) {
                        matchedObject.templates_data.forEach(templateData => {
                            const id = templateData.template_id;
                            const value = matchedObject.entityData[id];
                            ruleValues.push(value);
                        });
                    }
                });
            });
            if (ruleValues.length > 0) {
                const priorityKey = action.priority?.split("#")[1];
                const isFullDateTime = (value) => value.includes("T");
                const parseDateTimeOrTime = (value) => {
                    if (isFullDateTime(value)) {
                        return new Date(value); 
                    } else {
                        const [hours, minutes, seconds] = value.split(":").map(Number);
                        const now = new Date();
                        now.setHours(hours, minutes, seconds, 0);
                        return now;
                    }
                };
                if (action.order === "ascending") {
                    ruleValues.sort((a, b) => parseDateTimeOrTime(a[priorityKey]) - parseDateTimeOrTime(b[priorityKey]));
                } else if (action.order === "descending") {
                    ruleValues.sort((a, b) => parseDateTimeOrTime(b[priorityKey]) -parseDateTimeOrTime(a[priorityKey]));
                }
                return ruleValues[0];
            }
        }
        return null; 
    },
    getBackgroundColor(action, data) {
        const selectedValue = this.getSelectedValue(action, data);
        if (selectedValue) {
            const form = this.applyFormRules(selectedValue, [], action.rules);
            if (Array.isArray(form?.action)) {
                const colorAction = form.action.find(action => action.operator_type === "CHANGE_COLOR");
                return colorAction?.color || "white"; 
            } else {
                return form?.action?.color || "white";
            }
        }
      return action.color ? action.color : "white";
    },
    getTooltipContent(action, data) {
        const selectedValue = this.getSelectedValue(action, data);
        if (selectedValue) {
            const form = this.applyFormRules(selectedValue, [], action.rules);
            if (Array.isArray(form?.action)) {
                const colorAction = form.action.find(action => action.operator_type === "UPDATE");
                return colorAction?.value || action.label; 
            } else {
                return form?.action?.value || action.label;
            }
        }

      return action.label;
    },
    async getTemplateData(templateId) {
      await this.$store.dispatch(
        "companyTemplates/fetchSingleCompanyTemplate",
        templateId
      );
      return this.getSingleCompanyTemplate;
    },
    checkRolePermission(permission) {
      if (this.getUserType?.permissionSet && !this.isApplicationUserSide) {
        return this.getUserType.permissionSet.includes(permission);
      }
      return true;
    },
    async setCurrentField() {
      let field = this.getEntityFieldsByType(["SELECT", "ENTITY"]).find(
        (e) => e.key == this.showFields.board_field
      );
      if (!field) {
        this.showFields.board_field = null;
        return;
      }
      this.selectedField = field;
      if (field.inputType == "ENTITY" && field.entity_id) {
      this.$emit("SaveData");
      this.quickentityData = await fetchEntityById(field.entity_id, false); 
        }
    },
    updateShowFields() {
      this.$emit("update:showFields", this.localShowFields); // Emit the change to parent
    },
    getFieldLabels(item) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      if (field) {
        return field.orgLabel || "";
      }
      return "";
    },
    async addEntityData(index) {
      let query = this.getNavigationQuery(this.$route.query);
      let viewType = "STANDARD";
      if (this.currentEntity?.entity_type == "INDIVIDUAL") {
        viewType = "PROFILE";
      }
      const boardField = this.showFields.board_field;
      if (this.isApplicationUserSide) {
        this.$router.push({
          path: `/ap/entity-execute/${this.entity_id}`,
          query: {
            ...query,
            ...{
              menu_id: this.$route.params.menuId,
              layout: viewType,
              type: "s7aLF3NnAQg=",
              index: this.getSelectedFieldLabels[index], // Send index
              board_field: boardField,
            },
          },
        });
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
        } else {
          UrlPathAdd = `/entity/edit/${this.entity_id}`;
        }
        this.$router.push({
          path: UrlPathAdd,
          query: {
            ...query,
            ...{
              layout: viewType,
              index: this.getSelectedFieldLabels[index],
              board_field: boardField,
            },
          },
        });
      }
    },
    checkEntityDataSwipe(data, col) {
      setTimeout(() => {
        this.getSelectedFieldsOptions.forEach((column, i) => {
          if (i !== col) {
            (column || []).forEach((card) => {
              if (
                card &&
                data &&
                card._id === data._id &&
                this.showFields?.board_field &&
                this.showFields.board_field.includes("#")
              ) {
                this.updateEntityData(this.getSelectedFieldLabels[i], data);
              }
            });
          }
        });
      }, 300);
    },
    checkPerimission(access) {
      if (this.isApplicationUserSide) {
        if (
          this.getEntityPermission &&
          this.currentMenu &&
          (this.currentMenu.entity_id || this.currentMenu.relational_entity)
        ) {
          if (access == "UPDATE") {
            access = "EDIT";
          }
          if (this.currentMenu.entity_id) {
            let entityId = this.currentMenu.entity_id._id
              ? this.currentMenu.entity_id._id
              : this.currentMenu.entity_id;
            if (
              this.currentMenu.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              entityId &&
              this.getEntityPermission.other_entities[entityId] &&
              this.getEntityPermission.other_entities[entityId].permissions &&
              this.getEntityPermission.other_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu.relational_entity) {
            let entityId = this.currentMenu.relational_entity._id
              ? this.currentMenu.relational_entity._id
              : this.currentMenu.relational_entity;
            if (
              entityId &&
              this.getEntityPermission.relational_entities[entityId] &&
              this.getEntityPermission.relational_entities[entityId]
                .permissions &&
              this.getEntityPermission.relational_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          }
          return false;
        }
        return true;
      } else {
        if (
          this.entityFiltersData?.permissions?.access_set &&
          this.entityFiltersData?._id
        ) {
          if (access == "EDIT") {
            access = "UPDATE";
          }
          if (access == "IMPORT" || access == "EXPORT") {
            return true;
          }
          return (
            this.entityFiltersData.permissions.access_set.indexOf(access) != -1
          );
        }
        return true;
      }
    },
    getEntityName() {
      return this.currentEntity?.name;
    },
    async updateEntityData(selectedData, entityData) {
      let [temp, key] = this.showFields.board_field.split("#");
      if (entityData?.entityData && temp && key) {
        let form = entityData.entityData[temp];
        if (this.selectedField.inputType == "ENTITY") {
          form[key] = this.entityOptionsObj[selectedData];
          form[`${key}/name`] = selectedData;
        } else {
          form[key] = selectedData;
        }
        let params = {
          template_data: form,
          entity_data_id: entityData._id,
          template_id: temp,
          entity_id: entityData.entity_id,
        };
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    getFieldValue(item, col) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      let form = col.entityData[item.split("#")[0]];
      if (field) {
        let data = this.getFormattedFieldData(field, form);
        return data;
      }
      return null;
    },
    getHeadlabel() {
      if (
        this.showFields.take_value_from != "" ||
        this.showFields.take_value_from != undefined
      ) {
        let field = this.entityFields.find(
          (e) => e.key == this.showFields.take_value_from?.split("#")[1]
        );
        if (field) {
          const labelParts = field.label.split("-");
          if (labelParts.length > 1) {
            console.log("Field Value:", labelParts[1].trim());
            return labelParts[1].trim() + " Total";
          } else {
            return field.label;
          }
        }
        return "";
      }
      return "";
    },
    getFormattedTotalAmount(data) {
      if (
        this.showFields.take_value_from != "" ||
        this.showFields.take_value_from != undefined
      ) {
        const result = this.showFields.take_value_from?.split("#")[1];
        let totalAmount = 0;
        let templates_list = [];

        if (this.currentEntity?.templates) {
          templates_list = this.currentEntity.templates.map(
            (e) => e.template_id
          );
        }
        let currencyCode = "";

        data.forEach((item) => {
          if (item.entityData) {
            templates_list.forEach((templateId) => {
              const required_template_data = item.entityData[templateId];
              if (required_template_data) {
                if (required_template_data[`${result}_currency`]) {
                  currencyCode = required_template_data[`${result}_currency`];
                 }
                if (required_template_data[result]) {
                  totalAmount += Number(required_template_data[result]);
                }
              }
            });
          }
        });

        if (currencyCode) {
          const currencyLocale = this.getLocale(currencyCode);
          try {
            const formattedTotalAmount = new Intl.NumberFormat(currencyLocale, {
              style: "currency",
              currency: currencyCode,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(totalAmount);
            return formattedTotalAmount;
          } catch (error) {
            return `${currencyCode}`;
          }
        } else {
          return totalAmount;
        }
      }
      return "";
    },
    getLocale(currency) {
    switch (currency) {
    case "USD":
      return "en-US";
    case "CAD":
      return "en-CA";
    case "AUD":
      return "en-AU";
    case "INR":
      return "en-IN";
    case "EUR":
      return "en-IE";
    case "MXN":
      return "es-MX";
    case "ZAR":
      return "en-ZA";
    case "MYR":
      return "ms-MY";
    case "GBP":
      return "en-GB";
    default:
      return "en-US";
  }},
    getActionField(item) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      return field?.orgLabel;
    },
    resetChartData() {
      this.chartData.labels = [];
      this.chartData.datasets = [
        {
          backgroundColor: [],
          data: [],
          label: "",
        },
      ];
    },
    async viewWorkUserData(row) {
      if (row._id) {
        if (this.isApplicationUserSide) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/ap/entity-view/${this.entity_id}`,
            query: {
              ...query,
              dataId: row._id,
              filter: this.currentFilter,
              menu_id: this.$route.params.menuId,
            },
          });
        } else {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/entity/view/${this.entity_id}`,
            query: { ...query, dataId: row._id, filter: this.currentFilter },
          });
        }
      }
    },
    async editWorkUserData(row) {
      if (row._id) {
        let viewType = "STANDARD";
        if (this.currentEntity?.entity_type == "INDIVIDUAL") {
          viewType = "PROFILE";
        }
        if (this.isApplicationUserSide) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/ap/entity-execute/${this.entity_id}`,
            query: {
              ...query,
              dataId: row._id,
              filter: this.currentFilter,
              menu_id: this.$route.params.menuId,
              layout: viewType,
              page: this.$route.query.page || 1,
              pageSize: this.$route.query.pageSize || 10,
              type: "AUfYWb7SC6U=",
            },
          });
        } else {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          let UrlPathEdit = "";

          if (this.getIframeUrlInfo?.url_type) {
            UrlPathEdit = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
          } else {
            UrlPathEdit = `/entity/edit/${this.entity_id}`;
          }
          let path = UrlPathEdit;
          if (this.$route.path !== path) {
            this.$router.push({
              path: path,
              query: {
                ...query,
                dataId: row._id,
                filter: this.currentFilter,
                layout: viewType,
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || 10,
              },
            });
          }
        }
      }
    },

    async updateRouterParams() {
      let query = this.getNavigationQuery(this.$route.query);
      query["board_field"] = this.showFields.board_field;
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
    },
    async loadBoardData(filters = []) {
      if (this.showFields.board_field) {
        this.loading = true;
        if (
          this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
          this.isApplicationUserSide
        ) {
          filters = [...filters, ...this.getSelfEntityFilters];
        }
        filters = [
          ...filters,
          ...(this.entityFiltersData.filters || []),
          ...(this.entityUserFiltersData.filters || []),
          ...(this.appliedQuickFilters || []),
        ];
        this.loadingText = "Gathering " + this.currentEntity?.name + " data";
        let primaryActions;
         let relationalEntities=[];
          let relationalData
        if(this.customization)
        {
          primaryActions = this.customization.actions.filter(action => action.QAPrimary == true && action.active);
         if(primaryActions){
          relationalEntities = primaryActions
          .map(action => action.relational_entity_one)
          .filter(entity => entity !== undefined);

        }
      }
                  let params = {
                    entity_id: this.entity_id,
                    template_fields_data: (this.currentEntity.templates || []).map(
                      (template) => {
                        return {
                          template_id: template.template_id,
                        };
                      }
                    ),
                    filters: [...this.applyFiltersData.filters, ...filters],
                    include_contact_profile: true,
                    relationalEntities:relationalEntities,
                  };

                  // await this.$store.dispatch(
                  //   "entities/fetchEntityRecordsForTable",
                  //   params
                  // );
                  const response = await axios.post("/entities-data/entity/data", params);
                  if(response?.data?.relationaldataIdsObject){
                    if ( primaryActions && primaryActions.length > 0) {   
            const requests = primaryActions.map(async (action) => {
              const params = {
                entity_id: action.relational_entity_one,
              };
              if(action?.filter){
                 let currentFilter = await fetchFilterById(
                  action.filter
                );
                params["filters"] = [...(currentFilter?.filters || [])]
              }
              return axios.post("/entities-data/entity/data", params);
            });

            try {
              const relationalDataResponses = await Promise.all(requests);
              relationalData = relationalDataResponses.map(response => response.data);
            } catch (error) {
              console.error("Error fetching relational data", error);
            }
          } else {
            console.log("No primary actions found");
          }
          this.relationalDataOfEntityData = Object.keys(response.data.relationaldataIdsObject).reduce((acc, key) => {
            acc[key] = response.data.relationaldataIdsObject[key].map(id => {
              for (const dataChunk of relationalData) {
                const found = dataChunk.data.find(item => item._id === id);
                if (found) return found;
              }
              return undefined; 
            });
            return acc;
          }, {});  
        }

        this.customDashboardBoardStats = { ...response.data };
        if (
          this.customDashboardBoardStats &&
          this.customDashboardBoardStats.data
        ) {
          this.allEntityDataList = this.customDashboardBoardStats.data;
          await this.filterEntityData(this.allEntityDataList);
        }
        this.loading = false;
      }
    },
     async filterEntityData() {
      this.getSelectedFieldsOptions = [];
      this.getSelectedFieldLabels = [];
      this.getSelectedFieldEntityLabels=[];
      if (this.showFields?.board_field) {
        if (
          this.showFields?.board_field &&
          this.showFields.board_field.includes("#")
        ) {
          let field = this.getEntityFieldsByType(["SELECT", "ENTITY"]).find(
            (e) => e.key == this.showFields.board_field
          );
          if (
            field?.inputType &&
            field.inputType == "SELECT" &&
            field.options
          ) {
            field.options.forEach((op) => {
              this.getSelectedFieldsOptions.push(this.getCards(op));
              this.getSelectedFieldLabels.push(op);
              this.getStandardSelectedFieldOptions.push(this.getCards(op));
            });
          } else if (field?.inputType === "ENTITY" && field.key) {
            this.entityOptionsObj = {};
            let response = await postAPICall("POST", "/entities-data/entity/data", {
              entity_id: field.entity_id,
            });
            let options =[]

          response.data.forEach((entity) => {
          let combinedOption = field.primary_fields.map((primaryField) => {
            let [templateKey, selectedKey] = primaryField.split('#');
            let data = entity.entityData[templateKey];  
            return data && data[selectedKey] !== undefined ? data[selectedKey] : '';  
          }).filter(value => value !== '').join(' - '); 

          if (combinedOption && !options.some(item => item === combinedOption)) {
            options.push(combinedOption); 
          }

          this.entityOptionsObj[combinedOption] = entity._id;  
        });
           
          options.forEach((op) => {
            const entityCard = this.getCards(op, "ENTITY");
            if (
              this.getSelectedFieldsOptions.length < options.length )
          {
              this.getSelectedFieldsOptions.push(entityCard);
            }
          if (
            field.inputType === "ENTITY" &&
            !this.getSelectedFieldLabels.includes(this.entityOptionsObj[op])
          ) {
            this.getSelectedFieldLabels.push(this.entityOptionsObj[op]);
          }
          if (!this.getSelectedFieldEntityLabels.includes(op)) {
            this.getSelectedFieldEntityLabels.push(op);
          }
            if (!this.getStandardSelectedFieldOptions.some(item => JSON.stringify(item) === JSON.stringify(entityCard))) {
              this.getStandardSelectedFieldOptions.push(entityCard);
            }
          });
          }    
        }
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityById", {
        entity_id: this.entity_id,
      });
      if (this.getEntityDataById) {
        this.currentEntity = this.getEntityDataById;
        this.loadBoardData();
      }
      this.entityFields = await this.fetchEntityDetails(
        this.getEntityDataById._id,
        true
      );
      this.setCurrentField();
      this.selectedEntityFields = [];
      if (this.getEntityDataById) {
        this.currentEntity = this.getEntityDataById;
        if (this.currentEntity?.views_configuration) {
          this.localShowFields = { ...this.currentEntity.views_configuration };
        } else if (this.showFieldsParent) {
          this.localShowFields = { ...this.showFieldsParent };
        }
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              let dt = JSON.parse(JSON.stringify(e.templateInfo));
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(dt),
              ];
            }
          });
        }
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      fields.forEach((f) => {
        if (
          f.inputType === "NUMBER" ||
          f.inputType === "CURRENCY" ||
          f.inputType === "AGGREGATE_FUNCTION" ||
          (f.inputType === "FORMULA" && f.result_type === "NUMBER")
        ) {
          this.number_fields.push(f);
        }
      });
      return fields;
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity && this.currentEntity.templates
        ? this.currentEntity.templates
        : []
      ).forEach((temp) => {
        if (temp?.templateInfo?.sections && !temp.is_repeatable) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (!key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = key;
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (!key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = key;
                return el;
              }),
            ];
          }
        }
      });
      if (
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
    deleteEntityData(row) {
      this.$confirm("Are you sure to delete the data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.checkDeleteEntityData(row);
      });
    },
    async checkDeleteEntityData(entityData) {
      let params = {
        id: entityData._id,
        entity_id: entityData.entity_id,
      };
      let resourceFilters = this.selectedEntityFields
        .filter((e) => {
          if (e?.inputType == "ENTITY" && e.filters) {
            return e.filters.find((f) => f.field == "RESOURCE_MANAGEMENT");
          }
        })
        .map((e) => {
          let newField = {
            key: e.key,
            label: e.label,
            filters: e.filters,
            entity_id: e.entity_id,
            template_id: e.template_id,
          };
          if (e.data_table_key) {
            newField["data_table_key"] = e.data_table_key;
            newField["data_table_field_index"] = e.data_table_field_index;
          }
          return newField;
        });
      if (resourceFilters && resourceFilters.length) {
        params["resource_filters"] = resourceFilters;
        params["entityData"] = entityData;
      }
      await this.$store.dispatch("templatesData/deleteEntityData", params);
      if (this.getTemplateDeleteStatus) {
        // this.fetchAllEmployee();
        this.loadBoardData();
        this.$notify.success({
          title: "Success",
          message: "Entity Data deleted successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message:
            this.getTemplateDeleteError || "Error on deleting Entity data",
        });
      }
    },
    async duplicateEntityData(row) {
      this.loading = true;
      let data = {
        entity_id: this.entity_id,
        existed_data_id: row._id,
        current_date: this.getCurrentDate,
      };
      await this.$store.dispatch("templatesData/duplicateEntityData", data);
      if (this.getDuplicateEntityData?._id) {
        this.$notify.success({
          title: "Success",
          message: "Data duplicated successfully",
        });
        this.loading = false;
        this.loadBoardData();
        // this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message:
            "Error while duplicating " +
            (this.currentEntity && this.currentEntity.name
              ? this.currentEntity.name
              : "data"),
        });
      }
    },
  },

  // checkRolePermission(permission) {
  //   if (this.getUserType?.permissionSet && !this.isApplicationUserSide) {
  //     return this.getUserType.permissionSet.includes(permission);
  //   }
  //   return true;
  // },
};
</script>
<style scoped lang="scss">
.bold-key {
  font-weight: 700;
  color: black;
}
.card-data {
  justify-content: first baseline;
  align-items: center;
  margin-left: 8px;
  width: 50px;
}
.main-chart-container {
  width: 100% !important;
  .entity-navbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 5px;
  }
  .left {
    .entity-title {
      color: #0e1216;
      font-weight: 500;
      font-size: 18px;
      margin-top: 5px;
    }
    order: 1;
  }

  .right {
    order: 2;
  }
  .chart-main-container {
    margin-top: 5px;
    .pie-body {
      display: flex !important;
      gap: 10px;
      max-height: 70vh;
      .board-column {
        width: 25%;
        background-color: white;
        padding: 10px 0px 10px 10px;
        border-radius: 10px;
        cursor: pointer;
        background-color: #ebebeb;
        .board-column-head {
          display: flex !important;
          text-align: center;
          margin-bottom: 5%;
          .count-circle {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            border: 1px solid #e6e6e6;
            margin-left: 3px;
            margin-top: 3px;
            font-size: 10px;
            background-color: var(--primary-color);
            color: var(--primary-contrast-color);
            div {
              margin-top: 5px !important;
            }
          }
        }
        .board-card-body {
          cursor: pointer;
          width: 100%;
          height: auto;
          background: #ffffff;
          border: 0.5px solid #ebeef5;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
          border-radius: 2px;
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2% 0%;
          flex-direction: column;
        }

        .board-card-body:hover {
          transform: translateY(-4px); /* Slight lift effect */
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Increase shadow on hover */
          background-color: lightslategray;
          color: white;
        }
        .board-card-body:active {
          transform: translateY(-4px); /* Slight lift effect */
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Increase shadow on hover */
          background-color: var(--lighter-primary-color);
          color: var(--primary-contrast-color);
        }
      }
    }
  }
}
</style>

<style scoped>
.el-card {
  border-radius: 4px;
  border: 0px solid #ebeef5;
  background-color: transparent;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.el-card.is-always-shadow {
  box-shadow: none;
}

.card {
  border-radius: 10px;
}

.card-body {
   display: flex; 
  justify-content: flex-start;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  
}

.card-body:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
}

.add-card-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.circle-button {
  background-color: transparent;
  border: 2px solid #8a8e93; 
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: 18px;
  color: #8a8e93;
  line-height: 3px;
  text-align: center;
  cursor: pointer;
}

.circle-button:hover {
  background-color: rgba(64, 158, 255, 0.1);
}

.dropdown-bottom {
  bottom: -100%;
}

.container{
  padding:10px
}
.iconsContainer{
  display: flex;
  flex-direction: column;
  gap: 10px;
   margin-right: 1px;
}
.icon-circle {
  display: inline-flex; 
  justify-content: center;
  align-items: center;
  width: 35px;  
  height: 35px; 
  border-radius: 50%; 
  color: white; 
  font-size: 16px; 
}

.icon-circle i, .icon-circle svg {
  font-size: 14px;  
}
.col-6 {
  padding-right: 28px;
  flex-grow: 1;
}
</style>
