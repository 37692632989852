<template>
  <div :style="getElementStyle">
  <div id="app">
    <el-col
      :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
      ref="colRef"
      class="bottom-space"
    >
    <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
      <label for="text" :style="getLabelStyles">{{ label || data.label }}</label>
      <span
        v-if="data.validations.required"
        style="color: red; font-weight: bold"
      >
        *
      </span>
      <!-- <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i> -->
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description"></i>
      </span>
    </el-col>
    <el-input
      v-model="form[data.key]"
      v-if="!data.validations.mapViewRequired"
      :readonly="true"
    ></el-input>
    <div
      v-else
      class="map-container"
      style="border: 2px solid cadetblue; border-radius: 10px; flex-grow: 1"
    >
      <div class="mapResource">
        <el-input
          v-if="!isView"
          v-model="address"
          placeholder="Enter Address"
        ></el-input>
        <el-button
          v-if="!isView"
          @click="getCoordinates(address)"
          type="primary"
          >Search Address</el-button
        >
      </div>
      <l-map :zoom="zoom" :center="center" style="height: 100%">
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker :lat-lng="coordinates" :icon="markerIcon"></l-marker>
        <!-- Geofence circle -->
        <l-circle
          :lat-lng="data.validations.geoFenceCoordinates"
          :radius="data.validations.geoFenceRadius"
          :color="'red'"
          :fill-color="'red'"
          :fill-opacity="0.3"
        ></l-circle>
      </l-map>
    </div>
  </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LCircle } from "vue2-leaflet"; // Import LCircle for geofence
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

export default {
  name: "App",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle, // Register the LCircle component
  },
  props: ["data", "form", "isView", "label","isIconRequired"],
  mixins: [
    userPermissionsHelper,
  ],
  computed: {
    combinedCoordinates() {
      return `${this.coordinates[0]}, ${this.coordinates[1]}`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "font-weight: 700";
    },
  },
  data() {
    return {
      address: "",
      zoom: 10,
      computedStyles: {},
      showLabel: true,
      isDefalutPos: true,
      center: [17.4065, 78.4772], // default center coordinates
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      coordinates: [17.4065, 78.4772],
    };
  },

  methods: {
    async getCoordinates(address) {
      try {
        // These are query Params to make API call
        const config = {
          params: {
            address: address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        // Make the API request using the config Params object
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );

        // Extract coordinates from response
        const coordinates = response.data.results[0].geometry.location;
        // Update the coordinates for the marker to point
        this.coordinates = [coordinates.lat, coordinates.lng];
        this.$set(
          this.form,
          this.data.key,
          `${this.coordinates[0]}, ${this.coordinates[1]}`
        );
        // Update coordinates to focus map at where the pointer is
        this.center = [coordinates.lat, coordinates.lng];
        return coordinates;
      } catch (error) {
        console.error("getCoordinates", error);
        return null;
      }
    },
  },
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.getCoordinates(this.form[this.data.key]);
  },
};
</script>

<style>
#app {
  height: 50vh;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
}

#button {
  border: none;
  background-color: cadetblue;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  margin-top: 5px;
}

.map-container {
  flex-grow: 1;
  margin: 10px 0; /* Optional margin for better spacing */
}

.mapResource {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
