<template>
  <div>
    <el-button
      v-if="fromRepeatable"
      @click="openClickAdd"
      size="mini"
      icon="el-icon-plus"
      style="height: 40px !important"
    ></el-button>
    <el-link
      type="primary"
      @click="openClickAdd"
      :underline="false"
      v-else-if="!isFromAddOtherEntity"
    >
      <i class="el-icon-circle-plus" style="color: white;"></i>
    </el-link>
    <dialog-component
      ref="dialogRef"
      :title="
        isUpdateEntityData
          ? 'Edit ' + currentEntity.name + `'s Data`
          : 'Add New ' + currentEntity.name + `'s Data`
      "
      :visible="quickAddDialogVisible"
      :width="getIsMobile || detailed_add ? '100%' : '30%'"
      @before-close="handleClose"
      :close-on-press-escape="false"
      v-loading="loading"
      element-loading-text="Adding Data..."
      :fullscreen="true"
      class="el-dialog el-dialog-fullscreen"
      :containerWidth="detailed_add ? '80%' : '70%'"
    >
      <div
        v-if="primaryFields && primaryFields.length"
        @keyup.enter="handleEnterKey"
      >
        <template>
          <fields-preview-template
            :templateData="currentEntity.templates[0].templateInfo"
            :has-next="false"
            :workflow-step-id="currentEntity.templates[0]._id"
            :entityDataId="otherEntityDataId"
            :template-data-id="currentEntity.templates[0].templateDataId"
            :currentStep="currentEntity.templates[0]"
            :is-execute="true"
            :layout="'STANDARD'"
            :currentEntity="currentEntity"
            :isEdit="true"
            :entityData="entityDataForUpdate"
            :currentMenu="null"
            :entityDataExists="false"
            :fromAddWidget="true"
            @saveAndAssignRelation="saveAndAssignRelation"
            :suggestionEntityForm="isUpdateEntityData ? null : newEntityForm"
            @saveAndCloseEditDialog="saveAndCloseEditDialog"
            :otherEntityForm="otherEntityForm"
            :isFromQuickAdd="true"
          >
          </fields-preview-template>
        </template>
      </div>
      <div v-else style="text-align: center">No Primary fields</div>
      <span slot="footer" class="dialog-footer float-right">
        <el-button @click="handleClose" class="scaling-button"
          >Cancel</el-button
        >
        <template>
          <el-button
            type="primary"
            @click="addNewRelationship"
            class="scaling-button"
            >{{ isUpdateEntityData ? "Update " : "Add " }}
            {{ currentEntity.name }}</el-button
          >
        </template>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/config/axios";
import { makeSlug } from "@/helpers/appHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import { postAPICall } from "../../helpers/httpHelper";
//import { bus } from "../../main";
export default {
  mixins: [entityRelationshipMixin],
  props: [
    "currentEntity",
    "fromRepeatable",
    "parentForm",
    "parentFields",
    "openNewAdd",
    "detailed_add",
    "relationshipDetails",
    "actionButtonField",
    "isFromAddOtherEntity",
    "otherEntityDataId",
    "isUpdateEntityData",
    "parentEntityData",
    "selfTemplateId",
  ],
  components: {
    FieldsPreviewTemplate: () =>
      import("../templates/templateFieldsPreview.vue"),
  },
  data() {
    return {
      quickAddDialogVisible: false,
      newEntityForm: {},
      sections: [],
      loading: false,
      laodingText: "",
      primaryFields: [],
      standardTemplate: {},
      relationships: [],
      entityDataForUpdate: null,
      otherEntityForm: {},
    };
  },
  computed: {
    ...mapGetters("entitiesData", [
      "getNewEntityDataWithQuickAdd",
      "getNewEntityDataWithQuickAddErrors",
    ]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getPrimaryEntityDataErrors",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("entityRelationships", [
      "getEntityNestedRelationships",
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getCurrentEntityNestedRelationships",
      "getSubentityRelationships",
    ]),
    ...mapGetters("entities", ["getEntityPrimaryData"]),
    checkAllfieldsFilled() {
      if (
        Object.keys(this.newEntityForm) &&
        this.primaryFields &&
        Object.keys(this.newEntityForm).length >= this.primaryFields.length
      ) {
        let emptyField = this.primaryFields.find(
          (e) =>
            (e.key && this.newEntityForm[e.key] == "") ||
            this.newEntityForm[e.key] == undefined
        );
        if (emptyField) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
  async mounted() {
    // we have to group the primary fields
    // this.$nextTick(() => {
    //   // Assuming 'dialogRef' is the ref name of the dialog
    //   const dialogRef = this.$refs.dialogRef;

    //   if (dialogRef && dialogRef.$el) {
    //     // Scroll to the top of the dialog
    //     dialogRef.$el.scrollIntoView({ behavior: "smooth", block: "start" });
    //   }
    // });
    if (this.otherEntityDataId && this.isUpdateEntityData) {
      await this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
        id: this.otherEntityDataId,
      });
    }
    this.entityDataForUpdate = this.getEntityPrimaryData;
    if (
      this.openNewAdd ||
      this.isFromAddOtherEntity ||
      this.isUpdateEntityData
    ) {
      this.openClickAdd();
    }
  },

  methods: {
    async addNewRelationship() {
      try {
        this.$bus.$emit("saveTemplateFromQuickAdd", this.currentEntity._id);
      } catch (err) {
        console.log("addNewRelationship", err);
      }
    },
    handleEnterKey(event) {
      if (event.keyCode === 13 && !this.detailed_add) {
        this.addNewRelationship()
        // if (this.currentEntity.entity_type == "INDIVIDUAL") {
        //   this.addNewEntityData();
        // } else {
        //   this.addPrimaryDataToEntity();
        // }
      }
    },
    async saveAndAssignRelation(entityDataId) {
      if (this.relationshipDetails?.representation == "FIELD") {
        this.$emit("quickAddClose");
      } else {
        if (this.getSubentityRelationships) {
          this.relationships = [...(this.getSubentityRelationships || [])];
          await this.setRelationshipDetails(entityDataId);
        }
        this.$emit("onNewEntity", {
          entity_id: this.currentEntity._id,
          entity_data_id: entityDataId,
        });
      }
      this.quickAddDialogVisible = false;
    },
    saveAndCloseEditDialog() {
      this.quickAddDialogVisible = false;
      this.$emit("onNewEntity");
    },
    groupBy(array, f) {
      var groups = {};
      array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },

    handleClose() {
      this.quickAddDialogVisible = false;
      this.newEntityForm = {};
      this.loading = false;
      if (this.openNewAdd) {
        this.$emit("quickAddClose", false);
      }
      if (this.isFromAddOtherEntity) {
        this.$emit("onNewEntity");
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    checkFormData() {},
    async setRelationshipDetails(dataId) {
      let relationshipsData = [...(this.relationships || [])];

      relationshipsData = relationshipsData.map((e) => {
        if (e.parent_entity_id == this.currentEntity._id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      relationshipsData = relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      let allFields = this.getAllFieldsFormEntity(this.currentEntity);
      let toAssignRelationshipsData = this.prepareRelationshipData(
        allFields,
        relationshipsData,
        dataId,
        null,
        this.newEntityForm
      );
      if (toAssignRelationshipsData.length) {
        await this.$store.dispatch(
          "entityRelationships/assignFieldsEntityRelationshipData",
          toAssignRelationshipsData
        );
      }
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.getTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    async addNewEntityData() {
      try {
        this.loading = true;
        let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.newEntityForm && !this.newEntityForm.name) {
          this.newEntityForm.name =
            this.newEntityForm.first_name + " " + this.newEntityForm.last_name;
        }
        if (!re.test(this.newEntityForm.email)) {
          this.loading = false;

          this.$message({
            message: "Invalid email format!",
            type: "error",
          });
          return;
        }
        // let checkData = this.checkFormData();
        let data = {
          entity_details_id: null,
          template_id: this.standardTemplate.template_id,
          template_data: this.newEntityForm,
          entity_id: this.currentEntity._id,
          template_completion_status: true,
          entity_data_id: null,
        }; 

        let response = await postAPICall(
          "POST",
          "templates-data/check-duplicate-rules",
          {
            data: [
              {
                form: this.newEntityForm,
                template_id: this.standardTemplate.template_id,
              },
            ],
            entity_id: this.currentEntity?._id,
            parent_entityDataId: this.entityDataId || null,
          }
        );
        if (!response?.data?.allowed) {
          this.loading = false;
          return this.$notify.warning({
            title: "Warning",
            message:
              "With this data already " +
              (this.currentEntity && this.currentEntity.name
                ? this.currentEntity.name + " is"
                : "data is") +
              " created.",
          });
        }

        await Promise.all([
          this.$store.dispatch(
            "templatesData/createEntityDataByTemplateData",
            data
          ),
          this.$store.dispatch(
            "entityRelationships/fetchSubEntityRelationships",
            {
              entity_id: this.currentEntity._id,
            }
          ),
        ]);

        if (this.getEntityDataCreateByTemplateStatus) {
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: "Data saved successfully",
          });
          if (this.getNewEntityData) {
            if (this.getSubentityRelationships) {
              this.relationships = [...(this.getSubentityRelationships || [])];
              await this.setRelationshipDetails();
            }
            this.$emit("onNewEntity", {
              entity_id: this.getNewEntityData.entity_id,
              entity_data_id: this.getNewEntityData._id,
            });
          }
          this.quickAddDialogVisible = false;
        } else {
          this.loading = false;
          if (
            this.getNewEntityCreateError &&
            this.getNewEntityCreateError.message
          ) {
            this.$notify.error({
              title: "Error",
              message: this.getNewEntityCreateError.message,
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
        }
      } catch (err) {
        console.log("addEntityData", err);
      }
    },
    mapWithParentFields(fields, currentTemplateId) {
      if (
        // this.parentFields &&
        this.actionButtonField?.new_entity_data_mapping &&
        (this.parentEntityData?.templates_data || this.parentForm)
      ) {
        let currentTemplateObject = {};
        (this.parentEntityData?.templates_data || []).forEach((t) => {
          currentTemplateObject[t.template_id] =
            t?.template_data_id?.template_data || {};
        });
        currentTemplateObject["current_template"] = this.parentForm || {};
        fields.forEach((field) => {
          let key = field.key;
          if (!key.includes("#") && this.currentEntity?.templates?.[0]?._id) {
            key = currentTemplateId + "#" + key;
          }
          if (
            this.actionButtonField.new_entity_data_mapping[key] ==
            "current_entity"
          ) {
            if (
              field.entity_id == this.parentEntityData?.entity_id &&
              this.parentEntityData?._id
            ) {
              this.$set(
                this.newEntityForm,
                field.key,
                this.parentEntityData._id
              );
            }
          } else if (
            this.actionButtonField?.new_entity_data_mapping?.[key] &&
            this.actionButtonField.new_entity_data_mapping[key].includes("#")
          ) {
            let [t, k] =
              this.actionButtonField?.new_entity_data_mapping[key].split("#");
            if (currentTemplateObject?.[t]?.[k]) {
              this.$set(
                this.newEntityForm,
                field.key,
                currentTemplateObject[t][k]
              );
              if (currentTemplateObject?.[t]?.[k + "/name"]) {
                this.$set(
                  this.newEntityForm,
                  field.key + "/name",
                  currentTemplateObject[t][k + "/name"]
                );
              }
            }
          }
          if (this.currentEntity?.templates) {
            (this.currentEntity.templates || []).forEach((template) => {
              if (
                template?.template_id !== currentTemplateId &&
                template?.templateInfo?.sections?.[0]?.fields
              ) {
                let newEntityForm = {},
                  otherTemplateFields =
                    template.templateInfo.sections[0].fields;
                otherTemplateFields.forEach((field) => {
                  let key = field.key;
                  if (
                    !key.includes("#") &&
                    this.currentEntity?.templates?.[0]?._id
                  ) {
                    key = template.template_id + "#" + key;
                  }
                  if (
                    this.actionButtonField?.new_entity_data_mapping?.[key] &&
                    this.actionButtonField.new_entity_data_mapping[
                      key
                    ].includes("#")
                  ) {
                    let [t, k] =
                      this.actionButtonField?.new_entity_data_mapping[
                        key
                      ].split("#");
                    if (currentTemplateObject?.[t]?.[k]) {
                      this.$set(
                        newEntityForm,
                        field.key,
                        currentTemplateObject[t][k]
                      );
                      if (currentTemplateObject?.[t]?.[k + "/name"]) {
                        this.$set(
                          newEntityForm,
                          field.key + "/name",
                          currentTemplateObject[t][k + "/name"]
                        );
                      }
                    }
                  }
                });
                this.$set(
                  this.otherEntityForm,
                  template.template_id,
                  newEntityForm
                );
              }
            });
          }
          // let relatedField = this.parentFields.find(
          //   (e) => {
          //     if(e.input_type == field.input_type){
          //       if(e.input_type == 'ENTITY'){
          //         return (e.entity_id == field.entity_id)
          //       }else{
          //         return e.label == field.label;
          //       }
          //     }
          //   }
          // );
          // if (relatedField?.key && this.parentForm?.[relatedField.key]) {
          //   this.$set(
          //     this.newEntityForm,
          //     field.key,
          //     this.parentForm?.[relatedField.key]
          //   );
          //   if (this.parentForm?.[relatedField.key + "/name"]) {
          //     this.$set(
          //       this.newEntityForm,
          //       field.key + "/name",
          //       this.parentForm?.[relatedField.key + "/name"]
          //     );
          //   }
          // }
        });
      }
    },
    openClickAdd() {
      this.primaryFields = [];
      if (this.currentEntity.entity_type == "INDIVIDUAL") {
        this.standardTemplate = this.currentEntity.templates.find(
          (e) =>
            e.templateInfo &&
            e.templateInfo.slug == "contactdetails" &&
            e.templateInfo.type == "STANDARD"
        );
        if (this.standardTemplate) {
          let allFields = this.getTemplateFields(
            this.standardTemplate.templateInfo
          );
          this.primaryFields = allFields.filter(
            (e) =>
              e.key == "email" || e.key == "last_name" || e.key == "first_name"
          );
          this.primaryFields = this.primaryFields.map((e) => {
            e.filled_by = "SENDER_OR_RECEIVER";
            return e;
          });
          this.mapWithParentFields(
            allFields,
            this.standardTemplate?.templateInfo?._id
          );
        }
      } else {
        if (this.detailed_add) {
          this.currentEntity.templates = (
            this.currentEntity.templates || []
          ).map((template, i) => {
            if (template?.templateInfo?.sections?.[0]?.fields) {
              if (i == 0) {
                this.mapWithParentFields(
                  template.templateInfo.sections[0].fields,
                  template?.templateInfo?._id
                );
              }
              template.templateInfo.sections[0].fields.map((field) => {
                if (
                  this.relationshipDetails?.field_id == field._id &&
                  this.relationshipDetails?.representation == "FIELD" &&
                  this.actionButtonField?.parent_entityId ==
                    this.relationshipDetails?.child_entity_id
                ) {
                  field.default_entity_data_ids = [
                    this.actionButtonField.parent_entityDataId,
                  ];
                }
              });
            }
            return template;
          });
        }
        this.currentEntity.primaryFields.forEach((field) => {
          (this.currentEntity.templates || []).forEach((template) => {
            if (
              template.template_id == field.template_id &&
              template?.templateInfo?.sections?.[0]?.fields
            ) {
              let latestField = template.templateInfo.sections[0].fields.find(
                (pl) => pl.key == field.key
              );
              if (latestField) {
                // If parent template entity field is present on new entity also we will auto fill that
                if (
                  latestField?.input_type == "ENTITY" &&
                  this.parentFields &&
                  this.parentFields.length &&
                  this.parentForm
                ) {
                  let relatedField = this.parentFields.find(
                    (e) =>
                      e.entity_id == latestField.entity_id &&
                      e.input_type == "ENTITY"
                  );
                  if (
                    relatedField?.key &&
                    this.parentForm?.[relatedField.key]
                  ) {
                    this.$set(
                      this.newEntityForm,
                      latestField.key,
                      this.parentForm?.[relatedField.key]
                    );
                    this.$set(
                      this.newEntityForm,
                      latestField.key + "/name",
                      this.parentForm?.[relatedField.key + "/name"]
                    );
                  }
                }
                this.primaryFields.push(latestField);
              }
            }
          });
        });
        // this.primaryFields = this.currentEntity.primaryFields;
        this.primaryFields = this.primaryFields.map((e) => {
          if (!e.filled_by) {
            e.filled_by = "SENDER_OR_RECEIVER";
          }
          return e;
        });
      }
      this.quickAddDialogVisible = true;
      // this.sections = this.groupBy(
      //   this.currentEntity.primary_fields,
      //   function (item) {
      //     return [item.section_id];
      //   }
      // );

      // this.sections = this.sections.map((e) => {
      //   return {
      //     template_data: this.getEmptyFormDataForFields(e),
      //     fields: e,
      //     section_id: e[0].section_id,
      //     template_id: e[0].template_id,
      //     section: e[0].section,
      //   };
      // });
    },

    getEmptyFormDataForFields(fields) {
      let formData = {};
      fields.forEach((e) => {
        formData[e.key] = "";
      });
      return formData;
    },
    async prepareFormData() {
      for (let [key, value] of Object.entries(this.newEntityForm)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loading = true;
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.newEntityForm[key].path = path;
          this.newEntityForm[key].new = false;
        }
      }
      return this.newEntityForm;
    },
    async addPrimaryDataToEntity() {
      try {
        this.loading = true;
        if (this.newEntityForm) {
          let data = {
            primaryFields: this.currentEntity.primaryFields,
            formData: this.newEntityForm,
            templates: this.currentEntity.templates,
            entity_id: this.currentEntity._id,
          };
          let response = await postAPICall(
            "POST",
            "templates-data/check-duplicate-rules",
            {
              data: this.getTemplateWiseData() || [
                {
                  form: this.newEntityForm,
                  template_id: this.currentEntity.primaryFields[0]?.template_id,
                },
              ],
              entity_id: this.currentEntity._id,
            }
          );
          if (!response?.data?.allowed) {
            this.loading = false;
            return this.$notify.warning({
              title: "Warning",
              message:
                "With this data already " +
                (this.currentEntity && this.currentEntity.name
                  ? this.currentEntity.name + " is"
                  : "data is") +
                " created.",
            });
          }
          await Promise.all([
            this.$store.dispatch(
              "entityRelationships/fetchSubEntityRelationships",
              {
                entity_id: this.currentEntity._id,
              }
            ),
            this.$store.dispatch("templatesData/createEntityPrimaryData", data),
          ]);
          const fileFields = this.currentEntity.primaryFields.filter(
            (el) => el && el.input_type == "FILE"
          );
          if (fileFields && fileFields.length) {
            await this.prepareFormData();
          }
          if (this.getPrimaryEntityDataStatus) {
            if (this.getNewEntityData && this.getNewEntityData._id) {
              if (this.getSubentityRelationships) {
                this.relationships = [
                  ...(this.getSubentityRelationships || []),
                ];
                await this.setRelationshipDetails(this.getNewEntityData._id);
              }
              this.$emit("onNewEntity", {
                entity_id: this.getNewEntityData.entity_id,
                entity_data_id: this.getNewEntityData._id,
              });
              this.quickAddDialogVisible = false;
            }
            this.$notify.success({
              title: "Success",
              message: "Primary Data saved successfully",
            });
            this.loading = false;
            this.newEntityForm = {};
          } else {
            this.loading = false;
            let msg = "Error at saving data";
            if (
              this.getPrimaryEntityDataErrors &&
              this.getPrimaryEntityDataErrors.message
            ) {
              msg = this.getPrimaryEntityDataErrors.message;
            }
            this.$notify.error({
              title: "Error",
              message: msg,
            });
          }
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
      } catch (err) {
        console.log("addPrimaryDataToEntity", err);
      }
    },
    getTemplateWiseData() {
      let templateWiseData = {};
      this.currentEntity.primaryFields.map((fd) => {
        if (!templateWiseData[fd.template_id]) {
          templateWiseData[fd.template_id] = {
            [fd.key]: this.newEntityForm[fd.key],
          };
        } else {
          templateWiseData[fd.template_id][fd.key] = this.newEntityForm[fd.key];
        }
      });
      let returnArray = [];
      (Object.keys(templateWiseData) || []).map((tempId) => {
        returnArray.push({
          form: templateWiseData[tempId],
          template_id: tempId,
        });
      });
      return returnArray;
    },
    async uploadFile(file) {
      var formData = new FormData();
      file.forEach((el) => {
        formData.append("files", el.raw !== undefined ? el.raw : el);
      });
      formData.append("path", "template-data-documents");
      let filesData = await axios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(filesData);
    },
    async submitData() {
      try {
        this.loading = true;

        let params = {
          entity_id: this.currentEntity._id,
          sections_data: this.sections.map((e) => {
            return {
              section_id: e.section_id,
              template_id: e.template_id,
              template_data: e.template_data,
            };
          }),
        };

        await this.$store.dispatch(
          "entitiesData/addNewEntityDataWithQuickAdd",
          params
        );
        this.loading = false;
        if (this.getNewEntityDataWithQuickAdd) {
          this.$message(`New ${this.currentEntity.name} Added!`);

          this.$emit("onNewEntity", {
            entity_id: this.getNewEntityDataWithQuickAdd.entity_id,
            entity_data_id: this.getNewEntityDataWithQuickAdd._id,
          });

          this.quickAddDialogVisible = false;
        }
      } catch (err) {
        console.log("submitData", err);
      }
    },
  },
};
</script>

<style>
.scaling-button:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease; /* Add a smooth transition effect */
}

.el-dialog__footer {
  padding: 0px 20px 50px;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
