<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item class="rows-require">
              No of Rows Required?
              <span style="color:red; font-size: 20px;">*</span>             
              <el-input
              type="number"
              maxlength="50"
              min="3"
              resize="none"
              @change="checkMaxMinLen"
              v-model="field.rows"
              placeholder="Enter Number Of Rows"
            ></el-input>
            <p v-if="maxMinErr" style="color:red; font-size: 14px;">{{ maxMinErrMsg }}</p>
              <!-- <el-select
                v-model="field.rows"
                placeholder="Select rows"
              >
                <el-option
                  v-for="item in rowsOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="colSpan">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
      <el-form-item label="Value">
        <span style="color:red; font-size: 20px;">*</span>  
        <el-input
          type="textarea"
          :rows="field.rows"
          placeholder="Value"
          v-model="field.value"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
import Tag from "./Tag";
import { mapGetters } from "vuex";

export default {
  components: {
    TitleAndDescription,
    Tag,
  },
  props: ["field"],
  data() {
    return {
      rowsOptions: [1, 2, 3, 4 ,5],
      maxMinErrMsg: '',
      maxMinErr:false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen",["getIsMobile"]),
    colSpan() {
      return this.getIsMobile ? 24 : 12;
    }
  },
  methods: {
    checkMaxMinLen(){
      if(this.field && this.field.rows){
          const maxlength =this.field.rows;
            if( maxlength< 3){
              this.field.rows=3;
              this.maxMinErrMsg = 'Number Rows value should not less than 3';
              this.maxMinErr = true;
            }else{
              this.maxMinErr = false;
            }
      }
     },
    }
};

</script>

<style lang="scss" scoped>
  @media (max-width: 767px) {
    .rows-require{
      width:185px;
    }
}
</style>