<template>
  <section class="all-templates-view bt">
    <el-row v-if="fileManagementModal">
      <!-- Entity file Management -->
      <div>
        <entity-data-documents
          v-if="!refresh"
          :entityId="selectedFileEntity"
          :folder="folder"
          :showBack="true"
          @moveToFolder="moveToFolder"
          @back="fileManagementModalReset"
        >
        </entity-data-documents>
      </div>
    </el-row>
    <el-row type="flex" justify="center" align="center" v-else>
      <el-col :span="22">
        <el-row style="margin-bottom: 20px">
          <el-col :lg="8" :md="8" :sm="24" :xs="24">
            <h3
              class="title fw-normal text-dark mb-0 ls-05"
              v-if="
                this.$route.query &&
                this.$route.query.group &&
                getSingleEntityGroupData &&
                getSingleEntityGroupData.name
              "
            >
              {{ getSingleEntityGroupData.name }}
            </h3>
            <h3
              v-else
              class="title fw-normal text-dark mb-0 ls-05"
              style="margin-top: 10px"
            >
              {{ "All Entities" | getMenuTitle }}
            </h3>
          </el-col>

          <el-col
            :lg="16"
            :md="16"
            :sm="24"
            :xs="24"
            style="text-align: right"
            class="top-buttons-wrapper"
          >
            <el-tooltip
              content="Export All Entities With Data"
              placement="bottom"
              class="export-button"
            >
              <Button
                v-if="IframePermissions('EXPORT', 'EntityList') && total <= 30"
                @click="exportAllEntityData"
                label=""
                type="button"
                class="btn btn-sm btn-outline-secondary m-lr-1"
                ><i class="fa fa-cloud-upload" aria-hidden="true"></i>
              </Button>
            </el-tooltip>
            <el-select
              class="search"
              v-model="selectedType"
              placeholder="Select Type"
              clearable
              size="small"
              style="margin-right: 10px; width: 170px"
              @change="fetchEntities()"
              filterable
            >
              <el-option
                v-for="(item, index) of entityTypes"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              class="search"
              prefix-icon="el-icon-search"
              placeholder="Search"
              size="small"
              v-model="search_string"
              clearable
              @input="searchString"
              :style="getIsMobile ? 'width:130px' : 'width:170px;'"
            ></el-input>
            <button
              v-if="getIframeUrlInfo == null"
              class="btn btn-sm btn-outline-primary export-all m-lr-1"
              type="button"
              @click="openNewEntity"
              style="font-family: var(--font-family)"
            >
              <i class="fa fa-plus-circle"></i>
              {{ getIsMobile ? "" : "Create Entity" }}
            </button>
            <el-tooltip content="Create Iframe link" placement="top">
              <el-link
                v-if="getIframeUrlInfo == null"
                icon="el-icon-link"
                style="font-size: 24px; float: right"
                :underline="false"
                class="mt-"
                @click="openViewConfigPage"
              ></el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <div
          v-loading="configureLoading"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="vue-data-table-default"
        >
          <data-tables-server
            :fullscreen="true"
            :total="total"
            :data="allEntities"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            style="width: 100%"
            v-if="!getIsMobile"
            @query-change="loadData"
            @sort-change="sortChange"
          >
            <el-table-column
              label="Name"
              id="name"
              prop="name"
              width="500"
              fixed="left"
              sortable="custom"
            >
              <template slot-scope="scope">
                <el-button
                  slot="reference"
                  type="text"
                  :disabled="!IframePermissions('VIEW', 'EntityList')"
                  @click="
                    IframePermissions('VIEW', 'EntityList')
                      ? onView(scope.row)
                      : () => {}
                  "
                >
                  <div
                    style="
                      color: #4c4b4e;
                      font-weight: 400;
                      font-family: var(--font-family);
                    "
                  >
                    {{ scope.row.name }}
                    <i
                      class="el-icon-star-on"
                      v-if="scope.row.entity_type === 'BUSINESS'"
                    ></i>
                    <i
                      class="el-icon-user"
                      v-if="scope.row.entity_type === 'INDIVIDUAL'"
                    ></i>

                    <i
                      class="el-icon-magnet"
                      v-if="scope.row.entity_type === 'GENERAL'"
                    ></i>
                  </div>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Actions"
              prop="action"
              class="action"
              width="200"
            >
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <li>
                    <el-dropdown>
                      <el-button size="mini" class="el-dropdown-link px-2">
                        Actions
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <!-- <a
                          @click="goToEntity(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-files"></i> View Data</el-dropdown-item
                          >
                        </a>-->
                        <!-- <a @click="onCustomTemplateView(scope.row)" style="border:none;"><el-dropdown-item icon="el-icon-view" command="edit" :disabled="scope.row.type === 'STANDARD'">View</el-dropdown-item></a > -->
                        <a
                          v-if="IframePermissions('VIEW', 'EntityList')"
                          @click="onView(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item icon="el-icon-view" command="view"
                            >View</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="getIframeUrlInfo == null"
                          @click="onEdit(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item
                            icon="el-icon-edit-outline"
                            command="edit"
                            >Edit</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="getIframeUrlInfo == null"
                          @click="onRules(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item
                            icon="el-icon-s-grid"
                            command="rules"
                            >Rules</el-dropdown-item
                          >
                        </a>
                        <!-- <a
                          v-if="scope.row.entity_type === 'GENERAL'"
                          @click="onAssignToCompany(scope.row)"
                          style="border: none"
                          ><el-dropdown-item
                            icon="el-icon-edit-outline"
                            command="edit"
                          >
                            Attached To Company Info</el-dropdown-item
                          ></a
                        >-->
                        <a
                          v-if="IframePermissions('DELETE', 'EntityList')"
                          @click="onDelete(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item
                            icon="el-icon-delete"
                            command="delete"
                            >Delete</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="getIframeUrlInfo == null"
                          @click="openFiles(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item icon="el-icon-folder"
                            >Files</el-dropdown-item
                          >
                        </a>
                        <a
                          style="border: none"
                          @click="openPermissionsDialog(scope.row)"
                          v-if="
                            scope.row.entity_type !== 'GENERAL' &&
                            getIframeUrlInfo == null
                          "
                        >
                          <el-dropdown-item
                            icon="el-icon-unlock"
                            command="Permissions"
                            >Login Permissions</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="openMenuDialog(scope.row)"
                          style="border: none"
                          v-if="
                            scope.row.entity_type !== 'GENERAL' &&
                            getIframeUrlInfo == null
                          "
                        >
                          <el-dropdown-item icon="el-icon-menu" command="menu"
                            >Custom Menu</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="openCustomDashboard(scope.row)"
                          style="border: none"
                          v-if="
                            scope.row.entity_type !== 'GENERAL' &&
                            getIframeUrlInfo == null
                          "
                        >
                          <el-dropdown-item
                            icon="el-icon-data-analysis"
                            command="menu"
                            >Custom Dashboard</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="getIframeUrlInfo == null"
                          @click="openSendNotification(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item
                            icon="el-icon-s-promotion"
                            command="menu"
                            >Send Notification</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="getIframeUrlInfo == null"
                          @click="openSendWANotification(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item
                            icon="el-icon-chat-dot-round"
                            command="menu"
                            >Send Notification(WA)</el-dropdown-item
                          >
                        </a>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              id="status"
              prop="title"
              width="200"
              sortable="custom"
            >
              <template slot-scope="scope" id="category">
                <div class="d-flex activestatus">
                  <div
                    :class="scope.row.status.toLowerCase() + ' circle'"
                  ></div>
                  <div>
                    <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                    <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                    <p v-else>Deleted</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Last Modified"
              prop="updated_at"
              sortable="custom"
              min-width="200"
            >
              <template slot-scope="scope">
                {{ scope.row.updated_at | globalDateTimeFormat }}
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
        <data-tables-server
          :fullscreen="true"
          :total="total"
          :data="allEntities"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          style="width: 100%"
          v-if="getIsMobile"
          @query-change="loadData"
          @sort-change="sortChange"
          class="entities-list-mobile"
          :header-cell-style="{ background: '#F1EFF0' }"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item">
                <div class="expand-column-item-label">Status:</div>
                <div class="expand-column-item-content">
                  <div class="d-flex activestatus">
                    <div
                      :class="scope.row.status.toLowerCase() + ' circle'"
                    ></div>
                    <div>
                      <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                      <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                      <p v-else>Deleted</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Last Modified:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.updated_at">
                    <span class="fs-8 d-inline-block pr-2 text-muted">{{
                      scope.row.updated_at | globalDateTimeFormat
                    }}</span>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Name" id="name" min-width="120" fixed>
            <template slot-scope="scope">
              <a class="doc-title" @click="onView(scope.row)">
                {{ scope.row.name | truncate(15, "...") }}
                <i
                  class="el-icon-star-on"
                  v-if="scope.row.entity_type === 'BUSINESS'"
                ></i>
                <i
                  class="el-icon-user"
                  v-if="scope.row.entity_type === 'INDIVIDUAL'"
                ></i>
                <i
                  class="el-icon-magnet"
                  v-if="scope.row.entity_type === 'GENERAL'"
                ></i>
              </a>
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100" fixed>
            <template slot-scope="scope">
              <ul class="action-buttons hover-buttons">
                <li>
                  <el-dropdown>
                    <el-button size="mini" class="el-dropdown-link px-2">
                      Actions
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <a
                        @click="goToEntity(scope.row)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-files"></i> View Data</el-dropdown-item
                        >
                      </a>-->
                      <!-- <a @click="onCustomTemplateView(scope.row)" style="border:none;"><el-dropdown-item icon="el-icon-view" command="edit" :disabled="scope.row.type === 'STANDARD'">View</el-dropdown-item></a > -->
                      <a @click="onView(scope.row)" style="border: none">
                        <el-dropdown-item icon="el-icon-view" command="view"
                          >View</el-dropdown-item
                        >
                      </a>
                      <a @click="onEdit(scope.row)" style="border: none">
                        <el-dropdown-item
                          icon="el-icon-edit-outline"
                          command="edit"
                          >Edit</el-dropdown-item
                        >
                      </a>
                      <a @click="onRules(scope.row)" style="border: none">
                        <el-dropdown-item icon="el-icon-s-grid" command="rules"
                          >Rules</el-dropdown-item
                        >
                      </a>
                      <!-- <a
                        v-if="scope.row.entity_type === 'GENERAL'"
                        @click="onAssignToCompany(scope.row)"
                        style="border: none"
                        ><el-dropdown-item
                          icon="el-icon-edit-outline"
                          command="edit"
                        >
                          Attached To Company Info</el-dropdown-item
                        ></a
                      >-->
                      <a @click="onDelete(scope.row)" style="border: none">
                        <el-dropdown-item icon="el-icon-delete" command="delete"
                          >Delete</el-dropdown-item
                        >
                      </a>
                      <a @click="openFiles(scope.row)" style="border: none">
                        <el-dropdown-item icon="el-icon-folder"
                          >Files</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="openSendNotification(scope.row)"
                        style="border: none"
                      >
                        <el-dropdown-item icon="el-icon-s-promotion"
                          >Send notification</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="getIframeUrlInfo == null"
                        @click="openSendWANotification(scope.row)"
                        style="border: none"
                      >
                        <el-dropdown-item
                          icon="el-icon-s-promotion"
                          command="menu"
                          >Send notification(WA)</el-dropdown-item
                        >
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>
            </template>
          </el-table-column>
        </data-tables-server>
      </el-col>
      <dialog-component
        :title="'New Entity Information'"
        :visible="newEntityModal"
        class="doc-modal new-entity-information"
        v-loading="modalLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        @before-close="destroyCreateModal"
        :containerWidth="getIsMobile ? '100%' : '45%'"
        :containerHeight="'70%'"
      >
        <el-form label-position="top">
          <div class="scrollbar-content">
            <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
            <p class="mt-10">
              Title
              <span class="text-danger">*</span>
            </p>
            <el-input
              type="text"
              placeholder="Title"
              v-model="entityName"
            ></el-input>
            <p class="mt-20">Description</p>
            <el-input
              type="textarea"
              placeholder="Description"
              v-model="description"
            ></el-input>
            <el-row>
              <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                <p class="mt-20">Entity Type</p>
                <el-radio-group
                  size="mini"
                  v-model="entity_type"
                  fill="#409EFF"
                  style="margin-bottom: 1rem; margin-left: 0rem"
                  class="entity-type-radio"
                >
                  <div class="radio-group">
                    <el-radio-button
                      label="GENERAL"
                      @click.native="isAccount = false"
                    ></el-radio-button>
                    <el-radio-button
                      label="BUSINESS"
                      @click.native="isAccount = true"
                    ></el-radio-button>
                    <el-radio-button
                      label="INDIVIDUAL"
                      @click.native="isAccount = true"
                    ></el-radio-button>
                  </div>
                </el-radio-group>
              </el-col>
              <el-col
                :xl="12"
                :lg="12"
                :md="12"
                :sm="24"
                :xs="24"
                v-if="!this.$route.query.group"
              >
                <p class="mt-20 ml-3">
                  Groups
                  <span class="text-danger">*</span>
                </p>
                <el-col
                  :span="20"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;
                    margin-left: 20px;
                  "
                >
                  <el-select
                    v-model="entityGroupsData"
                    placeholder="Select Group"
                    multiple
                    filterable
                    collapse-tags
                  >
                    <el-option
                      v-for="(item, index) of getAllEntityGroupsData"
                      v-bind:key="index"
                      :label="item.name"
                      :value="item._id"
                    >
                    </el-option>
                  </el-select>
                  <el-tooltip
                    content="Add New Group"
                    placement="top"
                    effect="light"
                  >
                    <i
                      class="el-icon-circle-plus-outline"
                      @click="addGroupPopUP"
                    ></i>
                  </el-tooltip>
                </el-col>
              </el-col>
            </el-row>
            <el-form-item>
              <div class="entity-box d-flex flex-row">
                <div
                  class="entity-type-box"
                  @click="selectScratchbox"
                  v-if="isCreateFromScratchSelected === false"
                >
                  <el-row>
                    <el-col :xl="22" :lg="22" :md="22" :sm="24" :xs="24">
                      <img src="@/assets/img/scratch.svg" />
                    </el-col>
                    <el-col :xl="2" :lg="2" :md="2" :sm="24" :xs="24">
                      <el-radio></el-radio>
                    </el-col>
                  </el-row>
                  <h4>Start from scratch</h4>
                  <p>
                    Create a new app base with custom fields,tables, and
                    filters.
                  </p>
                </div>
                <div
                  class="entity-type-box"
                  @click="selectScratchbox"
                  style="border-color: #409eff"
                  v-else
                >
                  <el-row>
                    <el-col :xl="22" :lg="22" :md="22" :sm="24" :xs="24">
                      <img src="@/assets/img/scratch.svg" />
                    </el-col>
                    <el-col :xl="2" :lg="2" :md="2" :sm="24" :xs="24">
                      <el-radio></el-radio>
                    </el-col>
                  </el-row>
                  <h4>Start from scratch</h4>
                  <p>
                    Create a new app base with custom fields,tables, and
                    filters.
                  </p>
                </div>
                <div
                  class="entity-type-box"
                  @click="selectExcelbox"
                  v-if="isCreateFromExcelSelected === false"
                >
                  <el-row>
                    <el-col :xl="22" :lg="22" :md="22" :sm="24" :xs="24">
                      <img src="@/assets/img/Excel.svg" />
                    </el-col>
                    <el-col :xl="2" :lg="2" :md="2" :sm="24" :xs="24">
                      <el-radio></el-radio>
                    </el-col>
                  </el-row>
                  <h4>Create from excel</h4>
                  <p>
                    Transfer your current data into the system to generate an
                    application.
                  </p>
                </div>
                <div
                  class="entity-type-box"
                  @click="selectExcelbox"
                  style="border-color: #409eff"
                  v-else
                >
                  <el-row>
                    <el-col :xl="22" :lg="22" :md="22" :sm="24" :xs="24">
                      <img src="@/assets/img/Excel.svg" />
                    </el-col>
                    <el-col :xl="2" :lg="2" :md="2" :sm="24" :xs="24">
                      <el-radio></el-radio>
                    </el-col>
                  </el-row>
                  <h4>Create from excel</h4>
                  <p>
                    Transfer your current data into the system to generate an
                    application.
                  </p>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" style="text-align: right">
          <button
            class="btn btn btn-outline-danger btn-sm m-lr-1"
            type="button"
            @click="newEntityModal = false"
          >
            <i class="fa fa-close"></i> Cancel
          </button>
          <button
            class="btn btn btn-outline-primary btn-sm m-lr-1"
            type="button"
            :disabled="!entityName.trim()"
            @click="createEntity"
          >
            <i class="fa fa-check-square-o"></i> Confirm
          </button>
        </span>
      </dialog-component>
    </el-row>

    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Add New Group'"
      :visible="showAddGroupDialog"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      @before-close="showAddGroupDialog = false"
      :isShowFooter="false"
    >
      <el-form
        class="m-tb-2"
        :model="entitiesListForm"
        ref="entitiesListForm"
        :rules="rules"
        @submit.native.prevent="saveGroup"
      >
        <el-form-item label="Group Title" prop="groupName">
          <el-input
            type="text"
            v-model="entitiesListForm.groupName"
            placeholder="Enter Title"
            :style="{ height: '50px' }"
          ></el-input>
        </el-form-item>
        <div class="d-flex float-right">
          <el-form-item>
            <button
              class="btn btn-outline-danger btn-sm m-lr-1"
              type="button"
              @click="showAddGroupDialog = false"
            >
              <i class="fa fa-close"></i> Cancel
            </button>
          </el-form-item>
          <el-form-item>
            <button
              class="btn btn-outline-primary btn-sm m-lr-1"
              type="button"
              @click="saveGroup"
              :disabled="loadingSaveGroup"
            >
              <i v-if="loadingSaveGroup" class="fa fa-spinner fa-spin"></i>
              <i v-else class="fa fa-check-square-o"></i>
              {{ loadingSaveGroup ? "Saving..." : "Save Group" }}
            </button>
          </el-form-item>
        </div>
      </el-form>
    </dialog-component>
    <dialog-component
      :title="'Login permissions'"
      :visible="showPermissionsDialog"
      :containerWidth="'75vw'"
      @before-close="showPermissionDialog"
      :isShowFooter="false"
    >
      <div v-if="selectedEntity && selectedEntity._id" class="mt-3">
        <Permissions
          v-if="showPermissionsDialog"
          :selectedEntityId="selectedEntity._id"
          v-on:close="resetEntityPermissions"
          v-on:save="saveEntityPermissions"
        ></Permissions>
      </div>
    </dialog-component>
    <dialog-component
      :title="
        (selectedEntity && selectedEntity.name ? selectedEntity.name : '') +
        ' Custom Menu'
      "
      :visible="showMenuDialog"
      :containerWidth="'60%'"
      :containerHeight="'90%'"
      @before-close="showCustomMenuDialog"
      :isShowFooter="false"
    >
      <div v-if="selectedEntity && selectedEntity._id" class="popUp-content">
        <CustomMenu
          :selectedEntityId="selectedEntity._id"
          v-if="showMenuDialog"
        >
        </CustomMenu>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Delete & Edit Rules'"
      :visible="entityDataRulesModal"
      :containerWidth="getIsMobile ? '100%' : '60%'"
      :isShowFooter="false"
      @before-close="entityDataRulesModalDialog"
    >
      <div v-if="selectedEntity && selectedEntity._id">
        <EntityRules
          v-if="entityDataRulesModal"
          :selectedEntityId="selectedEntity._id"
          :currentEntity="selectedEntity"
          v-on:close="resetEntityRules"
          v-on:save="saveEntityRules"
        ></EntityRules>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Bulk Notification'"
      :visible="sendNotificationDialog"
      @before-close="sendNotificationsDialog"
      v-loading="entityNotificationLoading"
      :isShowFooter="false"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      :containerMinHeight="'50%'"
    >
      <div class="popUp-content">
        <EmailConfig
          :currentEntity="selectedNotifyEntity"
          :isSendIndividual="false"
          :paramsContent="paramsContent"
          :allEntities="allEntities"
          :emailTemplateId="selectedNotifyEntity.default_email_template"
          :parentEmailTemplateId="selectedNotifyEntity.default_parent_template"
          v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
        >
        </EmailConfig>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Bulk Whatsapp Notification'"
      :visible="sendWANotificationDialog"
      @before-close="sendNotificationsDialog"
      v-loading="entityNotificationLoading"
      element-loading-text="Please wait while we process your request."
      :isShowFooter="false"
      :containerWidth="'52%'"
      :containerMinHeight="'50%'"
    >
      <div class="popUp-content">
        <WAConfig
          :currentEntity="selectedNotifyEntity"
          :isSendIndividual="false"
          :paramsContent="paramsContent"
          v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
        >
        </WAConfig>
      </div>
    </dialog-component>

    <el-drawer
      title="Privacy Link"
      :visible.sync="copyModal"
      :direction="'rtl'"
      :with-header="true"
      :size="getIsMobile ? '80%' : '20%'"
      ><IframeLink :routeKey="url_key" type="EntityList"></IframeLink>
    </el-drawer>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import AutomationHelper from "@/mixins/AutomationHelper";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import Permissions from "./ApplicationUserPermissions.vue";
import CustomMenu from "./ApplicationUserMenu.vue";
import {
  fetchEntitiesByPagination,
  deleteEntity,
  addSingleEntityToLocal,
  updateEntityData,
} from "../../repo/entityRepo";
import { fetchGroups, addSingleGroupToLocal } from "@/repo/groupsRepo";
export default {
  name: "Entity-EntitiesList",
  data() {
    return {
      search_string: "",
      entitiesListForm: {
        groupName: "",
      },
      rules: {
        groupName: [
          {
            required: true,
            message: "Group Title is required",
            trigger: "blur",
          },
        ],
      },
      iconPickerdialogVisible:false,
      templateIconPath: "block-portfolio.svg",
      configureLoading: false,
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      newEntityModal: false,
      entityName: "",
      description: "",
      isAccount: false,
      entity_type: "GENERAL",
      modalLoading: false,
      allEntities: [],
      showAddGroupDialog: false,
      loadingSaveGroup: false,
      entityGroupsData: [],
      showPermissionsDialog: false,
      selectedEntity: null,
      showMenuDialog: false,

      isCreateFromScratchSelected: true,
      isCreateFromExcelSelected: false,
      //Entity notification variables
      entityNotificationLoading: false,
      sendNotificationDialog: false,
      sendWANotificationDialog: false,
      paramsData: {},
      emailConfigLoading: false,
      selectedNotifyEntity: {
        _id: "",
      },
      selectedFileEntity: null,
      fileManagementModal: false,
      refresh: false,
      folder: "",
      entityDataRulesModal: false,
      entityTypes: [
        {
          name: "Individual",
          value: "INDIVIDUAL",
        },
        {
          name: "Business",
          value: "BUSINESS",
        },
        {
          name: "General",
          value: "GENERAL",
        },
      ],
      selectedType: "",
      files: [],
      paramsContent: {},
      getAllEntityGroupsData: [],
      copyModal: false,
      url_key: "",
    };
  },
  mixins: [
    PermissionsHelper,
    NavigationHelper,
    EntitiesHelper,
    AutomationHelper,
  ],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
      "getWorkspaceList",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getUserTypeList",
    ]),
    ...mapGetters("entityGroups", ["getSingleEntityGroupData"]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityCreatedStatus",
      "getEntityCreatedData",
      "getEntityDeleteStatus",
      "getEntityDeleteError",
      "getEntityErrors",
      "getEntityDataUpdateStatus",
      "getSentEntityNotificationStatus",
      "getSentEntityNotificationStatusErrors",
      "getEntityVariableFieldsInputTypes",
    ]),
    ...mapGetters("entityGroups", [
      // "getAllEntityGroupsData",
      "getSingleEntityGroupData",
      "getEntityGroupErrors",
      "getError",
    ]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),
    ...mapGetters("customDashboard", ["getEntityCustomDashboard"]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("entityEmailTemplate", [
      "getEntityEmailTemplatesData",
      "getEmailTemplateError",
    ]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  components: {
    Permissions,
    CustomMenu,
    EntityDataDocuments: () => import("./EntityDataDocuments.vue"),
    EntityRules: () => import("./EntityRules.vue"),
    EmailConfig: () => import("../notifications/emailConfig.vue"),
    WAConfig: () => import("../notifications/WAConfig.vue"),
    IframeLink: () => import("../../views/iframeLink.vue"),
  },
  async mounted() {
    await this.fetchGroupDetails();
    await this.fetchEntities();
    if (this.$route.path.includes("/if/")) {
      await this.isIframe();
      // await this.checkUrlType()
    }
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.iconPickerdialogVisible = false;
    },
    async fetchGroupDetails() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        await this.$store.dispatch(
          "roles/fetchRoleById",
          this.getAuthenticatedUser.activeRole.role_id
        );
        if (this.$route.query.group && this.$route.query.group.length) {
          await this.$store.dispatch(
            "entityGroups/fetEntityGroupDataById",
            this.$route.query.group
          );
        }
      }
    },
    showAddgroupDialog() {
      this.showAddGroupDialog = false;
    },
    sendNotificationsDialog() {
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = false;
    },
    entityDataRulesModalDialog() {
      this.entityDataRulesModal = false;
    },
    showPermissionDialog() {
      this.showPermissionsDialog = false;
    },
    showCustomMenuDialog() {
      this.showMenuDialog = false;
    },
    async moveToFolder(folderId) {
      this.refresh = true;
      this.folder = folderId != "root" ? folderId : "";
      let query = this.getNavigationQuery(this.$route.query);
      if (this.folder) {
        query["folder"] = this.folder;
      } else {
        if (query.folder) {
          delete query.folder;
        }
      }
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
    fileManagementModalReset() {
      if (this.$route.query.folder) {
        const query = { ...this.$route.query };
        delete query.folder;
        this.$router.replace({ query });
      }
      this.folder = "";
      this.fileManagementModal = false;
      this.selectedFileEntity = null;
    },
    openFiles(entity) {
      this.selectedFileEntity = entity._id;
      this.fileManagementModal = true;
    },
    async openSendNotification(entity) {
      this.selectedNotifyEntity = entity;
      this.sendNotificationDialog = true;
      this.sendWANotificationDialog = false;
    },
    async openSendWANotification(entity) {
      console.log("WA Notification", entity);
      this.selectedNotifyEntity = entity;
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = true;
    },
    //Entity notification variables
    async closeBulkSendNotificationDialog(params) {
      this.entityNotificationLoading = true;
      if (
        params.attachments &&
        params.attachments?.length &&
        params.event_type == "WA"
      ) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      this.loadingText = "Saving Data";
      await this.$store.dispatch("entities/sendNotificationToAllUsers", params);
      if (this.getSentEntityNotificationStatus) {
        this.emailConfigLoading = false;
        if (this.sendNotificationDialog) {
          this.$notify({
            title: "Success",
            message: "Sending Email message",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Success",
            message: "Messages initiated",
            type: "success",
          });
        }
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: this.getSentEntityNotificationStatusErrors.message,
          type: "error",
        });
      }
      this.entityNotificationLoading = false;
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = false;
    },
    selectScratchbox() {
      this.isCreateFromScratchSelected = true;
      this.isCreateFromExcelSelected = false;
    },
    selectExcelbox() {
      this.isCreateFromScratchSelected = false;
      this.isCreateFromExcelSelected = true;
    },
    async openCustomDashboard(data) {
      this.loading = true;
      // await this.$store.dispatch(
      //   "customDashboard/getDashboardByEntityId",
      //   data._id
      // );
      // if (this.getEntityCustomDashboard?._id) {
      //   this.$router.push({
      //     name: "entity-custom-dashboard-config",
      //     params: {
      //       entityId: data._id,
      //     },
      //     query: {
      //       dashboardId: this.getEntityCustomDashboard._id,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: "entity-custom-dashboard-config",
      //     params: {
      //       entityId: data._id,
      //     },
      //   });
      // }
      this.$router.push(
        `/ap-user/custom-dashboards/${data._id}?name=${data.name}`
      );
    },
    resetEntityPermissions() {
      this.showPermissionsDialog = false;
    },
    resetEntityRules() {
      this.entityDataRulesModal = false;
    },
    async saveEntityRules(data, data1, data2, data3) {
      this.loading = true;
      let params = {
        delete_rules: data,
        delete_condition: data1,
        editable_fields: data3,
        id: this.selectedEntity._id,
        edit_filter: data2,
      };
      await this.$store.dispatch("entities/updateEntityById", params);
      console.log("currentEntity", this.selectedEntity);
      if (this.getEntityDataUpdateStatus) {
        this.loading = false;
        updateEntityData(this.selectedEntity._id, {
          ...this.selectedEntity,
          ...params,
        });
        this.resetEntityRules();
        this.$notify({
          title: "Success",
          message: "Entity delete rules updated successfully",
          type: "success",
        });
        this.fetchEntities();
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message: "Error while updating entity",
          type: "error",
        });
      }
    },
    async saveEntityPermissions(permissions) {
      this.loading = true;
      await this.$store.dispatch("entities/updateEntityById", {
        permissions: permissions,
        id: this.selectedEntity._id,
      });
      if (this.getEntityDataUpdateStatus) {
        this.loading = false;
        this.resetEntityPermissions();
        this.$notify({
          title: "Success",
          message: "Entity permissions updated successfully",
          type: "success",
        });
        this.fetchEntities();
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message: "Error while updating entity",
          type: "error",
        });
      }
    },
    //open permissions dialog
    openPermissionsDialog(data) {
      this.selectedEntity = null;
      this.selectedEntity = data;
      this.showPermissionsDialog = true;
    },
    async fetchAssignedRoleInformation(role) {
      this.loading = true;
      try {
        await this.$store.dispatch("roles/fetchRoleById", role.role_id);
      } catch (err) {
        console.log("fetchAssignedRoleInformation", err);
      }
    },
    openMenuDialog(data) {
      this.selectedEntity = null;
      this.selectedEntity = data;
      this.showMenuDialog = true;
    },

    /*  ***Fetch Groups  start  **** */
    async fetchCompanyGroups() {
      try {
        const groups = await fetchGroups({
          get_all: true,
        });
        if (groups?.data) {
          this.getAllEntityGroupsData = [...groups.data];
          this.entityGroupsData = [groups.data[0]._id];
        }
      } catch (e) {
        console.log("fetchCompanyGroups", e);
      }
    },
    /*  ***Fetch Groups  end  **** */
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchEntities();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchEntities();
      }
    },
    async refreshEntities() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.fetchEntities();
    },
    searchString() {
      this.pageSize = 10;
      this.page = 1;
      this.fetchEntities();
    },
    async loadData(data) {
      if ((data && data.type != "init") || !data) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchEntities();
      }
    },
    async goToEntity(data) {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "entities-Data",
        params: {
          entity_id: data._id,
        },
        query: query,
      });
    },
    async onEdit(document) {
      if (this.getIframeUrlInfo == null) {
        if (
          this.getAuthenticatedUser &&
          document &&
          document.created_by != this.getAuthenticatedUser._id &&
          !this.checkPermissionByPermissionName("editEntity")
        ) {
          this.$notify.error({
            title: "Error",
            message:
              "Permission denied for edit entities.Please contact owner.",
          });
        } else {
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "Edit Entity",
            params: {
              entity_id: document._id,
            },
            query: query,
          });
        }
      }
    },
    onAssignToCompany(document) {
      console.log(document);
    },
    onRules(data) {
      this.selectedEntity = null;
      this.selectedEntity = data;
      this.entityDataRulesModal = true;
    },
    async onView(document) {
      if (
        this.getAuthenticatedUser &&
        document &&
        document.created_by != this.getAuthenticatedUser._id &&
        !this.checkPermissionByPermissionName("viewEntity")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied for view entities.Please contact owner.",
        });
      } else {
        let urlName = "";
        if (this.getIframeUrlInfo?.url_type) {
          urlName = "EntityData1_External";
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: urlName,
            params: {
              entity_id: document._id,
            },
            query: query,
          });
        } else {
          urlName = "EntityData";
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: urlName,
            params: {
              entity_id: document._id,
            },
            query: {
              ...query,
              viewType: document.viewType || "TABLE",
            },
          });
        }
        // let query = await this.getNavigationQuery(this.$route.query);
        // this.$router.push({
        //   name: urlName,
        //   params: {
        //     entity_id: document._id,
        //   },
        //   query: query,
        // });
      }
    },
    destroyCreateModal() {
      this.entityName = "";
      this.description = "";
      this.isAccount = false;

      this.entity_type = "GENERAL";
      this.newEntityModal = false;
    },
    openNewEntity() {
      if (!this.checkPermissionByPermissionName("createEntity")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for creating entities.Please contact owner.",
        });
      } else {
        this.newEntityModal = true;
        this.fetchCompanyGroups();
      }
    },
    async createEntity() {
      let params = {
        icon:this.templateIconPath,
        name: this.entityName.trim(),
        description: this.description,
        entity_type: this.entity_type,
        isAccount: this.isAccount,
        entityGroups: this.$route.query.group
          ? [this.$route.query.group]
          : this.entityGroupsData,
      };
      if (
        this.isCreateFromScratchSelected === true ||
        this.isCreateFromExcelSelected === true
      ) {
        if (this.isCreateFromScratchSelected === true) {
          if (params.entityGroups && params.entityGroups.length) {
            await this.$store.dispatch("entities/createNewEntity", params);
            if (this.getEntityCreatedStatus) {
              if (this.getEntityCreatedData) {
                addSingleEntityToLocal(this.getEntityCreatedData);
              }
              this.$notify.success({
                title: "Success",
                message: "Entity created successfully",
              });
              let params = this.getNavigationQuery(this.$route.query);
              this.$router.replace({
                path: `/entity/${this.getEntityCreatedData._id}/edit`,
                query: params,
              });
            } else {
              this.$notify.error({
                title: "Error",
                message: this.getEntityErrors || "Error on creating Entity",
              });
            }
          } else {
            this.$message.warning("Please choose at least one Group");
          }
        }
        if (this.isCreateFromExcelSelected === true) {
          if (params.entityGroups && params.entityGroups.length) {
            await this.$store.dispatch("entities/createNewEntity", params);
            if (this.getEntityCreatedStatus) {
              this.$notify.success({
                title: "Success",
                message: "Entity created successfully",
              });
              this.$router.push({
                name: "entity-from-excel",
                params: {
                  entity_id: this.getEntityCreatedData._id,
                },
              });
            } else {
              this.$notify.error({
                title: "Error",
                message: this.getEntityErrors || "Error on creating Entity",
              });
            }
          } else {
            this.$message.warning("Please choose at least one Group");
          }
        }
      } else {
        this.$notify.error({
          title: "Error",
          message:
            'please select either "Create from scratch" or "Create from excel" ',
        });
      }
    },
    async fetchEntities() {
      try {
        this.configureLoading = true;
        if (this.pageSize == 0) {
          this.pageSize = 10;
        }
        let params = {
          limit: this.pageSize,
          page: this.page,
        };
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.selectedType) {
          params.entity_type = this.selectedType;
        }
        if(this.templateIconPath){
          params.icon=this.templateIconPath;
        }
        if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
          if (this.$route.query.group && this.$route.query.group.length) {
            params.group = this.$route.query.group;
          } else {
            if (this.getRoleById && this.getRoleById) {
              params.groups = this.getRoleById.allowed_groups;
            }
          }
        }

        // await this.$store.dispatch("entities/fetchEntities", params);
        const getAllEntities = await fetchEntitiesByPagination(params);
        if (getAllEntities) {
          this.allEntities = [...getAllEntities.data];
          this.total =
            getAllEntities && getAllEntities.total ? getAllEntities.total : 0;
          this.currentPage = parseInt(getAllEntities.page) || 1;
          this.configureLoading = false;
        } else {
          this.configureLoading = false;
        }
      } catch (err) {
        console.log("fetchEntities", err);
      }
    },
    async onDelete(row) {
      if (
        this.getAuthenticatedUser &&
        row &&
        row.created_by != this.getAuthenticatedUser._id &&
        !this.checkPermissionByPermissionName("deleteEntity")
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for deleting entities.Please contact owner.",
        });
      } else {
        this.$confirm("Are you sure to delete the Entity?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.deleteEntity(row);
        });
      }
    },
    async deleteEntity(template) {
      let params = {
        id: template._id,
      };
      await this.$store.dispatch("entities/deleteEntity", params);
      await deleteEntity(template._id);
      this.refreshEntities();
      if (this.getEntityDeleteStatus) {
        this.$notify.success({
          title: "Success",
          message: "Entity deleted successfully",
        });
        this.fetchEntities();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getEntityDeleteError || "Error on deleting Entity",
        });
      }
    },
    async addGroupPopUP() {
      this.showAddGroupDialog = true;

      await this.$store.dispatch("entityGroups/resetErrors");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      await this.$store.dispatch("entityGroups/addEntityGroup", {
        name: this.entitiesListForm.groupName,
        description: "",
        entities: [],
      });
      if (
        this.getSingleEntityGroupData &&
        this.getSingleEntityGroupData.success
      ) {
        await addSingleGroupToLocal(this.getSingleEntityGroupData.data);
        this.fetchCompanyGroups();
        this.loadingSaveGroup = false;
        this.entitiesListForm.groupName = "";
        this.showAddGroupDialog = false;
        this.entityGroupsData.push(this.getSingleEntityGroupData.data._id);
      } else if (this.getError && !this.getError.success) {
        this.loadingSaveGroup = false;
        let msg =
          this.getError && this.getError.message
            ? this.getError.message
            : "Something went wrong";
        this.$message.warning(msg);
      }
    },
    // async saveGroup() {
    //   this.loadingSaveGroup = true;
    //   await this.$store.dispatch("entityGroups/addEntityGroup", {
    //     name: this.groupName,
    //     description: "",
    //     entities: [],
    //   });
    //   if (this.getSingleEntityGroupData) {
    //     this.getAllEntityGroupsData.data.push(
    //       this.getSingleEntityGroupData.data
    //     );
    //   }
    //   this.loadingSaveGroup = false;
    //   this.groupName = "";
    //   this.showAddGroupDialog = false;
    // },
    async exportAllEntityData() {
      this.$confirm(
        "Do you want to export all the entities ?",
        "Are you sure ?",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        this.configureLoading = true;
        let currentWorkspace = this.getActiveWorkspace;
        let userWorkspaceList = this.getWorkspaceList;
        let currentWorkspaceInWorkspacesList = userWorkspaceList.find(
          (e) =>
            e.company_id._id.toString() ==
            currentWorkspace.company_id.toString()
        );
        let params = {
          companyName: currentWorkspaceInWorkspacesList.company_id.name,
        };
        await this.$store.dispatch("entities/allEntitiesDataExport", params);
        this.configureLoading = false;
      });
    },
    openViewConfigPage() {
      (this.url_key = this.$route.fullPath), (this.copyModal = true);
    },
  },
  watch: {
    "$route.query.group"() {
      this.fetchEntities();
      // this.currentSlug = slug;
    },
    "$route.query.key"() {
      this.fetchEntities();
    },
  },
  beforeDestroy() {
    this.$store.commit(
      "navigationOpen/setCurrentMenu",
      {},
      {
        root: true,
      }
    );
    this.$store.commit("entities/setAllEntities", null, { root: true });
    this.$store.commit("entities/setEntityDataUpdateStatus", false, {
      root: true,
    });
    this.$store.commit("entities/setSentEntityNotificationStatus", false, {
      root: true,
    });
    this.$store.commit("entities/setEntityCreatedData", null, { root: true });
    this.$store.commit("entities/setEntityDeleteStatus", false, { root: true });
    this.$store.commit("entities/setAllEntityDataExportStatus", false, {
      root: true,
    });

    this.$store.commit("entityGroups/setEntityGroupErrors", {}, { root: true });
    this.$store.commit(
      "entityGroups/setSingleEntityGroupData",
      {},
      { root: true }
    );
    this.$store.commit(
      "entityGroups/setAllEntityGroupsData",
      { data: [], total: 0, page: 0 },
      { root: true }
    );
    this.$store.commit("entityGroups/setEntityGroupDataAddStatus", false, {
      root: true,
    });
  },
  errorCaptured(error, compInst, errorInfo) {
    console.log("error: ", error);
    console.log("compInst: ", compInst);
    console.log("errorInfo: ", errorInfo);
  },
};
</script>
<style scoped lang="scss">
.search ::v-deep .el-input__inner {
  font-family: var(--font-family);
}
.doc-title {
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #6c757d !important;
}

.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}

.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #f562b3;
  }

  &.deleted::after {
    background: #8b1702;
  }

  &.archived::after {
    background: #404040;
  }

  &.disable::after {
    background: #c4c4c4;
  }

  &.viewed::after {
    background: #2f80ed;
  }
}
.name-types {
  .filename {
    height: 35px;
    margin-top: 5px;
    cursor: pointer;
    background: #ffffff;
    .el-upload-list__item-name {
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    // .delete-doc {
    //   visibility: hidden;
    // }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      // .delete-doc {
      //   visibility: visible;
      //   position: relative;
      //   top: -60%;
      //   left: 97%;
      // }
    }
  }
}
.custom-emails-input {
  margin-top: 0.5rem;
}
.info-button {
  border: none;
  margin-left: 10px;
  padding: 2px;
}
.attachments {
  display: flex;
  justify-content: center;
  margin: 5px;
}
.document {
  width: 180px;
  margin: 10px;
}
.upload-demo {
  margin: 10px;
}
</style>
<style lang="scss">
.top-buttons-wrapper {
  display: flex;
  gap: 10px;
  justify-content: end;
  @media (max-width: 991.98px) {
    display: flex;
    margin-top: 20px;
  }
}
.new-entity-information {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }

    .entity-type-radio {
      @media (max-width: 400px) {
        & > label {
          & > span {
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }
      }
    }
  }
}
.entity-type-box {
  border: 1px solid #ebdfdf;
  width: 50vw;
  margin-right: 1rem;
  border-radius: 3px;
  padding: 10px;
}

.el-tooltip.el-icon-circle-plus-outline::before {
  font-size: 20px;
}

.el-tooltip.el-icon-circle-plus-outline:hover {
  transform: scale(1.03); /* Scale up by 1.03 on hover */
  color: #f754a2; /* Change color to #f754a2 on hover */
}
</style>
<style lang="scss">
.notifying-email .el-dialog__body {
  max-height: 60vh;
  padding: 1em 1.5em;
  overflow-y: auto; /* or scroll */
  overflow-x: hidden;

  /* Reset styles for the webkit scrollbar in el-dialog__body */
  &::-webkit-scrollbar {
    width: initial;
    height: initial;
  }

  &::-webkit-scrollbar-thumb {
    width: initial;
    height: initial;
    border-radius: initial;
    background-clip: initial;
    border: initial;
  }

  /* Add the specific styles for the webkit scrollbar in el-dialog__body */
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 0px;
    border-radius: 10px;
    background-clip: padding-box;
    border: 10px solid rgba(0, 0, 0, 0.28);
  }
}

.vue-data-table-default tbody tr td:first-child {
  padding: 0px 10px;
}

.entity-add-btn {
  height: 30px;
  font-size: 21px;
  font-weight: 10px;
  margin-left: 11px;
  display: flex;
  align-items: center;
}

.data-import-btn {
  border-width: 1px;
  border-radius: 2px;
  background-color: #ffffff;
  border-color: #f754a2;
  border-style: solid;
  font-size: 14px !important;
  &:hover {
    background-color: #f754a2;
    color: #ffffff;
    border-color: #f754a2;
  }
}

.export-import-logo {
  margin-right: 10px;
}

.export-all {
  margin: 0 0.5rem;
}

.el-button:focus,
.el-button:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: white;
}

.popUp-content {
  margin-top: 20px;
}
</style>
<style lang="scss" scoped>
.bt ul {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.el-button--mini {
  padding: 2px 15px !important;
}

.el-icon-circle-plus-outline {
  position: absolute;
  top: 15px;
  right: 6rem;
  color: #409eff;
}
.radio-group {
  margin-right: 10px;
  @media (max-width: 1360px) {
    display: flex;
  }
}
@media (max-width: 1366px) {
  .radio-group {
    justify-content: space-between;
    display: flex;
    margin-right: 10px;
  }
}
.el-input,
.el-textarea {
  display: inline-block;
  width: 98%;
}
p {
  margin-bottom: 0rem;
}
.scrollbar-content {
  scrollbar-width: thin;
  height: 400px;
  overflow-y: auto;
}
.bt .btn-outline-primary {
  color: var(--primary-contrast-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
  &:hover {
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  &:active:focus {
    color: var(--primary-contrast-color) !important;
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 0.2rem var(--primary-color) !important;
  }
}
</style>
