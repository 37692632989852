<template>
    <div :style="getElementStyle">
      <div>
        <div style="display: flex; align-items: center">
          <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
          <label for="text" style="margin-right: 5px; white-space: nowrap"
            >{{ data.label }}
          </label>
          <span v-if="data.description" style="margin-right: 5px">
            <i class="el-icon-info" :title="data.description"></i>
          </span>
          <i
          class="el-icon-copy-document"
          v-if="!isDataTableField"
          @click="copyDocument"
        >
        </i>
        </div>
        <div>
          <span v-if="isActive || isDataTableField" class="setting-icon">
            <i class="el-icon-s-tools" @click="openSettings"></i>
          </span>
        </div>
      </div>
         <div style="background-color: white; border: 1px solid grey;">
        <CustomEntityCalendarWidgets
          :calenderData="[]"
          :selectedEntities="[]"
          :customDashboard="{}"
          :parentDate="new Date()"
          :parentView="'Month'"
          :isFromApplicationuser="false"
          :fromTemplate="true"
        >
        </CustomEntityCalendarWidgets>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import FieldStylesHelper from "@/mixins/FieldStylesHelper";
  
  export default {
    name: "CalenderView",
    components: {
      CustomEntityCalendarWidgets: () =>
        import("@/components/widgets/EntityCalendarWidgets.vue"),
    },
    props: ["data", "value", "isActive", "isDataTableField", "isInDataTable","isIconRequired"],
    mixins: [FieldStylesHelper],
  
    computed: {
      ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    },
  
    data() {
      return {
        validations: [],
        options: [],
        isList: false,
        showLabel: true,
        inputTextAlignmentValue: "left",
      };
    },
    mounted() {},
    methods: {
      copyDocument() {
        this.$emit("copy-document", this.data.key);
      },
      inputTextAlignment() {
        return this.data.styles && this.data.styles.inputTextAlignments
          ? this.data.styles.inputTextAlignments
          : "left";
      },
  
      openSettings() {
        this.$emit("settings");
      },
    },
    watch: {
      // isActive: {
      //   immediate: true,
      //   handler(val, oldVal) {
      //   }
      // },
      "data.styles.inputTextAlignments": {
        handler(newVal) {
          // Whenever inputTextAlignments changes, update the computed property.
          this.inputTextAlignmentValue = newVal;
        },
        immediate: true, // This will trigger the handler immediately when the component is created
      },
    },
  };
  </script>
  
  <style lang="scss"></style>
  
  <style lang="scss" scoped>
  .slv {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 32px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    &:hover {
      border-color: #409eff; /* Change the border color on hover */
    }
    &:focus {
      border-color: #409eff; /* Change the border color when focused */
    }
    &::placeholder {
      color: #ccc; /* Use your desired lighter gray color here */
    }
  }
  </style>
  