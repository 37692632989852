<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="template-sharing-button">
            <el-switch
              style="display: block"
              v-model="field.countryCodeSelector"
              active-color="#13ce66"
              inactive-color="#ff4949"
              inactive-text="Custom"
              active-text="Standard"
              v-on:change="onUpdate"
            ></el-switch>
          </div>
        </el-col>
        <el-col :span="12">
           <div class="vue-phone-validator">
            <VuePhoneNumberInput
              class="vue-phone-validator"
              required
              :default-country-code="field.defaultCountry"
              v-model="field.phoneNo"
              @update="onUpdate"
            /> 
          </div> 
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30">
        <el-col :span="12" class="mt">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" />
        </el-col>
        <el-col :span="10">
          <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-attributes :field="field" />
        </el-col>
      </el-row>
      <div class="mt-1"></div>
    </el-form>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
export default {
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    VuePhoneNumberInput: () => import("vue-phone-number-input"),
    FieldAttributes: () => import("./FieldAttributes"),
  },
  props: ["field"],
  data() {
    return {
      validations: [],
      iconPickerdialogVisible: false,
    };
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
     onUpdate(payload) {
      if (this.field.countryCodeSelector == true) {
        this.standardCode = false;
        this.field.countryDisable = false;
        this.field.countryCallingCode = payload.countryCallingCode;
        this.field.defaultCountry = payload.countryCode;
      } else {
        this.field.defaultCountry = payload.countryCode;
        this.field.countryCallingCode = payload.countryCallingCode;
        this.field.countryDisable = true;
      }
    },
    openSettings() {
      this.$emit("settings");
    },
  },
};
</script>

<style lang="scss">
.mt {
  margin-top: 10px;
}
</style>
