<template>
  <div
    :style="getElementStyle"
    v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        v-if="!data.properties.hideLabel"
        :style="computedStyles"
        class="bottom-space"
      >
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label
          for="text"
          v-if="hasLabel && !data.properties.hideLabel"
          :style="getLabelStyles"
        >
          {{ label || data.label }}
        </label>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="
            color: red;
            font-weight: bold;
            margin-left: 5px;
            margin-right: 5px;
          "
          >*</span
        >
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col
        v-else
        :span="showLabel ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <span
          v-if="hasLabel"
          style="color: white; display: inline-block; height: 20px; opacity: 0"
        ></span>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="
            color: red;
            font-weight: bold;
            margin-left: 5px;
            margin-right: 5px;
          "
          >*</span
        >
      </el-col>
      <!-- <el-col :span="data.description ? 4 : 0">
        <el-popover v-if="data.description" placement="top-start" title="Help" trigger="click"
          :content="data.description">
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
      </el-col> -->
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <el-time-picker
          v-if="
            isList &&
            !isView &&
            data.properties.filed_content != 'Hide' &&
            this.data.properties.time_settings === 'Range'
          "
          prefix-icon="el-icon-time"
          v-model="form[data.key]"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          :picker-options="getPickerOptions()"
          :value-format="'HH:mm:ss'"
          class="eb-ctm-input-time"
        ></el-time-picker>
        <el-time-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          prefix-icon="el-icon-time"
          v-model="form[data.key]"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          :picker-options="{
            format: format,
          }"
          :value-format="format"
          class="eb-ctm-input-time"
        ></el-time-picker>

        <el-time-select
          v-if="
            !isView &&
            !isList &&
            data.properties.filed_content != 'Hide' &&
            data.is_fixed
          "
          v-model="form[data.key]"
          :picker-options="getTimeProperties"
          :readonly="readonly()"
          :placeholder="data.placeholder || data.label"
          :disabled="isFromDocument ? false : checkReadonly()"
          :style="getStyle"
          prefix-icon="el-icon-time"
          class="eb-ctm-input-time"
          :class="{ 'highlighted-border': highlight }"
        >
        </el-time-select>
        <el-time-picker
          v-else-if="
            !isView &&
            !isList &&
            data.properties.filed_content != 'Hide' &&
            this.data.properties.time_settings === 'Range'
          "
          prefix-icon="el-icon-time"
          v-model="form[data.key]"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          :picker-options="getPickerOptions()"
          :value-format="'HH:mm:ss'"
          class="eb-ctm-input-time"
        ></el-time-picker>
        <el-time-picker
          v-else-if="
            !isView && !isList && data.properties.filed_content != 'Hide'
          "
          prefix-icon="el-icon-time"
          v-model="form[data.key]"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          :picker-options="{
            format: format,
          }"
          :value-format="'HH:mm:ss'"
          class="eb-ctm-input-time"
          :class="{ 'highlighted-border': highlight }"
        ></el-time-picker>

        <!-- <el-date-picker
        v-if="isList"
          prefix-icon="el-icon-date"
          v-model="form[this.data.key]" 
          :readonly="true"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker>-->

        <!-- <el-date-picker
        v-if="!isView  && !isList"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker>-->

        <!-- <p v-if="isView" :style="getStyle">
            <template v-if="form[data.key]">{{
              form[data.key] | moment("YYYY-DD-MM HH:mm")
            }}</template>
            <template v-else>-</template>
          </p>
        -->
      </el-col>
    </el-row>
    <!--<div class="form-group">
      <label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
      <el-popover
        v-if="data.description"
        placement="top-start"
        title="Help"
        trigger="click"
        :content="data.description"
      >
        <i class="el-icon-info" slot="reference"></i>
      </el-popover>

      <el-date-picker
        v-if="!isView"
        prefix-icon="el-icon-date"
        v-model="form[data.key]"
        type="date"
          :placeholder="data.placeholder || data.label" 
        :style="getStyle"
        :disabled="checkReadonly()"
      ></el-date-picker>

     
    </div>-->
    <p
      v-if="isView && data.properties.filed_content != 'Hide'"
      :style="getStyle"
    >
      <template v-if="form[data.key]">{{
        getGlobalFormattedTime(form[data.key])
      }}</template>
      <template v-else>-</template>
    </p>
  </div>
  <div v-else>
    <div v-if="isView">{{ getGlobalFormattedTime(form[data.key]) }}</div>
    <div v-else>
      <el-time-picker
        v-if="
          !isView && data.properties.filed_content != 'Hide' && !data.is_fixed
        "
        prefix-icon="el-icon-time"
        v-model="form[data.key]"
        :placeholder="data.placeholder || data.label"
        :style="getStyle"
        :disabled="isFromDocument ? false : checkReadonly()"
        :readonly="readonly()"
        @change="applyFormRules"
        :picker-options="{
          format: 'HH:mm',
        }"
        :value-format="'HH:mm'"
        class="eb-ctm-input-time"
      ></el-time-picker>
      <el-time-select
        v-if="
          !isView && data.properties.filed_content != 'Hide' && data.is_fixed
        "
        v-model="form[data.key]"
        :picker-options="getTimeProperties"
        :readonly="readonly()"
        :placeholder="data.placeholder || data.label"
        :disabled="isFromDocument ? false : checkReadonly()"
        :style="getStyle"
        prefix-icon="el-icon-time"
        class="eb-ctm-input-time"
      >
      </el-time-select>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import FieldsFormatHelper from "../../../mixins/FieldsFormatHelper";

import { mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
  name: "templates-formComponentsExecute-DateExecute",
  mixins: [userPermissionsHelper, FieldsFormatHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "profilePage",
    "checkhideFields",
    "checkIsDisabled",
    "colorFields",
    "highlight",
    "isIconRequired"
  ],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    getStyle() {
      return `height:${this.data.height - 30}px; width: 100%`;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    getTimeProperties() {
      if (
        this.data?.properties?.time_range &&
        this.data?.properties?.step_time
      ) {
        let properties = {};
        properties["start"] = moment(
          this.data?.properties?.time_range[0]
        ).format(this.format);
        properties["end"] = moment(this.data?.properties?.time_range[1]).format(this.format);
        if (
          this.data?.properties?.step_time == 60 ||
          this.data?.properties?.step_time == "60"
        ) {
          properties["step"] = "01:00:00";
        } else {
          properties["step"] = "00:" + this.data?.properties?.step_time + ":00";
        }
        return properties;
      }
      return {
        start: "00:00:00",
        step: "00:30:00",
        end: "23:59:00",
      };
    },
  },
  data() {
    return {
      mappedTimeField: null,
      mappedTimeCurrentvalue: [],
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
      format : null
    };
  },
  mounted() {
    this.loading = true;
    this.AutoFillCurrentTime();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data?.properties?.selected_time_mapped_field) {
      this.mappedTimeField = this.data.properties.selected_time_mapped_field;
    }
    this.format = this.getGlobalTimeFormat();
    this.loading = false;
  },
  methods: {
    isTime(string) {
      var timeRegex = /^\d{2}:\d{2}:\d{2}$/;
      if (timeRegex.test(string)) {
        return true;
      }
      return false;
    },
    timePicker() {
      let utcStartTime, utcEndTime;
      if (this.mappedTimeField && this.mappedTimeCurrentvalue.length) {
        utcStartTime = new Date(this.mappedTimeCurrentvalue[0]);
        utcEndTime = new Date(this.mappedTimeCurrentvalue[1]);
      } else {
        utcStartTime = new Date(this.data.properties.time_start_range);
        utcEndTime = new Date(this.data.properties.time_end_range);
      }

      // Convert UTC to IST
      const istStartTime = utcStartTime.toLocaleTimeString("en-US", {
        timeZone: "Asia/Kolkata",
        hour12: false,
      });
      const istEndTime = utcEndTime.toLocaleTimeString("en-US", {
        timeZone: "Asia/Kolkata",
        hour12: false,
      });

      return {
        startTime: istStartTime,
        endTime: istEndTime,
      };
    },
    getPickerOptions() {
      const additionalOptions = this.timePicker();
      return {
        selectableRange: `${additionalOptions.startTime} - ${additionalOptions.endTime}`,
        format: this.format,
        ...additionalOptions,
      };
    },
    AutoFillCurrentTime() {
      if (
        this.data.properties.autofill_time_response == true &&
        !this.isView &&
        !this.form[this.data.key]
      ) {
        //let localTimezone = 'America/New_York'
        let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let currentTime = moment().tz(localTimezone).format("HH:mm:ss");
        this.$set(this.form, this.data.key, currentTime);
      }
      this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    // async setGlobalVariableData() {
    //   let params = {
    //     id: this.data.global_variable_id
    //   };
    //   await this.$store.dispatch(
    //     "globalVariables/fetchGlobalVariableById",
    //     params
    //   );

    //   if (this.getGlobalVariableById) {
    //     if (this.getGlobalVariableById.input_type == "TIME") {
    //       console.log("input_type", this.getGlobalVariableById);
    //       this.isList = true;
    //       this.$set(this.form, this.data.key, this.getGlobalVariableById.value);
    //       // this.form[this.data.key] = this.getGlobalVariableById.value;
    //     }
    //   }
    // },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "TIME") {
          this.$set(this.form, this.data.key, this.getGlobalVariableById.value);
        }
      }
    },

    readonly() {
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_global_variable
      ) {
        return true;
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }

      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
  },
  watch: {
    form: {
      handler() {
        if (
          this.mappedTimeField &&
          this.form[this.mappedTimeField] != this.mappedTimeCurrentvalue
        ) {
          this.mappedTimeCurrentvalue = this.form[this.mappedTimeField];
        }
      },
      immediate: true,
      deep: true,
    },
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss"></style>
