<template>
  <div :style="getElementStyle">
    <div>
      <el-row style="align-items: center" :style="computedStyles">
        <span style="display: flex;">
        <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label v-if="!isInDataTable" for="text" style="white-space: nowrap; margin-right: 5px">{{
          data.label
        }}</label>
        <i
          class="el-icon-copy-document"
          v-if="!isDataTableField"
          @click="copyDocument"
        >
        </i>
      </span>
        <el-row v-if="isActive || isDataTableField" class="setting-icon">
            <i class="el-icon-s-tools" @click="openSettings"></i>
        </el-row>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <el-select
          v-model="data.default_value"
          placeholder="Select Currency"
          class="currency-select"
        >
          <el-option
            v-for="currency in CurrencyTypes"
            :key="currency.value"
            :label="`${currency.name} (${currency.value})`"
            :value="currency.value"
          />
        </el-select>
      </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencyTypesView",
  props: ["data", "value", "isActive", "isDataTableField", "isInDataTable","isIconRequired"],
  computed: {
    computedStyles() {
      const styles = {};
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getElementStyle() {
      let style = "";
      if (this.data.styles) {
        let borderType = this.data.styles.border_type || "solid";
        let borderSize = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let borderColor = this.data.styles.border_color || "";
        let background = this.data.styles.background || "";
        let labelColor = this.data.styles.label_color || "";

        style += `border: ${borderType} ${borderSize} ${borderColor};`;
        if (background) style += `background: ${background};`;
        if (labelColor) style += `color: ${labelColor};`;
      }
      return style;
    },
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  data() {
    return {
      CurrencyTypes: [
        { value: "USD", name: "$" },
        { value: "CAD", name: "$" },
        { value: "AUD", name: "$" },
        { value: "INR", name: "₹" },
        { value: "EUR", name: "€" },
        { value: "MXN", name: "$" },
        { value: "ZAR", name: "R" },
        { value: "MYR", name: "RM" },
        { value: "GBP", name: "£", },
      ],
      isDefalutPosq: true,
      showLabel: true,
    };
  },
    mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
    }
  },
};
</script>

<style lang="scss">
.currency-display {
  text-align: center;
}
.currency-select{
  display: block;
}
.currency-type {
  color: gray;
}
.setting-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
