<template>
  <div>
    <el-tooltip :content="fieldPlaceholder" placement="top-start">
      <el-select
        v-model="selectedFileds[filter]"
        @change="updateEntityData"
        clearable
        filterable
        multiple
        collapse-tags
        :placeholder="fieldPlaceholder"
        :loading="loading"
        size="mini"
        remote
        :remote-method="searchEntityData"
        loading-text="Loading..."
        @clear="handleClear"
      >
        <el-option
          value="ALL"
          label="All"
          v-if="allEntityDataList && allEntityDataList.length"
        >
        </el-option>
        <el-option
          v-for="(temp, i) in allEntityDataList"
          :key="temp._id + '_' + i"
          :value="temp._id"
          :label="getLabel(temp, i)"
          >{{ getLabel(temp, i) }}
        </el-option>
        <el-option
          value="LOADMORE"
          label="Load more..."
          v-if="!isSearching && hasMore"
        >
        </el-option>
      </el-select>
    </el-tooltip>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { postAPICall } from "../../helpers/httpHelper";
import EntitiesHelper from "../../mixins/EntitiesHelper";
export default {
  props: ["entityId", "fieldPlaceholder", "filter", "selectedFileds", "fromCD"],
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
    ]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
  },
  mixins: [EntitiesHelper],
  watch: {},
  data() {
    return {
      currentEntity: null,
      loading: false,
      allEntityDataList: [],
      selected_filter: "",
      page: 1,
      search_string: "",
      isSearching: false,
      hasMore: false,
      loadMore: false,
    };
  },
  async mounted() {
    this.loading = true;
    if (
      !this.selectedFileds[this.filter] ||
      !this.selectedFileds[this.filter].length
    ) {
      // this.selectedFileds[this.filter] = ["ALL"];
    }
    if(this.selectedFileds[this.filter]){
    this.updateEntityData(this.selectedFileds[this.filter]) 
    }
    await this.fetchEntityById(this.entityId);
  },
  methods: {
    handleClear() {
      this.isSearching = false;
      this.search_string = "";
      this.page = 1;
      this.loading = true;
      this.fetchEntitiesDataForTable(this.entityId);
    },
    searchEntityData(query) {
      if (query) {
        this.isSearching = true;
        if (query.length > 0) {
          this.search_string = query;
          this.page = 1;
          this.loading = true;
          this.fetchEntitiesDataForTable(this.entityId);
        }
      } else {
        this.isSearching = false;
        this.search_string = "";
        this.page = 1;
        this.loading = true;
        this.fetchEntitiesDataForTable(this.entityId);
      }
    },
    updateEntityData(value) {
      if (value.indexOf("LOADMORE") != -1) {
        this.page++;
        this.loadMore = true;
        this.fetchEntitiesDataForTable(this.entityId);
        this.selectedFileds[this.filter].splice(value.indexOf("LOADMORE"), 1);
      } else if (value.indexOf("ALL") != -1 && value.length > 1) {
        let i = value.indexOf("ALL");
        this.selectedFileds[this.filter].splice(i, 1);
        let v = this.selectedFileds[this.filter];
        this.$emit("entityFilterUpdated", {
          v,
          filter: this.filter,
        });
      } else {
        this.$emit("entityFilterUpdated", {
          value,
          filter: this.filter,
        });
      }
    },
    async fetchEntityById(entityId) {
      let response = await postAPICall("GET", `/entities/${entityId}`, {
        entity_id: entityId,
      });
      if (response) {
        this.currentEntity = response.data;
        this.fetchEntitiesDataForTable(entityId);
      } else {
        this.loading = false;
      }
    },
    async fetchEntitiesDataForTable(entityId) {
      this.hasMore = false;
      let params = {
        entity_id: entityId,
        template_fields_data: this.currentEntity.templates.map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: [],
        page: this.page,
        limit: 500,
      };
      if (this.search_string) {
        (params.search_string = this.search_string),
          (params.primary_search_fields = this.getSearchPrimaryFields(
            0,
            this.currentEntity
          ));
      }
      let response = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      if (this.isSearching || !this.loadMore) {
        this.allEntityDataList = [];
      }
      this.loadMore = false;
      // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (response) {
        this.hasMore = response.hasMore;
        this.allEntityDataList = [...this.allEntityDataList, ...response.data];
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name = "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          name =
            item.entityData &&
            primaryField.template_id &&
            item.entityData[primaryField.template_id]
              ? item.entityData[primaryField.template_id][key + "/name"]
                ? item.entityData[primaryField.template_id][key + "/name"]
                : item.entityData[primaryField.template_id][key]
                ? primaryField.input_type == "DATE"
                  ? moment(
                      item.entityData[primaryField.template_id][key]
                    ).format(this.getDefaultDateFormat)
                  : primaryField.input_type == "DATE_TIME"
                  ? moment(
                      item.entityData[primaryField.template_id][
                        primaryField.key
                      ]
                    ).format(this.getDefaultDateFormat + " HH:mm:ss")
                  : item.entityData[primaryField.template_id][key]
                : "data" + i
              : "data" + i;
        }
      }
      return name;
    },
  },
};
</script>
