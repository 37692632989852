import appConfig from '@/config/app';
import axios from 'axios';

const $axios = axios.create({
    baseURL: appConfig.WBA_API_URL,
    timeout: 100000,
});

export async function createInstance(data) {
    try {
        $axios.defaults.withCredentials = true;
        const headers = {
            'Apikey': appConfig.WBA_API_KEY,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
          };
        const response = await  $axios.post(`/instance/create`, data, { headers });
        return response.data;
    }
    catch (error) {
        //console.log(error);
        return error.response.data;
    }
}

export async function generateQR(token,instanceName) {
    try {
        $axios.defaults.withCredentials = true;
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
          };
        const response = await  $axios.get(`/instance/connect/${instanceName}`,{ headers });
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export async function logOutInstance(instanceName) {
    try {
        $axios.defaults.withCredentials = true;
        const headers = {
            'Apikey': appConfig.WBA_API_KEY
          };
        const response = await  $axios.delete(`/instance/logout/${instanceName}`,{headers});
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export async function validateInstance(instanceName) {
    try {
        $axios.defaults.withCredentials = true;
        const headers = {
            'Apikey': appConfig.WBA_API_KEY
          };
        const response = await  $axios.get(`/instance/connectionState/${instanceName}`,{headers});
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export async function connectionStatus(token,instanceName)
{
    try{
        $axios.defaults.withCredentials = true;
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
          };
        const response = await  $axios.get(`/instance/connectionState/${instanceName}`,{ headers });
        return response.data;
    }
    catch(error)
    {
        return error.response.data;
    }
}
